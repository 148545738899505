// @flow
import React from "react";
import { Page,  Grid } from "tabler-react";
import { Button,Alert} from 'react-bootstrap';
import Select from 'react-select';
import BaseLayout from "./BaseLayout";
import "./alert.js";
import "../App.css";
import { EmployeesAPI } from "../apis/EmployeesAPI";

const optionsStatus=[
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' }
];

class EditEmployee extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            DepotList:[],
            validationError: "",
            redirect: false,
            responsemessage:'',
            addstatusclass:'none',
            employeeId:'',
            employeeCode:'', 
            name:'',
            password:'',
            depotid:'',
            depotname:'',
            designation:'',
            role:'',
            status:'',
            fields: {},
            errors: {},
            hidesubmit:'hidesubmitbtn',
            isloading: false,
            showmessage: false,
            isAgentCommissionTextBox:false,
            isAgentCommission:localStorage.getItem('agentCommission'),
            optionsDesignation:[],
            agentCommision:'',
            authkey: localStorage.getItem('authkey')
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }
      
    componentDidMount() {
        let currentComponent = this;
        let conductordata ='';
        if(currentComponent.props.match.params.id !== ''){
            const dbName = "RIDLR AFCS";
            const version = 1; //versions start at 1
            var request = indexedDB.open(dbName, version);
            request.onsuccess = function(event) {
                var db          = event.target.result;
                var depotname;
                var depots      = db.transaction('depot', 'readwrite').objectStore("depot");
                var depotdata  = depots.getAll();
                depots.getAll().onsuccess = function(event) {
                    let DepotListFromInDB = depotdata.result.map(depots => { return { value: depots.id, label: depots.depotname }; });
                    currentComponent.setState({ DepotList: [{ value: '', label: 'Select Depot' }].concat(DepotListFromInDB) });
                };
                var conductor  = db.transaction('conductor', 'readwrite').objectStore("conductor");
                conductordata  = conductor.getAll(currentComponent.props.match.params.id);
                conductor.getAll(currentComponent.props.match.params.id).onsuccess = function(event) {
                    depotdata.result.map(depots => {
                        if(depots.id === conductordata.result[0].depot[0]){
                            depotname = depots.depotname;
                        }
                        return depotname;
                    });
                    if(conductordata.result[0].designation === "AGENT")
                    {
                        currentComponent.state.isAgentCommissionTextBox = true;
                    }
                    currentComponent.setState({ isAgentCommissionTextBox:currentComponent.state.isAgentCommissionTextBox, depotname:depotname, employeeId: conductordata.result[0].employeeId, employeeCode: conductordata.result[0].employeeCode, name: conductordata.result[0].name, depotid: conductordata.result[0].depot[0], designation: conductordata.result[0].designation,agentCommision:conductordata.result[0].commissionPercentage, role: conductordata.result[0].role, status: conductordata.result[0].status});
                };
            };
            currentComponent.state.optionsDesignation = [
                { value: 'CONDUCTOR', label: 'CONDUCTOR' },
                { value: 'DRIVER', label: 'DRIVER' },
                { value: 'INSPECTOR', label: 'INSPECTOR' }
            ];
            if(currentComponent.state.isAgentCommission === "true"){
                var agent ={ value: 'AGENT', label: 'AGENT' };
                currentComponent.state.optionsDesignation.push(agent);
            }
                currentComponent.setState({ optionsDesignation: currentComponent.state.optionsDesignation});
        } else {
            
        }
    }
    
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    
    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {
            case 'employeeCode': {
                let fields = this.state.fields;
                fields[type] =itemValue;
                const re = /^[0-9a-zA-Z\b]+$/;
                if (itemValue !== "" && !re.test(itemValue)) {
                    return false;
                } else {
                    this.setState({ employeeCode: itemValue,fields });
                }
                break;
            }
            case 'name': {
                let fields = this.state.fields;
                fields[type] =itemValue;
                const re = /^[0-9a-zA-Z\b]+$/;
                if (itemValue !== "" && !re.test(itemValue)) {
                    return false;
                } else {
                    this.setState({ name: itemValue,fields });
                }
                break;
            }
            case 'agentCommision':{
                let fields = this.state.fields;
                fields[type] =itemValue;
                this.setState({ agentCommision: itemValue, fields });
                break;
            }
            default:    
        }
    }
    

    handleDismiss() {
        this.setState({ showmessage: false });
    }

    handleShow() {
        this.setState({ showmessage: true });
    }
    
    dropdownvalid(item, type){
      
        switch (type) {
            case 'depotid': {
                let fields = this.state.fields;
                fields[type] =item; 
                this.setState({ depotid: item,fields });
                break
            }
            case 'designation': {
                let fields = this.state.fields;
                fields[type] =item;
                if(item === 'AGENT'){
                    this.setState({isAgentCommissionTextBox: true});
                }else {
                    this.setState({isAgentCommissionTextBox:false});
                } 
                return this.setState({ designation: item });
            }
            case 'status': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ status: item});
            }
           default:    
        }
    }
    
    handleValidation(){
        let errors = {};
        let formIsValid = true;
        if(!this.state.employeeCode){
            formIsValid = false;
            errors["employeeCode"] = "Cannot be empty";
        }
        if(!this.state.name){
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }
        if(!this.state.depotid){
            formIsValid = false;
            errors["depotid"] = "Cannot be empty";
        }
        if(!this.state.designation){
            formIsValid = false;
            errors["designation"] = "Cannot be empty";
        }
        if(this.state.isAgentCommissionTextBox && !this.state.agentCommision){
            formIsValid = false;
            errors["agentCommision"] = "Cannot be empty";
        }
        if(!this.state.status){
            formIsValid = false;
            errors["status"] = "Cannot be empty";
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    
    submitemployee(){
        if(this.handleValidation()){
            this.setState({isloading: true});
            let obj = {};
            let satus = this;
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.employeeCode = this.state.employeeCode;
            obj.name = this.state.name;
            obj.depots = [this.state.depotid];
            obj.designation = this.state.designation;
            obj.role = 'EMPLOYEE';
            obj.status = this.state.status;
            if(this.state.agentCommision !== ""){
                obj.commissionPercentage = this.state.agentCommision;
            }
            EmployeesAPI.putEmployee(this.state.employeeId, obj,
                (response) => {
                    const dbName = "RIDLR AFCS";
                    const version = 1; //versions start at 1
                    var request = indexedDB.open(dbName, version);
                    request.onsuccess = function(event) {
                        var db         = event.target.result;
                        var conductor      = db.transaction('conductor', 'readwrite').objectStore("conductor");
                        var clear =  conductor.clear();
                        clear.onsuccess = function(event) {
                            EmployeesAPI.getEmployees(
                                (response) => {
                                    let ConductorDataFromApi = response.data.data.content.map((conductor) => {
                                        return { employeeId: conductor.employeeId, employeeCode: conductor.employeeCode, name: conductor.name, depot: conductor.depots, designation: conductor.designation, commissionPercentage: conductor.commissionPercentage, role: conductor.role, status: conductor.status };
                                    });
                                    // Store values in the newly created objectStore.
                                    var conductorObjectStore = db.transaction("conductor", "readwrite").objectStore("conductor");
                                    conductorObjectStore.clear();
                                    ConductorDataFromApi.forEach(function (route) {
                                        conductorObjectStore.add(route);
                                    });
                                }
                            );
                        };
                    };
                    satus.setState({
                        isloading: false,
                        showmessage :true,
                        responsemessage:"Employee Details Updated Successfully. "
                    });
                },
                (error) => {
                    satus.setState({
                        isloading: false,
                        showmessage :true,
                        responsemessage: error.message
                    });
                   return;
                }
            );

        }
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        var opts = {};
        if( this.state.readonly=== true ) {
            opts['readOnly'] = 'readOnly';
        }
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="info" className="col-lg-4 m-2" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            );
        }
        
        const valueFromId = (opts, id) => opts.find(o => o.value === id);
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Edit Employee"  />
                    <React.Fragment>
                        <form>
                            {fadeinout} 
                            <Grid.Row md={12} lg={12}>
                                <Grid.Col  lg={6} className="bgdetails">
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Employee Code</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input { ...opts} type="text" className="form-control" value={this.state.employeeCode} maxLength="10"  onChange={(item) => this.valid(item, 'employeeCode')} ></input> 
                                            <span style={{color: "red"}}>{this.state.errors["employeeCode"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Name</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input { ...opts} type="text" className="form-control" value={this.state.name}  onChange={(item) => this.valid(item, 'name')} ></input> 
                                            <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Depot</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <Select options={this.state.DepotList}  value={valueFromId(this.state.DepotList, this.state.depotid,this.state.depotname)}  onChange={(item) => this.dropdownvalid(item.value, 'depotid')}  />
                                            <span style={{color: "red"}}>{this.state.errors["depotid"]}</span> 
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Designation</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <Select options={this.state.optionsDesignation}  value={valueFromId(this.state.optionsDesignation, this.state.designation,this.state.designation)} onChange={(item) => this.dropdownvalid(item.value, 'designation')}  />
                                            <span style={{color: "red"}}>{this.state.errors["designation"]}</span> 
                                        </Grid.Col>
                                    </Grid.Row>
                                    {this.state.isAgentCommissionTextBox &&
                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Commission</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input  type="number" className="form-control" value={this.state.agentCommision}  onChange={(item) => this.valid(item, 'agentCommision')} ></input>
                                            <span style={{color: "red"}}>{this.state.errors["agentCommision"]}</span> 
                                         </Grid.Col>
                                        </Grid.Row>
                                    }
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Employee Status</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <Select options={optionsStatus} value={valueFromId(optionsStatus, this.state.status,this.state.status)} onChange={(item) => this.dropdownvalid(item.value, 'status')}  />
                                            <span style={{color: "red"}}>{this.state.errors["status"]}</span> 
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild" >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull">
                                            <Button type="button"  onClick={() => this.submitemployee()} hidden={this.state.isloadin} className="btn btn-primary" >
                                                Submit
                                            </Button> 
                                            {this.state.isloading &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            <a href="/all-employees"> <Button  type="button" color="secondary mb-2 mr-2"   >Back</Button></a>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                        </form>
                    </React.Fragment>  
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default EditEmployee;