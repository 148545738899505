// @flow
import React from "react";
import { Page,  Grid } from "tabler-react";
import { Alert,Button} from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import Select from 'react-select';
import "./alert.js";
import "../App.css";
import { AgencyAPI } from "../apis/AgencyAPI";
import { PassAPI } from "../apis/PassAPI";
class CreatePass extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            validationError: "",
            redirect: false,
            responsemessage:'',
            addstatusclass:'none',
            passName:'',
            passType:'',
            passSubtype:'DISTANCE_BASED',
            zones:'',
            concessionType:'',
            concessionList :[],
            hidesubmit:'hidesubmitbtn',
            isloading: false,
            fields: {},
            errors: {},
            showmessage: false,
            authkey: localStorage.getItem('authkey')
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);
      
    }   
    handleChange(date) {
        this.setState({
            startDate: date
        });
    
    }
    componentDidMount() {
        let concession = this;
        AgencyAPI.getPassConcession(
            (response) => {
                let deviceIdFromApi = response.data.data.map(device => { return { value: device.id, label: device.name } })
                concession.setState({ concessionList: deviceIdFromApi });
            }
        );
    }
    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {
            case 'passName': {
                let fields = this.state.fields;
                fields[type] =itemValue; 
                return this.setState({ passName: itemValue })
                
            }
            case 'passType': {
                let fields = this.state.fields;
                fields[type] =itemValue; 
                return this.setState({ passType: itemValue })
                
            }
            default:    
        }
    }
    dropdownvalid(item, type){ 
        switch (type) {
            case 'concessionType': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ concessionType: item,fields})
            }
            default:    
        }
    }
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["passName"]){
            formIsValid = false;
            errors["passName"] = "Cannot be empty";
        }
        if(!fields["passType"]){
            formIsValid = false;
            errors["passType"] = "Cannot be empty";
        }
        if(!fields["concessionType"]){
            formIsValid = false;
            errors["concessionType"] = "Cannot be empty";
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    GetDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    handleShow() {
        this.setState({ showmessage: true });
    }
    
    submitpass(){ 
        
        let status = this; 
        if(this.handleValidation()){
            this.setState({isloading: true});
            let obj = {};
            obj.agencyCode      = localStorage.getItem('agencyname');
            obj.passName        = this.state.passName.toUpperCase();
            obj.passType        = this.state.passType.toUpperCase();
            obj.passSubtype     = this.state.passSubtype.toUpperCase();
            obj.concessionType  = this.state.concessionType;
            obj.passProductStatus = 'ACTIVE';

            PassAPI.postProduct(obj,
                (response) => {
                    status.setState({
                        isloading: false,
                        showmessage :true,
                        passName: '',
                        passType: '',
                        zones: '',
                        concessionType: '',
                        responsemessage:"Pass Product Create Successfully. "
                    });
                },
                (error) => {
                    status.setState({
                        isloading: false,
                        showmessage :true,
                        responsemessage: error.message
                    });
                    return;
                }
            );
        }
    }  
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        // const { editFields } = this.state;
        var opts = {};
        if( this.state.readonly=== true ) {
            opts['readOnly'] = 'readOnly';
        }
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="info" className="col-lg-4 m-2" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            )
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Create New Pass"  />
                    <React.Fragment>
                        <form>
                            {fadeinout} 
                            <Grid.Row md={12} lg={12}>  
                                <Grid.Col  lg={6} className="bgdetails">
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Pass Name</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input { ...opts} type="text" className="form-control" value={this.state.passName}  onChange={(item) => this.valid(item, 'passName')} ></input> 
                                            <span style={{color: "red"}}>{this.state.errors["passName"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Pass Type</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <select className="form-control" value={this.state.passType} onChange={(item) => this.valid(item, 'passType')}>
                                             <option value="" selected>Select Pass Type</option>
                                                <option value="GENERAL" >TRIP PASS</option>
                                                <option value="STORED_VALUE" >STORED VALUE PASS</option>
                                            </select>
                                            <span style={{color: "red"}}>{this.state.errors["passType"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Concession Type</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <Select options={this.state.concessionList} onChange={(item) => this.dropdownvalid(item.value, 'concessionType')}  />
                                            <span style={{color: "red"}}>{this.state.errors["concessionType"]}</span>
                                            
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild" >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull">
                                            <Button type="button"  onClick={() => this.submitpass()}  hidden={this.state.isloading}  className="btn btn-primary" >
                                                Submit
                                               
                                            </Button> 
                                            {this.state.isloading &&
                                                    <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                }
                                            <a href="/passproduct-list"> <Button  type="button" color="secondary mb-2 mr-2"   >Back</Button></a>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>       
                            </Grid.Row>
                        </form>
                    </React.Fragment>  
                </Page.Content>
            </BaseLayout>
        );
    }
}
export default CreatePass;