// @flow
import React from "react";
import { Page, Grid } from "tabler-react";
import { Table, Row, Col } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import ReactToPrint from 'react-to-print';
import DateUtils from "../utils/DateUtils";
import "./alert.js";
import "../App.css";
import { AnalyticsAPI } from "../apis/AnalyticsAPI";

class WaybillDetail extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.setState = {
            getwaybillno: '',
            getwabillsearch: false
        };
        var pageURL = props.location.pathname;
        var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
        var getwaybillno = '';
        var getwabillsearch = false;
        if (lastURLSegment !== 'Waybilldetail') {
            getwaybillno = lastURLSegment;
            getwabillsearch = true;
        }
        this.state = {
            getwaybillno: getwaybillno,
            getwabillsearch: getwabillsearch
        };
    }

    componentWillUpdate(nextProps, nextState) {
        var pageURL = nextProps.location.pathname;
        var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
        var getwaybillno = '';
        var getwabillsearch = false;
        if (lastURLSegment !== 'Waybilldetail') {
            getwaybillno = lastURLSegment;
            getwabillsearch = true;
        }
        nextState = {
            getwaybillno: getwaybillno,
            getwabillsearch: getwabillsearch
        };
    }
    render() {
        return (
            <BaseLayout>
                <Page.Content>
                    <React.Fragment>
                        <div>
                            <ReactToPrint trigger={() => <button className="btn btn-primery printbutton">Print To Pdf</button>}
                                content={() => this.componentRef}
                            />
                            <WaybillDetailpdf ref={el => (this.componentRef = el)} waybillId={this.state.getwaybillno} waybillSearch={this.state.getwabillsearch} />
                        </div>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        )
    }
}

class WaybillDetailpdf extends React.Component {

    constructor(props, context) {

        super(props, context);

        this.state = {
            waybillno: '',
            showmessage: false,
            fields: {},
            errors: {},
            isLoader: false,
            summarydata: [],
            etmno: '',
            etmdate: '',
            totalticketissue: 0,
            showwaybillreport: false,
            authkey: localStorage.getItem('authkey'),
            totalExpenses: 0,
            adultAmount: 0,
            childAmount: 0,
            luggageAmount: 0,
            concessionAmount: 0,
            conductorName: '',
            vehicleNo: '',
            driverName: '',
            penltyAmount: 0,
            rechargeAmount: 0,
            adultPassenger: 0,
            childPassenger: 0,
            luggageTicket: 0,
            concessionPassenger: 0,
            epurseRecharge:0,
            epursePassaenger: 0,
            monthlyPass: 0,
            otherPass: 0,
            totalConductorBookingAmount: 0,
            totalnumofpassenger: 0,
            epurserfidcardticket: 0,
            policewarrant: 0,
            windowBooking: 0,
            totalbookingamount: 0,
            conductorbooking: 0,
            onlineBooking: 0,
            expencessC: 0,
            grossamtAplusB: 0,
            amtRemitted: 0,
            netAmtABC: 0,
            RemintAmtAC: 0,
            EPKM: 0,
            tollgate: 0,
            onlineExpenseC: 0,
            busStantFeeC: 0,
            deiselTicket: 0,
            hotelExpense: 0,
            refundTicket: 0,
            agentCommissionAmount:0,
            totalAmtExpense: 0,
            driverBata: 0,
            conductorBata: 0,
            ticketCheckerBata: 0,
            timeKeeperCharge: 0,
            cleanerBata: 0,
            unionCharge: 0,
            poojaExpense: 0,
            otherExpense: 0
            

        }
        //this.handleDismiss = this.handleDismiss.bind(this);
    }
    valid(item, type) {
        let itemValue = item.target.value;

        switch (type) {
            case 'waybillno': {
                this.setState({ waybillno: itemValue })
                break
            }

            default:
        }
    }

    componentDidMount() {
        if (this.props.waybillSearch === true) {
            this.submitWaybill();
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (this.props.waybillSearch === false) {
            if (!this.state.waybillno) {
                formIsValid = false;
                errors["waybillID"] = "Cannot be empty";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    submitWaybill() {
        let currentComponent = this;

        if (this.handleValidation()) {
            let waybillnumber;
            if (this.props.waybillSearch === true) {
                waybillnumber = this.props.waybillId;
            } else {
                waybillnumber = currentComponent.state.waybillno;
            }
            currentComponent.setState({ isLoader: true, showwaybillreport: true });
            AnalyticsAPI.getReportsWaybill(waybillnumber,
                (response) => {
                    let kms = 0;
                    let totalticketsissue = 0;
                    // eslint-disable-next-line 
                    response.data.data.report.data.map(obj => {
                        kms += parseFloat(obj.totalKms);
                        totalticketsissue += parseFloat(obj.totalTickets);
                    });
                    
                    let depotName = "";
                    let adultTicketAmount = 0;
                    let totalAdultTickets = 0;
                    let childTicketAmount = 0;
                    let totalChildTickets = 0;
                    let luggageAmount = 0;
                    let totalLuggageTickets = 0;
                    let concessionAmount = 0;
                    let totalConcessionTickets = 0;
                    let penaltyAmount = 0;
                    let totalEpurseTapTickets = 0;
                    let totalEpurseReloadTickets = 0;
                    let rechargeAmount = 0;
                    let totalMonthlyPassTickets = 0;
                    let totalOtherTickets = 0;
                    let totalConductorBookingAmount = 0;
                    let totalPassengerTickets = 0;
                    let epurseAmount = 0;
                    let policeWarrantAmount = 0;
                    let totalNonConductorBookingAmount = 0;
                    let windowBookingAmount = 0;
                    let totalExpenses = 0;
                    let tollAmount = 0;
                    let onlineExpenseAmount = 0;
                    let busStandFeeAmount = 0;
                    let dieselAmount = 0;
                    let hotelExpenseAmount = 0;
                    let refundTicketAmount = 0;
                    let agentCommissionAmount = 0;
                    let driverBataAmount = 0;
                    let conductorBataAmount = 0;
                    let ticketCheckerBataAmount = 0;
                    let timeKeeperChargeAmount = 0;
                    let cleanerBataAmount = 0;
                    let unionChargeAmount = 0;
                    let poojaExpenseAmount = 0;
                    let otherExpenseAmount = 0;
        
                    response.data.data.summary.data.map(obj => {
                        depotName  = depotName + obj.depotName + ", ";
                        adultTicketAmount = adultTicketAmount + obj.adultTicketAmount;
                        totalAdultTickets = totalAdultTickets + obj.totalAdultTickets;
                        childTicketAmount = childTicketAmount + obj.childTicketAmount;
                        totalChildTickets = totalChildTickets + obj.totalChildTickets;
                        luggageAmount = luggageAmount + obj.luggageAmount;
                        totalLuggageTickets = totalLuggageTickets + obj.totalLuggageTickets;
                        concessionAmount = concessionAmount + obj.concessionAmount;
                        totalConcessionTickets = totalConcessionTickets + obj.totalConcessionTickets;
                        penaltyAmount = penaltyAmount + obj.penaltyAmount;
                        totalEpurseTapTickets = totalEpurseTapTickets + obj.totalEpurseTapTickets;
                        totalEpurseReloadTickets = totalEpurseReloadTickets + obj.totalEpurseReloadTickets;
                        rechargeAmount = rechargeAmount + obj.rechargeAmount;
                        totalMonthlyPassTickets = totalMonthlyPassTickets + obj.totalMonthlyPassTickets;
                        totalOtherTickets = totalOtherTickets + obj.totalOtherTickets;
                        totalConductorBookingAmount = totalConductorBookingAmount + obj.totalConductorBookingAmount;
                        totalPassengerTickets = totalPassengerTickets + obj.totalPassengerTickets;
                        epurseAmount = epurseAmount + obj.epurseAmount;
                        policeWarrantAmount = policeWarrantAmount +  obj.policeWarrantAmount;
                        totalNonConductorBookingAmount = totalNonConductorBookingAmount + obj.totalNonConductorBookingAmount;
                        windowBookingAmount =  windowBookingAmount + obj.windowBookingAmount;
                        totalExpenses = totalExpenses + obj.totalExpenses;
                        tollAmount = tollAmount + obj.tollAmount;
                        onlineExpenseAmount = onlineExpenseAmount + obj.onlineExpenseAmount;
                        busStandFeeAmount = busStandFeeAmount + obj.busStandFeeAmount;
                        dieselAmount = dieselAmount + obj.dieselAmount;
                        hotelExpenseAmount = hotelExpenseAmount + obj.hotelExpenseAmount;
                        refundTicketAmount = refundTicketAmount + obj.refundTicketAmount;
                        agentCommissionAmount = agentCommissionAmount + obj.agentCommissionAmount;
                        driverBataAmount = driverBataAmount +  obj.driverBataAmount;
                        conductorBataAmount = conductorBataAmount +  obj.conductorBataAmount;
                        ticketCheckerBataAmount = ticketCheckerBataAmount +  obj.ticketCheckerBataAmount;
                        timeKeeperChargeAmount = timeKeeperChargeAmount +  obj.timeKeeperChargeAmount;
                        cleanerBataAmount = cleanerBataAmount +  obj.cleanerBataAmount;
                        unionChargeAmount = unionChargeAmount +  obj.unionChargeAmount;
                        poojaExpenseAmount = poojaExpenseAmount +  obj.poojaExpenseAmount;
                        otherExpenseAmount = otherExpenseAmount +  obj.otherExpenseAmount;
                        return "";
                    });
                    depotName = depotName.substr(0, depotName.length - 2);
                    

                    currentComponent.setState({
                        totalticketissue: totalticketsissue,
                        depotName: depotName,
                        VehicleList: response.data.data,
                        etmdate: response.data.data.summary.data[0].dutyDate,
                        dutyStartTime: response.data.data.summary.data[0].dutyStartTime,
                        dutyEndTime: response.data.data.summary.data[0].dutyEndTime,
                        etmno: response.data.data.summary.data[0].etim,
                        waybillno: response.data.data.summary.data[0].waybillNo,
                        conductorName: response.data.data.summary.data[0].conductorName,
                        vehicleNo: response.data.data.summary.data[0].vehicleNo,
                        driverName: response.data.data.summary.data[0].driverName,
                        adultAmount: adultTicketAmount,
                        adultPassenger: totalAdultTickets,
                        childAmount: childTicketAmount,
                        childPassenger: totalChildTickets,
                        luggageAmount: luggageAmount,
                        luggageTicket: totalLuggageTickets,
                        concessionAmount: concessionAmount,
                        concessionPassenger: totalConcessionTickets,
                        penltyAmount: penaltyAmount,
                        epursePassaenger: totalEpurseTapTickets,
                        epurseRecharge: totalEpurseReloadTickets,
                        rechargeAmount: rechargeAmount,
                        monthlyPass: totalMonthlyPassTickets,
                        otherPass: totalOtherTickets,
                        totalConductorBookingAmount: totalConductorBookingAmount,
                        totalnumofpassenger: totalPassengerTickets,
                        epurserfidcardticket: epurseAmount,
                        conductorbooking: totalConductorBookingAmount,
                        policewarrant: policeWarrantAmount,
                        onlineBooking: totalNonConductorBookingAmount,
                        windowBooking: windowBookingAmount,
                        expencessC: totalExpenses,
                        totalbookingamount: totalNonConductorBookingAmount,
                        grossamtAplusB: totalConductorBookingAmount + totalNonConductorBookingAmount,
                        amtRemitted: (totalConductorBookingAmount - totalExpenses),
                        netAmtABC: (totalConductorBookingAmount + totalNonConductorBookingAmount) - totalExpenses,
                        tollgate: tollAmount,
                        EPKM: (totalConductorBookingAmount + totalNonConductorBookingAmount) - totalExpenses / kms,
                        onlineExpenseC: onlineExpenseAmount,
                        finaltotalkms: kms,
                        busStantFeeC: busStandFeeAmount,
                        deiselTicket: dieselAmount,
                        hotelExpense: hotelExpenseAmount,
                        refundTicket: refundTicketAmount,
                        agentCommissionAmount: agentCommissionAmount,
                        totalAmtExpense: totalExpenses,
                        fetchInProgress: false,
                        RemintAmtAC: totalExpenses,
                        summarydata: response.data.data.report.data,
                        pageCount: Math.ceil(response.data.data.length / 20),
                        driverBata: driverBataAmount,
                        conductorBata: conductorBataAmount,
                        ticketCheckerBata: ticketCheckerBataAmount,
                        timeKeeperCharge: timeKeeperChargeAmount,
                        cleanerBata: cleanerBataAmount,
                        unionCharge: unionChargeAmount,
                        poojaExpense: poojaExpenseAmount,
                        otherExpense: otherExpenseAmount,
                        isLoader: false
                    });
                },
                (error) => {
                    currentComponent.setState({ isLoader: false });
                }
            );
        }
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let waybillhtml;
        let searchwaybill;
        if (this.props.waybillSearch === false) {
            searchwaybill = (
                <Grid>
                    <Row className="show-grid print-only">
                        <Col xs={6} md={6}>
                            <div className="row gutters-xs">
                                <div className="form-group ">
                                    <label className="form-label">Waybill Number<span className="form-required">*</span></label>
                                    <input type="text" className="form-control" value={this.state.waybillno} onChange={(item) => this.valid(item, 'waybillno')} placeholder="Waybill Number"></input>
                                    <span style={{ color: "red" }}>{this.state.errors["waybillID"]}</span>

                                </div>
                                <div className="form-group">
                                    <button type="button" onClick={() => this.submitWaybill()} className="btn btn-primary m-5">Search
                                            {this.state.isLoader &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </button>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Grid>
            )
        }

        if (this.state.showwaybillreport) {
            waybillhtml = (
                <div className="A4size card">
                    <Table className="table borderwaybilltable ">
                        <tbody>
                            <tr>

                                <td colSpan="4" className="borderwaybilltable text-center"> <h4>Waybill Cum Trip Report</h4>   </td>

                            </tr>
                            <tr>

                                <td colSpan="4" className="borderwaybilltable text-center"> <h4>{localStorage.getItem('agencydisplayname')}</h4>   </td>

                            </tr>
                            <tr>
                                <td className="borderwaybilltable text-center"> <label className="m-2">Depot Name : {this.state.depotName}</label></td>
                                <td className="borderwaybilltable text-center"><label className="m-2">Conductor Name. : {this.state.conductorName}</label></td>
                                <td className="borderwaybilltable text-center"> <label className="m-2"> Driver Name: {this.state.driverName}</label>  </td>
                                <td className="borderwaybilltable text-center"> <label className="m-2"> Vehicle No : {this.state.vehicleNo}</label>  </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table className="table borderwaybilltable ">
                        <tbody>
                            <tr>


                                <td className="borderwaybilltable text-center"> <label className="m-2">ETM NO : {this.state.etmno}</label></td>
                                <td className="borderwaybilltable text-center"><label className="m-2">Waybill No. : {this.state.waybillno}</label></td>
                                <td className="borderwaybilltable text-center"> <label className="m-2"> Duty Date : {this.state.etmdate}</label>  </td>
                                <td className="borderwaybilltable text-center"> <label className="m-2"> Start Duty : {DateUtils.getFormattedDateTimeFromTimestamp(this.state.dutyStartTime)}</label>  </td>
                                <td className="borderwaybilltable text-center"> <label className="m-2"> End Duty : {DateUtils.getFormattedDateTimeFromTimestamp(this.state.dutyEndTime)}</label>  </td>

                            </tr>
                        </tbody>
                    </Table>

                    <Table className="table borderwaybilltable ">
                        <tbody>
                            <tr>

                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>A. Conductor Booking Details</h4></label></td>
                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>Amount</h4></label></td>
                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>Classification of Categories</h4></label></td>
                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>Count</h4></label></td>


                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Adult Amount</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.adultAmount}
                                </td>
                                <td className="borderwaybilltable text-center">Adult Passengers</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.adultPassenger}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Child Amount</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.childAmount}
                                </td>
                                <td className="borderwaybilltable text-center">Child Passengers</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.childPassenger}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Luggage Amount</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.luggageAmount}
                                </td>
                                <td className="borderwaybilltable text-center">Luggage Tickets</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.luggageTicket}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Concession Amount </td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.concessionAmount}
                                </td>
                                <td className="borderwaybilltable text-center">Concession Passengers</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.concessionPassenger}
                                </td>

                            </tr>
                            <tr>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">
                                &nbsp;
                                </td>
                                <td className="borderwaybilltable text-center">E-purse Recharge Count</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.epurseRecharge}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Penalty Amount</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.penltyAmount}
                                </td>
                                <td className="borderwaybilltable text-center">E-purse Passengers</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.epursePassaenger}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Recharge Amount</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.rechargeAmount}
                                </td>
                                <td className="borderwaybilltable text-center">Monthly pass</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.monthlyPass}
                                </td>

                            </tr>
                            <tr>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">Other Passengers(Police,Window etc)</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.otherPass}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center totalofwaybill">Total  </td>
                                <td className="borderwaybilltable text-center totalofwaybill">
                                    {this.state.totalConductorBookingAmount}
                                </td>
                                <td className="borderwaybilltable text-center totalofwaybill">Total Number of passengers</td>
                                <td className="borderwaybilltable text-center totalofwaybill">
                                    {this.state.totalnumofpassenger}
                                </td>

                            </tr>

                        </tbody>
                    </Table>
                    <Table className="table borderwaybilltable ">
                        <tbody>
                            <tr>

                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>B. DSA Booking Details</h4></label></td>
                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>Amount</h4></label></td>
                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>Collection Summary</h4></label></td>
                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>Amount</h4></label></td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Epurse (RFID Card Ticket)</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.epurserfidcardticket}
                                </td>
                                <td className="borderwaybilltable text-center">Conductor Booking (A)</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.conductorbooking}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Police Warrant</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.policewarrant}
                                </td>
                                <td className="borderwaybilltable text-center">Online Booking (B)</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.onlineBooking}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Window Booking</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.windowBooking}
                                </td>
                                <td className="borderwaybilltable text-center">Expenses (C)</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.expencessC}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center totalofwaybill totalofwaybill">Total Amount</td>
                                <td className="borderwaybilltable text-center totalofwaybill ">
                                    {this.state.totalbookingamount}
                                </td>
                                <td className="borderwaybilltable text-center ">Gross Amount(A+B)</td>
                                <td className="borderwaybilltable text-center ">
                                    {this.state.grossamtAplusB}
                                </td>

                            </tr>
                            <tr>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">
                                    -
                                            </td>
                                <td className="borderwaybilltable text-center ">Amount Remint (A-C)</td>
                                <td className="borderwaybilltable text-center ">
                                    {this.state.amtRemitted}
                                </td>

                            </tr>
                            <tr>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">
                                    -
                                            </td>
                                <td className="borderwaybilltable text-center totalofwaybill">Net Amount(A+B-C)</td>
                                <td className="borderwaybilltable text-center totalofwaybill">
                                    {this.state.netAmtABC}
                                </td>

                            </tr>
                        </tbody>
                    </Table>
                    <Table className="table borderwaybilltable ">
                        <tbody>
                            <tr>

                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>C. Expenses</h4></label></td>
                                <td className="borderwaybilltable text-center" ><label className="m-2"><h4>Amount</h4></label></td>
                                <td colSpan="2" className="bordernone text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Toll Gate</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.tollgate}
                                </td>
                                <td className="borderwaybilltable text-center">EPKM</td>
                                <td className="borderwaybilltable text-center">
                                    {Number(this.state.netAmtABC / this.state.finaltotalkms).toFixed(2)}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Online Expenses</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.onlineExpenseC}
                                </td>
                                <td className="borderwaybilltable text-center">Total KMs</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.finaltotalkms}</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Bus Stand Fee</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.busStantFeeC}
                                </td>
                                <td className="borderwaybilltable text-center totalofwaybill">Total Tickets Issued</td>
                                <td className="borderwaybilltable text-center totalofwaybill">
                                    {this.state.totalticketissue}
                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Diesel Ticket</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.deiselTicket}
                                </td>
                                <td className="borderwaybilltable text-center">  &nbsp; </td>
                                <td className="borderwaybilltable text-center">

                                </td>

                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Hotel Expenses</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.hotelExpense}
                                </td>
                                <td className="borderwaybilltable text-center"> &nbsp;</td>
                                <td className="borderwaybilltable text-center">
                                </td>


                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">Refund Ticket</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.refundTicket}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">AGENT COMMISSION</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.agentCommissionAmount}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">DRIVER BATA</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.driverBata}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">CONDUCTOR BATA</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.conductorBata}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">TICKET CHECKER BATA</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.ticketCheckerBata}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">TIME KEEPER CHARGE</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.timeKeeperCharge}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">CLEANER BATA</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.cleanerBata}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">UNION CHARGE</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.unionCharge}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">POOJA EXPENSE</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.poojaExpense}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center">OTHER EXPENSE</td>
                                <td className="borderwaybilltable text-center">
                                    {this.state.otherExpense}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                            </tr>
                            <tr>

                                <td className="borderwaybilltable text-center totalofwaybill">Total Amount</td>
                                <td className="borderwaybilltable text-center totalofwaybill">
                                    {this.state.totalAmtExpense}
                                </td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>
                                <td className="borderwaybilltable text-center">&nbsp;</td>

                            </tr>

                            <tr>

                                <td colSpan="4" className="borderwaybilltable text-center"></td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table >
                        <tbody>
                            <tr>
                                <td className="borderwaybilltable text-center">Date</td>
                                <td className="borderwaybilltable text-center">Trip No</td>
                                <td className="borderwaybilltable text-center">Route Name</td>
                                <td className="borderwaybilltable text-center">Start Stage</td>
                                <td className="borderwaybilltable text-center">End Stage</td>
                                <td className="borderwaybilltable text-center">Cond Amt</td>
                                <td className="borderwaybilltable text-center">Exp</td>
                                <td className="borderwaybilltable text-center">Total Tickets</td>
                                <td className="borderwaybilltable text-center">Total Passengers</td>
                                <td className="borderwaybilltable text-center">Total GJ</td>
                            </tr>
                            {
                                this.state.summarydata.map(function (obj, i) {

                                    return (
                                        <tr key={i}>
                                            <td className="borderwaybilltable text-center">{obj.dutyDate}</td>
                                            <td className="borderwaybilltable text-center">{obj.tripNo}</td>
                                            <td className="borderwaybilltable text-center">{obj.routeName}</td>
                                            <td className="borderwaybilltable text-center">{obj.startStage}</td>
                                            <td className="borderwaybilltable text-center">{obj.endStage}</td>
                                            <td className="borderwaybilltable text-center">{obj.condAmount}</td>
                                            <td className="borderwaybilltable text-center">{obj.exp}</td>
                                            <td className="borderwaybilltable text-center">{obj.totalTickets}</td>
                                            <td className="borderwaybilltable text-center">{obj.totalPassengers}</td>
                                            <td className="borderwaybilltable text-center">{obj.totalGj}</td>
                                        </tr>
                                    );
                                })
                            }

                            <tr><td colSpan="10" className="text-right bordertopnone">&nbsp;</td></tr>
                            <tr>
                                <td colSpan="10" className="text-right bordertopnone">Signature  : __________________________</td>
                            </tr>
                        </tbody>
                    </Table>

                </div>
            )
        } else {
            waybillhtml = (
                <h4>Please search waybill number..</h4>
            )
        }
        return (
            <div>
                <h2 className="print-only" >Waybill Cum Trip Report  </h2>
                {searchwaybill}
                {waybillhtml}
            </div>


        );

    }
}

export default WaybillDetail;