// @flow
import React from "react";
import { Page } from "tabler-react";
import { Alert,Button } from 'react-bootstrap';
import Select from 'react-select';
import "./alert.js";
import "../App.css";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI.js";
import SessionUtils from "../utils/SessionUtils.js";

const objFromId = (opts, id) => opts.find(o => o.id === id);
class Luggage extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            rows: [],
            validationError: "",
            name:'',
            RoutetypeList:[],
            routetype:'',
            routetypename:'',
            showmessage: false,
            fields: {},
            errors: {},
            products: [],
            fareRules:{},
            responsemessage :'',
            authkey: localStorage.getItem('authkey'),
            item:{
                id      : '',
                startkm : '',
                endkm   : '',
                fare    : ''
            },
            isloading:false,
            routeTypeList: []
        };
        this.state.filterText = "";
        this.state.products = [];
        this.handleDismiss = this.handleDismiss.bind(this);
    }
   
    componentDidMount(){
        let satus = this;
        AgencyAPI.getRouteType(
            (response) => {
                satus.setState({
                    fetchInProgress: false
                });
                let routeTypeList = [];
                let RoutetypeListFromDB = response.data.data.map((routetype, index) => {
                    routeTypeList.push(routetype);
                    if(index === 0){
                        satus.setState({ routetype: routetype.id, routetypename: routetype.name });
                    }
                    return {
                        value: routetype.id,
                        label: routetype.name
                    };
                });
                satus.setState({ routeTypeList: routeTypeList, RoutetypeList: [{ value: '', label: 'Select Route Type' }].concat(RoutetypeListFromDB) });
                satus.state.fields["routetype"] = satus.state.routetype;
                satus.state.fields["routetypename"] = satus.state.routetypename;
                satus.state.products = [];
                satus.fetchFareApi(satus.state.routetypename, satus.state.routetype);
            },
            (error) => {
                satus.setState({ isloading: false  });
            }
        );
    }
    
    fetchFareApi(routetypename, routeType){
        let satus = this;
        let selectedRouteType = objFromId(this.state.routeTypeList, routeType, routeType);
        AgencyAPI.getFareRulesLuggage(selectedRouteType.agencyCode + "/" + routetypename,
            (response) => {
                satus.fetchFareRules(response.data.data.fareRules);
            }
        );
    }
  
    fetchFareRules(faredata){
        let item;
        // eslint-disable-next-line
        this.state.products = [];
        for (var i=0; i < faredata.length; i++) {
            var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
            item={
                id      : id,
                startkm : faredata[i].minKm,
                endkm   : faredata[i].maxKm,
                fare    : faredata[i].fare
            };
            this.state.products.push(item);
        }
        return this.setState({products: this.state.products});
    }
    
    submitluggage(){
        if(this.handleValidation()){
            this.setState({isloading: true});
            let item;
            // eslint-disable-next-line
            this.state.rows = [];
            this.state.products.map(element => {
                //Set data in array wchich api want
                item = {
                    fare :  parseFloat(element.fare),
                    minKm : parseFloat(element.startkm),
                    maxKm : parseFloat(element.endkm)
                };
                return this.state.rows.push(item);
            });
            let obj = {};
            let satus = this;
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.routeType = this.state.routetypename;
            obj.fareRules = this.state.rows;
            //Create Luggage
            AgencyAPI.postFaresRulesLuggage(obj,
                (response) => {
                    satus.setState({
                        showmessage: true,
                        isloading: false,
                        fareRules:response.data.data.fareRules,
                        responsemessage:'Luggage Fare Rules Updated!'
                    });
                },
                (error) => {
                    satus.setState({
                        showmessage: true,
                        isloading: false,
                        fareRules:[],
                        responsemessage:error.message
                    });
                    return;
                }
            );
        }
        return;
    }
    
    dropdownvalid(item, label, type){
        let fields = this.state.fields;
        fields[type] = item;
        this.setState({ routetype: item, routetypename: label, products: [] });
        this.fetchFareApi(label, item);
        return;
    }
    
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["routetypename"]){
            formIsValid = false;
            errors["routetypename"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(Object.keys(this.state.errors).length !== 0){
            if(this.state.errors["startkm"] !== "" || this.state.errors["endkm"] !== "" || this.state.errors["fare"] !== ""){
                formIsValid = false;
            }
        }
        return formIsValid;
    }

    handleUserInput(filterText) {
        this.setState({filterText: filterText});
    };
    
    handleRowDel(product) {
        var index = this.state.products.indexOf(product);
        this.state.products.splice(index, 1);
        this.setState({products: this.state.products});
    };
    
    handleAddEvent(evt) {
        let product;
        let km = 0;
        let startkm = 0;
        let endkm = 0;
        let fare = "";
        let prevfare = 0;
        let errors = {};
        if(this.handleValidation()){
            errors["id"] = "";
            errors["startkm"] = "";
            errors["endkm"] = "";
            errors["fare"] = "";
            var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
            if(this.state.products.length === 0){
                km = 0;
                fare = "";
                errors["id"] = id;
            } else {
                var val = this.state.products[this.state.products.length - 1];
                km = val.endkm;
                startkm = parseInt(val.startkm, 10);
                endkm = parseInt(val.endkm, 10);
                if(val.fare !== "" && val.fare !== undefined){
                    fare = parseInt(val.fare, 10);
                }
                if(val.prevfare !== undefined){
                    prevfare = parseInt(val.prevfare, 10);
                }
                errors["id"] = val.id;
            }
            if(km !== ""){
                product = {
                    id: id,
                    startkm: parseInt(km, 10),
                    endkm: "",
                    fare: "",
                    prevfare: fare
                };
                if(km !== 0 && fare === ""){
                    errors["fare"] = "Cannot be empty";
                } else {
                    if(endkm !== 0 && endkm <= startkm){
                        errors["endkm"] = "Should be greater than Start Km";
                    } else if(fare !== "" && fare <= prevfare){
                        errors["fare"] = "Should be greater than Previous Fare Rule";
                    } else {
                        this.state.products.push(product);
                    }
                }
            } else {
                errors["endkm"] = "Cannot be empty";
            }
            this.setState({products: this.state.products, errors: errors});
        }
    }
    
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    
    handleProductTable(evt) {
        var item;
        item = {
            id: evt.target.id,
            name: evt.target.name,
            value: evt.target.value
        };
        let errors = {};
        errors["id"] = item.id;
        errors["startkm"] = "";
        errors["endkm"] = "";
        errors["fare"] = "";
        const re = /^[0-9\b]+$/;
        if (evt.target.value !== "" && !re.test(evt.target.value)) {
            // Filter non-digits from input value.
            return false;
        } else {
            var products = this.state.products.slice();
            var newProducts = products.map((product, index) => {
                for (var key in product) {
                    if (key === item.name && product.id === item.id) {
                        if(item.value !== ""){
                            item.value = parseInt(item.value, 10);
                        }
                        product[key] =  item.value;
                        if(product["endkm"] <= product["startkm"]){
                            errors["endkm"] = "Should be greater than Start Km";
                        }
                        var previous_product = this.state.products[index - 1];
                        var next_product = this.state.products[index + 1];
                        if(previous_product !== undefined){
                            if(product["fare"] <= previous_product["fare"]){
                                errors["fare"] = "Should be greater than Previous Fare Rule";
                            }
                        }
                        if(next_product !== undefined){
                            next_product["startkm"] = product["endkm"];
                            if(next_product["endkm"] <= next_product["startkm"]){
                                errors["id"] = next_product["id"];
                                errors["endkm"] = "Should be greater than Start Km";
                            }
                            if(product["fare"] >= next_product["fare"]){
                                errors["fare"] = "Should be less than Next Fare Rule";
                            }
                        }
                    }
                }
                return product;
            });
            this.setState({products:newProducts, errors: errors});
        }
    };
      
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        const valueFromId = (opts, id) => opts.find(o => o.value === id);
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                  <div>
                    <h4>{this.state.responsemessage}</h4>
                    <a href="/Luggage"><Button className="btn-primary">View</Button></a>
                        <span> or </span>
                    <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                    </div>
                </Alert>
            )
        }
        return (
            <BaseLayout> 
                <Page.Content>
                    {fadeinout}
                    <Page.Header title="Luggage Fare Rules Setup" />
                    <React.Suspense fallback={<div>Loader...</div>}>
                        <div className="card">
                            <div className="card-footer col-md-4">
                                <label className="form-label">Select Route Type<span className="form-required">*</span></label>
                                <Select options={this.state.RoutetypeList} value={valueFromId(this.state.RoutetypeList, this.state.routetype, this.state.routetypename)} onChange={(item) => this.dropdownvalid(item.value, item.label, 'routetypename')}  />
                                <span style={{color: "red"}}>{this.state.errors["routetypename"]}</span>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                  <ProductTable errors={this.state.errors} onProductTableUpdate={this.handleProductTable.bind(this)} onRowAdd={this.handleAddEvent.bind(this)} onRowDel={this.handleRowDel.bind(this)} products={this.state.products} filterText={this.state.filterText}/>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                            {!SessionUtils.isChildAgency() && <button type="button" onClick={() => this.submitluggage()}  hidden={this.state.isloading}  className="btn btn-primary">Save & Update
                                {this.state.isloading &&
                                    <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                }
                              </button>}
                            </div>
                        </div>
                    </React.Suspense>  
                </Page.Content>
            </BaseLayout>
        );
    }
}

class ProductTable extends React.Component {

    render() {
        var onProductTableUpdate = this.props.onProductTableUpdate;
        var rowDel = this.props.onRowDel;
        var errors = this.props.errors;
        var product = this.props.products.map(function(product) {
            return (<ProductRow onProductTableUpdate={onProductTableUpdate} product={product} onDelEvent={rowDel.bind(this)} errors={errors} key={product.id}/>);
        });
        return (
            <div>
                <table  className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Start KM</th>
                            <th>End KM </th>
                            <th>Fare</th>
                            {!SessionUtils.isChildAgency() && <th>Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {product}
                    </tbody>
                </table>
                {!SessionUtils.isChildAgency() &&  <button type="button" onClick={this.props.onRowAdd} className="btn btn-primary pull-right">Add Luggage</button>}

            </div>
        );
    }
}

class ProductRow extends React.Component {
  
    onDelEvent() {
        this.props.onDelEvent(this.props.product);
    }
    
    render() {
      
      return (
            <tr className="eachRow">
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "startkm",
                    value: this.props.product.startkm,
                    id: this.props.product.id,
                    readonly: true
                }}/>
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "endkm",
                    value: this.props.product.endkm,
                    id: this.props.product.id,
                    readonly: false
                }}/>
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "fare",
                    value: this.props.product.fare,
                    id: this.props.product.id,
                    readonly: false
                }}/>
                {!SessionUtils.isChildAgency() && <td className="del-cell">
                    <i className="icon fe fe-trash" onClick={this.onDelEvent.bind(this)}></i>
                </td>}
            </tr>
        );
    }
}

class EditableCell extends React.Component {

    render() {
        return (
            <td>
                <input className="form-control" type='text' readOnly={this.props.cellData.readonly} apistopid ={this.props.cellData.apistopid} name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} required /><br/>
                <span style={{color: "red"}}>
                    {this.props.errors.id === this.props.cellData.id && 
                        this.props.errors[this.props.cellData.type]
                    }
                </span>
            </td>
        );
    }
}

export default Luggage;