// @flow
import React from "react";
import { Page, Grid } from "tabler-react";
import { Button } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import "./alert.js";
import "../App.css";
import { isMobile } from 'react-device-detect';
import { PaymentAPI } from "../apis/PaymentAPI";
import SessionUtils from "../utils/SessionUtils";
import DateUtils from "../utils/DateUtils";
import { isNullOrUndefined } from "util";

class PaymentResult extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,
            showSuccess: false,
            msgHandler: null,
            statusResponse: {}
        };
    }

    fetchTransactionStatus() {
        let currentComponent = this;
        if (SessionUtils.getPaymentRidlrTransactionId() !== '' && SessionUtils.getPaymentRidlrTransactionId() !== null) {
            PaymentAPI.getTransactionStatus(SessionUtils.getPaymentRidlrTransactionId(),
                (response) => {
                    localStorage.removeItem('paymentId');
                    currentComponent.setState({ isLoading: false, showSuccess: true, statusResponse: response.data.data });
                },
                (error) => {
                    currentComponent.setState({ isLoading: false, showSuccess: false, msgHandler: error.msgHandler });
                }
            );
        } else {
            this.props.history.push('/payment');
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.fetchTransactionStatus();
    }

    goBack(e) {
        this.props.history.push('/payment');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }



    render() {
        var opts = {};
        if (this.state.readonly === true) {
            opts['readOnly'] = 'readOnly';
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <div className="page-header"><h1 className="page-title">Payment</h1></div>
                    <React.Fragment>
                        <div className="container">
                            {this.state.msgHandler}
                            <div>
                                {this.state.isLoading && <div>
                                    <div className="duty-loader"></div>
                                    <div className="center-align">Getting your payment status...</div>
                                </div>}
                                {this.state.showSuccess && <div className="report-issue-success-msg">
                                    <div className={isMobile ? "" : "div-width-60-percent"}>
                                        {this.state.statusResponse.paymentStatus === "COMPLETED" &&
                                            <div class="alert alert-icon alert-success" role="alert">
                                                {this.state.statusResponse.description}
                                            </div>}
                                        {this.state.statusResponse.paymentStatus === "INITIATED" &&
                                            <div class="alert alert-icon alert-info" role="alert">
                                                {this.state.statusResponse.description}
                                            </div>}
                                        {this.state.statusResponse.paymentStatus === "PENDING" &&
                                            <div class="alert alert-icon alert-warning" role="alert">
                                                {this.state.statusResponse.description}
                                            </div>}
                                        {(this.state.statusResponse.paymentStatus === "FAILED" ||
                                            this.state.statusResponse.paymentStatus === "DECLINED" ||
                                            this.state.statusResponse.paymentStatus === "CANCELLED") &&
                                            <div class="alert alert-icon alert-danger" role="alert">
                                                {this.state.statusResponse.description}
                                            </div>}
                                        {isMobile && <div className="paddingTop15">
                                            <div className="row row-cards">
                                                <div className="col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Transaction ID :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.paymentHandlerReferenceId) ? this.state.statusResponse.paymentHandlerReferenceId : ""}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Order ID :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.ridlrOrderId) ? this.state.statusResponse.ridlrOrderId : ""}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Payment Channel :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.paymentChannel) ? this.state.statusResponse.paymentChannel : ""}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Transaction Type :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.transactionType) ? this.state.statusResponse.transactionType : ""}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Amount :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.totalAmount) ? this.state.statusResponse.totalAmount : ""}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Date :</label>
                                                        <p className="">{(this.state.statusResponse.paymentStatus === "INITIATED" || this.state.statusResponse.paymentStatus === "PENDING")?!isNullOrUndefined(this.state.statusResponse.initiatedAt) ? DateUtils.getFormattedDateTimeFromTimestamp(this.state.statusResponse.initiatedAt) : ""
                                                        :!isNullOrUndefined(this.state.statusResponse.completedAt) ? DateUtils.getFormattedDateTimeFromTimestamp(this.state.statusResponse.completedAt) : ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {!isMobile && <div>
                                            <div className="row row-cards">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Transaction ID :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.paymentHandlerReferenceId) ? this.state.statusResponse.paymentHandlerReferenceId : ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-cards">
                                            <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Order ID :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.ridlrOrderId) ? this.state.statusResponse.ridlrOrderId : ""}</p>
                                                    </div>
                                                </div>
                                                </div>
                                            <div className="row row-cards">
                                            <div className="col-md-6 col-lg-6">
                                                     <div className="form-group">
                                                        <label className="form-label">Payment Channel :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.paymentChannel) ? this.state.statusResponse.paymentChannel : ""}</p>
                                                    </div>
                                                </div>
                                                </div>
                                            <div className="row row-cards">
                                            <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Transaction Type :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.transactionType) ? this.state.statusResponse.transactionType : ""}</p>
                                                    </div>
                                                </div>
                                                </div>
                                            <div className="row row-cards">
                                            <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Amount :</label>
                                                        <p className="">{!isNullOrUndefined(this.state.statusResponse.totalAmount) ? this.state.statusResponse.totalAmount : ""}</p>
                                                    </div>
                                                </div>
                                                </div>
                                            <div className="row row-cards">
                                            <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Date :</label>
                                                        <p className="">{(this.state.statusResponse.paymentStatus === "INITIATED" || this.state.statusResponse.paymentStatus === "PENDING")?!isNullOrUndefined(this.state.statusResponse.initiatedAt) ? DateUtils.getFormattedDateTimeFromTimestamp(this.state.statusResponse.initiatedAt) : ""
                                                        :!isNullOrUndefined(this.state.statusResponse.completedAt) ? DateUtils.getFormattedDateTimeFromTimestamp(this.state.statusResponse.completedAt) : ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {/**!isMobile && <Grid.Row md={12} lg={12}>
                                            <Grid.Col lg={12} className="bgdetails">
                                                <Grid.Row lg={12} className="detailschild">
                                                    <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright">Transaction ID: </label></Grid.Col>
                                                    <Grid.Col lg={7} className="detailschildreadonly">{this.state.statusResponse.paymentHandlerReferenceId} </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row lg={12} className="detailschild">
                                                    <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright">Order ID: </label></Grid.Col>
                                                    <Grid.Col lg={7} className="detailschildreadonly">{this.state.statusResponse.ridlrOrderId} </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row lg={12} className="detailschild">
                                                    <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright">Payment Channel: </label></Grid.Col>
                                                    <Grid.Col lg={7} className="detailschildreadonly">{this.state.statusResponse.paymentChannel} </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row lg={12} className="detailschild">
                                                    <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright">Transaction Type: </label></Grid.Col>
                                                    <Grid.Col lg={7} className="detailschildreadonly">{this.state.statusResponse.transactionType} </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row lg={12} className="detailschild">
                                                    <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright">Amount: </label></Grid.Col>
                                                    <Grid.Col lg={7} className="detailschildreadonly">{this.state.statusResponse.totalAmount} </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row lg={12} className="detailschild">
                                                    <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright">Date: </label></Grid.Col>
                                                    <Grid.Col lg={7} className="detailschildreadonly">{DateUtils.getFormattedDateTimeFromTimestamp(this.state.statusResponse.completedAt)} </Grid.Col>
                                                </Grid.Row>
                                            </Grid.Col>
                                        </Grid.Row>*/}
                                    </div>
                                </div>}
                            </div>
                            {!this.state.isLoading && <Grid.Row lg={6} className="detailschild">
                                <Grid.Col lg={6} className="">
                                    <Button type="button" onClick={(event) => this.goBack(event)} className="btn btn-primary" >Back</Button>
                                </Grid.Col>
                            </Grid.Row>}
                        </div>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default PaymentResult;
