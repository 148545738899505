// @flow
import * as React from "react";
import { Page, Grid, Button } from "tabler-react";
import { Table, Row, Col } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import ReactPaginate from "react-paginate";
import { PassAPI } from "../apis/PassAPI";
const perPage = 20;
class varientproductlist extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      PassproductList: [],
      vairaintdata: [],
      currentStopList: [],
      currentPage: null,
      totalPages: null,
      offset: 0,
      status: '',
      name: '',
      isLoader: false,
      authkey: localStorage.getItem('authkey')
    }

  }

  loadCommentsFromServer() {
    let currentComponent = this;
    currentComponent.setState({ isLoader: true });
    PassAPI.getProduct(
      (response) => {
        response.data.data.content.map(function (obj, i) {
          PassAPI.getProductIdVariant(obj.id,
            (response) => {
              let item;
              response.data.data.map(function (obj) {
                item = {
                  passProductId: obj.passProductId,
                  title: obj.title,
                  tripPerDay: obj.tripPerDay,
                  tripValue: obj.tripValue,
                  validityType: obj.validityType,
                  validityMultiplier: obj.validityMultiplier
                }

                return currentComponent.state.vairaintdata.push(item);
              })
              currentComponent.setState({ data: currentComponent.state.vairaintdata });
            }
          );
          return response.data.data.content;
        })
        currentComponent.setState({
          fetchInProgress: false,
          PassproductList: response.data.data.content,
          pageCount: Math.ceil(response.data.data.totalElements / response.data.data.size),
          isLoader: false
        });
      },
      (error) => {
        currentComponent.setState({ isLoader: false });
      }
    );
  }


  componentDidMount() {
    this.loadCommentsFromServer();
  }

  handlePageClick = data => {
    let selected = data.selected;
    let offset = Math.ceil(selected * perPage);
    this.setState({ offset: offset, page: selected }, () => {
      this.loadCommentsFromServer();
    });
  };

  render(){
      let paginationtransaction;
      let addrow;
      const {
        PassproductList
      } = this.state;
      if (this.state.pageCount > 0) {
          paginationtransaction = (
    
            <ReactPaginate
              previousButton={"previous"}
              previousClassName={"page-link"}
              nextClassName={"page-link"}
              nextButton={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              onPageChange={this.handlePageClick}
              forcePage={this.state.currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={"pagination "}
              subContainerClassName={"Page navigation example"}
              activeClassName={"active page-item"}
              pageLinkClassName={"page-link"}
              currentLinkClassName={"page-link"}
            />
          )
        } else {
          paginationtransaction = (
            <div>
              No Data Found...
            </div>
    
          )
        }
        addrow=(<Grid>
          <Row className="show-grid">
            <Col xsHidden md={12}>
              <a href="/createvarient-pass"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Create Variant</Button></a>
            </Col>
          </Row>
        </Grid>
      );
        if (this.state.isLoader) {
          return (
            <BaseLayout>
              <Page.Content>
              <Page.Header title="Pass Variant List" />
                <React.Fragment>
                {addrow}
                <Table striped bordered condensed hover >
                  <thead>

                    <tr>
                      <th >Pass Variant Name</th>
                      <th >Pass Product</th>
                      <th >Validity(Months)</th>
                      <th >Number Of Trips</th>
                      <th >Trips Per Day</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr><td colSpan="5" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                  </tbody>
                  </Table>
                  {paginationtransaction}
                  </React.Fragment>
              </Page.Content>
            </BaseLayout>
          );
    
        } else {
      return(
          <BaseLayout>
            <Page.Content>
              <Page.Header title="Pass Variant List" />
              {addrow}
              <React.Fragment>
              
                <Table striped bordered condensed hover >
                  <thead>

                    <tr>
                      <th >Pass Variant Name</th>
                      <th >Pass Product</th>
                      <th >Validity(Months)</th>
                      <th >Total Trips</th>
                      <th >Trips Per Day</th>
                    </tr>
                  </thead> 
                    <tbody>
                      {this.state.vairaintdata.map(function(obj, i){
                        let passname = '';
                        PassproductList.find(function (element) {
                          if (element.id === obj.passProductId) {
                            passname = element.passName;
                          }
                          return passname;
                        });
                        return (
                          <tr key={i}>
                            <td>
                              {obj.title}
                            </td>
                            <td >
                              {passname}
                            </td>
                            <td >
                              {obj.validityMultiplier}
                            </td>
                            <td >
                              {obj.tripValue}
                            </td>
                            <td >
                              {obj.tripPerDay}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                </Table>
                {paginationtransaction}
             </React.Fragment>
            </Page.Content>
          </BaseLayout>
          )
      }
  };
}
export default varientproductlist;
