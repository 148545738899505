// @flow
import React from "react";
import { Page } from "tabler-react";
import { Button } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import MessageResponse from '../enums/MessageResponse';
import { ViewUtils } from '../utils/ViewUtils';
import "./alert.js";
import "../App.css";
import { isMobile } from 'react-device-detect';
import { PaymentAPI } from "../apis/PaymentAPI";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter, selectFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DateUtils from "../utils/DateUtils";
import PaymentList from "./PaymentList";

const { ExportCSVButton } = CSVExport;

const options = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true
};


const selectOptions = {
    'COMPLETED': 'COMPLETED',
    'INITIATED': 'INITIATED',
    'PENDING': 'PENDING',
    'FAILED': 'FAILED',
    'DECLINED': 'DECLINED',
    'CANCELLED': 'CANCELLED',
};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {filterElement}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
                {column.text}
                {sortElement}
            </div>

        </div>
    );
}

function dateTimeFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.paymentStatus === "INITIATED" || row.paymentStatus === "PENDING") {
        return (
            DateUtils.getFormattedDateFromDateString(row.initiatedAt)
        );
    } else {
        return (
            DateUtils.getFormattedDateFromDateString(cell)
        );
    }
}

class Payment extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            isLoading: false,
            msgHandler: null,
            fields: [],
            errors: [],
            amount: '',
            paymentHistoryList: [],
            isPhonePeChecked: false,
        };
        this.redirectTOURL = this.redirectTOURL.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.onPhonePeChanged = this.onPhonePeChanged.bind(this);
    }

    redirectTOURL(redirectURL) {
        window.open(redirectURL, "_self");
    }

    makePayment() {
        if (this.handleValidation()) {
            this.setState({ isLoading: true });
            let obj = {};
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.userId = JSON.parse(localStorage.getItem('currentUser')).employeeId;
            obj.paymentChannel = "PHONEPE";
            obj.transactionType = "CHARGE";
            obj.totalAmount = parseFloat(this.state.amount);

            PaymentAPI.postPayment(obj,
                (response) => {
                    this.setState({
                        isLoading: false,
                        redirectType: response.data.data.redirectType
                    });
                    localStorage.setItem('paymentId', response.data.data.ridlrTransactionId);
                    this.redirectTOURL(response.data.data.redirectUrl);
                },
                (error) => {
                    this.setState({ isLoading: false, msgHandler: ViewUtils.createMessageHandler("Failed to make payment. Please try again after sometime", MessageResponse.ERROR) });
                }
            );
        }
    }

    dropdownvalid(item, type) {
        let itemValue = item.target.value;

        switch (type) {
            case 'amount': {
                let fields = this.state.fields;
                fields[type] = itemValue;
                this.setState({ amount: itemValue, fields })
                break;
            }
            default:
                break;
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.fields["amount"]) {
            formIsValid = false;
            errors["amount"] = "Cannot be empty";
        }
        if (!this.state.isPhonePeChecked) {
            formIsValid = false;
            errors["isPhonePeChecked"] = "Please select payment method";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    componentDidMount() {
        this.fetchPaymentHistory();
    }

    fetchPaymentHistory() {
        let currentComponent = this;
        PaymentAPI.getPaymentHistory(
            (response) => {
                currentComponent.setState({ paymentHistoryList: response.data.data.content });
            },
            (error) => {

            }
        );
    }

    onPhonePeChanged(event) {
        if (event !== undefined) {
            this.setState({
                isPhonePeChecked: event.target.value,
            });
        }
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleChangeEnd(date) {
        this.setState({
            endDate: date
        });
    }

    handleSubmitClick() {
        let from = this.state.startDate;
        let to = this.state.endDate;
        if (from === null || to === null) {
            alert("Please select date range!");
            return;
        }
        this.setState({ from: from, to: to, isloadingviewduty: true }, () => {
            this.fetchPaymentHistory();
        });
    }


    render() {
        const { columns = [{
            dataField: 'paymentHandlerReferenceId',
            text: 'Transaction ID',
            filter: textFilter(),
            headerStyle: (colum, colIndex) => {
                return { width: '200px', textAlign: 'center' };
            },
            headerFormatter: headerFormatter
        }, {
            dataField: 'ridlrOrderId',
            text: 'Order Id',
            headerStyle: (colum, colIndex) => {
                return { width: '280px', textAlign: 'center' };
            },
            filter: textFilter(),
            headerFormatter: headerFormatter
            /**  }, {
                dataField: 'paymentChannel',
                text: 'Channel',
                headerStyle: (colum, colIndex) => {
                    return { width: '100px', textAlign: 'center' };
                },
                filter: textFilter(),
                headerFormatter: headerFormatter */
        }, {
            dataField: 'transactionType',
            text: 'Transaction Type',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'paymentStatus',
            text: 'STATUS',
            formatter: cell => selectOptions[cell],
            filter: selectFilter({
                options: selectOptions
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'totalAmount',
            text: 'Amount',
            headerFormatter: headerFormatter,
        }, {
            dataField: 'completedAt',
            text: 'Date',
            formatter: dateTimeFormatter,
            filter: dateFilter({
                comparators: [Comparator.GE, Comparator.LE]
            }),
            headerFormatter: headerFormatter
        }] } = this.state;

        /**let filterTransaction = (
            <div className="row row-cards">
                <div className="col-md-3 col-lg-2">
                    <div className="form-group">
                        <label className="form-label">From :</label>
                        <DatePicker name="transFromDate"
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            isClearable={true}
                            placeholderText="I have been cleared!"
                            className="form-control mb-2"
                        />
                    </div>
                </div>
                <div className="col-md-3 col-lg-2">
                    <div className="form-group">
                        <label className="form-label">To :</label>
                        <DatePicker name="transToDate"
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.endDate}
                            onChange={this.handleChangeEnd}
                            isClearable={true}
                            placeholderText="I have been cleared!"
                            className="form-control mb-2"
                        />
                    </div>
                </div>
                <div className="col-md-3 col-lg-2">
                    <div className="form-group">
                        <label className="form-label">&nbsp;&nbsp;</label>
                        <Button type="button"  onClick={this.handleSubmitClick} hidden={this.state.isloadingviewduty}  color="primary mb-2 ">Search</Button>
                        {this.state.isloadingviewduty &&
                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                        }
                    </div>
                </div>
            </div>
        );*/

        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        return (
            <BaseLayout>
                <Page.Content>
                    <div className="page-header"><h1 className="page-title paddingLeft15">Payment</h1></div>
                    <React.Fragment>
                        <div className="container">
                            {this.state.msgHandler}
                            <div className="row row-cards">
                                <div className="col-md-4">
                                    <div className="form-group ">
                                        <label className="form-label">Amount<span className="form-required">*</span></label>
                                        <input type="text" className="form-control" value={this.state.amount} onChange={(item) => this.dropdownvalid(item, 'amount')} placeholder="Amount"></input>
                                        <span style={{ color: "red" }}>{this.state.errors["amount"]}</span>
                                    </div>

                                    <div className="radio">
                                        <label className="form-label"><input type="radio" onChange={this.onPhonePeChanged} name="phonepe" value={this.state.isPhonePeChecked} /><img className="paddingLeft15" alt="phonepe" src="/images/PHONEPE_HOR.png"></img></label>
                                        <span style={{ color: "red" }}>{this.state.errors["isPhonePeChecked"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <br />
                                <div><Button type="button" onClick={() => this.makePayment()} className="btn btn-primary" >Make Payment</Button>
                                    &nbsp;&nbsp;{this.state.isLoading && <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />}</div>
                            </div>
                            {!isMobile &&
                                <div className="paddingTop15">
                                    <ToolkitProvider
                                        keyField="srNo"
                                        data={this.state.paymentHistoryList}
                                        columns={columns}
                                        exportCSV={{ fileName: "PAYMENT-HISTORY-LIST_".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <hr />
                                                    <BootstrapTable
                                                        {...props.baseProps} bootstrap4
                                                        keyField="srNo"
                                                        data={this.state.paymentHistoryList}
                                                        columns={columns}
                                                        bordered={false}
                                                        pagination={paginationFactory(options)}
                                                        filter={filterFactory()}
                                                        noDataIndication="No Data Found" />
                                                    <hr />
                                                    <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>}
                            {isMobile && <div>
                                <PaymentList ref={paymentList => this.paymentList = paymentList} paymentList={this.state.paymentHistoryList} isLoadingPaymentList={this.state.isLoading} />
                            </div>
                            }

                        </div>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default Payment;
