import React from "react";
import { Page } from "tabler-react";
import { Alert,Button } from 'react-bootstrap';
import ReactDropdownAutoComplete from "./autocomplete";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI";

const options = [
    { value: 'ORDINARY', label: 'ORDINARY' },
    { value: 'AC', label: 'AC' },
    { value: 'LIMITED', label: 'LIMITED' },
    { value: 'EXPRESS', label: 'EXPRESS' }
  ];
class Createroutetype extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            routetype:'',
            commentonprint:false,
            routetypedata:[],
            validationError:'',
            showmessage: false,
            isloading:false,
            responsemessage:'',
            authkey: localStorage.getItem('authkey')
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
    }  
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    handleChange() {
        this.setState({ isChecked: !this.state.isChecked })
      }

    submitRouteType(){
        let currentComponent = this;
        currentComponent.setState({isloading: true});
        let obj = {}
        obj.agencyCode = localStorage.getItem('agencyname');
        obj.name = this.state.routetype;
        AgencyAPI.postRouteType(obj,
            (response) => {
                    currentComponent.setState({
                                  responsemessage:"Route type created successfully",
                                  showmessage:true,
                                  isloading: false
                  });
            },
            (error) => {
                currentComponent.setState({isloading: false,responsemessage:error.message,showmessage:true  });
            }
        );
        
    }
    componentDidMount() { 
       
    }
    goBack(e) {
        this.props.history.push('/operator-setup#servicetype');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                  <div className="col-md-5">
                      <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                          <h4>{this.state.responsemessage}</h4>
                          <p>
                            <Button bsStyle="btn-primary"  onClick={(event)=>this.goBack(event)}  className="btn-primary">View</Button>
                                <span> or </span>
                            <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                            </p>

                      </Alert>
                  </div>
            );
        }
        return (
            <BaseLayout>
                <Page.Content>
               
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                            {fadeinout }
                            <h3 className="card-title">Bus service Configration</h3>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                        <label className="form-label">Bus service Type<span className="form-required">*</span></label>
                                        <ReactDropdownAutoComplete
                                              getItemValue={item => item.label}
                                              className="form-control"
                                              id="conductor"
                                              name="conductor"
                                              placeholder="Route Type"
                                              data={options || []}
                                              renderItem={item => (
                                                  <div
                                                  role="button"
                                                  tabIndex="-1"
                                                  onClick={(val) => {  }}
                                                  >{item.label}</div>
                                              )}
                                              icon="search"
                                              iconColor="#ff000"
                                              iconClick={() => { /* TODO */ }}
                                              value={this.state.routetype}
                                              onChange={(val) => { this.setState({ routetype: val }); }} 
                                              onEnter={() => { /* TODO */ }}
                                            />  
                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                {this.state.validationError}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                        <label className="form-label"> &nbsp; </label>
                                        <button type="button" onClick={() => this.submitRouteType()} hidden={this.state.isloading}  className="btn btn-primary">Create
                                        </button>
                                        {this.state.isloading &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                        <a href="/operator-setup"> <Button type="button"  color="primary "> View </Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        )}
}
export default Createroutetype;