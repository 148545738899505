// @flow
import * as React from "react";
import { Site, Nav } from "tabler-react";
import COMConnection from "./COMConnection";
import SessionUtils from "../utils/SessionUtils";
import Role from "../enums/Role";
import { userService } from "../_services";
import { history } from "../utils/history";
import { isMobile } from 'react-device-detect';
import { NavLink, withRouter } from "react-router-dom";
//import { Offline } from "react-detect-offline";
import Drawer from '@material-ui/core/Drawer';

const homeNavBarItem = { value: "Home", to: "/home", icon: "home", LinkComponent: withRouter(NavLink) };

const manufacturerDeviceListNavBarItem = { value: "Device List", to: "/manufacturer-device-list", icon: "info", LinkComponent: withRouter(NavLink) };
const deviceListNavBarItem = { value: "Device List", to: "/device-list", icon: "info", LinkComponent: withRouter(NavLink) };
const apcsDeviceListNavBarItem = { value: "APCS Device List", to: "/apcs-device-list", icon: "info", LinkComponent: withRouter(NavLink) };

var masterNavBarItem = { value: "Masters", icon: "info" };
const operatorSetupNavBarSubItem = { value: "Operator Setup", to: "/operator-setup", LinkComponent: withRouter(NavLink) };
const routeListNavBarSubItem = { value: "Route List", to: "/route-list", LinkComponent: withRouter(NavLink) };
const deviceListNavBarSubItem = { value: "Device List", to: "/device-list", LinkComponent: withRouter(NavLink) };
const apcsDeviceListNavBarSubItem = { value: "APCS Device List", to: "/apcs-device-list", LinkComponent: withRouter(NavLink) };
const vehicleListNavBarSubItem = { value: "Vehicle List", to: "/vehicle-list", LinkComponent: withRouter(NavLink) };
const stopListNavBarSubItem = { value: "Stops List", to: "/stop-list", LinkComponent: withRouter(NavLink) };
const luggageNavBarSubItem = { value: "Luggage", to: "/Luggage", LinkComponent: withRouter(NavLink) };
const scheduleNavBarSubItem = { value: "Schedule", to: "/schedule-list", LinkComponent: withRouter(NavLink) };

const assignDutyNavBarItem = { value: "Assign Duty", to: "/assign-duty", icon: "clock", LinkComponent: withRouter(NavLink) };
const viewDutyNavBarItem = { value: "View Duty", to: "/view-duty", icon: "clock", LinkComponent: withRouter(NavLink) };

const apcsNavBarItem = { value: "APCS", to: "/apcs-report", icon: "clock", LinkComponent: withRouter(NavLink) };

const collectionNavBarItem = { value: "Collection", to: "/cashcollection", icon: "briefcase", LinkComponent: withRouter(NavLink) };

const employeeNavBarItem = { value: "Employees", to: "/all-employees", icon: "users", LinkComponent: withRouter(NavLink) };

var reportsNavBarItem = { value: "Reports", icon: "bar-chart" };
const wayBillDetailNavBarSubItem = { value: "Waybilldetail", to: "/Waybilldetail", LinkComponent: withRouter(NavLink) };
const abcRouteAnalysisReportNavBarSubItem = { value: "ABC route Analysis", to: "RouteAnalytics", LinkComponent: withRouter(NavLink) };
const routewiseDetailReportNavBarSubItem = { value: "Routewise Detail Report", to: "RoutewiseDetail", LinkComponent: withRouter(NavLink) };
const cashCollectionReportNavBarSubItem = { value: "Cash collection", to: "CashCollectionReport", LinkComponent: withRouter(NavLink) };
const conductorwiseReportNavBarSubItem = { value: "Conductorwise report", to: "Conductorwisereport", LinkComponent: withRouter(NavLink) };
const depotWiseIncomeReportNavBarSubItem = { value: "Depotwise income report", to: "Depotwiseincomereport", LinkComponent: withRouter(NavLink) };
const etimwiseRevenuePatternReportNavBarSubItem = { value: "Etimwise revenue pattern report", to: "Etimwise", LinkComponent: withRouter(NavLink) };
const etimwiseDetailReportNavBarSubItem = { value: "Etimwise detail report", to: "etimsdatewise", LinkComponent: withRouter(NavLink) };
const vehicleNumberDetailReportNavBarSubItem = { value: "Vehicle Number detail report", to: "vehicledatewise", LinkComponent: withRouter(NavLink) };
const busTypeWiseReportNavBarSubItem = { value: "Bus type wise report", to: "Bustypewisereport", LinkComponent: withRouter(NavLink) };
const farewiseReportNavBarSubItem = { value: "Farewise report", to: "Farewisereport", LinkComponent: withRouter(NavLink) };
const tripTicketIssuedReportNavBarSubItem = { value: "Trip Ticket Issued report", to: "Tripticketissuedreport", LinkComponent: withRouter(NavLink) };
const doubleDutyReportNavBarSubItem = { value: "Double Duty Report", to: "Doubledutyreport", LinkComponent: withRouter(NavLink) };
const stagewiseSurveyReportNavBarSubItem = { value: "Stagewise Survey Report", to: "StagewiseSurveyReport", LinkComponent: withRouter(NavLink) };
const breakDownWiseReportNavBarSubItem = { value: "Break down wise report", to: "Breakdownwisereport", LinkComponent: withRouter(NavLink) };
const inspectorReportNavBarSubItem = { value: "inspector report", to: "inspectorreport", LinkComponent: withRouter(NavLink) };
const kilometerReportNavBarSubItem = { value: "Kilometer operated by different depot", to: "Killometeroperated", LinkComponent: withRouter(NavLink) };
const payrollReportNavBarSubItem = { value: "Payroll Report", to: "PayrollReport", LinkComponent: withRouter(NavLink) };
const depotwiseShortExcessReportNavBarSubItem = { value: "Depotwise Short Excess Report", to: "DepotwiseShortReport", LinkComponent: withRouter(NavLink) };
const issuedSmartCardReportNavBarSubItem = { value: "Issued smartcard report", to: "Issuedsmartcard", LinkComponent: withRouter(NavLink) };
const smartCardRechargeReportNavBarSubItem = { value: "Smart card recharge report", to: "Smartcardrecharge", LinkComponent: withRouter(NavLink) };
const dieselReportNavBarSubItem = { value: "Diesel report", to: "Diesel", LinkComponent: withRouter(NavLink) };
const transactionsNavBarSubItem = { value: "Transactions", to: "/transactions", LinkComponent: withRouter(NavLink) };
const agentReportNavBarSubItem = { value: "Agent Report", to: "/agentreport", LinkComponent: withRouter(NavLink) };
const stagewiseDetailReportNavBarSubItem = { value: "Stagewise Detail Report", to: "/stagedetail", LinkComponent: withRouter(NavLink) };
const stopwisePassengerCountReportNavBarSubItem = { value: "Stopwise Passenger Count Report", to: "/stop-wise-passenger-count", LinkComponent: withRouter(NavLink) };

var passIssueNavBarItem = { value: "Pass Issue", icon: "users" };
const addUserNavBarSubItem = { value: "Add User", to: "/Adduserlist", LinkComponent: withRouter(NavLink) };
const issuePassNavBarSubItem = { value: "Issue Pass", to: "/Issuepass", LinkComponent: withRouter(NavLink) };
const reloadPassNavBarSubItem = { value: "Reload Pass", to: "/Reloadpass", LinkComponent: withRouter(NavLink) };

var passInventoryNavBarItem = { value: "Pass Inventory", icon: "save" };
const passListNavBarSubItem = { value: "Pass List", to: "/passproduct-list", LinkComponent: withRouter(NavLink) };
const passVariantNavBarSubItem = { value: "Pass Variant", to: "/varient-pass-list", LinkComponent: withRouter(NavLink) };

const communicationNavBarItem = { value: "Communication", to: "/master", icon: "upload", LinkComponent: withRouter(NavLink) };

var reportIssueNavBarItem = { value: "Report Issue", to: "/report-issue" };


class BaseLayout extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            leftDrawerOpen: false,
            menuItems: [],
            authkey: localStorage.getItem('authkey'),
            empName: localStorage.getItem('employeeName'),
            agencyName: localStorage.getItem('agencydisplayname'),
            hasNewNotification: localStorage.getItem("hasNewNotification")==="true"?true:false,
            businessType: localStorage.getItem("businessType")===null?"AFCS":localStorage.getItem("businessType")
        };
    }

    componentDidMount() {
        let currentComponent = this;
        this.createMenu();
        if (this.state.empName === '' || this.state.empName === null) {
            this.setState({ empName: 'Admin' });
        }
        if (this.state.agencyName === '' || this.state.agencyName === null) {
            this.setState({ agencyName: localStorage.getItem('agencyname') });
        }

        if(!isMobile) {
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker.addEventListener("message", (message) => {
                    localStorage.setItem("hasNewNotification", true);
                    currentComponent.setState({hasNewNotification: true});
                });    
            }
        }
    }

    createMenu() {
        let currentComponent = this;
        var menuItems = [];

        //Home menu
        menuItems.push(homeNavBarItem);

        if (!isMobile && (SessionUtils.getRole() === Role.DEVICE_PROVIDER ||
            SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR)) {
            menuItems.push(manufacturerDeviceListNavBarItem);
        }
        if (!isMobile && (SessionUtils.getRole() === Role.INVENTORY)) {
            menuItems.push(deviceListNavBarItem);
            menuItems.push(apcsDeviceListNavBarItem);
        }

        //Masters
        masterNavBarItem.subItems = [];

        if (!isMobile && (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
            SessionUtils.getRole() === Role.ADMINISTRATOR ||
            SessionUtils.getRole() === Role.EMPLOYEE ||
            SessionUtils.getRole() === Role.OPERATIONS)) {
            masterNavBarItem.subItems.push(operatorSetupNavBarSubItem);
            if(this.state.businessType !== 'APCS') {
                masterNavBarItem.subItems.push(routeListNavBarSubItem);
            }
        }
        /// add device roles here
        if (!isMobile && (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
            SessionUtils.getRole() === Role.ADMINISTRATOR ||
            SessionUtils.getRole() === Role.OPERATIONS ||
            SessionUtils.getRole() === Role.EMPLOYEE)) {
                if(this.state.businessType !== 'APCS') {
                    masterNavBarItem.subItems.push(deviceListNavBarSubItem);
                } 
                if(this.state.businessType !== 'AFCS') { 
                    masterNavBarItem.subItems.push(apcsDeviceListNavBarSubItem);
                }
        }

        if (!isMobile && (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
            SessionUtils.getRole() === Role.ADMINISTRATOR ||
            SessionUtils.getRole() === Role.EMPLOYEE ||
            SessionUtils.getRole() === Role.OPERATIONS)) {
            masterNavBarItem.subItems.push(vehicleListNavBarSubItem);
            masterNavBarItem.subItems.push(stopListNavBarSubItem);
            if(this.state.businessType !== 'APCS') {
                masterNavBarItem.subItems.push(luggageNavBarSubItem);
            }
            if(SessionUtils.isRouteScheduleEnabled() === true) {
                masterNavBarItem.subItems.push(scheduleNavBarSubItem);
            }
        }
        if (!isMobile && (SessionUtils.getRole() !== Role.INVENTORY && SessionUtils.getRole() !== Role.FINANCE
            && SessionUtils.getRole() !== Role.DEVICE_PROVIDER)) {
            menuItems.push(masterNavBarItem);
        }

        if (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
            SessionUtils.getRole() === Role.ADMINISTRATOR ||
            SessionUtils.getRole() === Role.OPERATIONS) {
            if(this.state.businessType !== 'APCS') {
                menuItems.push(assignDutyNavBarItem);
                if(isMobile) {
                    menuItems.push(viewDutyNavBarItem);
                }
                menuItems.push(collectionNavBarItem);
            }
        }



        if (!isMobile && (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
            SessionUtils.getRole() === Role.ADMINISTRATOR ||
            SessionUtils.getRole() === Role.OPERATIONS)) {
            menuItems.push(employeeNavBarItem);
        }


        if (!isMobile && (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
            SessionUtils.getRole() === Role.ADMINISTRATOR ||
            SessionUtils.getRole() === Role.OPERATIONS ||
            SessionUtils.getRole() === Role.FINANCE)) {
                if(this.state.businessType !== 'APCS') {
            
                    reportsNavBarItem.subItems = [];
                    reportsNavBarItem.subItems.push(wayBillDetailNavBarSubItem);
                    reportsNavBarItem.subItems.push(abcRouteAnalysisReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(routewiseDetailReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(cashCollectionReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(conductorwiseReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(depotWiseIncomeReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(etimwiseRevenuePatternReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(etimwiseDetailReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(vehicleNumberDetailReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(busTypeWiseReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(farewiseReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(tripTicketIssuedReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(doubleDutyReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(stagewiseSurveyReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(stagewiseDetailReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(breakDownWiseReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(inspectorReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(kilometerReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(payrollReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(depotwiseShortExcessReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(issuedSmartCardReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(smartCardRechargeReportNavBarSubItem);
                    // reportsNavBarItem.subItems.push(dieselReportNavBarSubItem);
                    reportsNavBarItem.subItems.push(transactionsNavBarSubItem);
                    // reportsNavBarItem.subItems.push(stopwisePassengerCountReportNavBarSubItem);
                    if (localStorage.getItem('agentCommission') === "true") {
                        reportsNavBarItem.subItems.push(agentReportNavBarSubItem);
                    }
                    menuItems.push(reportsNavBarItem);
                }
            menuItems.push(apcsNavBarItem);
            // remove APCS navbar item for now
            menuItems.pop();
        }

        // if (!isMobile && (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
        //     SessionUtils.getRole() === Role.ADMINISTRATOR ||
        //     SessionUtils.getRole() === Role.OPERATIONS)) {
        //         if(this.state.businessType !== 'APCS') {
        //
        //     passIssueNavBarItem.subItems = [];
        //     passIssueNavBarItem.subItems.push(addUserNavBarSubItem);
        //     passIssueNavBarItem.subItems.push(issuePassNavBarSubItem);
        //     passIssueNavBarItem.subItems.push(reloadPassNavBarSubItem);
        //     menuItems.push(passIssueNavBarItem);
        //         }
        // }

        // if (!isMobile && (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
        //     SessionUtils.getRole() === Role.ADMINISTRATOR ||
        //     SessionUtils.getRole() === Role.OPERATIONS ||
        //     SessionUtils.getRole() === Role.EMPLOYEE)) {
        //         if(this.state.businessType !== 'APCS') {
        //
        //     passInventoryNavBarItem.subItems = [];
        //     passInventoryNavBarItem.subItems.push(passListNavBarSubItem);
        //     passInventoryNavBarItem.subItems.push(passVariantNavBarSubItem);
        //     menuItems.push(passInventoryNavBarItem);
        //         }
        // }

        if (!isMobile && (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
            SessionUtils.getRole() === Role.ADMINISTRATOR ||
            SessionUtils.getRole() === Role.OPERATIONS)) {
                if(this.state.businessType !== 'APCS') {
                    menuItems.push(communicationNavBarItem);
                }
        }

        // menuItems.push(reportIssueNavBarItem);
        currentComponent.setState({ menuItems: menuItems });

    }

    logout() {
        userService.logout();
        history.push("/login");
    }

    goToSettings() {
        history.push("/download-agreement");
    }

    goToChangePassword() {
        history.push("/change-password");
    }

      

    render() {
        const toggleDrawer = (open) => event => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            this.setState({ leftDrawerOpen: open });
        };
        const sideList = side => (
            <div
                className="drawer-list"
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}>
                <div className="drawer-user-details">
                    <div>
                        <i  className="fe fe-chevron-left drawer-back-arrow"></i>
                        {/*<img src="/images/ridlr.png" className="drawer-brand-img" alt="Bustech AFCS" />*/}
                    </div>
                    <div className="drawer-user-name">{localStorage.getItem('employeeName')}</div>
                    <div className="drawer-agency-name">{localStorage.getItem('agencydisplayname')}</div>

                </div>
                <div className="nav nav-tabs border-0 flex-column flex-lg-row">
                    <Nav.Item className="nav-item" to="/home" value="Dashboard" LinkComponent={withRouter(NavLink)}></Nav.Item>
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/view-duty" value="View Duty" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/assign-duty" value="Assign Duty" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/cashcollection" value="Duty Collection" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    <Nav.Item className="nav-item" to="/apcs-report" value="APCS Report" LinkComponent={withRouter(NavLink)}></Nav.Item>
                    {this.state.businessType !== 'APCS' && <div className="reports-menu-header">Reports</div>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-weekly-collection" value="Weekly Collection" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-conductor-collection" value="Conductor Collection" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-routewise-collection" value="Route Wise Collection" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-routewise-epkm" value="Route Wise EPKM" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-bus-inspection-count" value="Bus Inspection Count" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-breakdown-alert" value="Breakdown alert" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-diesel-amount" value="Diesel Amount" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-stage-detail" value="Stagewise Detail" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-trip-ticket-issue" value="Trip Ticket Issued" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    {this.state.businessType !== 'APCS' && <Nav.Item className="nav-item" to="/report-transactions" value="Transactions" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    <div className="reports-menu-header"> </div>
                    <Nav.Item className="nav-item" to="/report-issue" value="Report Issue" LinkComponent={withRouter(NavLink)}></Nav.Item>
                    {SessionUtils.isUpiPaymentEnabled() === true && <Nav.Item className="nav-item" to="/payment" value="Payment" LinkComponent={withRouter(NavLink)}></Nav.Item>}
                    <Nav.Item className="nav-item" to="/change-password" value="Change Password" LinkComponent={withRouter(NavLink)}></Nav.Item>
                    <Nav.Item className="nav-item" to="/login" onClick={() => this.logout()} value="Logout" LinkComponent={withRouter(NavLink)}></Nav.Item>
                </div>
            </div>
        );
        return (
            <div>{isMobile &&
                <div>
                    <div id="root">
                        <div>
                            <div className="page">
                                <div className="page-main">
                                    <div className="header py-4 stickyheader">
                                        <div className="container">
                                            <div className="d-flex">
                                                <a className="header-brand" href="/home">
                                                    <img src="/images/ridlr.png" className="header-brand-img" alt="Bustech AFCS" />
                                                </a>
                                                {/*<div className="d-flex order-lg-2 ml-auto">*/}
                                                {/*    <div>*/}
                                                {/*        {this.state.hasNewNotification === true && <div className="paddingTop5 floatright"><div className="nav-unread notification-active"></div></div>}*/}
                                                {/*        <div className="floatright notification-div mobile-call-icon"><a className="" href="/notifications"><i className="fe fe-bell"></i></a></div>*/}
                                                {/*        <div className="floatright"><b><a className="mobile-call-icon" href="tel:1800-121-3233"><i className="fe fe-phone-call"></i></a></b></div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <span className="header-toggler d-lg-none ml-3 ml-lg-0 header-toggler-icon"  onClick={toggleDrawer(true)}></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pageMainContent">
                                        {/*<Offline>
                                            <Page.Content>
                                                <React.Fragment>
                                                    <MessageHandler message="No internet connection" messageResponse="danger" />
                                                </React.Fragment>
                                            </Page.Content>

                                        </Offline>*/}
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Drawer open={this.state.leftDrawerOpen} onClose={toggleDrawer(false)}>
                            {sideList('left')}
                        </Drawer>
                    </div>
                </div>
            }
                {!isMobile &&
                    <Site.Wrapper
                        headerProps={{
                            href: "/home",
                            alt: "Bustech AFCS",
                            imageURL: "/images/ridlr.png",
                            accountDropdown: {
                                avatarURL: "/images/person-placeholder.png",
                                name: this.state.empName,
                                description: this.state.agencyName,
                                options: [
                                    SessionUtils.isUpiPaymentEnabled() === true && { icon: "credit-card", value: "Payment", to: "/payment" },
                                    { icon: "user", value: "Change Password", to: "/change-password" },
                                    { icon: "settings", to: "/download-agreement", value: "Settings" },                 
                                    { isDivider: true },
                                    { icon: "log-out", to: "/login", onClick: () => this.logout(), value: "Sign out" }
                                ]
                            },
                            navItems:
                            <div>
                                <div>
                                    {!isMobile &&
                                        <div>
                                            {this.state.hasNewNotification === true && <div className="paddingTop5 floatright"><div className="nav-unread notification-active"></div></div>}
                                            <div className="floatright paddingTop5 notification-div"><a className="" href="/notifications"><i className="fe fe-bell"></i></a></div>
                                            {this.state.businessType !== 'APCS' && <div className="floatright"><React.Fragment><COMConnection /></React.Fragment></div>}
                                            {/*<div className="floatright paddingTop5"><b><i className="fe fe-phone-call"></i>&nbsp;&nbsp;1800-121-3233&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></div>*/}
                                        </div>
                                    }
                                    {isMobile && 
                                        <div>
                                            <div className="floatright paddingTop5"><b><a className="call-icon" href="tel:1800-121-3233"><i className="fe fe-phone-call"></i></a></b></div>
                                        </div>
                                    }
                                </div>
                                </div>
                                
                            
                        }}
                        navProps={{ withSearchForm: true, itemsObjects: this.state.menuItems, rightColumnComponent: (
                            <React.Fragment>
                            report issue button
                </React.Fragment>
                        )
                         }}
                        footerProps={{
                            copyright: (
                                <React.Fragment>
                                    Copyright &copy; 2021 BusTech. All rights reserved.
                        </React.Fragment>
                            )
                        }}
                    >
                        <div>
                            {/*<Offline>
                                <Page.Content>
                                    <React.Fragment>
                                        <MessageHandler message="No internet connection" messageResponse="danger" />
                                    </React.Fragment>
                                </Page.Content>

                            </Offline>*/}
                        </div>
                        {/*<div>Mobile:{isMobile?"true":"false"}</div>*/}
                        {this.props.children}
                    </Site.Wrapper>
                }
            </div>

        );
    };
};

export default BaseLayout;
