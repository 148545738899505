import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userService } from '../_services';
import ErrorBoundary from '../logger/ErrorBoundary';
import { ClevertapReact } from '../CleverTap/ClevertapReact';
import { EventProperties } from '../CleverTap/EventProperties';
export const PrivateRoute = ({ pageName,component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = userService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.role) === -1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/forbidden'}} />
        }
        if(pageName !== 'Home') {
            ClevertapReact.event("PageClick",EventProperties.eventPropertiesPageClick(pageName));
        }
        // authorised so return component
        return (<ErrorBoundary><Component {...props} /></ErrorBoundary>);
    }} />
)