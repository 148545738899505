import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';

export const AuthAPI = {
    postAuth
}

function postAuth(obj, callbackSuccess, callbackError) {
    API.post('/apis/auth/', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to login. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}
