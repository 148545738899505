import React from 'react';
import { userService } from '../_services';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

      //  userService.logout();

        this.state = {
            agency: '',
            email: '',
            password: '',
            agencyMemoId: '',
            submitted: false,
            registerDone: false,
            loginDone: false,
            loading: false,
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // Remove all saved data from localStorage
        localStorage.clear();
        localStorage.clear();
    }

    handleChange(e) {
        const { name, value } = e.target;
        if(name === "agency"){
            this.setState({ [name]: value.toUpperCase() });
        } else {
            this.setState({ [name]: value });
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { agency, email, password } = this.state;

        // stop here if form is invalid
        if (!(agency && email && password)) {
            return;
        }

        this.setState({ loading: true });
        userService.getDefaultAuthKey("1000", "admin").then(default_authorization => {
                userService.register(default_authorization, agency, email, password).then(register => {
                        if(register){
                            this.setState({ agencyMemoId: register, registerDone: true });
                            userService.login(email, password).then(user => {
                                    this.setState({ loading: false });
                                    if(user){
                                        this.setState({ loginDone: user });
                                        this.getSelectedProperties();
                                        const { from } = this.props.location.state || { from: { pathname: "/home" } };
                                        this.props.history.push(from);
                                    } 
                                },
                                error => this.setState({ error, loading: false })
                            );
                        }
                    },
                    error => this.setState({ error, loading: false })
                );
            },
            error => this.setState({ error, loading: false })
        );
    }
    
    getSelectedProperties() {
        return this.state.loginDone;
    }
    
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
       };
        const { agency, email, password, submitted, loading, error } = this.state;
        return (
            <div className={"page"}>
                <div className={"page-single"}>
                    <div className={"container"}> 
                    <div className={"row"}>
                    <div className={"col col-login mx-auto"}>
                        <form className={"card"} onSubmit={this.handleSubmit}> 
                            <div className={"card-body p-6"}> 
                                <div className={"card-title"}>Register New User</div>
                                <div  className={'form-group' + (submitted && !agency ? ' has-error' : '')}>
                                    <label className={"form-label"}>Agency</label>
                                    <input type="text" className="form-control" name="agency" value={agency} maxLength="10" onChange={this.handleChange} />
                                    {submitted && !agency &&
                                            <div className="help-block danger">Agency is required</div>
                                    }
                                </div>
                                <div  className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                    <label className={"form-label"}>Email</label>
                                    <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
                                    {submitted && !email &&
                                        <div className="help-block danger">Email is required</div>
                                    }
                                </div>
                                <div  className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                    <label className={"form-label"}>Password</label>
                                    <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                                    {submitted && !password &&
                                        <div className="help-block">Password is required</div>
                                    }
                                </div>
                                <div className={"form-footer"}>
                                    <button className="btn btn-block btn-primary"   disabled={loading} >Register
                                    {loading &&
                                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="download" />
                                    }
                                    </button><br/> or <br/><br/><a href="/login" className="btn btn-block btn-primary">Back to Login</a>
                                </div>
                                {error &&
                                    <div className={'alert alert-danger margin-3' } style={stylescope.splitterStyle}>{error}</div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
        );
    }
}

export default RegisterPage;
