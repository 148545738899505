import React from "react";
import { Link } from 'react-router-dom';
import { Page, Grid, Button} from "tabler-react";
import { Table, Row, Col} from 'react-bootstrap';
import Pagination from "./Pagination"

const perPage = 20;

class Expenses extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            currentExpensesList:[],
            authkey: localStorage.getItem('authkey')
        };
    }
    
    onPageChanged = data => {
        const { currentPage, totalPages, pageLimit } = data;
      
        const offset = (currentPage - 1) * pageLimit;
        const currentExpensesList = this.props.expenses.slice(offset, offset + pageLimit);
      
        this.setState({ currentPage, currentExpensesList, totalPages });
    };
    
    render() {
        let paginationtransaction;
        const {
            currentPage,
            totalPages
        } = this.state;
        const totalExpenses = this.props.expenses.length;
        if (totalExpenses > 0) {
            paginationtransaction = (
              <Pagination
                totalRecords={totalExpenses}
                pageLimit={perPage}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            );
        } else {
            paginationtransaction = (
                <div>
                No Data Found...
                </div>
            );
        }
        if (this.state.isLoader) {
            return (
                <Page.Content>
                <Grid>
                    <Row className="show-grid floatright">
                    <Col xsHidden md={12}>
                        <Link to={{ pathname: '/add-expense', state: { expenses: this.props.expenses, configId: this.props.configId} }}><Button type="button"  color="primary mb-2 mr-2 float-right"> Add Expense </Button></Link>
                    </Col>
                    </Row>
                </Grid>
                <Page.Header title="Expenses List" />
                  <React.Fragment>
                  <Table striped bordered condensed hover >
                    <thead>
                      <tr>
                        <th >Expense Name</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr><td colSpan="1" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                    </tbody>
                    </Table>
                    {paginationtransaction}
                    </React.Fragment>
                </Page.Content>
            );
        } else {
          let currentComponent = this;
            return(
                <Page.Content>
                <Grid>
                    <Row className="show-grid floatright">    
                    <Col xsHidden md={12}>
                        <Link to={{ pathname: '/add-expense', state: { expenses: this.props.expenses, configId: this.props.configId} }}><Button type="button"  color="primary mb-2 mr-2 float-right"> Add Expense </Button></Link>
                    </Col>
                    </Row>
                </Grid>
                <Page.Header title="Expenses List" />
                <React.Fragment>
                <Table striped bordered condensed hover >
                <thead>
                    <tr>
                        <th >Expense Name</th>
                    </tr>
                </thead>
                <tbody>
                  {this.state.currentExpensesList.map(function(obj, i){
                    return (
                        <tr key={i}>
                        <td>
                            {obj.toString().replace(/_/g, ' ')}
                        </td>
                        </tr>
                        );
                    }, currentComponent)}
                </tbody>
                </Table>
                {paginationtransaction} {currentPage && (
                    <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                    Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                    <span className="font-weight-bold">{totalPages}</span>
                    </span>
                    )}
                </React.Fragment>
                </Page.Content>
            );
        }
    }
}
export default Expenses;
