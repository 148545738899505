import React from "react";
import { Page } from "tabler-react";
import { Alert,Button } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import "./alert.js";
import "../App.css";
import { AgencyAPI } from "../apis/AgencyAPI";

class AddDepot extends React.Component {
    
    constructor(props, context) {
        super(props, context);
        this.state = {
            validationError: "",
            depotname:'',
            isLoader: false,
            showmessage: false,
            fields: {},
            errors: {},
            authkey: localStorage.getItem('authkey')
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }
    
    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {
            case 'depotname': {
                let fields = this.state.fields;
                fields[type] = itemValue;
                this.setState({ depotname: itemValue,fields });
                break
            }
          default:    
        }
    }
    
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["depotname"]){
            formIsValid = false;
            errors["depotname"] = "Cannot be empty";
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    
    submitDepot(){
        if(this.handleValidation()){
            this.setState({ isloading: true });
            let obj = {};
            let satus = this;
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.name = this.state.depotname.toUpperCase();
            obj.depotCode = this.state.depotname.toUpperCase().substring(0, 3);

            AgencyAPI.postDepot(obj, 
                (response) => {
                    const dbName = "RIDLR AFCS";
                    const version = 1; //versions start at 1
                    var request = indexedDB.open(dbName, version);
                    request.onsuccess = function(event) {
                        var db         = event.target.result;
                        var depot      = db.transaction('depot', 'readwrite').objectStore("depot");
                        var clear =  depot.clear();
                        clear.onsuccess = function(event) {
                            AgencyAPI.getDepot(
                                (response) => {
                                    let DepotIdFromApi = response.data.data.map((depot) => {
                                        return { id: depot.id, depotname: depot.name  };
                                    });
                                    // Store values in the newly created objectStore.
                                    var depotObjectStore = db.transaction("depot", "readwrite").objectStore("depot");
                                    depotObjectStore.clear();
                                    DepotIdFromApi.forEach(function (depot) {
                                        depotObjectStore.add(depot);
                                    });
                                }
                            );
                        };
                    };
                    satus.setState({
                        showmessage: true,
                        isloading: false,
                        depotname:'',
                        responsemessage:'Depot Added successfully!'
                    });
                },
                (error) => {
                    satus.setState({
                        showmessage: true,
                        isloading: false,
                        depotname:'',
                        responsemessage:error.message
                    });
                    return;
                }
            );
        }
    }
    
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            );
        }
        return (
            <BaseLayout>
                <Page.Content>
                {fadeinout}
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                                <h3 className="card-title">Add Depot</h3>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Depot Name<span className="form-required">*</span></label>
                                            <input type="text" className="form-control upercase" value={this.state.depotname}   onChange={(item) => this.valid(item, 'depotname')} placeholder="Depot Name"></input>
                                            <span style={{color: "red"}}>{this.state.errors["depotname"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">&nbsp;</label>
                                            <button type="button" onClick={() => this.submitDepot()} hidden={this.state.isloading} className="btn btn-primary">Save
                                            </button>
                                            {this.state.isloading &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            <a href="/operator-setup"> <Button type="button"  color="primary "> View </Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>  
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default AddDepot;