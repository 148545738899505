// @flow
import React from "react";
import { Page } from "tabler-react";
import "./alert.js";
import "../App.css";
import BaseLayout from "./BaseLayout";
import DateUtils from "../utils/DateUtils";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { NotificationAPI } from "../apis/NotificationAPI.js";

class NotificationList extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            notificationList: [],
            isLoadingDutyList: false,
        };
    }


    getNotificationList() {
        let currentComponent = this;
        NotificationAPI.getNotifications(
            (response) => {
                currentComponent.setState({ notificationList: response.data.data.content });
                localStorage.setItem("hasNewNotification", false);
            },
            (error) => {
                //console.log("error:" + JSON.stringify(error));
            }
        )
    }

    componentDidMount() {
        this.getNotificationList();
    }

    handleCardClick(card, index) {
        this.props.history.push(card.landingUrl);
    }

    render() {
        return (
            <BaseLayout>
                <Page.Content>
                    <div className="my-3 my-md-5">
                        <div className="container">
                            <div className="page-header">
                                <h1 className="page-title">Notifications</h1>
                            </div>

                            <div className="duty-page">
                                {this.state.isLoadingDutyList && <div className="duty-loader"></div>}
                                <div className="cards-list">
                                    {this.state.notificationList.map((card, i) => {
                                        return <Card className="cash-collection-card" onClick={() => this.handleCardClick(card, i)}
                                            key={card.id}
                                            card={card}
                                        >
                                            <CardContent className="cash-collection-card-content">
                                                <div className="cash-collection-card-header">
                                                    <div className="cash-collection-row">
                                                        <div className="cash-collection-header-row">
                                                            <div className="notification-list-label">{card.title}</div>
                                                            <div className="notification-list-text">{card.body}</div>
                                                        </div>
                                                    </div>
                                                    <div className="cash-collection-row">
                                                        <div className="cash-collection-header-row">
                                                            <div className="notification-list-date">Sent At: {DateUtils.getFormattedDateTimeFromTimestamp(card.createdAt)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </Page.Content>
            </BaseLayout>

        );
    }
}

NotificationList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default NotificationList;
