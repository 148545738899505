const Role = {
    AFCS_ADMINISTRATOR: 'ROLE_AFCS_ADMINISTRATOR',
    DEVICE_PROVIDER: 'ROLE_DEVICE_PROVIDER',
    ADMINISTRATOR: 'ROLE_ADMINISTRATOR',
    INVENTORY: 'ROLE_INVENTORY',
    FINANCE:'ROLE_FINANCE',
    OPERATIONS:'ROLE_OPERATIONS',
    EMPLOYEE: 'ROLE_EMPLOYEE'
}

export default Role;