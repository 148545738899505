// @flow
import React from "react";
import { Page,Button} from "tabler-react";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';
import "./alert.js";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI.js";

const optionsStatus=[
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' },
]

class EditCause extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            responseMessage: '',
            showMessage: false,
            isLoader: false,
            causeName: '',
            fields: [],
            errors: [],
            status: '',
            authkey: localStorage.getItem('authkey')
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }  

    dropdownvalid(item, type) {
      let itemValue = item.value;
      
      switch (type) {
          case 'causeName': {
              let fields = this.state.fields;
              fields[type] = itemValue; 
              this.setState({ causeName: itemValue, fields })
              break;
          }
          case 'status': {
              let fields = this.state.fields;
              fields[type] = item;
              this.setState({ status: itemValue})
              break;
          }
          default:    
              break;
      }
    }

    handleDismiss() {
        this.setState({ showMessage: false });
    }

    handleValidation(){
       
        let errors = {};
        let formIsValid = true;
        /**if(!this.state.fields["causeName"]){
          formIsValid = false;
          errors["causeName"] = "Cannot be empty";
        } */
        /**if(!this.state.fields["status"]){
            formIsValid = false;
            errors["status"] = "Cannot be empty";
        } */
        this.setState({errors: errors});
        return formIsValid;
    } 

    submitCause() {
        if(this.handleValidation()){
            
        let obj = {}
        obj.status = this.state.status.toUpperCase();
        
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        AgencyAPI.putCause(this.props.location.state.cause.id, obj,
            (response) => {
                currentComponent.setState({
                            responseMessage:'Cause edited successfully!',
                            causeName:'',
                            isLoader: false,
                            showMessage:true
                });
            },
            (error) => {
                currentComponent.setState({isLoader: false,responseMessage:error.message,showMessage:true  });
            }
        );
        
    }return;
}

goBack(e) {
    this.props.history.push('/operator-setup#cause');
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
}

render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
              }
          };
        let fadeinout;
        const statusValueFromId = (opts, id) => opts.find(o => o.value === id);
      
        if (this.state.showMessage) {
            fadeinout = (
            <div className="col-md-5">
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responseMessage}</h4> 
                    <p>
                    <Button onClick={(event)=>this.goBack(event)}  className="btn-primary">View</Button>
                        <span> or </span>
                    <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                    </p>
                </Alert>
            </div>)
        }
        
        return (
            <BaseLayout>
                <Page.Content>
               
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                            {fadeinout }
                            <h3 className="card-title">Edit Cause</h3>
                                <div className="row">
                                
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Cause Name<span className="form-required">*</span></label>
                                                <input type="text" disabled={true} value={this.props.location.state.cause.name} className="form-control" placeholder="Cause name"></input>
                                                <span style={{color: "red"}}>{this.state.errors["causeName"]}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Status<span className="form-required">*</span></label>
                                            <Select options={optionsStatus} defaultValue={statusValueFromId(optionsStatus, this.props.location.state.cause.status, this.props.location.state.cause.status)} onChange={(item) => this.dropdownvalid(item, 'status')}  />
                                            <span style={{color: "red"}}>{this.state.errors["status"]}</span> 
                                    </div>
                                    </div>

                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label"> &nbsp; </label>
                                            <button type="button" onClick={() => this.submitCause()} className="btn btn-primary">Save
                                            {this.state.isLoader &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            </button>
                                            <a href="/operator-setup"> <Button type="button"  color="primary mb-2 mr-2 float-right"> View </Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                   
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default EditCause;
