import { isMobile } from "react-device-detect";

export const EventProperties = {
    profilePush(){
        return {
        "Site": {
            "Name": localStorage.getItem('agencydisplayname'),
            "Identity": localStorage.getItem('agencyname')
        }
      }
    },
    eventPropertiesLogin(){
      return {
        "agencyCode":localStorage.getItem('agencyname'),
        "agencyName":localStorage.getItem('agencydisplayname'),
        "employeeName":localStorage.getItem('employeeName'),
        "role": localStorage.getItem('role'),
        "source":isMobile?"Mobile":"Web"
        };
    },
     eventPropertiesPageClick(pageName){
        return {
          "agencyname":localStorage.getItem('agencydisplayname'),
          "agencyCode":localStorage.getItem('agencyname'),
          "employeeName":localStorage.getItem('employeeName'),
          "pageName":pageName,
          "source":isMobile?"Mobile":"Web"
          };
      },
      eventPropertiesCommunicationRequest(caseName){
        return {
          "agencyname":localStorage.getItem('agencydisplayname'),
          "agencyCode":localStorage.getItem('agencyname'),
          "employeeName":localStorage.getItem('employeeName'),
          "type":caseName,
          "source":isMobile?"Mobile":"Web"
          };
      },
      eventPropertiesCommunicationResponse(caseName,status,msg){
        return {
          "agencyname":localStorage.getItem('agencydisplayname'),
          "agencyCode":localStorage.getItem('agencyname'),
          "employeeName":localStorage.getItem('employeeName'),
          "type":caseName,
          "status":status,
          "errorMsg":msg,
          "source":isMobile?"Mobile":"Web"
          };
      }
};
