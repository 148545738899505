// @flow
import * as React from "react";
import { Link } from 'react-router-dom';
import { Page, Grid, Button } from "tabler-react";
import { Row, Col, Alert } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DateUtils from "../utils/DateUtils";
import { InventoryAPI } from "../apis/InventoryAPI";
import "../App.css";
const { ExportCSVButton } = CSVExport;
const options = {
  sizePerPage: 10,
  hideSizePerPage: false,
  hidePageListOnlyOnePage: true
};

const selectOptions = {
  'REGISTERED': 'REGISTERED',
  'UNASSIGNED': 'UNASSIGNED',
  'ASSIGNED': 'ASSIGNED',
  'LOCKED': 'LOCKED'
};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filterElement}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
        {column.text}
        {sortElement}
      </div>
    </div>
  );
}

class DeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.type = this.props.location.pathname.split('/')[1]; //this is the name of the route
    this.state = {
      isManufactureRegisterDevice: this.type === 'manufacturer-device-list' ? true : false,
      depotList: [],
      deviceList: [],
      isLoader: false,
      data: [],
      isLockLoader: false,
    };
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    this.loadDepotList();
  }

  handleDismiss() {
    this.setState({ showMessage: false });
  }


  loadDeviceList() {
    let currentComponent = this;
    currentComponent.setState({ isLoader: true });
    var url = "/apis/inventory/device?agencyCode=" + localStorage.getItem('agencyname');
    if (currentComponent.state.isManufactureRegisterDevice) {
      url = "/apis/inventory/device?manufacturer=BALAJI";
    }
    url = url + "&sort=createdAt,DESC";
    InventoryAPI.getDeviceList(url,
      (response) => {
        let deviceListFromAPI = [];
        response.data.data.content.map((device) => {
          this.state.depotList.find(function (depot) {
            if (device.depot === depot.value) {
              device.depotName = depot.display;
            }
            return "";
          });
          deviceListFromAPI.push(device);
          return "";
        });
        currentComponent.setState({
          data: deviceListFromAPI,
          isLoader: false,
        });
      },
      (error) => {
        currentComponent.setState({ isLoader: false });
      }
    );
  }

  loadDepotList() {
    let currentComponent = this;
    const dbName = "RIDLR AFCS";
    const version = 1; //versions start at 1
    var request = indexedDB.open(dbName, version);
    request.onsuccess = function (event) {
      var db = event.target.result;
      var depots = db.transaction('depot', 'readwrite').objectStore("depot");
      var depotdata = depots.getAll();
      depots.getAll().onsuccess = function (event) {
        let DepotListFromInDB = depotdata.result.map(depots => { return { value: depots.id, display: depots.depotname } })
        currentComponent.setState({ depotList: [{ value: '', display: 'Select Depot' }].concat(DepotListFromInDB) });
        currentComponent.loadDeviceList();
      };
    }

  }

  lockDevice(obj) {
    let currentComponent = this;
    currentComponent.setState({ isLockLoader: true });

    let requestBody = {};
    requestBody.deviceState = 'LOCKED';
    InventoryAPI.putDeviceState(obj.deviceSerialId, requestBody,
      (response) => {
        currentComponent.setState({
          isLockLoader: false,
          showMessage: true,
          responseMessage: 'Device Locked Successfully'
        });
        currentComponent.loadDeviceList();
      },
      (error) => {
        currentComponent.setState({ isLockLoader: false });
      }
    );
  }


  unlockDevice(obj) {
    let currentComponent = this;
    currentComponent.setState({ isLockLoader: true });

    let requestBody = {};
    requestBody.deviceState = 'UNLOCKED';

    InventoryAPI.putDeviceState(obj.deviceSerialId, requestBody,
      (response) => {
        currentComponent.setState({
          isLockLoader: false,
          showMessage: true,
          responseMessage: 'Device Unlocked Successfully'
        });
        currentComponent.loadDeviceList();
      },
      (error) => {
        currentComponent.setState({ isLockLoader: false });
      }
    );

  }

 

  render() {
    let fadeinout;
    if (this.state.showMessage) {
      fadeinout = (
        <Alert bsStyle="success" >
          <h4>{this.state.responseMessage}</h4>
          <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
        </Alert>
      )
    }

    const { data } = this.state;
    const { agencyColumns = [{
      dataField: 'deviceSerialId',
      text: 'DEVICE SERIAL ID',
      filter: textFilter(),
      headerFormatter: headerFormatter
    }, {
      dataField: 'depotName',
      text: 'DEPOT',
      filter: textFilter(),
      headerFormatter: headerFormatter
    }, {
      dataField: 'currentState',
      text: 'DEVICE STATE',
      headerStyle: { width: '120px' },
      formatter: cell => selectOptions[cell],
          filter: selectFilter({
          options: selectOptions
      }),
      headerFormatter: headerFormatter
    }] } = this.state;

    const { manufacturerColumns = [{
      dataField: 'deviceSerialId',
      text: 'DEVICE SERIAL ID',
      filter: textFilter(),
      headerFormatter: headerFormatter
    }, {
      dataField: 'phoneNumber',
      text: 'SIM ID',
      filter: textFilter(),
      headerFormatter: headerFormatter
    }, {
      dataField: 'agencyName',
      text: 'AGENCY',
      filter: textFilter(),
      headerFormatter: headerFormatter
    }, {
      dataField: 'currentState',
      text: 'DEVICE STATE',
      formatter: cell => selectOptions[cell],
        filter: selectFilter({
        options: selectOptions
      }),
      headerStyle: { width: '120px' },
      headerFormatter: headerFormatter
    }, {
      dataField: 'action',
      text: 'ACTION',
      csvExport: false,
      headerFormatter: headerFormatter,
      isDummyField: true,
      headerStyle: { width: '120px' },
      formatExtraData: this.state.isLockLoader,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div>
            {this.state.isManufactureRegisterDevice &&
              <Link to={{ pathname: `/manufacture-edit-device/${row.deviceSerialId}`, state: { device: row } }} className="active"><i className="fe fe-edit"></i></Link>}
            {!this.state.isManufactureRegisterDevice &&
              <Link to={{ pathname: `/edit-device/${row.deviceSerialId}`, state: { device: row } }} className="active"><i className="fe fe-edit"></i></Link>}
            {row.currentState !== 'LOCKED' ? <i hidden={formatExtraData} onClick={() => this.lockDevice(row)} className="active download-icon cursor-pointer fe fe-lock paddingLeft15"></i> :
              <i hidden={formatExtraData} className="active download-icon fe fe-unlock paddingLeft15" onClick={() => this.unlockDevice(row)}></i>}
            {formatExtraData &&
              <img src="./images/download.gif" className="paddingLeft15" alt="Loading..." />
            }
          </div>
        );
      }
    }] } = this.state;

    let addDeviceButton = (<Grid>
      <Row className="show-grid">
        <Col xsHidden md={12}>
          {this.state.isManufactureRegisterDevice &&
            <a href="/manufacturer-register-device"><Button type="button" color="primary mb-2 mr-2 float-right">Add Device </Button></a>}
          {!this.state.isManufactureRegisterDevice &&
            <a href="/register-device"><Button type="button" color="primary mb-2 mr-2 float-right"> Add Device </Button></a>}
        </Col>
      </Row>
    </Grid>
    );

    return (
      <BaseLayout>
        <Page.Content>
          <Page.Header title="Device List" />
          {fadeinout}
          {addDeviceButton}
          <div className="card paddingTop15">
            <div className="card-body" >
              <div>
                <ToolkitProvider
                  keyField="deviceSerialId"
                  data={data}
                  columns={this.state.isManufactureRegisterDevice ? manufacturerColumns : agencyColumns}
                  exportCSV={{ fileName: "DEVICE-LIST".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
                >
                  {
                    props => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="deviceSerialId"
                          data={data}
                          bordered={false}
                          columns={this.state.isManufactureRegisterDevice ? manufacturerColumns : agencyColumns}
                          filter={filterFactory()}
                          pagination={paginationFactory(options)}
                        />
                        <hr />
                        <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                      </div>
                    )
                  }
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </Page.Content>
      </BaseLayout>
    );
  }
}
export default DeviceList;