// @flow
import * as React from "react";
import { Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { ETIMCommunicator, DeviceTranslator } from '../_components';
import MessageResponse from "../enums/MessageResponse";
import { ViewUtils } from "../utils/ViewUtils";
import { DutyAPI } from "../apis/DutyAPI";
import { AgencyAPI } from "../apis/AgencyAPI";
import {isMobile} from 'react-device-detect';
import DutyList from './DutyList';
import DateUtils from "../utils/DateUtils";
import { InventoryAPI } from "../apis/InventoryAPI";
import RepeatWaybill from "../pages/RepeatWaybill";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter, selectFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

const { ExportCSVButton } = CSVExport;

const options = {
  sizePerPage: 10,
  hideSizePerPage: false,
  hidePageListOnlyOnePage: true
};


const selectOptions = {
    'ASSIGNED': 'ASSIGNED',
    'STARTED':'STARTED',
    'ENDED': 'ENDED',
    'PENDING': 'PENDING',
    'COLLECTED': 'COLLECTED',
    'COLLECTION_MISMATCH': 'COLLECTION MISMATCH',
    'COLLECTED_WITH_ETIM_LOCKED': 'COLLECTED WITH ETIM LOCKED',
    'CANCELLED': 'CANCELLED',
  
  };

 
function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {filterElement}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold',color:'#495057', fontSize:'11px' }}>
          {column.text}
          {sortElement}
        </div>
        
      </div>
    );
  }
  
  function dateTimeFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      DateUtils.getFormattedDateFromDateString(cell)
    );
   }
  
  
class Dutys extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            datatransection: [],
            MemosId: [],
            waybillId: [],
            ConductorList: [],
            vehicleNumbers: [],
            data: [],
            offset: 0,
            //agencyCode: "GMCBL", //new agency
            isLoader: false,
            isloadingviewduty:false,
            from: new Date(),
            to: new Date(),
            isLoadingAssign:false,
            isLoadingCancelDuty: false,
            authkey: localStorage.getItem('authkey'),
            etmBResponseString: '',
            driverListFromDb: [], 
            conductorListFromDb: [], 
            imeiNumber: '',
            simId:'',
            swVersion:'',
            isLoadingDutyList: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.cancelDuty = this.cancelDuty.bind(this);
        this.showReplicateWaybillDialog = this.showReplicateWaybillDialog.bind(this);
        this.handleCloseReplicateDialog = this.handleCloseReplicateDialog.bind(this);
        this.repeatWaybillHandler = this.repeatWaybillHandler.bind(this);
    }
    
    GetDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }
    
    loadCommentsFromServer() {
        let currentComponent = this;
        currentComponent.setState({ isLoader: true, isLoadingDutyList: true});
        if(isMobile) {
            this.searchFilterData('', DateUtils.getDateFormatted(new Date()), false);
        } else {
            DutyAPI.getCompleteList(this.GetDateFormat(this.state.from), 
            this.GetDateFormat(this.state.to), 
            (response) => {

                currentComponent.setState({
                    isloadingviewduty:false,
                    data: response.data.data.content,
                    isLoader: false
                });
                currentComponent.mapData();
            },
            (error) => {
                currentComponent.setState({isLoader: false, isloadingviewduty:false });
            }
        );
        }
        
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let currentComponent = this;
        AgencyAPI.getVehicle(
            (response) => {
                if(response.data.code === 2000){
                    let deviceIdFromApi = response.data.data.map(device => { return { value: device.id, label: device.vehicleNumber }; });
                    currentComponent.setState({ vehicleNumbers: deviceIdFromApi });
                    currentComponent.mapData();
                }
            }
        );
        
        currentComponent.loadCommentsFromServer();
        
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        let driverListFromDb1 = [];
        let ConductorListFromApi = [];
        let condata;
        request.onsuccess = function (event) {
            var db = event.target.result;
            var conductor = db.transaction('conductor', 'readwrite').objectStore("conductor");
            var conductordata = conductor.getAll();

            conductor.getAll().onsuccess = function (event) {
                conductordata.result.forEach(conductor => {
                    if (conductor.designation === 'DRIVER') {
                        var driverData = {
                            value: conductor.employeeId,
                            name: conductor.name,
                            designation: conductor.designation,
                            employeeCode: conductor.employeeCode,
                            label: conductor.name + "/" + conductor.employeeCode
                        };
                        driverListFromDb1.push(driverData);
                    }
                    if (conductor.designation === 'CONDUCTOR') {
                        condata = {
                            value: conductor.employeeId,
                            name: conductor.name,
                            designation: conductor.designation,
                            employeeCode: conductor.employeeCode,
                            label: conductor.name + "/" + conductor.employeeCode
                        };
                        ConductorListFromApi.push(condata);
                    }
                });
                currentComponent.setState({ 
                    driverListFromDb: driverListFromDb1, 
                    conductorListFromDb: ConductorListFromApi, 
                    ConductorList: [{ value: '', label: 'Select Conductor' }].concat(ConductorListFromApi)
                });
                currentComponent.mapData();
            };
        };

    }

    mapData() {
        let currentComponent = this;
        let tempList = [];
        currentComponent.state.data.forEach((obj) => {
            currentComponent.state.conductorListFromDb.find(function(conductor) {
                if (conductor.value === obj.conductorId) {
                    obj.conductorName = conductor.label;
                }
                return obj.conductorName;
            });
            currentComponent.state.driverListFromDb.find(function(driver) {
                if (driver.value === obj.driverId) {
                    obj.driverName = driver.label;
                }
                return obj.driverName;
            });
            currentComponent.state.vehicleNumbers.find(function (vehicle) {
                if (vehicle.value === obj.vehicleId) {
                    obj.vehicleName = vehicle.label;
                }
                return obj.vehicleName;
            });
            obj.expanded = false;
            tempList.push(obj);
        });

        currentComponent.setState({data: tempList});
    }

    onDateChange(event) {
        if(event !== undefined) {
            this.setState({
                selectedReplicateDate: event.target.value,
            });    
        }
        
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    
    handleChangeEnd(date) {
        this.setState({
            endDate: date
        });
    }

    handleSubmitClick() {
        let from = this.state.startDate;
        let to = this.state.endDate;
        if (from === null || to === null) {
            alert("Please select date range!");
            return;
        }
        this.setState({ from: from, to: to, isloadingviewduty:true }, () => {
            this.loadCommentsFromServer();
        });
    }

    readEtmWayBill(conductorId, dutyDate) {
        let currentComponent = this;
        currentComponent.setState({ isLoadingAssign:true });
        
        ETIMCommunicator.postDownloadDataDeviceDetails(
            (response) => {
                
                currentComponent.setState({ 
                    etmBResponseString: response.data,
                    isLoadingAssign: false
                });
                currentComponent.props.handleShowMessageHandler(response.msgHandler);
                return currentComponent.sendDataToTranslator(conductorId, dutyDate);
            },
            (error) => {
                currentComponent.setState({ 
                    isLoadingAssign:false
                });
                currentComponent.props.handleShowMessageHandler(error.msgHandler);
            }
        );
    }

    sendDataToTranslator(conductorId, dutyDate) {
        let currentComponent = this;
        currentComponent.setState({ isLoadingAssign: true });
        let command = currentComponent.state.etmBResponseString;
        DeviceTranslator.getDeviceDetails(command,
            (response) => {
                let params = response.data.data;
                
                if ("simSerialNumber" in params){
                    let simno = params.simSerialNumber;
                    currentComponent.setState({ simId:  simno.slice(0, -1) });
                }
                if ("osVersion" in params){
                    currentComponent.setState({ swVersion: params.osVersion + "," + params.date });
                }
                if ("imeiNumber" in params){
                    currentComponent.setState({ imeiNumber: params.imeiNumber});
                }
                let obj = {}; 
                obj.imei =currentComponent.state.imeiNumber;
                obj.simSerialNumber = currentComponent.state.simId;
                obj.softwareVersion = currentComponent.state.swVersion;
                InventoryAPI.putDevice(params.deviceSerialId, obj,
                    (response) => {
                        //console.log(response); 
                    }
                );
                let deviceSerialId = params.deviceSerialId;
                let hexWayBillNo = params.hexWayBillNo;
                
                if(hexWayBillNo !== ""){
                    currentComponent.setState({ isLoadingAssign: false });
                    currentComponent.props.handleShowMessageHandler(ViewUtils.createMessageHandler("Waybill already exists in the ETIM Device", MessageResponse.ERROR));
                } else {
                    return currentComponent.startDuty(conductorId, deviceSerialId, dutyDate);
                }
            },
            (error) => {
                currentComponent.setState({
                    isLoadingAssign: false
                });
                currentComponent.props.handleShowMessageHandler(error.msgHandler);
            }
        );
    }

    startDuty(conductorId, deviceSerialId, dutyDate){
        this.setState({isLoadingAssign: true});
        let currentComponent = this;
        DeviceTranslator.convertDuty(conductorId, deviceSerialId, dutyDate,
            (response) => {
                ETIMCommunicator.postUploadData(response.data, 
                    (response) => {
                        currentComponent.setState({
                            isLoadingAssign: false,
                        });
                        var text = response.data;
                        if(text !== ''){
                            if (text.startsWith("O")) {
                                currentComponent.props.handleShowMessageHandler(ViewUtils.createMessageHandler('Started Duty Successfully!', MessageResponse.SUCCESS));
                            } else {
                                currentComponent.props.handleShowMessageHandler(ViewUtils.createMessageHandler('Start Duty Failed!', MessageResponse.ERROR));
                            }
                        }
                        return currentComponent.loadData();
                    },
                    (error) => {
                        currentComponent.setState({
                            isLoadingAssign: false,
                        });
                        currentComponent.props.handleShowMessageHandler(error.msgHandler);
                    }
                );
            },
            (error) => {
                currentComponent.setState({
                    isLoadingAssign: false
                });
                currentComponent.props.handleShowMessageHandler(error.msgHandler);
            }
        );
    }
 
    cancelDuty(dutyId){
        this.setState({isLoadingCancelDuty: true});
        let currentComponent = this; 
        let obj = {};
        obj.state = "CANCELLED";
        DutyAPI.putDuty(dutyId, obj,
            (response) => {
                var text = response.data;
                if(text !== ''){
                    currentComponent.props.handleShowMessageHandler(ViewUtils.createMessageHandler("Cancelled Duty successfully!", MessageResponse.SUCCESS));
                    currentComponent.setState({
                        isLoadingCancelDuty :false
                    });
                    if(isMobile) {
                        return currentComponent.searchFilterData('', DateUtils.getDateFormatted(new Date()), false);
                    } else {
                        return currentComponent.componentDidMount();
                    }
                }
            },
            (error) => {
                currentComponent.setState({
                    isLoadingCancelDuty: false
                });
                currentComponent.props.handleShowMessageHandler(ViewUtils.createMessageHandler("Cancel API is Down, Please try after some time!", MessageResponse.ERROR));
            }
        );
    }

    searchFilterData = (conductorId, dutyDate, showErrorMessage) => {
        let currentComponent = this;
        if(showErrorMessage) {
            currentComponent.props.handleShowMessageHandler(null);
        }
        currentComponent.setState({data:[], isLoadingDutyList: true});
        DutyAPI.getListFilter(dutyDate, conductorId, '',
        (response) => {
            let tempList = [];
            response.data.data.content.forEach((obj) => {
                obj.expanded = false;
                tempList.push(obj);
            });
            currentComponent.setState({ data: tempList, isLoadingDutyList: false });
            currentComponent.setState({
                isloadingviewduty:false,
                pageCount: Math.ceil(response.data.data.totalElements / response.data.data.size),
                isLoader: false
            });
            currentComponent.mapData();
        },
        (error) => {
            currentComponent.props.handleShowMessageHandler(error.msgHandler);
            currentComponent.setState({isLoader: false, isloadingviewduty:false, isLoadingDutyList: false });
        }
        );
    }

    fetchAgencyEndedOrPendingDuties() {

    }

    setMessagesFromDutyList() {

    }

    showReplicateWaybillDialog(obj) {
        this.setState({
            showReplicateDialog: true,
            replicateObj: obj
        });
    }

    handleCloseReplicateDialog() {
        this.setState({
            showReplicateDialog: false
        });
    }

    repeatWaybillHandler(success, msgHandler) {
        let currentComponent = this;
        if(success === 1 && !isMobile) {
            currentComponent.props.loadDutys();
        } else if(success === 1 && isMobile) {
            let currentComponent = this;
            currentComponent.dutyList.componentDidMount();
        }
        currentComponent.handleCloseReplicateDialog();
        currentComponent.props.handleShowMessageHandler(msgHandler);
    }

    render() {
        const { columns = [{
            dataField: 'waybillNumber',
            text: 'WAYBILL',
            filter: textFilter(),
            headerFormatter: headerFormatter
          }, {
            dataField: 'conductorName',
            text: 'BADGE NUMBER',
            filter: textFilter(),
            headerFormatter: headerFormatter
          }, {
            dataField: 'driverName',
            text: 'DRIVER',
            filter: textFilter(),
            headerFormatter: headerFormatter,
        }, {
            dataField: 'deviceSerialId',
            text: 'DEVICE NO',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'vehicleName',
            text: 'VEHICLE NO',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'dutyState',
            text: 'STATUS',
            formatter: cell => selectOptions[cell],
                filter: selectFilter({
                options: selectOptions
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'totalAmount',
            text: 'TOTAL AMOUNT',
            headerFormatter: headerFormatter
        }, {
            dataField: 'dutyDate',
            text: 'DUTY DATE',
            headerStyle: (colum, colIndex) => {
                return { width: '100px', textAlign: 'center' };
            },
            formatter: dateTimeFormatter,
            filter: dateFilter({
                comparators: [Comparator.GE, Comparator.LE]
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'action',
            text: 'DUTY ACTIONS',
            csvExport: false,
            headerStyle: (colum, colIndex) => {
                return { width: '160px', textAlign: 'center' };
            },
            headerFormatter: headerFormatter,
            isDummyField: true,
            formatExtraData: this.state,
            formatter: (cell, row, rowIndex, formatExtraData) => {
               let showDuty = '';
               if(row.dutyState === "ASSIGNED"){
                showDuty = (
                    <div>
                        {!isMobile && (<Button type="button"   hidden={this.state.isLoadingAssign} onClick={() => this.readEtmWayBill(row.conductorId, row.dutyDate)} className="btn btn-primary" >Start</Button>)}
                        {!isMobile && this.state.isLoadingAssign &&
                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                        }
                        <Button type="button"  hidden={this.state.isLoadingCancelDuty} onClick={() => this.cancelDuty(row.id)} className="btn btn-primary m-2" >Cancel</Button>
                        {this.state.isLoadingCancelDuty &&
                           <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                        }
                    </div>
                );
            } else if(row.dutyState === "STARTED" && !isMobile){
                showDuty = (
                    <div>
                        <Button type="button" hidden={this.state.isLoadingAssign} onClick={() => this.readEtmWayBill(row.conductorId, row.dutyDate)} className="btn btn-primary" >Re-Upload</Button>
                        {this.state.isLoadingAssign &&
                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                        }
                    </div>
                );
              } else if((row.dutyState === "ENDED" || row.dutyState === "COLLECTED") && !isMobile){
                showDuty = (
                      <div>
                          <RepeatWaybill showReplicateDialog={this.state.showReplicateDialog} replicateObj={this.state.replicateObj} repeatWaybillHandler={this.repeatWaybillHandler} handleCloseReplicateDialog={this.handleCloseReplicateDialog} isDutyPage={true}></RepeatWaybill>
                          <Button type="button" hidden={this.state.isLoadingRepeat} onClick={() => this.showReplicateWaybillDialog(row)} className="btn btn-primary" >Repeat</Button>
                          {this.state.isLoadingRepeat &&
                              <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                          }
                      </div>
                  );
            }
            return showDuty; 
            }
          }]} = this.state;
      
        let filterTransaction;
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        filterTransaction = (
            <div className="row row-cards">
                <div className="col-md-3 col-lg-2">
                    <div className="form-group">
                        <label className="form-label">From :</label>
                        <DatePicker name="transFromDate"
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            isClearable={true}
                            placeholderText="I have been cleared!"
                            className="form-control mb-2"
                        />
                    </div>
                </div>
                <div className="col-md-3 col-lg-2">
                    <div className="form-group">
                        <label className="form-label">To :</label>
                        <DatePicker name="transToDate"
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.endDate}
                            onChange={this.handleChangeEnd}
                            isClearable={true}
                            placeholderText="I have been cleared!"
                            className="form-control mb-2"
                        />
                    </div>
                </div>
                <div className="col-md-3 col-lg-2">
                    <div className="form-group">
                        <label className="form-label">&nbsp;&nbsp;</label>
                        <Button type="button"  onClick={this.handleSubmitClick} hidden={this.state.isloadingviewduty}  color="primary mb-2 ">Search</Button>
                        {this.state.isloadingviewduty &&
                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                        }
                    </div>
                </div>
            </div>
        );
        if(!isMobile) {
               return(
                   <div className="paddingTop15">
                  {filterTransaction}
                   <ToolkitProvider
                    keyField="srNo"
                    data={this.state.data}
                    columns={columns}
                    exportCSV={ { fileName: "DUTY-LIST_".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false } }
                  >
                    {
                      props => (
                        <div>
                          <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                          <hr />
                          <BootstrapTable
                            {...props.baseProps} bootstrap4
                            keyField="srNo"
                            data={this.state.data}
                            columns={columns}
                            bordered={false}
                            pagination={paginationFactory(options)}
                            filter={filterFactory()}
                            noDataIndication="No Data Found" />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div> );
        } else {
            return (
                <div>
                    <RepeatWaybill showReplicateDialog={this.state.showReplicateDialog} replicateObj={this.state.replicateObj} repeatWaybillHandler={this.repeatWaybillHandler} handleCloseReplicateDialog={this.handleCloseReplicateDialog} isDutyPage={true}></RepeatWaybill>
                    <DutyList ref={dutyList => this.dutyList = dutyList} dutyList={this.state.data} conductorList={this.state.ConductorList} searchFilterData={this.searchFilterData} fetchDutyList={this.fetchAgencyEndedOrPendingDuties} setMessage={this.setMessagesFromDutyList} showDateFilter={true} isViewDuty={true} cancelDuty={this.cancelDuty}  isLoadingDutyList={this.state.isLoadingDutyList} showReplicateWaybillDialog={this.showReplicateWaybillDialog}/> 
                </div>
                
            );
        }
    }
}

export default Dutys;