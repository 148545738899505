// @flow
import React from "react";
import { Grid } from "tabler-react";
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import "./alert.js";
import "../App.css";
import DatePicker from "react-datepicker";
import MessageResponse from "../enums/MessageResponse";
import { ViewUtils } from '../utils/ViewUtils';
import { DutyAPI } from "../apis/DutyAPI";
import DateUtils from "../utils/DateUtils";
import { isMobile } from 'react-device-detect';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import { isNullOrUndefined } from "util";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DutyList extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            conductorList: [],
            driverList: [],
            vehicleList: [],
            startDate: new Date(),
            isLoadingSearchFilter: false,
            isFilterExpanded: true,
            conductorName: '',
            cashCollected: '',
            cashSubmit: '',
            cashNote: '',
            isloading: false,
            iscashsubmit: false,
            errors: {},
            fields: {},
            matchcash: 'notcashmatch',
            selectedConductorName: '',
            open: false,
            selectedCard: null,
            isLoadingDutyList: false,
            dutyList: []
        };
        this.prevDateClick = this.prevDateClick.bind(this);
        this.nextDateClick = this.nextDateClick.bind(this);
        this.filterClick = this.filterClick.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        let currentComponent = this;
        currentComponent.enableNextButton();
    }

    componentWillUpdate() {
    }

    prevDateClick() {
        let currentComponent = this;
        let prevDate = DateUtils.getPrevDate(currentComponent.state.startDate);
        currentComponent.setState(
            {
                startDate: prevDate,
                endDate: prevDate
            }, function () {
                currentComponent.enableNextButton();
            }
        );
    }

    nextDateClick() {
        let currentComponent = this;
        let nextDate = DateUtils.getNextDate(currentComponent.state.startDate);
        currentComponent.setState(
            {
                startDate: nextDate,
                endDate: nextDate
            }, function () {
                currentComponent.enableNextButton();
            }
        );
    }

    enableNextButton() {
        let currentComponent = this;
        currentComponent.setState({
            isNextEnabled: DateUtils.isCurrentDate(currentComponent.state.startDate)
        });
    }

    handleExpandClick(selectedCard, index) {
        const newIds = this.props.dutyList.slice(); //copy the array
        newIds[index].expanded = !selectedCard.expanded; //execute the manipulations
        this.setState({ dutyList: newIds });
    }

    filterClick() {
        let currentComponent = this;
        let filterState = currentComponent.state.isFilterExpanded;
        currentComponent.setState({ isFilterExpanded: !filterState });
    }


    handleDateChange(date) {
        this.setState({
            startDate: date
        });
    }

    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {
            case 'cashSubmit': {
                return this.setState({ cashSubmit: itemValue, errors: {} });
            }
            case 'cashNote': {
                return this.setState({ cashNote: itemValue, errors: {} });
            }
            default:
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (this.state.cashSubmit === "") {
            formIsValid = false;
            errors["cashSubmit"] = "Cannot be empty";
        }
        if (this.state.cashSubmit !== "" && this.state.cashCollected !== "" && parseInt(this.state.cashSubmit, 10) !== parseInt(this.state.cashCollected, 10) && this.state.cashNote === "") {
            formIsValid = false;
            errors["cashNote"] = "Cannot be empty";
        }
        if ((this.state.dutyStatus === "PENDING" || this.state.dutyStatus === "MISMATCH") && this.state.cashNote === "") {
            formIsValid = false;
            errors["cashNote"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    submitcash() {
        if (this.handleValidation()) {
            this.setState({ iscashsubmit: true });
            let obj = {};
            let currentComponent = this;
            let collectiondetails = {};
            obj.state = "COLLECTED";
            collectiondetails.amountCollected = this.state.cashSubmit;
            collectiondetails.comments = this.state.cashNote;
            obj.collectionDetails = collectiondetails;
            DutyAPI.putDuty(this.state.dutyid, obj,
                (response) => {
                    var collectStates = ["COLLECTED", "COLLECTED_WITH_ETIM_LOCKED", "COLLECTION_MISMATCH"];
                    if (collectStates.some(v => response.data.data.dutyState === v)) {
                        currentComponent.setState({ dutyStateCollected: true });
                    }
                    let link = "/Waybilldetail/" + response.data.data.waybillNumber;
                    let waybillReportView = (
                        <p>
                            <a href={link} className="btn btn-primary m-2" >Waybill Report</a>
                        </p>
                    );

                    currentComponent.setState({
                        iscashsubmit: false,
                        iscashCollected: true,
                        hidesubmit: 'hidesubmitbtn',
                        conductorName: '',
                        memoid: '',
                        dutyStatus: '',
                        cashCollected: '',
                        waybillNumber: '',
                        dutyid: '',
                        cashSubmit: '',
                        cashNote: '',
                        msgHandler: isMobile ? ViewUtils.createMsgHandlerCustomView("Cash Collected successfully!", MessageResponse.SUCCESS) :
                            ViewUtils.createMsgHandlerCustomView("Cash Collected successfully!", MessageResponse.SUCCESS, waybillReportView)
                    });
                    currentComponent.props.setMessage(currentComponent.state.msgHandler);
                    currentComponent.props.searchFilterData(currentComponent.state.conductorId, DateUtils.getDateFormatted(this.state.startDate), true);
                    currentComponent.setState({ open: false, selectedCard: null });
                },

                (error) => {
                    currentComponent.setState({
                        iscashsubmit: false,
                        open: false,
                        selectedCard: null,
                        hidesubmit: 'hidesubmitbtn',
                        msgHandler: error.msgHandler
                    });
                    currentComponent.props.setMessage(currentComponent.state.msgHandler);
                    return;
                }
            );
        }
    }

    dropdownvalid(item, type) {
        switch (type) {
            case 'conductorId': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ conductorId: item, fields, errors: {} });
            }
            default:
        }
    }

    handleClickOpen(card, showMessage) {
        let currentComponent = this;
        let conductor_name = '';
        currentComponent.setState({ selectedCard: card, open: true });
        if (card.dutyState === 'ENDED') {
            if (showMessage) {
                currentComponent.setState({
                    msgHandler: ViewUtils.createMessageHandler("Duty has been Ended. Please do the Collection", MessageResponse.SUCCESS)
                });
                currentComponent.props.setMessage(currentComponent.state.msgHandler);
            }
            currentComponent.setState({ isloadingreadetm: false });
            currentComponent.setState({ readonly: false, hidesubmit: '', addstatusclass: 'green', dutyStateCollected: false });
        } else if (card.dutyState === 'PENDING') {
            currentComponent.setState({ readonly: false, hidesubmit: '', addstatusclass: 'pending', dutyStateCollected: false });
        } else if (card.dutyState === 'STARTED' || card.dutyState === 'ASSIGNED') {
            currentComponent.setState({ readonly: true, hidesubmit: 'hidesubmitbtn', addstatusclass: 'green', dutyStateCollected: false });
        } else if (card.dutyState === 'COLLECTED' || card.dutyState === 'COLLECTED_WITH_ETIM_LOCKED' || card.dutyState === 'COLLECTION_MISMATCH') {
            if (showMessage) {
                currentComponent.setState({
                    msgHandler: ViewUtils.createMessageHandler("Cash has already been Collected. You can Erase the Data from the ETIM!", MessageResponse.SUCCESS)
                });
                currentComponent.props.setMessage(currentComponent.state.msgHandler);
            }
            currentComponent.setState({ readonly: true, hidesubmit: 'hidesubmitbtn', addstatusclass: 'green', dutyStateCollected: true });
        } else {
            currentComponent.setState({ hidesubmit: 'hidesubmitbtn', addstatusclass: 'none', dutyStateCollected: false });
        }
        this.props.conductorList.find(function (element) {
            if (element.value === card.conductorId) {
                conductor_name = element.name;
            }
            return conductor_name;
        });
        currentComponent.setState({
            data: card,
            conductorId: card.conductorId,
            conductorName: conductor_name,
            waybillNumber: card.waybillNumber,
            dutyStatus: card.dutyState,
            cashCollected: card.totalAmount,
            dutyid: card.id,
            isloadingreadetm: false,
            isloadingconductor: false
        });
        return;
    }

    handleClose() {
        let currentComponent = this;
        currentComponent.setState({ open: false, selectedCard: null });
    };



    searchFilter(showErrorMessage) {
        let currentComponent = this;
        if (showErrorMessage) {
            currentComponent.setState({ responsemessage: '', showmessage: false });
        }
        let dutyDate = DateUtils.getDateFormatted(this.state.startDate);
        currentComponent.setState({ isloadingconductor: true, isLoadingDuty: true });
        if (this.state.startDate === '' && currentComponent.state.conductorId === '') {
            currentComponent.setState({
                isloading: false,
                showmessage: true,
                responsemessage: "Please select duty date or conductor."
            });
            return false;
        }
        currentComponent.setState({ isLoadingSearchFilter: true });
        currentComponent.props.searchFilterData(currentComponent.state.conductorId, dutyDate, showErrorMessage);
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        var opts = {};
        return (
            <div className="duty-page">
                <div>
                    <button type="button" onClick={() => this.filterClick()} className="btn duty-filter-btn">Filter<i className="fe fe-chevron-down"></i></button>
                    {this.state.isFilterExpanded && 
                    <div className="duty-filter-box">
                        <div className="duty-filter-search">
                            <div className="row">
                                <div className={this.props.showDateFilter===true?'cash-collection-filter-left col-width45':'cash-collection-filter-left width100'}>
                                    <label className="cash-collection-card-text">Conductor Name</label>
                                    <Select menuPlacement={isMobile?"top":"bottom"} options={this.props.conductorList} onChange={(item) => this.dropdownvalid(item.value, 'conductorId')} />
                                    <span style={{ color: "red" }}>{this.state.errors["conductorId"]}</span>
                                </div>
                                {this.props.showDateFilter && <div className="cash-collection-filter-right">
                                    <label className="cash-collection-card-text">Date</label>
                                    <br />
                                    <button type="button" onClick={() => this.prevDateClick()} className="btn btn-icon  btn-primary  btn-secondary arrowbtn"><i className="fe fe-chevron-left"></i></button>
                                    <DatePicker name="dutyDate"
                                        maxDate={new Date()}
                                        dateFormat="dd MMM, yyyy"
                                        selected={this.state.startDate}
                                        onChange={this.handleDateChange}
                                        //isClearable={true}
                                        placeholderText="Select Date"
                                        className="form-control mb-2 duty-date-picker"
                                    />
                                    <button type="button" disabled={this.state.isNextEnabled} onClick={() => this.nextDateClick()} className="btn btn-icon  btn-primary  btn-secondary arrowbtn"><i className="fe fe-chevron-right"></i></button>
                                </div>}
                            </div>
                            <div className="row duty-search">
                                <div className="col-md-12 col-lg-12">
                                    <button type="button" onClick={() => this.searchFilter(true)} className="btn btn-primary duty-search-btn">Search
                                                {this.state.isLoadingDutyList &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                {this.props.isLoadingDutyList && <div className="duty-loader"></div>}
                {(this.props.dutyList !== null && this.props.dutyList !== undefined && this.props.dutyList.length > 0) && <div className="cash-collection-click-label">Click to view more details</div>}
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <DialogContent>
                        {!isNullOrUndefined(this.state.selectedCard) && <div className="cash-collection-card-header">
                            <div className="cash-collection-row">
                                <div className="cash-collection-header-row">
                                    <div className="cash-collection-card-arrow-dialog">
                                        <i onClick={() => this.handleClose()} className="fe fe-chevron-left"></i>
                                    </div>
                                    <div className="cash-collection-card-arrow-dialog-right">
                                        <div className="cash-collection-card-label">Conductor No.</div>
                                        <div className="cash-collection-card-heading">{this.state.selectedCard.conductorName}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="cash-collection-row">
                                <div className="cash-collection-column">
                                    <div className="cash-collection-card-label">Waybill No.</div>
                                    <div className="cash-collection-card-text">{this.state.selectedCard.waybillNumber}</div>
                                </div>
                                <div className="cash-collection-column">
                                    <div className="cash-collection-card-label">Device No.</div>
                                    <div className="cash-collection-card-text">{this.state.selectedCard.deviceSerialId}</div>
                                </div>
                                <div className="cash-collection-column">
                                    <div className="cash-collection-card-label">Status</div>
                                    <div className="cash-collection-card-text-bold">{this.state.selectedCard.dutyState}</div>
                                </div>
                            </div>
                            <hr className="duty-dialog-hr" />
                            <Grid.Row lg={12}>
                                <Grid.Col lg={6} className="detailschildreadonlypad"> <label className="cash-collection-form-label">Cash To Be Collected</label>
                                    <div className="detailschildreadonly alignright">&#8377;{this.state.selectedCard.totalAmount}</div></Grid.Col>
                                <Grid.Col lg={6} className="detailschildreadonlypad"> <label className="cash-collection-form-label">Cash Submited</label>
                                    <input autoFocus {...opts} type="text" className={'duty-cash-submit-text form-control ' + this.state.matchcash} value={this.state.cashSubmit} onChange={(item) => this.valid(item, 'cashSubmit')} ></input>
                                    <span style={{ color: "red" }}>{this.state.errors["cashSubmit"]}</span>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row lg={12}>
                                <Grid.Col lg={12} className="detailschildreadonlypad"> <label className="cash-collection-form-label">Note</label>
                                    <textarea cols="2"   {...opts} placeholder="Enter a note in case of a mismatch" value={this.state.cashNote} className="form-control " onChange={(item) => this.valid(item, 'cashNote')} >{this.state.cashNote}</textarea>
                                    <span style={{ color: "red" }}>{this.state.errors["cashNote"]}</span>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row lg={12}>
                                <Grid.Col lg={12}>
                                    {!this.state.iscashsubmit && <Button type="button" disabled={this.state.cashSubmit.length > 0 ? false : true} onClick={() => this.submitcash()} className="btn btn-primary duty-search-btn" >
                                        Submit
                                    </Button>}
                                    {this.state.iscashsubmit && <div className="duty-loader"></div>}
                                </Grid.Col>
                            </Grid.Row>
                        </div>}
                    </DialogContent>
                </Dialog>
                <div className="cards-list">
                    {this.props.dutyList.map((card, i) => {
                        return <Card className="cash-collection-card" onClick={() => this.handleExpandClick(card, i)}
                            key={card.id}
                            card={card}
                        >
                            <CardContent className="cash-collection-card-content">
                                <div className="cash-collection-card-header">
                                    <div className="cash-collection-row">
                                        <div className="cash-collection-header-row">
                                            <div className="cash-collection-card-label">Conductor No.</div>
                                            <div className="cash-collection-card-heading">{card.conductorName}</div>
                                        </div>
                                        <div className="cash-collection-card-arrow">
                                            {!card.expanded && <i className="fe fe-chevron-down"></i>}
                                            {card.expanded && <i className="fe fe-chevron-up"></i>}
                                        </div>
                                    </div>
                                    <div className="cash-collection-row">
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Waybill No.</div>
                                            <div className="cash-collection-card-text">{card.waybillNumber}</div>
                                        </div>
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Device No.</div>
                                            <div className="cash-collection-card-text">{card.deviceSerialId}</div>
                                        </div>
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Status</div>
                                            <div className="cash-collection-card-text-bold">{card.dutyState}</div>
                                        </div>
                                    </div>
                                    {card.expanded && <div className="cash-collection-row">
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Driver No.</div>
                                            <div className="cash-collection-card-text">{card.driverName}</div>
                                        </div>
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Vehicle No.</div>
                                            <div className="cash-collection-card-text">{card.vehicleName}</div>
                                        </div>
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Duty Date</div>
                                            <div className="cash-collection-card-text">{card.dutyDate}</div>
                                        </div>
                                    </div>}
                                </div>
                            </CardContent>
                            {(card.expanded && this.props.isViewDuty === false) && <CardActions className="cash-collection-card-actions">
                                <button type="button" onClick={() => this.handleClickOpen(card)} className="btn btn-primary collectBtn">Collect</button>
                            </CardActions>}
                            {(card.expanded && this.props.isViewDuty === true && card.dutyState === 'ASSIGNED') && <CardActions className="cash-collection-card-actions">
                                <Button type="button"  hidden={this.state.isLoadingCancelDuty} onClick={() => this.props.cancelDuty(card.id)} className="btn btn-primary collectBtn" >Cancel</Button>
                                    {this.state.isLoadingCancelDuty &&
                                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                    }
                            </CardActions>
                            }
                            {(card.expanded && this.props.isViewDuty === true && (card.dutyState === 'ENDED' || card.dutyState === 'COLLECTED')) && <CardActions className="cash-collection-card-actions">
                                <Button type="button" onClick={() => this.props.showReplicateWaybillDialog(card)} className="btn btn-primary collectBtn" >Repeat</Button>
                            </CardActions>
                            }

                        </Card>
                    })}

                </div>
            </div>

                        
        );
}
}

DutyList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default DutyList;
