import * as firebase from "firebase/app";
import "firebase/messaging";
const firebaseConfig = {
   apiKey: "AIzaSyCgDkBNrGgWqHTuJkKIJO6pfRy43g5AaMM",
   authDomain: "afcsweb.firebaseapp.com",
   databaseURL: "https://afcsweb.firebaseio.com",
   projectId: "afcsweb",
   storageBucket: "afcsweb.appspot.com",
   messagingSenderId: "685118129007",
   appId: "1:685118129007:web:7a2794367e7d9f57c81e9a",
   measurementId: "G-VYJTNZHBH8"
};
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging.isSupported()?firebase.messaging():null;
if(firebase.messaging.isSupported()) {
   messaging.usePublicVapidKey("BG9EA3kdaLPPKGgBs5geq6zmYkKwxsmbjv_iaN6dlIlFqnfXObEFPT3sKOv44O3tbNwHOqDaq5AHv9ProH6StSY");
}
export { messaging };