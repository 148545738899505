import React from 'react';
import "./alert.js";
import "../App.css";
class Aboutus extends React.Component {
    
    render() {
        
        return(
            
            <div className={"page "}>
            <div className={"page-single"}>
                <div className={"container"}> 
                    <div className={"row"}>
                        <div className={"col-md-2"}>
                            <img src="/images/ridlr.png" alt="Ridlr AFCS" className="logo-login"></img>
                        </div>
                     </div>
                    <div className={"row login"}>
                        <div className={"card-body p-6"}> 
                            <h3 className="signincolor"><u><strong>About Us</strong></u></h3>
                            <p> Ridlr tells you exactly when the next bus, train or metro rail will arrive so you can plan your journey with ease, even using multiple modes of transport.</p>
                            <p>Timetables can be downloaded and used without an internet connection to view them later.</p>
                            <h4>Plan Your Travel</h4>
                            <p>Live arrival times & updates to keep you one step ahead.</p>
                            <h5>Personal City Guide</h5>
                            <p>Find the quickest, most convenient routes in real time across your city's entire transit system.</p>
                        </div>
                    </div>
                    <div className={"d-flex loginfooter"}>
                        <div className={"text-left ml-8 mt-2"}><a href="/login" className="policy-terms-color logintextfields mr-1"> Login </a></div>
                        <div className={"d-flex order-lg-2 mt-2 mr-8 ml-auto m-2 "}><a href="/privacy-policy" className="policy-terms-color logintextfields mr-1"> Privacy Policy </a> | <a href="/terms" className="policy-terms-color logintextfields ml-1">Terms & Use</a></div>
                    </div>
                </div>
            </div>
        </div>
            
        );
    }
}
export default Aboutus