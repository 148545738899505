import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';

export const AnalyticsAPI = {
    getReportsRevenue,
    getReportsRoute,
    getReportsWaybill,
    getMobileReports,
    getReports,
    getApcsReports,
    getApcsWaybillDetailsReport,
    getApcsTripDetailsReport,
    getApcsSurvellianceReport,
    getApcsLiveLocationReport

}

function getReportsRevenue(from, to, callbackSuccess, callbackError) {
    API.get("/apis/analytics/reports/revenue?platform=app&agencyCode=" + localStorage.getItem('agencyname') + "&from=" + from + "&to=" + to,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch reports data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getReportsRoute(from, to, callbackSuccess, callbackError) {
    API.get("/apis/analytics/reports/routes?platform=app&agencyCode=" + localStorage.getItem('agencyname') +
        "&from=" + from + "&to=" + to,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch reports route data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getReportsWaybill(waybillNumber, callbackSuccess, callbackError) {
    API.get("/apis/analytics/reports/waybill/" + waybillNumber + "?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch reports waybill data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getReports(reportType, from, to, page, size, callbackSuccess, callbackError) {
    let url = "/apis/analytics/reports/" + reportType + "&agencyCode=" + localStorage.getItem('agencyname') +
            "&from=" + from + "&to=" + to + "&page=" + page + "&size=" + size;
    API.get(url, 
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch reports data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getMobileReports(reportType, from, to, page, size, callbackSuccess, callbackError) {
    let url = "/apis/analytics/reports/" + reportType + "platform=app&agencyCode=" + localStorage.getItem('agencyname') +
            "&from=" + from + "&to=" + to + "&page=" + page + "&size=" + size;
    API.get(url, 
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch reports data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getApcsReports(from, to, callbackSuccess, callbackError) {
    API.get("/apis/analytics/apcs/waybill?agencyCode="+ localStorage.getItem('agencyname')
    +"&from=" + from + "&to=" + to,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch reports waybill data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getApcsWaybillDetailsReport(waybillNumber, callbackSuccess, callbackError) {
    API.get("/apis/analytics/apcs/waybill/"+ waybillNumber,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch apcs waybill details data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getApcsTripDetailsReport(waybillNumber, tripNumber, callbackSuccess, callbackError) {
    API.get("/apis/analytics/apcs/waybill/"+ waybillNumber + "/trip/" + tripNumber ,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch apcs waybill trip details data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getApcsSurvellianceReport(waybillNumber, tripNumber, callbackSuccess, callbackError) {
    API.get("/apis/apcs/surveillance/video/waybill/" + waybillNumber + "/trip/" + tripNumber ,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch apcs survelliance trip details data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}
function getApcsLiveLocationReport(deviceSerialId, callbackSuccess, callbackError) {
    API.get("/apis/apcs/tracking/location/" + deviceSerialId,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch apcs live location details data. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}


