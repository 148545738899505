// @flow
import React from "react";
import { Page  } from "tabler-react";
import { Alert,Button} from 'react-bootstrap';
import Select from 'react-select';
import "./alert.js";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI.js";

const expensesList = [
    { value: 'TOLL_TICKET', label: 'TOLL TICKET'},
    { value: 'ONLINE_EXPENSE', label: 'ONLINE EXPENSE'},
    { value: 'BUS_STAND_FEE', label: 'BUS STAND FEE'},
    { value: 'DIESEL_TICKET', label: 'DIESEL TICKET'},
    { value: 'HOTEL_EXPENSE_TICKET', label: 'HOTEL EXPENSE TICKET'},
    { value: 'WINDOW_BOOKING', label: 'WINDOW BOOKING'},
    { value: 'POLICE_WARRANT', label: 'POLICE WARRANT'},
    { value: 'DRIVER_BATA', label: 'DRIVER BATA'},
    { value: 'CONDUCTOR_BATA', label: 'CONDUCTOR BATA'},
    { value: 'TICKET_CHECKER_BATA', label: 'TICKET CHECKER BATA'},
    { value: 'TIME_KEEPER_CHARGE', label: 'TIME KEEPER CHARGE'},
    { value: 'CLEANER_BATA', label: 'CLEANER BATA'},
    { value: 'UNION_CHARGE', label: 'UNION CHARGE'},
    { value: 'POOJA_EXPENSE', label: 'POOJA EXPENSE'},
    { value: 'OTHER_EXPENSE', label: 'OTHER EXPENSE'}
];

class AddExpense extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            authkey: localStorage.getItem('authkey'),
            multipleExpenses:'',
            expenseTypeSelected:[],
            showMessage: false,
            responseMessage:'',
            isloading:false,
            errors: {},
            selectedExpenses: [],
            expensesList:[],
            configId: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
    }  

    handleDismiss() {
        this.setState({ showMessage: false });
    }

    handleChange() {
        this.setState({ isChecked: !this.state.isChecked })
    }
    
    handleValidation(){
        let errors = {};
        let formIsValid = true;
        if(!this.state.multipleExpenses) {
            formIsValid = false;
            errors["expenses"] = "Cannot be empty";
        }
        this.setState({errors: errors});
        return formIsValid;
    }
     
    
submitExpense() {
    if(this.handleValidation()){
    
    this.setState({expenseTypeSelected:[]});
    this.state.multipleExpenses.map(element => {
        return this.state.expenseTypeSelected.push(element.value);
    })
    
    let obj = {}
    obj.expenses = this.state.expenseTypeSelected;
    
    let currentComponent = this;
    currentComponent.setState({ isloading: true });
    AgencyAPI.putConfiguration(this.props.location.state.configId, obj,
        (response) => {
            currentComponent.setState({
                    responseMessage:'Expense added successfully!',
                    isloading: false,
                    multipleExpenses: '',
                    expenseTypeSelected: [],
                    showMessage:true,
                    params: ''
            });
        },
        (error) => {
            currentComponent.setState({isloading: false,responseMessage:error.message,showMessage:true  });
        }
    );
    }
}

    setExpenses(type, data){
        if(data === 'expenses'){
            this.setState({multipleExpenses:type});
        }
    }

    componentDidMount() {
        let expList = expensesList.map(
            exp => {
                    return { value: exp.value, label: exp.label } 
                }
            );

        this.setState(
            {
                expensesList: expList,
                configId: this.props.match.params.configId
            }
        );
        
    }

    componentWillReceiveProps() {
        
    }

    goBack(e) {
        this.props.history.push('/operator-setup#expenses');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }


render() {
    
    var stylescope = {
        splitterStyle: {
            margin: 5
            }
        };
    let fadeinout;
    let params = this.props.location.state.expenses.map(exp => {
        return { value: exp, label: exp.toString().replace(/_/g, ' ') }
    });
    
    if (this.state.showMessage) {
        fadeinout = (
            <div className="col-md-5">
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                <h4>{this.state.responseMessage}</h4> 
                    <p>
                    <Button className="btn-primary"  onClick={(event)=>this.goBack(event)} >View</Button>
                        <span> or </span>
                    <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                  </p>
                </Alert>
            </div>
            
        )
    }
    
    return (
        <BaseLayout>
            <Page.Content>
            
                <React.Fragment>
                    <form className="card">
                        <div className="card-body">
                        {fadeinout }
                        <h3 className="card-title">Expense Configration</h3>
                            <div className="row">
                            
                                <div className="col-sm-6 col-md-4">
                                    <div className="form-group">
                                        <label className="form-label">Expense Types<span className="form-required">*</span></label>
                                        <Select defaultValue={params} options={expensesList} isMulti onChange={(item) => this.setExpenses(item, 'expenses')}  />
                                        <span style={{color: "red"}}>{this.state.errors["expenses"]}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-2">
                                    <div className="form-group">
                                    <label className="form-label"> &nbsp; </label>
                                    <button type="button" onClick={() => this.submitExpense()} hidden={this.state.isloading} className="btn btn-primary">Save
                                   
                                </button>
                                {this.state.isloading &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                <a href="/operator-setup"> <Button type="button"  color="primary mb-2 mr-2 float-right"> View </Button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </React.Fragment>
                
            </Page.Content>
        </BaseLayout>
    );
}

}

export default AddExpense;