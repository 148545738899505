import React from 'react';
import "./alert.js";
import "../App.css";
class Terms extends React.Component {
    
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 20
            }
       };
        return(
            
                <div>
                    <div>
                    
                        <main className='main-content bgc-grey-100'>
                        <div id='mainContent'>
                            <div className="full-container">
                            <h1 className="text-center"><u><strong>OPERATOR TERMS AND CONDITIONS</strong></u></h1>
                            <p style={stylescope.splitterStyle}>These general terms of business ("Terms") apply to your access and use of the Ticketing Tools (defined and more particularly described below) owned and/or operated by us, pursuant to signing of the Operator Onboarding Form ("Form"). The Ticketing Tools are provided by ANI Technologies Private Limited ("us", "we", "our"), a company incorporated under the laws of India with its registered office in Regent Insignia, #414, 3rd Floor, 4th Block, 17th Main, 100 Feet Road, Koramangala, Bangalore - 560034 (include its affiliates, successors-in-interest and permitted assigns). </p>
                            <p style={stylescope.splitterStyle}>IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, YOU MUST IMMEDIATELY STOP ACCESSING AND USING THE TICKETING TOOLS BEING PROVIDED UNDER THESE TERMS. By accessing and using the Ticketing Tools, you accept and agree that you fully understand these Terms in the banner stated below, and that these Terms shall be a binding agreement between you and us. We may revise, amend or change these Terms, and you shall be responsible to check and review these Terms periodically. </p>
<ol>
        <li><strong><u>Definitions</u></strong>
        <ol type="a">
                <li>Registered Users shall mean registered users of the Platform including you, your authorised and appointed personnel (including employees and agents), and any third party who have been given access to or you have permitted to use the AFCS. </li>
                <li>On-Ground Users shall mean your Vehicle conductors or any other authorised and appointed personnel (including employees and agents) who have been permitted to use the Device.  </li>
                <li>Ticket means the ticket to your end-customer generated by you using the Device, for entry into and taking a ride on your Vehicle.    </li>
                <li>Vehicle shall mean your bus(es) and/or other stage carriage vehicles owned and operated by you in accordance with applicable law.  </li>
                <li>Onboarding Process shall mean the process of creating an account for you and your Registered Users to access and use the Ticketing Tools.  </li>
                <li>Ticketing Tools shall mean, wholly or partly:  
                    <ol type="i">
                        <li>the proprietary electronic ticketing management software, individually referred to as “Software” in these Terms.  </li>
                        <li>physical ticketing device with the pre-installed Software, individually referred to as “Device” in these Terms. Specifications for the Device will be communicated to you at the the time of signing the Form.   </li>
                        <li>automatic fare collection system, an electronic interface dashboard using which You may may view and access, on a real-time basis, any ticketing information regarding Your vehicle fleet, individually referred to as “AFCS” in these Terms; and </li>
                        <li>maintenance and support services, specifically Software and AFCS customer support services and Device maintenance services.  </li>
                    </ol>
                </li>
            </ol></li>
           
            <li><strong><u>What we provide</u></strong>
                <p>Upon your signing of the Form, completion of your Onboarding Process and subject to complete payment of the applicable subscriptions fees, the following services and products shall be provided by us to you: </p>
                    <ol type="a">
                        <li>a limited, non-exclusive, non-transferable, personal license applicable only to the territory of India from us to you for the use of the Software on the Device and the AFCS, and the right to possess and use the Device for the limited purpose describe in Clause 3 below (subject to the expiry of or our termination of these Terms). For the Software, his license is limited only to the use over the Devices. Other than as detailed under this sub-clause, no other right or license is granted to you. </li>
                        <li>the ability to receive ticketing information regarding Your vehicle fleet over the AFCS on a real-time basis.  This includes your Registered Users to filling in certain pre-uploaded forms, uploading the required data/documents, allotting Vehicles against routes and prices for Tickets, and access the real-time reporting and Ticket information viewing features on the ACFS. Such access and use shall only be permitted through the login credentials assigned to you. </li>
                        <li>customer support services for the Ticketing Tools.  </li>
                        <li>subject to natural wear and tear and the terms of Clause 3 below, provision of support and maintenance for the Devices, including repair  and provision of spare parts.  </li>
                        <li>Onboarding Process shall mean the process of creating an account for you and your Registered Users to access and use the Ticketing Tools.  </li>
                    
                    </ol>
                    <p>&nbsp;</p>
                    <p>You agree that in the event you cease to do business or close operations for a material term or are otherwise unable to offer transport service through your Vehicles for any reason, you shall inform us immediately and without undue delay and and we shall suspend your use and access of the Ticketing Tools. Within two (2) months of such notice, you shall return in whole any Device(s) provided by us and pay full subscription fees for this period.  Clause 3.7 of these Terms shall apply in the event of your failure to return the Devices, and we are entitled to take any further action necessary to recover the Devices from you. </p>      
            </li>
            <li><strong><u>Your use of the Device: </u></strong>
                <ol>
                    <li>You and your Registered Users shall use the Devices solely for the purpose of: 
                        <ol type="a">
                            <li>printing Tickets, </li>
                            <li>reviewing the particulars regarding the Tickets, and/or  </li>
                            <li>any other use that may be prescribed in writing by us in this regard. </li>
                        </ol>
                    </li>
                    <li>Other than in relation to Ticket issuing and viewing information related to these Tickets, no other purposes are either explicitly or implicitly agreed under these Terms. </li>
                    <li>You undertake to use the Device in compliance with the instructions for use that may be issued by us from time to time in this regard.  </li>
                    <li>You and Registered Users shall not remove the preloaded SIM card from the Device and/or make/receive calls, text messages, etc. Any use of the SIM card (including mobile data and GPS services) is configured by us and you shall not impair, tamper or otherwise circumvent such configuration. It is clarified that the SIM Card has been issued by a third party telecom service provider and that we have solely transferred to you a limited right to use this SIM Card as described herein, for the duration specified in the Form, exclusively for the purposes of issuing Tickets and the services to be provided under the Ticketing Tools. You acknowledge and agree that any unauthorized use or misuse of the SIM card by you shall be considered as a material breach of these Terms and render you liable for action under the relevant applicable laws.  </li>
                    <li>Upon delivery of the Device(s) to you, you shall be required to acknowledge the receipt of the Device in writing either through a delivery receipt or any other documentation as requested by us, however delivery of the Devices shall be deemed completed irrespective of your acknowledgement. Upon delivery of the Device to you, you shall be responsible for risk of loss, theft, damage or destruction to the Device. If the Device is lost or damaged, you shall continue to pay all subscription fees under the Form and shall immediately intimate us regarding such loss or damage.  </li>
                    <li>In the event that you, your employees or Registered Users damage the Device or any of its accessories, you shall endeavour to repair the same at additional cost to you and/or charge you for such loss. You agree that we shall be entitled to recover from you all amounts towards lost and/ or damaged Device and associated accessories provided to you by us. Conditions for fulfilment of support and maintenance shall be conveyed to you periodically. You acknowledge and agree that: <br></br>
In the event of loss, theft or non-repairable damage to Device: We are entitled to receive the subscription fees for the entire period of thirty-six (36) months as detailed in the Form. Notwithstanding anything in the Form, the ownership of the Device shall not transfer to you. 
 
In the event of partially repairable damage to Device: You will be responsible for full payment of repair charges. <br></br>
 
In the event of return of Device: You may terminate the Form by providing two (2) months’ prior notice, and you will return all Devices to us as of the date of termination. In the event that the returned Devices are damaged in any way, we may at our discretion either, (i) recover complete repair costs to reinstate the Device to working condition; or (ii) where the Device is not repairable, recover the value of the Device i.e. thirty-six (36) months’ subscription fees. 

                    </li>
                    <li>You undertake to return the complete Device and all its components in full working condition to us immediately upon: (i) the termination of these Terms, or (ii) when requested by us. The Device will be collected by us from you through our authorized representatives at an applicable cost of Rs. 200 (two hundred Rupees only). You acknowledge and agree that any failure on your part in returning the Devices in accordance with this sub-clause shall render you liable to pay a penalty of INR 50/- per day as of the date on which you are directed to return the Device, capped at INR 10,000. We reserve all rights to seek any damages or compensation against you in this regard as permitted under applicable law. 
                    </li>
                </ol>
            </li>
            <li><strong><u>Support and Maintenance: </u></strong>
                <ol>
                    <li>We shall provide certain support and maintenance services, such as cleaning, assembling, maintaining, and repairing the machine, its spare parts and/or accessories, and replacing damaged spares, mother board, Integrated Circuit (IC) and other parts, including but not limited to paper flap, display glass, keypad, printer parts, printer rolls, and any other parts damaged or non-operational due to natural wear and tear (excluding damage caused by fire, water or due to negligence), to return the Device to working condition..</li>
                    <li>We may, at our discretion, repair the Device using new parts or repaired parts, or replace the Device with an alternative in operating condition</li>
                    <li>The following are excluded from this Clause:
                        <ol type="a">
                        <li>damage resulting from insufficient packaging and/or improper packaging of Device re-shipped or dispatched by you to us; </li>
                            <li>communications issues associated with an unfavourable environment or failure of telecommunication. transmission services, local network issues and other such factors that are not within our control; </li>
                            <li>any Device or part thereof that is modified, removed, dismantled, added, re-assembled or otherwise changed or used in a manner contrary to these Terms, without our written consent;</li>
                            <li>issues relating to use of ancillary products, services or software that are not provided by us under these Terms. </li>
                        </ol>
                    </li>
                    <li>In the event that the Device is not repaired within a period of two (2) days from the date of receipt its receipt at the service centre (not accounting for any delays in repair, shipment or delivery that is not attributable to us), you will receive Rs. 50 (fifty Rupees only) for each Device per day of additional delay. We shall, at our discretion, determine whether such amount is payable to you for delays, subject to verification of records of servicing maintained by the service centre.</li>
                    <li>We shall provide continuous support for the Software and ACFS, which you may avail through our customer care support on the following number: 1800 121 3233. </li>
                </ol>
            </li>
            <li><strong><u>You further acknowledge and agree that:  </u></strong>
                <ol>
                    <li>you will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how, data, documentation or algorithms relevant to the Ticketing Tools; modify, translate, or create derivative works based Ticketing Tools; use the Ticketing Tools for any commercial or other purpose other than as provided under these Terms; for the benefit of any third party; or remove any proprietary notices or labels. </li>
                    <li>you will access and use the Ticketing Tools only in compliance with our standard instructions (guidelines) and all applicable laws and regulations. </li>
                    <li>you shall be responsible for obtaining and maintaining any other equipment and ancillary services needed to connect to, access or otherwise use the Ticketing Tools, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, “Ancillary Systems”). You shall also be responsible for maintaining the security of the Ancillary Systems, your accounts, passwords (including but not limited to administrative and user passwords) and files, and for all uses of your or Registered Users accounts or the Ancillary Systems with or without your knowledge or consent. </li>
                    <li>you are responsible for the acts and omissions of all Registered Users and your employees, agents and sub-contractors that access and use the Ticketing Tools. </li>
                    <li>you shall not give access to the Ticketing Tools to third party other than Registered Users without obtaining our written approval in advance. In the event that you do provide such access, you will remain fully liable for the acts, omission and defaults of your representatives/subcontractor. </li>
                    <li>for the purpose of use and access to the Ticketing Tools, you may be required to collect and share personal data, and that you shall at all times comply with all applicable data protection legislation</li>
                    <li>subject to the terms of the Form detailing ownership of the Device by you, you agree and accept that we shall be the sole and absolute owner of the Ticketing Tools. You shall not encumber or allow to be encumbered, pledge, sell, license, lease, or otherwise dispose of the Ticketing Tools in any manner. The ownership and full title in the Software and AFCS shall remain with us.</li>

                </ol>
            </li>
            <li><strong><u>Our Representation and Limited Warranties: </u></strong>
                <p>We represent and warrant that the Ticketing Tools supplied pursuant to these Terms have been produced, sold, delivered, and/or furnished in compliance with all applicable laws and as per these Terms. You acknowledge and agree that we are not the manufacturer of the Device and therefore cannot provide any warranties or guarantees viz-a-viz the quality, merchantability, and durability of the Device. You acknowledge using the Device keeping the aforementioned information in mind. We also warrant and guarantee we have full and valid title to supply the Ticketing Tools free from any liens or encumbrances. We do not guarantee or warrant that the Software and AFCS will be free from defects or malfunctions, and if any errors occur, we will use best endeavours to resolve such errors, and such Software and AFCS is provided on an as-is basis to you.  </p>
            </li>
            <li><strong><u>Invoicing and Payments  </u></strong>
                <p>You will pay us the subscription fees for the amounts and in the manner agreed in the Form for the use and access of the Ticketing Tools. We shall raise an invoice in accordance with the Form. If you believe that we have billed you incorrectly, you must contact us no later than fifteen (15) days after the closing date on the first billing statement in which the error or problem appeared, in order to receive any adjustments or credit, which shall be at our discretion. </p>
                <p>You shall unconditionally and irrevocably authorize our designated bank through standing instructions or Electronic Clearing System (ECS) instructions to debit your bank account towards the dues payable to us. Alternatively, where the ECS facility is not available to you, we may permit other modes of payment in writing on a case-to-case basis. You undertake that sufficient balance shall be made available for honouring all payments due under these payment methods.</p>
                <p>We reserve the right to suspend or withhold the access and use of the Ticketing Tools in case of continued delayed payments. You will pay penalties for delay in payment as agreed under the Form, and it is acknowledged and accepted that such liquidated damages amount is a correct representation of costs we will suffer as a result of such delay or deficiency in payment. The foregoing is without prejudice to any other costs or damages suffered due to non-performance of your obligations related to the Form or these Terms.    </p>
            </li>
            <li><strong><u>Applicable Taxes   </u></strong>
                <p>The subscription fees in the Form are inclusive of taxes. You agree to adhere to all tax laws including by not limited to Goods and Service Tax and Income Tax. You agree to provide the GSTIN and the corresponding address for the issuance of invoices, and the declaration under the Form. In case your details are not provided or are provided later than the time of issue of invoice, we shall not be obliged to revise any invoice. </p>
            </li>
            <li><strong><u>Additional costs   </u></strong>
                <p>The price indicated in the Form represents the total purchase price, including but not limited to packing and delivery costs. </p>
            </li>
            <li><strong><u>Audit   </u></strong>
                <p>During the Term of the Form, and for a period of one (1) year thereafter, we may audit, inspect, make copies or extracts, or request for copies or extracts, of relevant records and documents associated with your performance of, and compliance with, this Terms (“Documents”). We may conduct such audit either directly or through our auditors, consultants or agents during normal business hours upon prior notice. We and our identified personnel and agents will have unrestricted access to the Documents, whether maintained electronically or otherwise. </p>
            </li>
            <li><strong><u>Audit   </u></strong>
                <p>During the Term of the Form, and for a period of one (1) year thereafter, we may audit, inspect, make copies or extracts, or request for copies or extracts, of relevant records and documents associated with your performance of, and compliance with, this Terms (“Documents”). We may conduct such audit either directly or through our auditors, consultants or agents during normal business hours upon prior notice. We and our identified personnel and agents will have unrestricted access to the Documents, whether maintained electronically or otherwise. </p>
            </li>
            <li><strong><u>Relationship between you and us   </u></strong>
                <p>During the Term of the Form, and for a period of one (1) year thereafter, we may audit, inspect, make copies or extracts, or request for copies or extracts, of relevant records and documents associated with your performance of, and compliance with, this Terms (“Documents”). We may conduct such audit either directly or through our auditors, consultants or agents during normal business hours upon prior notice. We and our identified personnel and agents will have unrestricted access to the Documents, whether maintained electronically or otherwise. </p>
            </li>
            <li><strong><u>Confidentiality   </u></strong>
                <p>You understand that these Terms, all communication and all information whether oral or in writing, exchanged by the parties are confidential and/or proprietary in nature and will be disclosed only to your employees or agents, who, in furtherance of the Terms, have a need to know such information. No information will be disclosed by you to any third party without our prior written consent, except where such information is requested further to legislation or a court order. You will exercise reasonable and sufficient level of care to protect our confidential information.  </p>
            </li>
            <li><strong><u>Intellectual Property   </u></strong>
                <p>Both parties shall continue to be the sole and exclusive owners of their respective intellectual property including trademarks and service names, texts, logos, graphics, photos, design, marketing materials as existing on the date of signing of the Form and for the duration of the Form.  </p>
                <p>We are the sole and absolute owner of all intellectual property rights (whether registered or not, and irrespective of the medium of representing such intellectual property) in the Ticketing Tools. You will not sell, license, encumber or allow to be encumbered, pledge or otherwise exploit any hardware, software, equipment in relation to the Ticketing Tools as security in any manner.  </p>
                <p>Any intellectual property developed by you while performing the obligations under this Form and Terms is transferred automatically to us on the moment of creation with full title guarantee, which transfer is hereby accepted by us.   </p>
            </li>
            <li><strong><u>Limitation of Liability   </u></strong>
                <p>Notwithstanding anything contained in these Terms and Form, our total aggregate liability arising out of or in connection with the performance or contemplated performance shall be limited to Rs. 10,000 (ten thousand Rupees only). </p>
            </li>
            <li><strong><u>Governing Law and Jurisdiction   </u></strong>
                <p>These Terms shall be interpreted, governed, and construed under the laws of India. The courts located in Bengaluru, India shall have exclusive jurisdiction under these Terms.   </p>
            </li>
            <li><strong><u>Termination   </u></strong>
                <p>Unless agreed otherwise between the parties, these Terms and the Form shall terminate automatically on the expiry of the duration specified in the Form. We can terminate these Term without cause by giving seven (7) days’ prior written notice to you. You may terminate the Terms forthwith in the event of any material breach of the Terms, and if such breach continues to be uncured for a period of sixty (60) days, after a written notice has been served. You may also terminate the Terms by providing us advance notice of sixty (60) days. We shall have no further obligation to you in the event of such termination, however any of your obligations under these Terms shall survive termination, and pending payments due under the Form by you shall remain due until completely disbursed. It is clarified that you shall not have access to or use the Ticketing Tools unless you have signed the Form, and we retain the right to suspend and withhold your access to the Ticketing Tools in case of unauthorised access or use. We retain the right to suspend access in case we believe the Ticketing Tools are used inappropriately or the login credentials are incorrectly used. Upon termination, you will immediately cease all use and access of the Ticketing Tools and return Devices in accordance with these Terms.  </p>
            </li>
            <li><strong><u>Notices   </u></strong>
                <p>Any notice or other communication given pursuant to this Terms must be in writing and (a) delivered personally or by courier, (b) sent by registered mail with acknowledgment due, postage prepaid, at the present addresses set forth in this Terms or Forms, unless and until a different address has been given by written notice to the other party, or (c) sent by electronic mail.  </p>
            </li>
            <li><strong><u>Anti-Bribery   </u></strong>
                <p>You shall take all reasonable steps to ensure that there is no slavery, servitude, forced or compulsory labour or human trafficking occurring in your business or in your supply chain. A breach of this clause by you shall constitute a material breach entitling us to immediately terminate these Terms on written notice. </p>
            </li>
            <li><strong><u>Survival   </u></strong>
                <p>Notwithstanding the termination of these Terms, the provisions regarding Confidentiality, Intellectual Property, Indemnity, Governing Law and Jurisdiction, Termination, and such other clauses which by their nature and context are intended by both the parties to remain binding post the expiry/termination of these Terms, shall survive and remain in effect in accordance with their terms.</p>
            </li>
            <li><strong><u>Electronic Record   </u></strong>
                <p>This document is an electronic record in terms of Information Technology Act, 2000 and the rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. Acceptance in accordance with these Terms shall constitute consent as under applicable law. </p>
            </li>
            <li><strong><u>Entire Agreement   </u></strong>
                <p>These Terms shall constitute the entire and final statement of the agreement between the parties with respect to the subject matter hereof and supersedes all prior and contemporaneous discussions, communications, negotiations and agreements, written or oral, with respect to the subject matter hereof. In the event of any conflict between the Terms set out herein and Form, the terms set out in the Form shall prevail to the extent of conflict. Notwithstanding anything to the contrary contained herein, if the parties have executed a service agreement which governs the access and use of the Ticketing Tools in issue, the terms of such service agreement shall take precedence over these Terms and any additional or different terms contained in any document generated by us.</p>
            </li>
</ol>

                            <p className="text-center m-4"><a href="/login"> <button type="button"  className="btn submit-btn-bg "> Back </button></a></p>
                            </div>
                        </div>
                        </main>
                    </div>
                    </div>
            
        );
    }
}
export default Terms