import * as React from "react";
import { Page,Grid} from "tabler-react";
import { Button, Alert } from 'react-bootstrap';
import BaseLayout from "../BaseLayout";
import Select from 'react-select';
import './Formstyle.css';
import { RFIDCardCommunicator } from "../../_components";
import { ViewUtils } from "../../utils/ViewUtils";
import MessageResponse from "../../enums/MessageResponse";
import { PassAPI } from "../../apis/PassAPI";
import { OrderAPI } from "../../apis/OrderAPI";

class Reloadpass extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            RouteList:[],
            StopList:[],
            startDate: new Date(),
            first_name:'',
            last_name:'',
            passSerialNumber:'',
            availableBalance:'',
            bonusAmount:'',
            totalAmount:'',
            passType:'',
            passProductId:'',
            rechargeAmount:'',
            variantId:'',
            passId:'',
            isloadingfare:false,
            expirydate:'',
            errors:{},
            isloadingsearch:false,
            isloadingreadcard:false,
            fetchcarddetails:true,
            disablereloadbutton:true,
            isloadingwrite:false,
            showmessage:false,
            responsemessage:'',
            varianttypeList: [],
            variantmsg: '',
            variantstatus: false,
            msgHandler: null,
            showStoredValuePassDetail: false,
            showroutedetail:false,
            route:'',
            stopsdropdowndata:[],
            stopFrom:'',
            stopTo:'',
            authkey: sessionStorage.getItem('authkey')
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    componentDidMount() {
        let currentComponent = this;
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function (event) {
            var db = event.target.result;
            var routes = db.transaction('routes', 'readwrite').objectStore("routes");
            var stops = db.transaction('stops', 'readwrite').objectStore("stops");
            let routedata = routes.getAll();
            let stopsdata = stops.getAll();
            // get data from Stops object
            routes.getAll().onsuccess = function (event) {
                let routesFromApi = routedata.result.map(route => { return { value: route.id, label: route.agencyRouteId, stops: route.stops }; });
                currentComponent.setState({ RouteList: [{ value: '', label: 'Select Route' }].concat(routesFromApi) });
            };
            stops.getAll().onsuccess = function (event) {
                let stopFromApi = stopsdata.result.map(stop => { return { value: stop.id, label: stop.longName }; });
                currentComponent.setState({ StopList: [{ value: '', label: 'Select Stop' }].concat(stopFromApi) });
            };
        };
    }

    fetchFare() {
        this.setState({ isloadingfare: true });
        let currentComponent = this;
        let obj = {};
        obj.routeId         =  this.state.route;
        obj.fromStopId      =  this.state.stopFrom;
        obj.toStopId        =  this.state.stopTo;
        obj.passProductId   =  this.state.passProductId;
        obj.variantId       =  this.state.variant;
        PassAPI.postProductCalculatePassFare(obj,
            (response) => {
                currentComponent.setState({isloadingfare: false,rechargeAmount:response.data.data.amount});
            }
        );
    }


    getDateofbirth(ticks){
        var date = new Date(ticks);
        var t = (date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear());
        return t;
    }
    
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
            return v.toString(16);
        });
    }

    getcurrentTime(){
        var Time_retrun = new Date();
        return Time_retrun.getTime();
    }
    
    convertDateIntoTime(t){
        var Time_retrun = new Date(t);
        return Time_retrun.getTime();
    }
     
    handleDismiss() {
        this.setState({ showmessage: false });
    }

    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {
            case 'passSerialNumber': {
                this.setState({ passSerialNumber: itemValue, errors: {} });
                break;
            }
            case 'rechargeAmount': {
                const re = /^[0-9\b]+$/;
                if (itemValue !== "" && !re.test(itemValue)) {
                    // Filter non-digits from input value.
                    return false;
                } else if(itemValue === ""){
                    this.setState({ disablereloadbutton: true, rechargeAmount: itemValue, bonusAmount: '', totalAmount: parseFloat(this.state.availableBalance, 10), errors: {} });
                } else {
                    var rechargeAmount = parseFloat(itemValue, 10);
                    this.setState({ disablereloadbutton: false, rechargeAmount: rechargeAmount, errors: {} });
                }
                break;
            }
            default:
        }
    }

    searchCardDetails(mode){
        let errors = {};
        let currentComponent = this;
        if(this.state.passSerialNumber === ''){
            errors["passSerialNumber"] = "Cannot be empty";
            this.setState({errors: errors});
            return false;
        } else {
            this.setState({ isloadingsearch: true, fetchcarddetails: true, disablereloadbutton: true });
            return PassAPI.getPassDetails(this.state.passSerialNumber ,
                (response) => {
                    currentComponent.setState({
                        passId              : response.data.data.id,
                        customerId          : response.data.data.customer.id,
                        first_name          : response.data.data.customer.firstName,
                        last_name           : response.data.data.customer.lastName,
                        availableBalance    : parseFloat(response.data.data.availableBalance, 10),
                        rechargeAmount      : '',
                        bonusAmount         : '',
                        totalAmount         : parseFloat(response.data.data.availableBalance, 10),
                        passType            : response.data.data.passType,
                        productType         : 'PASS',
                        passProductId       : response.data.data.passProductId, 
                        passSerialNumber    : response.data.data.passSerialNumber,
                        variantId           : response.data.data.variantId,
                        isloadingsearch     : false,
                        fetchcarddetails    : false,
                        expirydate          : currentComponent.getDateofbirth(response.data.data.expirationDate)
                    });
                    switch(mode){
                        default:
                        case "READ":
                            return;
                        case "WRITE":
                            let cardwriteCommand = response.data.data.rfidFormat.commands;
                            let string = cardwriteCommand.toString();
                            let outputcommand = string.replace(/,/g , '|');
                            return currentComponent.writeToCard(outputcommand);
                    }
                },
                (error) => {
                    currentComponent.setState({
                        passId              : '',
                        customerId          : '',
                        first_name          : '',
                        last_name           : '',
                        availableBalance    : '',
                        rechargeAmount      : '',
                        bonusAmount         : '',
                        totalAmount         : '',
                        passType            : '',
                        productType         : '',
                        passProductId       : '',
                        passSerialNumber    : '',
                        variantId           : '',
                        expirydate          : '',
                        isloadingsearch     : false,
                        fetchcarddetails    : true,
                        disablereloadbutton : true,
                    });
                    if(error.status !== null) {
                        currentComponent.setState({
                            msgHandler: ViewUtils.createMessageHandler(error.data.message, MessageResponse.ERROR)
                        });
                    } else {
                        currentComponent.setState({
                            msgHandler: ViewUtils.createMessageHandler("Fetch API is down. Please try after some time", MessageResponse.ERROR)
                        });
                    }
                }
            );
        }
    }
    
    readCardData(){
        let currentComponent = this;
        currentComponent.setState({ isloadingreadcard: true, fetchcarddetails: true, disablereloadbutton: true });
        PassAPI.getReadCardCommands(
            (response) => {
                var cardreadCommand = response.data.data;
                let string = cardreadCommand.toString();
                let outputcommand = string.replace(/,/g , '|');
                RFIDCardCommunicator.postRFIDData(outputcommand,
                    (response) => {
                        var blocks = response.data.split('|').filter(function(v){return v !== '' && v !== '#xFF\n' && v !== '#xF6\n';});
                        return currentComponent.interpretCardDetails(blocks);
                    },
                    (error) => {
                        currentComponent.setState({
                            isloadingreadcard   : false,
                            fetchcarddetails    : true,
                            disablereloadbutton : true,
                            msgHandler: error.msgHandler
                        });
                    }
                );
            },
            (error) => {
                currentComponent.setState({
                    isloadingreadcard   : false,
                    fetchcarddetails    : true,
                    disablereloadbutton : true,
                    msgHandler: error.msgHandler,
                });
            }
        );
    }
    
    interpretCardDetails(cardData){
        let currentComponent = this;
        currentComponent.setState({
            isloadingreadcard   : true,
            showmessage         : true,
            responsemessage     : "Fetching Card Details"
        });
        let obj = {};
        obj.agencyCode  = localStorage.getItem('agencyname');
        obj.cardType    = "PASS";
        obj.cardData    = cardData;
        PassAPI.postCardInterpret(obj,
            (response) => {
                currentComponent.setState({
                    passId              : response.data.data.id,
                    customerId          : response.data.data.customer.id,
                    first_name          : response.data.data.customer.firstName,
                    last_name           : response.data.data.customer.lastName,
                    availableBalance    : parseFloat(response.data.data.availableBalance, 10),
                    rechargeAmount      : '',
                    bonusAmount         : '',
                    totalAmount         : parseFloat(response.data.data.availableBalance, 10),
                    passType            : response.data.data.passType,
                    productType         : 'PASS',
                    passProductId       : response.data.data.passProductId, 
                    passSerialNumber    : response.data.data.passSerialNumber,
                    variantId           : response.data.data.variantId,
                    isloadingsearch     : false,
                    fetchcarddetails    : false,
                    expirydate          : currentComponent.getDateofbirth(response.data.data.expirationDate),
                    isloadingreadcard   : false,
                    showmessage         : true,
                    responsemessage     : "Read Card Data Successfully!"
                });
                currentComponent.getVariant(currentComponent.state.passProductId, currentComponent.state.passType);
            },
            (error) => {
                currentComponent.setState({
                    passId              : '',
                    customerId          : '',
                    first_name          : '',
                    last_name           : '',
                    availableBalance    : '',
                    rechargeAmount      : '',
                    bonusAmount         : '',
                    totalAmount         : '',
                    passType            : '',
                    productType         : '',
                    passProductId       : '',
                    passSerialNumber    : '',
                    variantId           : '',
                    expirydate          : '',
                    isloadingreadcard   : false,
                    msgHandler          : error.msgHandler
                });
            }
        );
    }
    
    handleValidation(){
        let errors = {};
        let formIsValid = true;
        if(this.state.passSerialNumber === ''){
            formIsValid = false;
            errors["passSerialNumber"] = "Cannot be empty";
            this.setState({ errors: errors });
            return false;
        }
        if(this.state.rechargeAmount === ''){
            formIsValid = false;
            errors["rechargeAmount"] = "Cannot be empty";
            this.setState({ errors: errors });
            return false;
        }
        if(parseFloat(this.state.rechargeAmount, 10) === 0){
            formIsValid = false;
            errors["rechargeAmount"] = "Amount Should be greater than 0";
            this.setState({ errors: errors });
            return false;
        }
        return formIsValid;
    }

    reloadCardBalance(){
        let currentComponent = this;
        currentComponent.setState({ isloadingwrite: true});
        if(currentComponent.handleValidation()){
            let obj = {};
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.customerId              = this.state.customerId; 
            obj.clientTransactionId     = this.uuidv4();
            obj.firstName               = this.state.first_name;
            obj.lastName                = this.state.last_name;
            obj.availableBalance        = parseFloat(this.state.availableBalance, 10);
            obj.passType                = this.state.passType;
            obj.productType             = this.state.productType;
            //obj.expirydate              = this.convertDateIntoTime(this.state.expirydate);
            obj.taxAmount               = 0;
            obj.passProductId           = currentComponent.state.passProductId;
            obj.dutyId                  = this.uuidv4();
            if(this.state.showStoredValuePassDetail) {
                obj.bonusAmount = parseFloat(this.state.bonusAmount); 
                obj.baseAmount = parseFloat(this.state.fareAmount);
                obj.totalAmount = parseFloat(this.state.fareAmount) + parseFloat(this.state.bonusAmount);
            } else {
                obj.sourceStopId = this.state.stopFrom;
                obj.destinationStopId = this.state.stopTo;
                obj.routeId = this.state.route;
                obj.baseAmount = parseFloat(this.state.rechargeAmount);
                obj.totalAmount = parseFloat(this.state.rechargeAmount);
            }
            obj.availableBalance = parseFloat(this.state.rechargeAmount);
            obj.totalBalance = parseFloat(this.state.rechargeAmount);
            obj.transactionDateTime     = this.getcurrentTime();
            obj.paidBy                  = 'CASH';
            obj.variantId               = currentComponent.state.variantId;
            obj.action                  = 'RELOAD';
            obj.passSerialNumber        = this.state.passSerialNumber;
            obj.passId                  = this.state.passId;
            OrderAPI.postOrder(obj,
                (response) => {
                    return currentComponent.searchCardDetails("WRITE");
                },
                (error) => {
                    currentComponent.setState({
                        isloadingwrite:false,
                        showmessage :true,
                        responsemessage: error.message
                    });
                    return;
                }
            );
        } else {
            currentComponent.setState({ isloadingwrite:false });
        }
    }

    getVariant(productId, passType) {
        let currentComponent = this;
        PassAPI.getProductIdVariant(productId,
            (response) => {
                let varianttypeFromApi = response.data.data.map(device => { return { value: device.id, label: device.title, tripValue: device.tripValue, tripPerDay: device.tripPerDay, validity: device.validityMultiplier, fareAmount: device.fareAmount, bonusAmount: device.bonusAmount }; });
                currentComponent.setState({ variantstatus: false, varianttypeList: [{ value: '', label: 'Select variant type' }].concat(varianttypeFromApi) });
            },
            (error) => {
                currentComponent.setState({
                    msgHandler: error.msgHandler,
                    variantmsg: "Failed to Fetch Variants", variantstatus: true, varianttypeList: []
                });
            }
        );
    }

    writeToCard(outputcommand) {
        let currentComponent = this;
        currentComponent.setState({isloadingwrite:true});
        RFIDCardCommunicator.postRFIDData(outputcommand,
            (response) => {
                var blocks = response.data.split('|').filter(function(v){return v !== '' && v !== '#xFF\n' && v !== '#xF6\n';});
                currentComponent.printCallBack(blocks);
            },
            (error) => {
                currentComponent.setState({ isloadingwrite: false, msgHandler: error.msgHandler });
            }
        );
    }

    getRoute(routedata) {
        let currentComponent = this;
        currentComponent.state.stopsdropdowndata = [];
        this.setState({ stops: routedata.stops });
        var stopsdropdown = [];
        if (routedata.stops) {
            routedata.stops.forEach(obj => {
                currentComponent.state.StopList.forEach(element => {
                    if (element.value === obj.stopId) {
                        stopsdropdown = {
                            value: obj.stopId,
                            label: element.label
                        };
                        currentComponent.state.stopsdropdowndata.push(stopsdropdown);
                    }
                });
            });
            this.setState({ route: routedata.value, stopsdropdowndata: currentComponent.state.stopsdropdowndata });
        }
        return;
    }

    getStop(stopid, type) {
        if (type === 'stopFrom') {
            this.setState({ 'stopFrom': stopid });
        } else {
            this.setState({ 'stopTo': stopid });
        }
    }

    
    getVariantDetails(variant) {
        if (this.state.passType === 'STORED_VALUE') {
            this.setState({ showroutedetail: false, showStoredValuePassDetail: true });
        } else {
            this.setState({ showroutedetail: true, showStoredValuePassDetail: false });
        }
        this.setState({
            tripvalidity: variant.validity,
            tripValue: variant.tripValue,
            tripPerDay: variant.tripPerDay,
            variant: variant.value,
            fareAmount: variant.fareAmount,
            bonusAmount: variant.bonusAmount,
            rechargeAmount: variant.fareAmount + variant.bonusAmount,
            disablereloadbutton: false,
            variantId: variant.value

        });
    }

    printCallBack(cardData){
        let currentComponent = this;
        let obj = {};
        obj.agencyCode = localStorage.getItem('agencyname');
        obj.passSerialNumber = this.state.passSerialNumber;
        obj.responseData = cardData;
        PassAPI.putPrintedCard(obj, 
            (response) => {
                currentComponent.setState({
                    isloadingwrite      : false,
                    fetchcarddetails    : true,
                    disablereloadbutton : true,
                    msgHandler: ViewUtils.createMessageHandler("Card Write Successfully.", MessageResponse.SUCCESS)
                });
            },
            (error) => {
                currentComponent.setState({
                    isloadingwrite  : false,
                    msgHandler: error.msgHandler
                });
            }
        );
    }

    render(){
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="info" className="col-lg-12 m-2" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            );
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Reload Pass" />
                    {fadeinout}
                    {this.state.msgHandler}
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                                <h4>Read Card</h4><br/>
                                <div className="row">
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <button type="button" onClick={() => this.readCardData()} hidden={this.state.isloadingreadcard} className="form-control btn btn-primary">
                                                Read
                                            </button>
                                            {this.state.isloadingreadcard &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        Please place pass on the card reader and then click to read
                                    </div>
                                </div>
                                <Grid.Col lg={12}>
                                    <Grid.Col lg={6}>
                                        <Grid.Row lg={4} className="detailschild">
                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Pass Number</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="pass_number" value={this.state.passSerialNumber} readOnly></input></Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row lg={4} className="detailschild">
                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Expiry Date</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="expirydate" value={this.state.expirydate} readOnly></input></Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row lg={4} className="detailschild">
                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Current Balance</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="availableBalance" value={this.state.availableBalance} readOnly></input></Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                    <Grid.Col lg={6}>
                                        <Grid.Row lg={4} className="detailschild">
                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >First Name</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="first_name" value={this.state.first_name} readOnly></input></Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row lg={4} className="detailschild">
                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Last Name</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="last_name" value={this.state.last_name} readOnly></input></Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row lg={4} className="detailschild">
                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Pass Type</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="passType" value={this.state.passType} readOnly></input></Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                </Grid.Col>
                            </div>
                            <div className="card-footer">
                                <h4>Reload Card</h4>
                                <Grid.Col lg={12}>
                                    <Grid.Col lg={6}>
                                    <Grid.Row lg={4} className="detailschild">
                                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Variant</label></Grid.Col>
                                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                            <Select options={this.state.varianttypeList} searchable={false} loading={true} onChange={(item) => this.getVariantDetails(item, 'variant')} />
                                                            <span style={{ color: "red" }}>{this.state.errors["variant"]}</span>
                                                            {this.state.variantstatus &&
                                                                <span style={{ color: "red" }}>{this.state.variantmsg}</span>
                                                            }
                                                        </Grid.Col>
                                                    </Grid.Row>

                                        {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild ">
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Trip Value</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="number" className="form-control" value={this.state.tripValue} readOnly></input></Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild ">
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Trip Per Day</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="number" className="form-control" value={this.state.tripPerDay} readOnly></input></Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild " >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Routes</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                                <Select options={this.state.RouteList} searchable={false} loading={true} onChange={(item) => this.getRoute(item, 'route')} />
                                                                <span style={{ color: "red" }}>{this.state.errors["route"]}</span>
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild " >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >From </label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                                <Select options={this.state.stopsdropdowndata} searchable={false} loading={true} onChange={(item) => this.getStop(item.value, 'stopFrom')} />
                                                                <span style={{ color: "red" }}>{this.state.errors["stopFrom"]}</span>
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild " >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >To</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                                <Select options={this.state.stopsdropdowndata} searchable={false} loading={true} onChange={(item) => this.getStop(item.value, 'stopTo')} />
                                                                <span style={{ color: "red" }}>{this.state.errors["stopTo"]}</span>
                                                                <Button type="button" hidden={this.state.isloadingfare} onClick={() => this.fetchFare()} className="btn btn-primary m-2" >
                                                                    Fetch Fare
                                                            </Button>
                                                                {this.state.isloadingfare &&
                                                                    <img src="./images/download.gif" alt="Loading..." />
                                                                }
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                                
                                                                {this.state.showStoredValuePassDetail &&<Grid.Row lg={4} className="detailschild">
                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Fare amount</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                <input type="text" readOnly className="form-control" name="fareAmount" value={this.state.fareAmount} disabled={this.state.fetchcarddetails} onChange={(item) => this.valid(item, 'fareAmount')} maxLength="30" ></input>
                                                <span style={{color: "red"}} >{this.state.errors["fareAmount"]}</span>
                                            </Grid.Col>
                                        </Grid.Row>}
                                        <Grid.Row lg={4} className="detailschild">
                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Recharge Amount</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="rechargeAmount" value={this.state.rechargeAmount} readOnly></input></Grid.Col>
                                                </Grid.Row> 
                                    </Grid.Col>
                                    <Grid.Col lg={6}>
                                    { this.state.showStoredValuePassDetail && <Grid.Row lg={4} className="detailschild">
                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Bonus Amount</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="bonusAmount" value={this.state.bonusAmount} readOnly></input></Grid.Col>
                                        </Grid.Row> }
                                        <Grid.Row lg={4} className="detailschild" >
                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull">
                                                <Button type="button" onClick={() => this.reloadCardBalance()} hidden={this.state.isloadingwrite} disabled={this.state.disablereloadbutton} className="btn btn-primary" >
                                                    Reload Card
                                                </Button> 
                                                {this.state.isloadingwrite &&
                                                    <img src="./images/download.gif" alt="Loading..." />
                                                }
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                </Grid.Col>
                            </div>
                        </form>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default Reloadpass;