// @flow
import * as React from "react";
import { Link } from 'react-router-dom';
import { Page } from "tabler-react";
import BaseLayout from "../BaseLayout";

class ForbiddenPage extends React.Component {
  render() {
    return (
      <BaseLayout>
        <Page.Content>
          <Page.Header title="Not Authorised" />
          <div className="card">
            <div className="card-body" >
              <div>
                <p>You are not authorised to view this page. Please contact your manager to give you the access rights.</p>
                <Link to='/' className="active">Home</Link>
              </div>
            </div>
          </div>
        </Page.Content>
      </BaseLayout>
    );
  }
}
export default ForbiddenPage;