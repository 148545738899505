import React from "react";
import { Button,Page } from "tabler-react";
import { Alert } from 'react-bootstrap';
import ReactDropdownAutoComplete from "./autocomplete";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI";

const options = [
    { value: 'ORDINARY', label: 'ORDINARY' },
    { value: 'AC', label: 'AC' },
    { value: 'LIMITED', label: 'LIMITED' },
    { value: 'EXPRESS', label: 'EXPRESS' }
];

class Editroutetype extends React.Component {
    
    constructor(props, context) {
        super(props, context);
        this.state = {
            routetype:'',
            validationError:'',
            showmessage: false,
            responsemessage:'',
            authkey: localStorage.getItem('authkey')
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }
    
    handleDismiss() {
        this.setState({ showmessage: false });
    }

    submitRouteType(){
        let currentComponent = this;
        let obj = {}
        obj.agencyCode = localStorage.getItem('agencyname');
        obj.name = this.state.routetype;
        AgencyAPI.putRouteType(currentComponent.props.match.params.id, obj,
            (response) => {
                currentComponent.setState({
                    responsemessage:"Route type updated successfully",
                    showmessage:true,
                    isLoader: false
                });
            },
            (error) => {
                currentComponent.setState({isLoader: false,responsemessage:error.message,showmessage:true  });
            }
        );
    }
    
    componentDidMount() { 
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        AgencyAPI.getRouteTypeId(currentComponent.props.match.params.id,
            (response) => {
                if (response.data.code === 2000 && response.data.message === "SUCCESS") {
                    currentComponent.setState({
                        routetype: response.data.data.name,
                        isLoader: false
                    });
                } else{
                    currentComponent.setState({isLoader: false  });
                }
            }
        );
    }
    
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <div className="col-md-5">
                    <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                        <h4>{this.state.responsemessage}</h4>
                    </Alert>
                </div>
            );
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                            {fadeinout}
                            <h3 className="card-title">Bus service Configration</h3>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Bus service Type<span className="form-required">*</span></label>
                                            <ReactDropdownAutoComplete
                                                getItemValue={item => item.label}
                                                className="form-control"
                                                id="conductor"
                                                name="conductor"
                                                placeholder="Route Type"
                                                data={options || []}
                                                renderItem={item => (
                                                    <div
                                                    role="button"
                                                    tabIndex="-1"
                                                    onClick={(val) => {  }}
                                                    >{item.label}</div>
                                                )}
                                                icon="search"
                                                iconColor="#ff000"
                                                iconClick={() => { /* TODO */ }}
                                                value={this.state.routetype}
                                                onChange={(val) => { this.setState({ routetype: val }); }} 
                                                onEnter={() => { /* TODO */ }}
                                            />  
                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                {this.state.validationError}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label"> &nbsp; </label>
                                            <button type="button" onClick={() => this.submitRouteType()} className="btn btn-primary">Save
                                            {this.state.isloading &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            </button>
                                            <a href="/operator-setup"> <Button type="button"  color="primary "> View </Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default Editroutetype;