import moment from 'moment';
const DateUtils = {
    getDateTimeFormatted: function(date){
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day + "_" + date.getHours() + "-" + date.getMinutes();
    },
    getFormattedDateTimeFromTimestamp: function(date){
        return moment.utc(parseInt(date, 10)).add(330, 'minutes').format('DD-MM-YYYY HH:mm:ss');
    },
    getFormattedDateTimeFromDateString: function(str){
        return moment(new Date(str)).add(330, 'minutes').format("DD-MM-YYYY HH:mm:ss");
    },
    getFormattedDateFromDateString: function(str){
        return moment(new Date(str)).add(330, 'minutes').format("DD-MM-YYYY");
    },
     getDateFormatted: function(date){
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    },
    getDateSelectedDays(date,days){
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = (date.getDate() - days).toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    },
    getDateRangeLastSevenDays(ourDate, days){
        var lastSevenDays = new Date(ourDate.getTime());
        lastSevenDays.setDate(ourDate.getDate() - days);
        return moment(lastSevenDays).format("YYYY-MM-DD");
    },

    getDateMinusDays(ourDate, days){
        var lastSevenDays = new Date(ourDate.getTime());
        lastSevenDays.setDate(ourDate.getDate() - days);
        return lastSevenDays;
    },

    getDateToDDMMMYYYYFormattedString(date){
        return moment(date).utc(parseInt(date, 10)).format('DD MMM, YYYY');
    },

    getNextDateToDDMMMYYYYFormattedString(date){
        return moment(date).add(1, 'days').utc(parseInt(date, 10)).format('DD MMM, YYYY');
    },

    getDateToFormattedString(date, formatString){
        return moment(date).format(formatString);
    },
    
    getPrevDate(date) {
        var yesterday = new Date(date.getTime());
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
    },

    getNextDate(date) {
        var tomorrow = new Date(date.getTime());
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    },

    isCurrentDate(date) {
        var diff = moment(new Date()).endOf('day').diff(date, 'day');
        if (diff <= 0) {
            return true;
        } else {
            return false;
        }
    },
    getCurrentDate(date) {
        return date;
    },
    
    
}

export default DateUtils;
