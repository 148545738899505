import * as React from "react";
import { Page } from "tabler-react";
import { Button, Alert } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import "./alert.js";
import { AgencyAPI } from "../apis/AgencyAPI";

class DownloadAgreement extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isloader:false,
            showmessage:false,
            responsemessage:''
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    onClickHandler = () => { 
        AgencyAPI.getAgreement(
            (response) => {
                let url = response.data.data.url;
                let filename = localStorage.getItem('agencydisplayname')+'_Contract.pdf';
                AgencyAPI.getAgreementFile(url,
                    (response) => {
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        const blobUrl = window.URL.createObjectURL(response);
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                        setTimeout(() => {
                            window.URL.revokeObjectURL(blobUrl);
                            document.body.removeChild(a);
                        }, 0);
                    }
                ); 
                //currentComponent.downloadUrl(url, localStorage.getItem('agencydisplayname')+'_Contract.pdf');
            }
        );
    }

    /**downloadUrl(url, filename) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = function(e) {
          if (this.status === 200) {
            const blob = this.response;
            const a = document.createElement("a");
            document.body.appendChild(a);
            const blobUrl = window.URL.createObjectURL(blob);
            a.href = blobUrl;
            a.download = filename;
            a.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(blobUrl);
              document.body.removeChild(a);
            }, 0);
          }
        };
        xhr.send();
      }*/

    render() {
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success" className="col-lg-4 m-2" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                    <p> <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                    </p>
                </Alert>
            )
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Agency Agreement " />
                    {fadeinout}
                    <div className="card">
                        <div className="card-body" >
                            <button   className="btn btn-primary btn-sm m-2" onClick={this.onClickHandler}>Download Agreement</button>
                        
                        </div>
                    </div>
                   
                </Page.Content>
            </BaseLayout>
        )
    }
}
export default DownloadAgreement;