// @flow
import React from "react";
import { Page, Button } from "tabler-react";
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import DateUtils from "../utils/DateUtils";
import { isMobile } from 'react-device-detect';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { AnalyticsAPI } from "../apis/AnalyticsAPI.js";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

const { ExportCSVButton } = CSVExport;

const selectOptions = {
    'UP': 'UP',
    'DOWN': 'DOWN',
};

const options = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true
};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {filterElement}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
                {column.text}
                {sortElement}
            </div>

        </div>
    );
}


class APCSWaybillDetailsReport extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            fields: {},
            errors: {},
            isLoadingReport: false,
            msgHandler: null,
            data: [],
            isFilterExpanded: true,
            summaryData: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleShowMessageHandler = this.handleShowMessageHandler.bind(this);
        this.goToWaybillDetailsPage = this.goToWaybillDetailsPage.bind(this);
    }

    loadData() {
        this.setState({ isLoadingReport: true })
        AnalyticsAPI.getApcsWaybillDetailsReport(this.props.location.state.waybill.waybillNo,
            (response) => {
                this.setState({
                    isLoadingReport: false,
                    data: response.data.data.report.data,
                    summaryData: response.data.data.summary.data
                });
            },
            (error) => {
                this.setState({ isLoadingReport: false });
            });
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleChangeEnd(date) {
        this.setState({
            endDate: date
        });
    }

    getDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    handleShowMessageHandler(msgHandler) {
        this.setState({ msgHandler: msgHandler });
    }

    handleExpandClick(selectedCard, index) {
        const newIds = this.state.data.slice(); //copy the array
        newIds[index].expanded = !selectedCard.expanded; //execute the manipulations
        this.setState({ data: newIds });
    }

    componentDidMount() {
        let currentComponent = this;
        currentComponent.loadData();
    }

    handleClickOpen(card, showMessage) {
        let currentComponent = this;
        currentComponent.setState({ selectedCard: card, open: true });
        return;
    }

    handleClose() {
        let currentComponent = this;
        currentComponent.setState({ open: false, selectedCard: null });
    };

    goToWaybillDetailsPage(card) {
        console.log("trip details card:" + JSON.stringify(card));
        this.props.history.push({pathname:'/apcs-trip-details-report', state:{ waybill:this.props.location.state.waybill.waybillNo, trip: card.tripNumber}});
    }



    render() {
        const { columns = [{
            dataField: 'tripNumber',
            text: 'TRIP NUMBER',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'startStage',
            text: 'Start Stage',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'endStage',
            text: 'End Stage',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'routeDirection',
            text: 'DIRECTION',
            formatter: cell => selectOptions[cell],
            filter: selectFilter({
                options: selectOptions
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'afcsPassengers',
            text: 'AFCS Count',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'apcsPassengers',
            text: 'APCS Count',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'leakage',
            text: 'LEAKAGE',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'action',
            text: 'DETAILS',
            csvExport: false,
            headerStyle: (colum, colIndex) => {
                return { width: '110px', textAlign: 'center' };
            },
            headerFormatter: headerFormatter,
            isDummyField: true,
            formatExtraData: this.state,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <Button type="button" onClick={() => this.goToWaybillDetailsPage(row)} className="btn btn-primary collectBtn">TRIP Details</Button>
            }
        }] } = this.state;
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="APCS Waybill Report" />
                    <div className="row row-cards">
                        <div className="col-md-4 col-lg-4">
                        <div className="cash-collection-card-label">WAYBILL NUMBER: 
                            <span className="cash-collection-card-heading">{this.props.location.state.waybill.waybillNo}</span></div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="cash-collection-card-label">DEVICE ID: <span className="cash-collection-card-heading">{this.state.summaryData.length>0?this.state.summaryData[0].deviceSerialId:""}</span></div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="cash-collection-card-label">BUS NUMBER: <span className="cash-collection-card-heading">{this.state.summaryData.length>0?this.state.summaryData[0].vehicleNumber:""}</span></div>
                        </div>
                    </div>
                    {!isMobile && <div><br/><br/></div>}
                    {this.state.msgHandler}
                    {!isMobile &&
                        <div className="col-12">
                            <div className="paddingTop15">
                                <ToolkitProvider
                                    keyField="waybillNo"
                                    data={this.state.data}
                                    columns={columns}
                                    exportCSV={{ fileName: "APCS-WAYBILL-DETAILS-" + this.props.location.state.waybill.waybillNo + "-REPORT_".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
                                >
                                    {
                                        props => (
                                            <div>
                                                <BootstrapTable
                                                    {...props.baseProps} bootstrap4
                                                    keyField="waybillNo"
                                                    data={this.state.data}
                                                    columns={columns}
                                                    bordered={false}
                                                    pagination={paginationFactory(options)}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data Found" />
                                                <hr />
                                                <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    }
                    {isMobile && <div>
                        {(this.state.data !== null && this.state.data !== undefined && this.state.data.length > 0) && <div className="cash-collection-click-label">Click to view more details</div>}
                        <div className="cards-list">
                            {this.state.data.map((card, i) => {
                                return <Card className="cash-collection-card" onClick={() => this.handleExpandClick(card, i)}
                                    key={card.id}
                                    card={card}
                                >
                                    <CardContent className="cash-collection-card-content">
                                        <div className="cash-collection-card-header">
                                            <div className="cash-collection-row">
                                                <div className="cash-collection-header-row">
                                                    <div className="cash-collection-card-label">TRIP NUMBER</div>
                                                    <div className="cash-collection-card-heading">{card.tripNumber}</div>
                                                </div>
                                                <div className="cash-collection-card-arrow">
                                                    {!card.expanded && <i className="fe fe-chevron-down"></i>}
                                                    {card.expanded && <i className="fe fe-chevron-up"></i>}
                                                </div>
                                            </div>
                                            <div className="cash-collection-row">
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">Start Stage</div>
                                                    <div className="cash-collection-card-text">{card.startStage}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">End Stage</div>
                                                    <div className="cash-collection-card-text">{card.endStage}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">Direction</div>
                                                    <div className="cash-collection-card-text-bold">{card.routeDirection}</div>
                                                </div>
                                            </div>
                                            {card.expanded && <div className="cash-collection-row">
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">AFCS COUNT.</div>
                                                    <div className="cash-collection-card-text">{card.afcsPassengers}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">APCS Count</div>
                                                    <div className="cash-collection-card-text">{card.apcsPassengers}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">Leakage</div>
                                                    <div className="cash-collection-card-text">{card.leakage}</div>
                                                </div>
                                            </div>}
                                        </div>
                                    </CardContent>
                                    {card.expanded && <CardActions className="cash-collection-card-actions">
                                        <Button type="button" onClick={() => this.goToWaybillDetailsPage(card)} className="btn btn-primary collectBtn">TRIP Details</Button>
                                    </CardActions>}
                                </Card>
                            })}

                        </div>
                    </div>
                    }
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default APCSWaybillDetailsReport;