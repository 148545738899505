// @flow
import React from "react";
import { Page } from "tabler-react";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import Dutys from "./Dutys";
import { DutyAPI } from "../apis/DutyAPI";
import MessageResponse from '../enums/MessageResponse';
import { ViewUtils } from '../utils/ViewUtils';

class ViewDuty extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            ConductorList: [],
            DriverList: [],
            DeviceIDs: [],
            deviceid: '',
            selectedMemos: "",
            selectedConductor: "",
            validationError: "",
            validationErrorConductor: "",
            memoid: '',
            name: '',
            conductorid: '',
            driverid: '',
            agencyName: '',
            redirect: false,
            machinemsg: '',
            fields: {},
            errors: {},
            authkey: localStorage.getItem('authkey'),
            msgHandler: null,
            isManualFareChecked: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleShowMessageHandler = this.handleShowMessageHandler.bind(this);
        this.manualFareChanged = this.manualFareChanged.bind(this);
    }

    dropdownvalid(item, type) {
        switch (type) {
            case 'conductorid': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ conductorid: item, fields });
            }
            case 'driverid': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ driverid: item, fields });
            }
            case 'deviceid': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ deviceid: item, fields });
            }
            case 'vehicle': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ vehicle: item, fields });
            }
            default:
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["conductorid"]) {
            formIsValid = false;
            errors["conductorid"] = "Cannot be empty";
        }
        if (!fields["driverid"]) {
            formIsValid = false;
            errors["driverid"] = "Cannot be empty";
        }
        if (!fields["deviceid"]) {
            formIsValid = false;
            errors["deviceid"] = "Cannot be empty";
        }
        if (!fields["vehicle"]) {
            formIsValid = false;
            errors["vehicle"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    GetDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    handleShowMessageHandler(msgHandler) {
        this.setState({ msgHandler: msgHandler });
    }

    manualFareChanged(checked) {
        this.setState({ isManualFareChecked: checked });
    }

    submit() {
        if (this.handleValidation()) {
            let obj = {};
            let satus = this;
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.memoId = "";
            obj.conductorId = this.state.conductorid;
            obj.driverId = this.state.driverid;
            obj.deviceSerialId = this.state.deviceid;
            obj.vehicleId = this.state.vehicle;
            obj.dutyDate = this.GetDateFormat(this.state.startDate);
            obj.manualFare = this.state.isManualFareChecked ? 1 : 0;
            satus.setState({ isloading: true });
            //Create Duty 
            DutyAPI.postDuty(obj,
                (response) => {
                    satus.dutys.componentDidMount();
                    satus.setState({
                        isloading: false,
                        msgHandler: ViewUtils.createMessageHandler('Duty created successfully WayBill- ' + response.data.data.waybillNumber, MessageResponse.SUCCESS)
                    });
                    return;
                },
                (error) => {
                    satus.setState({
                        isloading: false,
                        msgHandler: error.msgHandler
                    });
                    return;
                }
            );
        }
    }

    render() {
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="View Duty" />
                    {this.state.msgHandler}
                    <React.Fragment>
                        <Dutys ref={dutys => this.dutys = dutys} handleShow={this.handleShow} handleShowMessageHandler={this.handleShowMessageHandler} />
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default ViewDuty;