import React from 'react';
import BaseLayout from "../pages/BaseLayout";
import { userService } from '../_services';

class ChangePasswordPage extends React.Component {
    constructor(props) {
      super(props);

      this.state = { 
        oldPassword:'',         
        newPassword: '',
        confirmPassword: '',       
        submitted: false,
        requestDone: false,
        loading: false,
        passwordMismatch:false,
        error: ''
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);   
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { oldPassword, newPassword, confirmPassword } = this.state;

        // stop here if form is invalid
        if (!(oldPassword && newPassword && confirmPassword)) {                                 
            return;
        }else if (newPassword !==confirmPassword) {
            this.setState({ passwordMismatch: true });                      
            return;
        }        

        this.setState({ passwordMismatch: false });
        this.setState({ loading: true });
        this.setState({ requestDone: true });  // testing        
        
        // Change Pwd API request
        userService.changePassword(oldPassword, newPassword,
            (response) => {
                this.setState({ loading: false });
                this.setState({ requestDone: true ,resetPwdToken:""});                
            },
            (error) => {
                this.setState({ error:error.message, loading: false , requestDone: false });
            }
        );
    }


    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
       };
        const { oldPassword, newPassword, confirmPassword,submitted, requestDone, loading, passwordMismatch, error } = this.state;

        return(
            <BaseLayout>
            <div className={"page"}>
            <div className={"page-single"}>
            <div className={"container"}> 
            <div className={"row"}>
            <div className={"col col-login mx-auto"}>
                <form className={"card"} onSubmit={this.handleSubmit}> 
                    <div className={"card-body p-6"}> 
                        <div className={"card-title"}>Change your Password</div>

                        {!requestDone &&
                        <div  className={'form-group' + (submitted && !oldPassword ? ' has-error' : '')}>
                            <label className={"form-label"}>Old Password</label>
                            <input type="password" className="form-control" name="oldPassword" value={oldPassword} onChange={this.handleChange} />
                            {submitted && !oldPassword &&
                                <div className="help-block danger">Please enter old Password</div>
                            }
                        </div>
                        }

                        {!requestDone &&
                        <div  className={'form-group' + (submitted && !newPassword ? ' has-error' : '')}>
                            <label className={"form-label"}>New Password</label>
                            <input type="password" className="form-control" name="newPassword" value={newPassword} onChange={this.handleChange} />
                            {submitted && !newPassword &&
                                <div className="help-block danger">Please enter new Password</div>
                            }
                        </div>
                        }
                        {!requestDone &&
                        <div  className={'form-group' + (submitted && !confirmPassword ? ' has-error' : '')}>
                            <label className={"form-label"}>Confirm New Password</label>
                            <input type="password" className="form-control" name="confirmPassword" value={confirmPassword} onChange={this.handleChange} />
                            {submitted && !confirmPassword &&
                                <div className="help-block danger">Please confirm new Password</div>
                            }
                        </div>
                        }
                        {!requestDone &&
                        <div className={"form-footer"}>
                            <button className="btn btn-block btn-primary"   hidden={loading} >Change Password</button>  
                            {loading &&
                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="download" />
                            }                                                      
                        </div>
                        }
                        {error &&
                            <div className={'alert alert-danger margin-3' } style={stylescope.splitterStyle}>{error}</div>
                        }

                        {!requestDone && passwordMismatch &&
                            <div  className={"form-footer" } >
                                
                                <label className={"form-label"} >                                        
                                    New password and Confirm password values are not same.
                                </label>
                                <br></br>                   
                                
                            </div>
                        }

                        {requestDone && 
                                <div  className={"form-footer" } >
                                    
                                    <label className={"form-label"} >                                        
                                        Password successfully reset.Please relogin to dashboard using new password to continue.
                                    </label>
                                    <br></br>
                                    <a href="/login"><p className="text-center">bustech.in</p></a>
                                    
                                </div>
                                }
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</div>
</BaseLayout>
);
}
    

}

export default ChangePasswordPage;