import React from "react";
import { Link } from 'react-router-dom';
import { Page, Grid,Button} from "tabler-react";
import { Table, Row, Col} from 'react-bootstrap';
import Pagination from "./Pagination";
import { AgencyAPI } from "../apis/AgencyAPI";
const perPage = 20;
class Causes extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            causeList: [],
            currentCauseList: [],
            isLoader: false,
            pageCount: null,
            authkey: localStorage.getItem('authkey')
        };
    }
    
    componentDidMount() {
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        AgencyAPI.getCauses(
          (response) => {
            currentComponent.setState({ causeList:response.data.data,
                isLoader: false,
                pageCount: Math.ceil(response.data.data.length / 20) });
          },
          (error) => {
            currentComponent.setState({isLoader: false  });
          }
        );
    }
    
    onPageChanged = data => {
        const { causeList } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
      
        const offset = (currentPage - 1) * pageLimit;
        const currentCauseList = causeList.slice(offset, offset + pageLimit);
      
        this.setState({ currentPage, currentCauseList, totalPages });
    };
    
   render() {
        let paginationtransaction;
        const {
            causeList,
            currentPage,
            totalPages
        } = this.state;
        const totalCauses = causeList.length;
        if (this.state.pageCount > 0) {
            paginationtransaction = (
              <Pagination
                totalRecords={totalCauses}
                pageLimit={perPage}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            );
        } else {
            paginationtransaction = (
                <div>
                No Data Found...
                </div>
            );
        }
        if (this.state.isLoader) {
            return (
                <Page.Content>
                <Grid>
                    <Row className="show-grid floatright">
                    <Col xsHidden md={12}>
                        <a href="/add-cause"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Add Cause</Button></a>
                    </Col>
                    </Row>
                </Grid>
                <Page.Header title="Cause List" />
                  <React.Fragment>
                  <Table striped bordered condensed hover >
                    <thead>
                      <tr>
                      <th>Cause Name</th>
                      <th>Status</th>
                      <th >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr><td colSpan="3" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                    </tbody>
                    </Table>
                    {paginationtransaction}
                    </React.Fragment>
                </Page.Content>
            );
        } else {
            let currentComponent = this;
            return(
                <Page.Content>
                <Grid>
                    <Row className="show-grid floatright">
                    <Col xsHidden md={12}>
                        <a href="/add-cause"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Add Cause </Button></a>
                    </Col>
                    </Row>
                </Grid>
                <Page.Header title="Cause List" />
                <React.Fragment>
                <Table striped bordered condensed hover >
                <thead>
                    <tr>
                        <th >Cause Name</th>
                        <th>Status</th>
                        <th >Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.currentCauseList.map(function(obj, i){
                    return (
                        <tr key={i}>
                        <td>
                          {obj.name}
                        </td>
                        <td>
                          {obj.status}
                        </td>
                        <td>
                          <Link to={{ pathname: `/edit-cause/${obj.id}`, state: { cause: obj} }}  className="active"><i className="fe fe-edit"></i></Link>
                        </td>
                        </tr>
                        );
                    }, currentComponent)}
                </tbody>
                </Table>
                {paginationtransaction} {currentPage && (
                    <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                    Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                    <span className="font-weight-bold">{totalPages}</span>
                    </span>
                    )}
                </React.Fragment>
                </Page.Content>
            );
        }
    }
}
export default Causes;
