import * as React from "react";
import { Page, Grid } from "tabler-react";
import { Button, Alert } from 'react-bootstrap';
import BaseLayout from "../BaseLayout";
import Select from 'react-select';
import './Formstyle.css';
import { RFIDCardCommunicator } from "../../_components";
import { PassAPI } from "../../apis/PassAPI";
import { CustomerAPI } from "../../apis/CustomerAPI";
import { OrderAPI } from "../../apis/OrderAPI";

class Issuepass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            firstName: '',
            lastName: '',
            phoneNumber: '',
            passSerialNumber: '',
            passType: '',
            rechargeAmount: '',
            totalAmount: '',
            totalBalance: '',
            showmessage: false,
            responsemessage: '',
            productTypeList: [],
            varianttypeList: [],
            agencyCode: '',
            product: '',
            productType: '',
            varianttype: '',
            product_name: '',
            clientTransactionId: '',
            customerId: '',
            Time_retrun: '',
            fields: {},
            errors:{},
            authkey: localStorage.getItem('authkey'),
            RouteList:[],
            productList : [],
            variantList : [],
            variant:'',
            variantmsg:'',
            variantstatus:false,
            tripvalidity:'',
            tripValue:'',
            tripPerDay:'',
            route:'',
            stops:[],
            StopList:[],
            stopsdropdowndata:[],
            stopFrom:'',
            stopTo:'',
            isloadingfare:false,
            isloadingwrite:false,
            isloadingsearch:false,
            showroutedetail:false,
            showcarddetail:false,
            showStoredValuePassDetail: false,
            fareAmount:'',
            bonusAmount:'',
            disablesubmitbutton: true,
            msgHandler: null
        };
        this.handleDismiss = this.handleDismiss.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    handleDismiss() {
        this.setState({ showmessage: false });
    }

    valid(item, type) {
        let itemValue = item.target.value;
        let errors = {};
        switch (type) {
            case 'phoneNumber': {
                this.setState({ phoneNumber: itemValue, errors: errors });
                break
            }
            case 'rechargeAmount': {
                const re = /^[0-9\b]+$/;
                if (itemValue !== "" && !re.test(itemValue)) {
                    // Filter non-digits from input value.
                    return false;
                } else if (itemValue === "") {
                    this.setState({ disablesubmitbutton: true, rechargeAmount: itemValue, bonusAmount: '', totalAmount: parseFloat(this.state.availableBalance, 10), errors: {} });
                } else {
                    var rechargeAmount = parseFloat(itemValue, 10);
                    this.setState({ disablesubmitbutton: false, rechargeAmount: rechargeAmount, errors: {} });
                }
                break;
            }
            default:
        }
    }

    searchCustomerDetails() {
        let errors = {};
        let phoneNumber = this.state.phoneNumber;
        let currentComponent = this;
        if (phoneNumber === '') {
            errors["phoneNumber"] = "Cannot be empty";
            this.setState({ errors: errors });
            return false;
        } else {
            this.setState({ isloadingsearch: true, disablesubmitbutton: true });
            CustomerAPI.getPhoneSearch(phoneNumber, 
                (response) => {
                    currentComponent.setState({
                        firstName: response.data.data.firstName,
                        lastName: response.data.data.lastName,
                        customerId: response.data.data.id,
                        disablesubmitbutton: false,
                        isloadingsearch: false
                    });
                },
                (error) => {
                    currentComponent.setState({
                        firstName: '',
                        lastName: '',
                        disablesubmitbutton: true,
                        isloadingsearch: false,
                        showmessage: true,
                        responsemessage: error.message
                    });
                }
            );
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
            return v.toString(16);
        });
    }

    getcurrentTime() {
        var Time_retrun = new Date();
        return Time_retrun.getTime();
    }

    resetForm() {
        this.setState({ phoneNumber: "", firstName: "", lastName: "", rechargeAmount: "", bonusAmount: '', fareAmount: '', showroutedetail: false, showcarddetail: false, showStoredValuePassDetail: false });
    }

    getVariant(productId, passType) {
        let currentComponent = this;
        this.setState({
            tripvalidity: '',
            tripValue: '',
            tripPerDay: '',
            variant: ''
        });
        if (passType === 'STORED_VALUE') {
            this.setState({ showroutedetail: false, showStoredValuePassDetail: true });
        } else {
            this.setState({ showroutedetail: true, showStoredValuePassDetail: false });
        }
        // Fetch product base variants.
        PassAPI.getProductIdVariant(productId,
            (response) => {
                    let varianttypeFromApi = response.data.data.map(device => { return { value: device.id, label: device.title, tripValue:device.tripValue,tripPerDay:device.tripPerDay,validity:device.validityMultiplier, fareAmount: device.fareAmount, bonusAmount: device.bonusAmount  }; });
                    currentComponent.setState({ variantstatus:false, productType: productId, varianttypeList: [{ value: '', label: 'Select variant type'}].concat(varianttypeFromApi)});
                
            },
            (error) => {
                currentComponent.setState({
                    variantmsg: error.message,variantstatus:true,varianttypeList:[]
                });
            }
        );
    }

    getVariantDetails(variant) {
        this.setState({
            tripvalidity: variant.validity,
            tripValue: variant.tripValue,
            tripPerDay: variant.tripPerDay,
            variant: variant.value,
            fareAmount: variant.fareAmount,
            bonusAmount: variant.bonusAmount,
            rechargeAmount: variant.fareAmount + variant.bonusAmount

        });
    }

    getRoute(routedata) {
        let currentComponent = this;
        currentComponent.state.stopsdropdowndata = [];
        this.setState({ stops: routedata.stops });
        var stopsdropdown = [];
        if (routedata.stops) {
            routedata.stops.forEach(obj => {
                currentComponent.state.StopList.forEach(element => {
                    if (element.value === obj.stopId) {
                        stopsdropdown = {
                            value: obj.stopId,
                            label: element.label
                        };
                        currentComponent.state.stopsdropdowndata.push(stopsdropdown);
                    }
                });
            });
            this.setState({ route: routedata.value, stopsdropdowndata: currentComponent.state.stopsdropdowndata });
        }
        return;
    }

    getStop(stopid, type) {
        if (type === 'stopFrom') {
            this.setState({ 'stopFrom': stopid });
        } else {
            this.setState({ 'stopTo': stopid });
        }
    }

    fetchFare() {
        this.setState({ isloadingfare: true });
        let currentComponent = this;
        let obj = {};
        obj.routeId         =  this.state.route;
        obj.fromStopId      =  this.state.stopFrom;
        obj.toStopId        =  this.state.stopTo;
        obj.passProductId   =  this.state.productType;
        obj.variantId       =  this.state.variant;
        PassAPI.postProductCalculatePassFare(obj,
            (response) => {
                currentComponent.setState({isloadingfare: false,rechargeAmount:response.data.data.amount});
            }
        );
    }

    submitIssuePass() {
        let obj = {};
        let currentComponent = this;
        let errors = {};
        if (this.state.productType === '') {
            errors["productType"] = " Cannot be empty";
            this.setState({ errors: errors });
            return false;
        }
        if (this.state.variant === '') {
            errors["variant"] = " Cannot be empty";
            this.setState({ errors: errors });
            return false;
        }
        if (this.state.rechargeAmount === '') {
            errors["rechargeAmount"] = " Balance Cannot be empty";
            this.setState({ errors: errors });
            return false;
        }
        currentComponent.setState({ isloadingwrite: true, showcarddetail: true });
        obj.agencyCode = localStorage.getItem('agencyname');
        obj.firstName = this.state.firstName;
        obj.lastName = this.state.lastName;
        obj.productType = "PASS";
        obj.clientTransactionId = this.uuidv4();
        obj.customerId = this.state.customerId;
        obj.passProductId = this.state.productType;
        obj.dutyId = this.uuidv4();
        if(this.state.showStoredValuePassDetail) {
            obj.bonusAmount = parseFloat(this.state.bonusAmount); 
            obj.baseAmount = parseFloat(this.state.fareAmount);
            obj.totalAmount = parseFloat(this.state.fareAmount) + parseFloat(this.state.bonusAmount);
        } else {
            obj.sourceStopId = this.state.stopFrom;
            obj.destinationStopId = this.state.stopTo;
            obj.routeId = this.state.route;
            obj.baseAmount = parseFloat(this.state.rechargeAmount);
            obj.totalAmount = parseFloat(this.state.rechargeAmount);
        }
        obj.taxAmount = 0;
        obj.availableBalance = parseFloat(this.state.rechargeAmount);
        obj.totalBalance = parseFloat(this.state.rechargeAmount);
        obj.transactionDateTime = this.getcurrentTime();
        obj.variantId = this.state.variant;
        obj.action = 'ISSUE';
        obj.paidBy = 'CASH';

        OrderAPI.postOrder(obj,
            (response) => {
                currentComponent.setState({
                    isloadingwrite      : false,
                    showmessage         : true,
                    responsemessage     : "Pass Issued Successfully. ",
                    passSerialNumber    : response.data.data.bookingList[0].passSerialNumber,
                    passType            : response.data.data.bookingList[0].passType,
                    totalAmount         : response.data.data.bookingList[0].totalAmount,
                    totalBalance        : response.data.data.bookingList[0].totalBalance
                });
                return currentComponent.writeToCard();
            },
            (error) => {
                currentComponent.setState({
                    isloadingwrite  : false,
                    showmessage     : true,
                    responsemessage : error.message
                });
                currentComponent.resetForm();
            }
        );
    }

    writeToCard() {
        let currentComponent = this;
        let passSerialNumber = this.state.passSerialNumber;
        if (passSerialNumber === '') {
            alert("Can not be empty");
            return false;
        } else {
            currentComponent.setState({ isloadingwrite: true });
            PassAPI.getPassDetails(passSerialNumber,
                (response) => {
                    let initialCommand = response.data.data.rfidFormat.initialCommands;
                    let string1 = initialCommand.toString();
                    let outputcommand1 = string1.replace(/,/g, '|') + '|';
                    let cardwriteCommand = response.data.data.rfidFormat.commands;
                    let string2 = cardwriteCommand.toString();
                    let outputcommand2 = string2.replace(/,/g, '|');
                    let outputcommand = outputcommand1 + outputcommand2 + '|';
                    RFIDCardCommunicator.postRFIDData(outputcommand,
                        (response) => {
                            var blocks = response.data.split('|').filter(function (v) { return v !== '' && v !== '#xFF\n' && v !== '#xF6\n'; });
                            currentComponent.printCallBack(blocks);
                        },
                        (error) => {
                            currentComponent.setState({
                                msgHandler: error.msgHandler,
                                isloadingwrite: false
                            });
                        }
                    );
                },
                (error) => {
                    currentComponent.setState({
                        isloadingwrite  : false,
                        showmessage     : true,
                        responsemessage : error.message
                    });
                }
            );
        }
    }

    printCallBack(cardData) {
        let currentComponent = this;
        let obj = {};
        obj.agencyCode = localStorage.getItem('agencyname');
        obj.passSerialNumber = this.state.passSerialNumber;
        obj.responseData = cardData;
        PassAPI.putPrintedCard(obj,
            (response) => {
                currentComponent.setState({
                    isloadingwrite      : false,
                    disablesubmitbutton : true,
                    showmessage         : true,
                    responsemessage     : "Card Write Successfully"
                });
            },
            (error) => {
                currentComponent.setState({
                    isloadingwrite  : false,
                    showmessage     : true,
                    responsemessage : error.message
                });
            }
        );
    }


    loadProductsAndVariantsFromServer() {
        let currentComponent = this;
        PassAPI.getProduct(
            (response) => {
                let productTypeFromApi = response.data.data.content.map(device => { return { value: device.id, label: device.passName, passType: device.passType }; });
                currentComponent.setState({ productTypeList: [{ value: '', label: 'Select Product Type' }].concat(productTypeFromApi) });
            }
        );
    }

    componentDidMount() {
        let currentComponent = this;
        this.loadProductsAndVariantsFromServer();
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function (event) {
            var db = event.target.result;
            var routes = db.transaction('routes', 'readwrite').objectStore("routes");
            var stops = db.transaction('stops', 'readwrite').objectStore("stops");
            let routedata = routes.getAll();
            let stopsdata = stops.getAll();
            // get data from Stops object
            routes.getAll().onsuccess = function (event) {
                let routesFromApi = routedata.result.map(route => { return { value: route.id, label: route.agencyRouteId, stops: route.stops }; });
                currentComponent.setState({ RouteList: [{ value: '', label: 'Select Route' }].concat(routesFromApi) });
            };
            stops.getAll().onsuccess = function (event) {
                let stopFromApi = stopsdata.result.map(stop => { return { value: stop.id, label: stop.longName }; });
                currentComponent.setState({ StopList: [{ value: '', label: 'Select Stop' }].concat(stopFromApi) });
            };
        };
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="info" className="col-lg-12 m-2" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            );
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Issue Pass" />
                    {fadeinout}
                    {this.state.msgHandler}
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <input type="text" name="phoneNumber" className="form-control" value={this.state.phoneNumber} onChange={(item) => this.valid(item, 'phoneNumber')} placeholder="Search Mobile no" maxLength="500"></input>
                                            <span style={{ color: "red" }}>{this.state.errors["phoneNumber"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <button type="button" onClick={() => this.searchCustomerDetails()} hidden={this.state.isloadingsearch} className="btn btn-primary">
                                                Search
                                            </button>
                                            {this.state.isloadingsearch &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <h5>Add User Detail</h5>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Grid.Row md={12} lg={12}>
                                                <Grid.Col lg={6}>
                                                    <Grid.Row lg={4} className="detailschild"  >
                                                        <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >First Name</label></Grid.Col>
                                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="firstName" value={this.state.firstName} readOnly></input></Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row lg={4} className="detailschild" >
                                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Last Name</label></Grid.Col>
                                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" className="form-control" name="lastName" value={this.state.lastName} readOnly></input> </Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row lg={4} className="detailschild"  >
                                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Product Type</label></Grid.Col>
                                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                            <Select options={this.state.productTypeList} searchable={false} loading={true} onChange={(item) => this.getVariant(item.value, item.passType, 'productType')} />
                                                            <span style={{ color: "red" }}>{this.state.errors["productType"]}</span>
                                                        </Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row lg={4} className="detailschild">
                                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Variant</label></Grid.Col>
                                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                            <Select options={this.state.varianttypeList} searchable={false} loading={true} onChange={(item) => this.getVariantDetails(item, 'variant')} />
                                                            <span style={{ color: "red" }}>{this.state.errors["variant"]}</span>
                                                            {this.state.variantstatus &&
                                                                <span style={{ color: "red" }}>{this.state.variantmsg}</span>
                                                            }
                                                        </Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row lg={4} className="detailschild ">
                                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Trip Validity</label></Grid.Col>
                                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="number" className="form-control" value={this.state.tripvalidity} readOnly></input></Grid.Col>
                                                    </Grid.Row>
                                                    {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild ">
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Trip Value</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="number" className="form-control" value={this.state.tripValue} readOnly></input></Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild ">
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Trip Per Day</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="number" className="form-control" value={this.state.tripPerDay} readOnly></input></Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild " >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Routes</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                                <Select options={this.state.RouteList} searchable={false} loading={true} onChange={(item) => this.getRoute(item, 'route')} />
                                                                <span style={{ color: "red" }}>{this.state.errors["route"]}</span>
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild " >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >From </label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                                <Select options={this.state.stopsdropdowndata} searchable={false} loading={true} onChange={(item) => this.getStop(item.value, 'stopFrom')} />
                                                                <span style={{ color: "red" }}>{this.state.errors["stopFrom"]}</span>
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showroutedetail &&
                                                        <Grid.Row lg={4} className="detailschild " >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >To</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                                <Select options={this.state.stopsdropdowndata} searchable={false} loading={true} onChange={(item) => this.getStop(item.value, 'stopTo')} />
                                                                <span style={{ color: "red" }}>{this.state.errors["stopTo"]}</span>
                                                                <Button type="button" hidden={this.state.isloadingfare} onClick={() => this.fetchFare()} className="btn btn-primary m-2" >
                                                                    Fetch Fare
                                                            </Button>
                                                                {this.state.isloadingfare &&
                                                                    <img src="./images/download.gif" alt="Loading..." />
                                                                }
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showStoredValuePassDetail &&
                                                        <Grid.Row lg={4} className="detailschild"  >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Fare amount</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                                <input type="text" readOnly className="form-control" name="fareAmount" value={this.state.fareAmount} onChange={(item) => this.valid(item, 'fareAmount')} maxLength="30" ></input>
                                                                <span style={{ color: "red" }} >{this.state.errors["fareAmount"]}</span>
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }
                                                    {this.state.showStoredValuePassDetail &&
                                                        <Grid.Row  lg={4} className="detailschild"  >
                                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Bonus amount</label></Grid.Col>
                                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                                                <input type="text" readOnly className="form-control" name="bonusAmount" value={this.state.bonusAmount} onChange={(item) => this.valid(item, 'bonusAmount')} maxLength="30" ></input>
                                                                <span style={{color: "red"}} >{this.state.errors["bonusAmount"]}</span>
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }

                                                    <Grid.Row lg={4} className="detailschild"  >
                                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Recharge amount</label></Grid.Col>
                                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                            <input type="text" readOnly className="form-control" name="rechargeAmount" value={this.state.rechargeAmount} onChange={(item) => this.valid(item, 'rechargeAmount')} maxLength="30" ></input>
                                                            <span style={{ color: "red" }} >{this.state.errors["rechargeAmount"]}</span>
                                                        </Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row lg={4} className="detailschild" >
                                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                                        <Grid.Col lg={6} className="paddingnull">
                                                            <Button type="button" hidden={this.state.isloadingwrite} disabled={this.state.disablesubmitbutton} onClick={() => this.submitIssuePass()} className="btn btn-primary m-2" >
                                                                Submit
                                                            </Button>
                                                            {this.state.isloadingwrite &&
                                                                <img src="./images/download.gif" alt="Loading..." />
                                                            }
                                                            <Button type="button" color="secondary mb-2 m-2" onClick={this.resetForm}>Reset</Button>
                                                        </Grid.Col>
                                                    </Grid.Row>
                                                </Grid.Col>
                                                {this.state.showcarddetail &&
                                                    <Grid.Col lg={6}>
                                                        <Grid.Row lg={4} className="detailschild"  >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Pass Number</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" name="passSerialNumber" readOnly value={this.state.passSerialNumber} className="form-control" ></input></Grid.Col>
                                                        </Grid.Row>
                                                        <Grid.Row lg={4} className="detailschild"  >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Pass Type</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" name="PassType" readOnly value={this.state.passType} className="form-control"></input> </Grid.Col>
                                                        </Grid.Row>
                                                        <Grid.Row lg={4} className="detailschild ">
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Trip Validity</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="number" className="form-control" readOnly value={this.state.tripvalidity} ></input></Grid.Col>
                                                        </Grid.Row>
                                                        {this.state.showroutedetail &&
                                                            <Grid.Row lg={4} className="detailschild ">
                                                                <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Trip Value</label></Grid.Col>
                                                                <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="number" className="form-control" readOnly value={this.state.tripValue} ></input> </Grid.Col>
                                                            </Grid.Row>
                                                        }
                                                        {this.state.showroutedetail &&
                                                            <Grid.Row lg={4} className="detailschild ">
                                                                <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Trips Per Day</label></Grid.Col>
                                                                <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="number" className="form-control" readOnly value={this.state.tripPerDay} ></input></Grid.Col>
                                                            </Grid.Row>
                                                        }
                                                        <Grid.Row lg={4} className="detailschild"  >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Total Amount</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" name="totalAmount" readOnly value={this.state.totalAmount} className="form-control" ></input> </Grid.Col>
                                                        </Grid.Row>
                                                        <Grid.Row lg={4} className="detailschild"  >
                                                            <Grid.Col lg={4} className="detailschildreadonlypad"><label className="floatright" >Total Balance</label></Grid.Col>
                                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px"><input type="text" name="totalBalance" readOnly value={this.state.totalBalance} className="form-control" ></input> </Grid.Col>
                                                        </Grid.Row>
                                                    </Grid.Col>
                                                }
                                            </Grid.Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default Issuepass;