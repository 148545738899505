// @flow
import * as React from "react";
import BaseLayout from "../BaseLayout";
import { Page } from "tabler-react";
import DatePicker from "react-date-picker";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import DateUtils from "../../utils/DateUtils";
import { AnalyticsAPI } from "../../apis/AnalyticsAPI";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactDropdownAutoComplete from "../autocomplete";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { DutyAPI } from "../../apis/DutyAPI";
import MessageResponse from '../../enums/MessageResponse';
import { ViewUtils } from '../../utils/ViewUtils';


function headerFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold',color:'#495057', fontSize:'11px' }}>
        {column.text}
      </div>
    </div>
  );
}

function filterHeaderFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filterElement}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold',color:'#495057', fontSize:'11px' }}>
        {column.text}
        {sortElement}
      </div>
      
    </div>
  );
}


const options = {
  sizePerPage: 10,
  hideSizePerPage: false,
  hidePageListOnlyOnePage: true
};



function headerRightFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ fontWeight: 'bold',color:'#495057', fontSize:'11px' }}>
        {column.text}
      </div>
    </div>
  );
}


class MobileReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoader: false,
      data: [],
      columns: [{ dataField: "srNo", text: "Sr No" }],
      page: 1,
      weekStartDate: new Date(),
      endDate: new Date(),
      pageTitle: '',
      isTripTicketIssuedReport: false,
      isStageDetailReport: false,
      routesList: [],
      selectedRoute: '',
      waybillsList: [],
      selectedWaybill: '',
      msgHandler: null,
      authkey: localStorage.getItem('authkey')
    };
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  loadReportDataFromServer(pathname, date) {
    let currentComponent = this;
    currentComponent.setState({ isLoader: true, data: [], showMessage: false, responseMessage: '' });
    if (date == null) {
      currentComponent.setState({ isLoader: false});
      return;
    }
    let reportType = '';
    if(pathname === "/report-weekly-collection") {
      currentComponent.setState({pageTitle: "Weekly Collection"})
      reportType = "revenue?";
    } else if(pathname === "/report-conductor-collection") {
      currentComponent.setState({pageTitle: "Conductor Collection"})
      reportType = "conductors?";
    } else if(pathname === "/report-routewise-collection") {
      currentComponent.setState({pageTitle: "Route Wise Collection"})
      reportType = "routes?";
    } else if(pathname === "/report-routewise-epkm") {
      currentComponent.setState({pageTitle: "Route Wise EPKM"})
      reportType = "routes?";
    } else if(pathname === "/report-bus-inspection-count") {
      currentComponent.setState({pageTitle: "Bus Inspection Count"})
      reportType = "inspector?";
    } else if(pathname === "/report-breakdown-alert") {
      currentComponent.setState({pageTitle: "Breakdown alert"})
      reportType = "breakdown?";
    } else if(pathname === "/report-diesel-amount") {
      currentComponent.setState({pageTitle: "Diesel Amount"})
      reportType = "diesel?";
    } else if(pathname === "/report-stage-detail") {
      currentComponent.setState({pageTitle: "Stagewise Detail Report"})
      reportType = "stagedetail?limit=2147483647&routeNo=" + this.state.selectedRoute + "&waybillNo=" + this.state.selectedWaybill + "&";
    } else if(pathname === "/report-trip-ticket-issue") {
      currentComponent.setState({pageTitle: "Trip Ticket Issued Report"})
      reportType = "routes/"+this.state.selectedRoute +"?";
    } else if(pathname === "/report-transactions") {
      currentComponent.setState({pageTitle: "Transactions"})
      reportType = "transactions?limit=2147483647";
    } 

    let fromDate = new Date();
    if(pathname === "/report-weekly-collection") {
      fromDate = DateUtils.getDateRangeLastSevenDays(date, 6);
    } else {
      fromDate = DateUtils.getDateFormatted(date);
    }
    
    if(pathname === "/report-transactions") {
      AnalyticsAPI.getReports(reportType, fromDate, DateUtils.getDateFormatted(date),0, 10,
        (response) => {
          currentComponent.processResponse(response, pathname);
        }
      );
    } else {
      AnalyticsAPI.getMobileReports(reportType,
          fromDate, 
          DateUtils.getDateFormatted(date), 
          0, 
          10000,
        (response) => { 
            currentComponent.processResponse(response, pathname);
        }
      );
    }
  }

  processResponse(response, pathname) {
    let currentComponent = this;
    if (response.data.code === 2000 && response.data.message === "SUCCESS") {
      let columnsList = [];
      if(pathname === "/report-weekly-collection") {
        columnsList.push({ dataField: "date", text: "Date", headerFormatter: headerFormatter });
        columnsList.push({ dataField: "totalTickets", text: "Passengers", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
        columnsList.push({ dataField: "totalAmount", text: "Collections", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      } else if(pathname === "/report-conductor-collection") {
        columnsList.push({ dataField: "conductorName", text: "Conductor", headerFormatter: headerFormatter });
        columnsList.push({ dataField: "totalRidership", text: "Passengers", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
        columnsList.push({ dataField: "totalEarnings", text: "Collections", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      } else if(pathname === "/report-routewise-collection") {
        columnsList.push({ dataField: "routeName", text: "Route", headerFormatter: headerFormatter });
        columnsList.push({ dataField: "totalRidership", text: "Passengers", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
        columnsList.push({ dataField: "totalEarnings", text: "Collections", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      } else if(pathname === "/report-routewise-epkm") {
        columnsList.push({ dataField: "routeName", text: "Route", headerFormatter: headerFormatter });
        columnsList.push({ dataField: "epk", text: "EPKM", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      } else if(pathname === "/report-bus-inspection-count") {
        columnsList.push({ dataField: "routeName", text: "Route", headerFormatter: headerFormatter });
        columnsList.push({ dataField: "inspectorName", text: "Inspector", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
        columnsList.push({ dataField: "time", text: "Time", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      } else if(pathname === "/report-breakdown-alert") {
        columnsList.push({ dataField: "routeName", text: "Route", headerFormatter: headerFormatter });
        columnsList.push({ dataField: "vehicleNo", text: "Vehicle No", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
        columnsList.push({ dataField: "time", text: "Time", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      } else if(pathname === "/report-diesel-amount") {
        columnsList.push({ dataField: "vehicleNo", text: "Vehicle No", headerFormatter: headerFormatter });
        columnsList.push({ dataField: "dieselAmount", text: "Amount", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      } else if(pathname === "/report-transactions") {
        columnsList.push({ dataField: "ticketNo", text: "Ticket No", filter: textFilter(), headerFormatter: filterHeaderFormatter });
        columnsList.push({ dataField: "routeName", text: "Route", filter: textFilter(),  headerFormatter: filterHeaderFormatter });
        columnsList.push({ dataField: "waybillNo", filter: textFilter(), text: "Waybill No", headerFormatter: filterHeaderFormatter });
        columnsList.push({ dataField: "total", text: "Total", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      } else if(pathname === "/report-trip-ticket-issue") {
        columnsList.push({ dataField: "waybillNo", text: "WAYBILL NO", filter: textFilter(), headerFormatter: filterHeaderFormatter });
        columnsList.push({ dataField: "tripNo", text: "TRIP NO", filter: textFilter(),  headerFormatter: filterHeaderFormatter });
        columnsList.push({ dataField: "totalTickets", filter: textFilter(), text: "TOTAL TICKETS",  headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
        columnsList.push({ dataField: "totalAmount", text: "TOTAL AMOUNT", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      } else if(pathname === "/report-stage-detail") {
        columnsList.push({ dataField: "stopName", text: "STAGE", headerFormatter: filterHeaderFormatter });
        columnsList.push({ dataField: "boardFtCount", text: "FULL", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right'  });
        columnsList.push({ dataField: "boardHtCount", text: "HALF",  headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
        columnsList.push({ dataField: "boardLtCount", text: "LUGG",  headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
        columnsList.push({ dataField: "totalAmount", text: "AMOUNT", headerFormatter: headerRightFormatter, align: 'right', headerAlign: 'right' });
      }
    
      currentComponent.setState({
        data: response.data.data.report.data,
        columns: columnsList,
        isLoader: false,
        pageCount: Math.ceil(response.data.data.report.data.length / 10)
      });
    } else {
      currentComponent.setState({ isLoader: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.initialLoad(nextProps);  
  }

  componentDidMount() {
    this.initialLoad(this.props);
  }

  initialLoad(allProps) {
    let pathname = allProps.location.pathname;
    if(pathname === "/report-trip-ticket-issue"){
      this.setState({pageTitle: "Trip Ticket Issued Report"})
      this.setState({isTripTicketIssuedReport: true, isStageDetailReport: false, responseMessage:"Please enter Route No!. ", showMessage :true});
      this.loadRoutes();
    } else if(pathname === "/report-stage-detail") {
      this.setState({pageTitle: "Stagewise Detail Report"})
      this.setState({isStageDetailReport: true, isTripTicketIssuedReport: false, responseMessage:"Please enter Route No!. ", showMessage :true});
      this.loadWaybills();
      this.loadRoutes();
    } else {
      this.setState({endDate:new Date()});
      this.loadReportDataFromServer(pathname, new Date());
    }
  }


  loadWaybills() {
    let currentComponent = this;
    DutyAPI.getList(DateUtils.getDateFormatted(this.state.endDate), 
    DateUtils.getDateFormatted(this.state.endDate), 0, 1000,
    (response) => {
        currentComponent.setState({
            isloadingviewduty:false,
            waybillsList: response.data.data.content,
            pageCount: Math.ceil(response.data.data.totalElements / response.data.data.size),
            isLoader: false
        });
    },
    (error) => {
        currentComponent.setState({isLoader: false, isloadingviewduty:false });
    });
  }

  loadRoutes() {
    let currentComponent = this;
    let routedata='';
    const dbName = "RIDLR AFCS";
    const version = 1; //versions start at 1
    var request = indexedDB.open(dbName, version);
    request.onsuccess = function(event) {
        var db          = event.target.result;
        var routes      = db.transaction('routes', 'readwrite').objectStore("routes");
        routedata  = routes.getAll();
        // get data from Stops object
        routes.getAll().onsuccess = function(event) {
        currentComponent.setState({ routesList:routedata.result });
        };
    }
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date
    }, this.handleSubmitClick(date));
  }

  handleSubmitClick(date) {
    if(this.state.isTripTicketIssuedReport && this.state.selectedRoute === '') {
        return this.setState({
          msgHandler:ViewUtils.createMessageHandler('Please enter Route No!. ', MessageResponse.ERROR)
        });
    }else if(this.state.isStageDetailReport && (this.state.selectedRoute === '' ||  this.state.selectedWaybill === '')) {
        return this.setState({
          msgHandler:ViewUtils.createMessageHandler('Please enter Route No and Waybill No!. ', MessageResponse.ERROR)
        });
    } else {
      this.setState({msgHandler: null});
      let pathname = this.props.location.pathname;
      this.loadReportDataFromServer(pathname, date);
    }
  }

  render() {
    const { editFields } = this.state;
    return (
      <BaseLayout>
        <Page.Content>
          <Page.Header title={this.state.pageTitle} ><div className="reports-calendar"><DatePicker
          onChange={this.handleChangeEnd}
          value={this.state.endDate}
          calendarIcon={<CalendarIcon/>}
          maxDate={new Date()}
          className="calendar-date-picker"
          clearIcon={null}
        /></div></Page.Header>
        {this.state.msgHandler}
        {(this.state.isTripTicketIssuedReport || this.state.isStageDetailReport) && 
        <div className="duty-filter-box">
        <div className="duty-filter-search">
            <div className="row">
                <div className={this.state.isTripTicketIssuedReport===false?'cash-collection-filter-left col-width45':'cash-collection-filter-left width100'}>
                    <label className="cash-collection-card-text">Route Number</label>
                    <ReactDropdownAutoComplete
                      getItemValue={item => item.agencyRouteId}
                      className="form-control mb-2"
                      id="route"
                      name="route"
                      placeholder="Enter Route No"
                      data={this.state.routesList || []}
                      renderItem={item => (
                          <div
                          role="button"
                          tabIndex="-1"
                          onClick={(val) => { editFields.name = val; }}
                          >{item.agencyRouteId}</div>
                      )}
                      value={this.state.selectedRoute}
                      onChange={(val) => { this.setState({ selectedRoute: val }); }}
                      onEnter={() => { }}
                  />
                </div>
                {this.state.isStageDetailReport && <div className="cash-collection-filter-right">
                    <label className="cash-collection-card-text">WAYBILL Number</label>
                    <br />
                    <ReactDropdownAutoComplete
                      getItemValue={item => item.waybillNumber}
                      className="form-control mb-2"
                      id="waybill"
                      name="waybill"
                      placeholder="Enter Waybill Number"
                      data={this.state.waybillsList || []}
                      renderItem={item => (
                          <div
                          role="button"
                          tabIndex="-1"
                          onClick={(val) => { editFields.name = val; }}
                          >{item.waybillNumber}</div>
                      )}
                      value={this.state.selectedWaybill}
                      onChange={(val) => { this.setState({ selectedWaybill: val }); }}
                      onEnter={() => { }}
                  />
                </div>}
            </div>
            <div className="row duty-search">
                <div className="col-md-12 col-lg-12">
                    <button type="button" onClick={() => this.handleSubmitClick(this.state.endDate)} className="btn btn-primary duty-search-btn">Search</button>
                </div>
            </div>
        </div>
    </div>}
        {this.state.isLoader && <div className="duty-loader"></div>}
        <div className="card">
            <div className="card-body" >
              <BootstrapTable
                bootstrap4
                keyField="srNo"
                data={this.state.data}
                pagination={paginationFactory(options)}
                filter={filterFactory()}
                columns={this.state.columns}
                bordered={false}
                noDataIndication="No Data Found" />
            </div>
          </div>
        </Page.Content>
      </BaseLayout>
    );
  }

}

class CalendarIcon extends React.Component {
  render() {
      return (
        <i className="fe fe-calendar"></i>
      );
  }
}


export default MobileReport;
