// @flow
import React from "react";
import { Page, Grid } from "tabler-react";
import { Button, Alert } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import Select from 'react-select';
import "./alert.js";
import "../App.css";
import { PassAPI } from "../apis/PassAPI";
class VarientCreate extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            validationError: "",
            redirect: false,
            responsemessage: '',
            addstatusclass: 'none',
            passName: '',
            passType: '',
            validity: '',
            numbermonth: '',
            passvariantname: '',
            fareInfoStatus: 'ACTIVE',
            tripperday: 0,
            totaltrips: 0,
            passProductStatus: '',
            producttypeList: [],
            hidesubmit: 'hidesubmitbtn',
            isloading: false,
            showmessage: false,
            showtripdetail: false,
            errors: {},
            fareAmount: '',
            bonusAmount: '',
            showStoredValueDetail: false,
            authkey: sessionStorage.getItem('authkey')
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);

    }
    handleChange(date) {
        this.setState({
            startDate: date
        });

    }

    componentDidMount() {
        let concession = this;
        PassAPI.getProduct(
            (response) => {
                let producttypeFromApi = response.data.data.content.map(producttype => { return { value: producttype.id, label: producttype.passName,passtype: producttype.passType } })
                concession.setState({ producttypeList: [{ value: '', label: 'Select Product Type' }].concat(producttypeFromApi) });
            }
        );
    }


    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {

            case 'passvariantname': {
                return this.setState({ passvariantname: itemValue })

            }
            case 'numbermonth': {
                return this.setState({ numbermonth: itemValue })

            }
            case 'validity': {
                return this.setState({ validity: itemValue })

            }
            case 'totaltrips': {
                return this.setState({ totaltrips: itemValue })

            }
            case 'tripperday': {
                return this.setState({ tripperday: itemValue })
            }
            case 'fareAmount': {
                return this.setState({ fareAmount: itemValue })
            }
            case 'bonusAmount': {
                return this.setState({ bonusAmount: itemValue })
            }
            default:
        }
    }
    dropdownvalid(item, passtype, type) {
        switch (type) {
            case 'passType': {
                if (passtype === 'STORED_VALUE') {
                    this.setState({ showtripdetail: false, showStoredValueDetail: true });
                } else {
                    this.setState({ showtripdetail: true, showStoredValueDetail: false });
                }
                return this.setState({ passType: item })
            }
            default:
        }
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.passvariantname) {
            formIsValid = false;
            errors["passvariantname"] = "Cannot be empty";
        }
        if (!this.state.passType) {
            formIsValid = false;
            errors["passType"] = "Cannot be empty";
        }
        if (!this.state.numbermonth) {
            formIsValid = false;
            errors["numbermonth"] = "Cannot be empty";
        }
        if (this.state.showtripdetail) {
            if (!this.state.totaltrips) {
                formIsValid = false;
                errors["totaltrips"] = "Cannot be empty";
            }
            if (!this.state.tripperday) {
                formIsValid = false;
                errors["tripperday"] = "Cannot be empty";
            }
        }
        if (this.state.showStoredValueDetail) {
            if (!this.state.fareAmount) {
                formIsValid = false;
                errors["fareAmount"] = "Cannot be empty";
            }
            if (!this.state.bonusAmount) {
                formIsValid = false;
                errors["bonusAmount"] = "Cannot be empty";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    GetDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    getroutetype(type, data) {
        if (data === 'passType') {
            this.setState({ passType: type });
        } else {
            this.setState({ multipleroutetype: type });
        }
    }
    handleDismiss() {
        this.setState({ showmessage: false });
    }

    handleShow() {
        this.setState({ showmessage: true });
    }

    submitpass() {
        if (this.handleValidation()) {
            this.setState({ isloading: true });
            let obj = {};
            let status = this;
            obj.title = this.state.passvariantname;
            obj.tripValue = parseFloat(this.state.totaltrips);
            obj.validityMultiplier = parseFloat(this.state.numbermonth);
            obj.validityType = this.state.validity;
            obj.tripPerDay = parseFloat(this.state.tripperday);
            obj.fareAmount = parseInt(this.state.fareAmount, 10);
            obj.bonusAmount = parseInt(this.state.bonusAmount, 10);
            obj.fareInfoStatus = 'ACTIVE';
            
            PassAPI.postProductIdVariant(this.state.passType, obj,
                (response) => {
                    status.setState({
                        isloading: false,
                        showmessage: true,
                        passvariantname: '',
                        tripvalue: '',
                        numbermonth: '',
                        validity: '',
                        tripperday: '',
                        passType: '',
                        fareAmount:'',
                        bonusAmount:'',
                        responsemessage: "Pass Varient Create Successfully. "
                    });
                },
                (error) => {
                    status.setState({
                        isloading: false,
                        showmessage: true,
                        responsemessage: error.message
                    });
                    return;
                }
            );
        }
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        var opts = {};
        if (this.state.readonly === true) {
            opts['readOnly'] = 'readOnly';
        }
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="info" className="col-lg-4 m-2" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            )
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="New Variant" />
                    <React.Fragment>
                        <form>
                            {fadeinout}
                            <Grid.Row md={12} lg={12}>
                                <Grid.Col lg={6} className="bgdetails">
                                    <Grid.Row lg={4} className="detailschild"  >
                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Pass Variant Name</label></Grid.Col>
                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input {...opts} type="text" className="form-control" value={this.state.passvariantname} onChange={(item) => this.valid(item, 'passvariantname')} ></input>
                                            <span style={{ color: "red" }}>{this.state.errors["passvariantname"]}</span>
                                        </Grid.Col>

                                    </Grid.Row>
                                    <Grid.Row lg={4} className="detailschild"  >
                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Select Pass Product </label></Grid.Col>
                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <Select options={this.state.producttypeList} onChange={(item) => this.dropdownvalid(item.value, item.passtype, 'passType')} />
                                            <span style={{ color: "red" }}>{this.state.errors["passType"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>

                                    <Grid.Row lg={4} className="detailschild"  >
                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Select Validity (Months)</label></Grid.Col>
                                        <Grid.Col lg={2} className="paddingnull" bsStyle="padding:0px">
                                            <select className="form-control" value={this.state.numbermonth} onChange={(item) => this.valid(item, 'numbermonth')}>
                                                <option value="0" >0</option>
                                                <option value="1" >1</option>
                                                <option value="2" >2</option>
                                                <option value="3" >3</option>
                                                <option value="4" >4</option>
                                                <option value="5" >5</option>
                                                <option value="6" >6</option>
                                                <option value="7" >7</option>
                                                <option value="8" >8</option>
                                                <option value="9" >9</option>
                                                <option value="10" >10</option>
                                                <option value="11" >11</option>
                                                <option value="12" >12</option>
                                            </select>
                                            <span style={{ color: "red" }}>{this.state.errors["numbermonth"]}</span>
                                        </Grid.Col>
                                        <Grid.Col lg={3} className="paddingnull" bsStyle="padding:0px">
                                            <select className="form-control" value={this.state.validity} onChange={(item) => this.valid(item, 'validity')}>
                                                <option select="selected" value="" >Select</option>
                                                <option select="selected" value="MONTH" >MONTH</option>
                                            </select>
                                            <span style={{ color: "red" }}>{this.state.errors["validity"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>

                                    {this.state.showStoredValueDetail &&
                                        <Grid.Row lg={4} className="detailschild"  >
                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Fare Amount</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                <input {...opts} type="number" className="form-control" value={this.state.fareAmount} onChange={(item) => this.valid(item, 'fareAmount')} ></input>
                                                <span style={{ color: "red" }}>{this.state.errors["fareAmount"]}</span>
                                            </Grid.Col>
                                        </Grid.Row>
                                    }
                                    {this.state.showStoredValueDetail &&
                                        <Grid.Row lg={4} className="detailschild"  >
                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Bonus Amount</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                <input {...opts} type="number" className="form-control" value={this.state.bonusAmount} onChange={(item) => this.valid(item, 'bonusAmount')} ></input>
                                                <span style={{ color: "red" }}>{this.state.errors["bonusAmount"]}</span>
                                            </Grid.Col>
                                        </Grid.Row>
                                    }
                                    {this.state.showtripdetail &&
                                        <Grid.Row lg={4} className="detailschild"  >
                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Total Trips </label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                <input {...opts} type="number" className="form-control" value={this.state.totaltrips} onChange={(item) => this.valid(item, 'totaltrips')} ></input>
                                                <span style={{ color: "red" }}>{this.state.errors["totaltrips"]}</span>
                                            </Grid.Col>

                                        </Grid.Row>
                                    }
                                    {this.state.showtripdetail &&
                                        <Grid.Row lg={4} className="detailschild"  >
                                            <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Trip Per Day</label></Grid.Col>
                                            <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                <input {...opts} type="number" className="form-control" value={this.state.tripperday} onChange={(item) => this.valid(item, 'tripperday')} ></input>
                                                <span style={{ color: "red" }}>{this.state.errors["tripperday"]}</span>
                                            </Grid.Col>

                                        </Grid.Row>
                                    }
                                    <Grid.Row lg={4} className="detailschild" >
                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                        <Grid.Col lg={6} className="paddingnull">
                                            <Button type="button" onClick={() => this.submitpass()} hidden={this.state.isloading} className="btn btn-primary" >
                                                Submit

                                            </Button>
                                            {this.state.isloading &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            <a href="/varient-pass-list"> <Button type="button" color="secondary mb-2 mr-2"   >Back</Button></a>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                        </form>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}
export default VarientCreate;