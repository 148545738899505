// @flow
import React from "react";
import { Page,  Grid } from "tabler-react";
import { Button,Alert} from 'react-bootstrap';
import Select from 'react-select';
import BaseLayout from "./BaseLayout";
import "./alert.js";
import "../App.css";
import { EmployeesAPI } from "../apis/EmployeesAPI";


const optionsStatus=[
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' }
];

class CreateEmployee extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            DepotList:[],
            validationError: "",
            redirect: false,
            responsemessage:'',
            addstatusclass:'none',
            employeeCode:'', 
            name:'',
            password:'',
            depotId:'',
            designation:'',
            agentCommision:'',
            role:'',
            status:'',
            hidesubmit:'hidesubmitbtn',
            isloading: false,
            showmessage: false,
            fields: {},
            errors: {},
            isInspectorDesignation: false,
            optionsDesignation:[],
            isAgentCommission:localStorage.getItem('agentCommission'),
            isAgentCommissionTextBox:false,
            authkey: localStorage.getItem('authkey')
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }
      
    componentDidMount() {
        let thisdepot = this;
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function(event) {
            var db          = event.target.result;
            var depots      = db.transaction('depot', 'readwrite').objectStore("depot");
            var depotdata  = depots.getAll();
            depots.getAll().onsuccess = function(event) {
                let DepotListFromInDB = depotdata.result.map(depots => { return { value: depots.id, label: depots.depotname }; });
                thisdepot.setState({ DepotList: DepotListFromInDB });
            };
        };
        thisdepot.state.optionsDesignation = [
            { value: 'CONDUCTOR', label: 'CONDUCTOR' },
            { value: 'DRIVER', label: 'DRIVER' },
            { value: 'INSPECTOR', label: 'INSPECTOR' }
        ];
        if(thisdepot.state.isAgentCommission === "true"){
            var agent ={ value: 'AGENT', label: 'AGENT' };
            thisdepot.state.optionsDesignation.push(agent);
        }
        thisdepot.setState({ optionsDesignation: thisdepot.state.optionsDesignation});
    }
    
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    
    valid(item, type) {
        let itemValue = item.target.value;
        
        switch (type) {
            case 'employeeCode': {
                let fields = this.state.fields;
                fields[type] =itemValue; 
                const re = /^[0-9a-zA-Z\b]+$/;
                if (itemValue !== "" && !re.test(itemValue)) {
                    return false;
                } else {
                    this.setState({ employeeCode: itemValue,fields })
                }
                break;
            }
            case 'name': {
                let fields = this.state.fields;
                fields[type] =itemValue;
                const re = /^[0-9a-zA-Z\b]+$/;
                if (itemValue !== "" && !re.test(itemValue)) {
                    return false;
                } else {
                    this.setState({ name: itemValue,fields });
                }
                break;
            }
            case 'password': {
                let fields = this.state.fields;
                fields[type] =itemValue;
                this.setState({ password: itemValue, fields });
                break;
            }
            case 'agentCommision':{
                let fields = this.state.fields;
                fields[type] =itemValue;
                this.setState({ agentCommision: itemValue, fields });
                break;
            }
            default:    
        }
    }
    
    dropdownvalid(item, type){
        switch (type) {
            case 'depotId': {
                let fields = this.state.fields;
                fields[type] =item; 
                this.setState({ depotId: item,fields });
                break
            }
            case 'designation': {
                let fields = this.state.fields;
                fields[type] =item;
                if(item.value === 'INSPECTOR') {
                    this.setState({isInspectorDesignation: true});
                } else if(item.value === 'AGENT'){
                    this.setState({isAgentCommissionTextBox: true});
                }else {
                    this.setState({isInspectorDesignation: false,isAgentCommissionTextBox:false});
                } 
                return this.setState({ designation: item });
            }
            case 'status': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ status: item});
            }
            default:
        }
    }
    
    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["employeeCode"]){
            formIsValid = false;
            errors["employeeCode"] = "Cannot be empty";
        }
        if(!fields["name"]){
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }
        if(!fields["depotId"]){
            formIsValid = false;
            errors["depotId"] = "Cannot be empty";
        }
        if(!fields["designation"]){
            formIsValid = false;
            errors["designation"] = "Cannot be empty";
        }
        if(this.state.isAgentCommissionTextBox && !fields["agentCommision"]){
            formIsValid = false;
            errors["agentCommision"] = "Cannot be empty";
        }
        if(this.state.isInspectorDesignation && !fields["password"]){
            formIsValid = false;
            errors["password"] = "Cannot be empty";
        }
        if(!fields["status"]){
            formIsValid = false;
            errors["status"] = "Cannot be empty";
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    

    handleDismiss() {
        this.setState({ showmessage: false });
    }

    handleShow() {
        this.setState({ showmessage: true });
    }
    
    submitemployee(){
        if(this.handleValidation()){
            this.setState({isloading: true});
            let obj = {};
            let satus = this;
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.employeeCode = this.state.employeeCode;
            obj.name = this.state.name.toUpperCase();
            obj.password = this.state.isInspectorDesignation?this.state.password:'DEFAULT';
            obj.depots =[satus.state.depotId.value];
            obj.designation = this.state.designation.value.toUpperCase();
            obj.role = 'EMPLOYEE';
            obj.status = this.state.status.value;
            if(this.state.agentCommision !== ""){
                obj.commissionPercentage = this.state.agentCommision;
            }
            EmployeesAPI.postEmployee(obj,
                (response) => {
                    const dbName = "RIDLR AFCS";
                    const version = 1; //versions start at 1
                    var request = indexedDB.open(dbName, version);
                    request.onsuccess = function(event) {
                        var db         = event.target.result;
                        var conductor      = db.transaction('conductor', 'readwrite').objectStore("conductor");
                        var clear =  conductor.clear();
                        clear.onsuccess = function(event) {
                            EmployeesAPI.getEmployees(
                                (response) => {
                                    let ConductorDataFromApi = response.data.data.content.map((conductor) => {
                                        return { employeeId: conductor.employeeId, employeeCode: conductor.employeeCode, name: conductor.name, depot: conductor.depots, designation: conductor.designation, role: conductor.role, status: conductor.status };
                                    });
                                    // Store values in the newly created objectStore.
                                    var conductorObjectStore = db.transaction("conductor", "readwrite").objectStore("conductor");
                                    conductorObjectStore.clear();
                                    ConductorDataFromApi.forEach(function (route) {
                                        conductorObjectStore.add(route);
                                    });
                                }
                            );
                        }
                    }
                    satus.setState({
                        isloading: false,
                        showmessage :true,
                        employeeCode:'',
                        password: '',
                        name:'',
                        depotId:'',
                        designation:'',
                        status:'',
                        responsemessage:"Employee Created Successfully. "
                    });
                },
                (error) => {
                    satus.setState({
                        isloading: false,
                        showmessage :true,
                        responsemessage: error.message
                    });
                    return;
                }
            );
        }
    }

    goBack(e) {
        this.props.history.push('/all-employees');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        var opts = {};
        if( this.state.readonly=== true ) {
            opts['readOnly'] = 'readOnly';
        }
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success"  className="col-lg-4 m-2" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                    <p>
                    <Button className="btn-primary" onClick={(event)=>this.goBack(event)} >View</Button>
                        <span> or </span>
                    <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                    </p>

                </Alert>
            );
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Create New Employee"  />
                    <React.Fragment>
                        <form>
                            {fadeinout} 
                            <Grid.Row md={12} lg={12}>  
                                <Grid.Col  lg={6} className="bgdetails">
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Employee Code</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input { ...opts} type="text" className="form-control" value={this.state.employeeCode} maxLength="10"  onChange={(item) => this.valid(item, 'employeeCode')} ></input>
                                            <span style={{color: "red"}}>{this.state.errors["employeeCode"]}</span> 
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Name</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input { ...opts} type="text" className="form-control" value={this.state.name}  onChange={(item) => this.valid(item, 'name')} ></input> 
                                            <span style={{color: "red"}}>{this.state.errors["name"]}</span> 
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Depot</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                        <Select value={this.state.depotId} options={this.state.DepotList}  onChange={(item) => this.dropdownvalid(item, 'depotId')}  />
                                        <span style={{color: "red"}}>{this.state.errors["depotId"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Designation</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <Select value={this.state.designation}  options={this.state.optionsDesignation}  onChange={(item) => this.dropdownvalid(item, 'designation')}  />
                                            <span style={{color: "red"}}>{this.state.errors["designation"]}</span> 
                                        </Grid.Col>
                                    </Grid.Row>
                                    {this.state.isAgentCommissionTextBox &&
                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Commission</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="number" className="form-control" value={this.state.agentCommision}  onChange={(item) => this.valid(item, 'agentCommision')} ></input>
                                            <span style={{color: "red"}}>{this.state.errors["agentCommision"]}</span> 
                                         </Grid.Col>
                                        </Grid.Row>
                                    }
                                    {this.state.isInspectorDesignation &&
                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Password</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="password" className="form-control" value={this.state.password}  onChange={(item) => this.valid(item, 'password')} ></input>
                                            <span style={{color: "red"}}>{this.state.errors["password"]}</span> 
                                         </Grid.Col>
                                        </Grid.Row>
                                    }
                                    
                                    <Grid.Row  lg={4} className="detailschild"  >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Employee Status</label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <Select value={this.state.status} options={optionsStatus}  onChange={(item) => this.dropdownvalid(item, 'status')}  />
                                            <span style={{color: "red"}}>{this.state.errors["status"]}</span> 
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row  lg={4} className="detailschild" >
                                        <Grid.Col  lg={4} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                        <Grid.Col  lg={6} className="paddingnull">
                                            <Button type="button"  onClick={() => this.submitemployee()} className="btn btn-primary" >
                                                Submit
                                                {this.state.isloading &&
                                                    <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                }
                                            </Button> 
                                            <a href="/all-employees"> <Button  type="button" color="secondary mb-2 mr-2"   >Back</Button></a>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                        </form>
                    </React.Fragment>  
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default CreateEmployee;