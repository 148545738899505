// @flow
import * as React from "react";
import { Page,Grid,Form } from "tabler-react";
import { Table, Row,Button } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Pagination from "./Pagination";
import SessionUtils from "../utils/SessionUtils";
const perPage = 20;
class Stoplist extends React.Component {
    constructor() {
		super();
		this.state = {
            data:  [],
            StopList: [],
            stopsDropDown:[],
            currentStopList: [],
            currentPage: null,
            totalPages: null,
            offset: 0,
            status:'',
            errors:{},
            name:'',
            selecteddisplay:'',
            isloader: false,
            authkey: localStorage.getItem('authkey')
    }
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
     }
     loadCommentsFromServer() {
      
      let currentComponent = this;
      let stopsdata ='';
      currentComponent.setState({ isloader: true });
      const dbName = "RIDLR AFCS";
      const version = 1; //versions start at 1
      var request = indexedDB.open(dbName, version);
        let assignDuty = this;
        request.onsuccess = function(event) {
            var db          = event.target.result;
            var transaction = db.transaction(['stops'], 'readonly');
            var stops = transaction.objectStore('stops');
            var index = stops.index("shortName");
            if(currentComponent.state.selecteddisplay !== ''){ 
              stopsdata  = index.getAll(currentComponent.state.selecteddisplay);
              stopsdata.onsuccess = function(event) {
              assignDuty.setState({ StopList:event.target.result,
                                    isloader: false,
                                    pageCount: Math.ceil(event.target.result.length / 20) });
                
                  };
            }else{ 
              stopsdata  = stops.getAll();
              // get data from Stops object
              stops.getAll().onsuccess = function(event) {
              assignDuty.setState({ StopList:stopsdata.result,
                                    isloader: false,
                                    pageCount: Math.ceil(stopsdata.result.length / 20) });
              
                };
            }
           
            
        }     
 }
 componentDidMount() {
    let currentComponent = this;
    let stopsdata='';
    const dbName = "RIDLR AFCS";
    const version = 1; //versions start at 1
    currentComponent.setState({ isLoader: true });
    var request = indexedDB.open(dbName, version);
    request.onsuccess = function(event) {
        var db          = event.target.result;
        var conductor      = db.transaction('stops', 'readwrite').objectStore("stops");
        stopsdata  = conductor.getAll();
        /*For Conductor Dropdown  */
        conductor.getAll().onsuccess = function(event) {
            let StopsListFromApi = stopsdata.result.map(stops => { return { value: stops.shortName, label: stops.shortName } })
            currentComponent.setState({ stopsDropDown: [{ value: '', label: 'Select Short Name' }].concat(StopsListFromApi)});
        };
    }
    this.loadCommentsFromServer();
  }
  onPageChanged = data => {
    const { StopList } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentStopList = StopList.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentStopList, totalPages });
  };
  

  handleSubmitClick() {
    let key = this.state.selecteddisplay;
    let errors = {};
    if(!key){
      errors["selecteddisplay"] = "Cannot be empty";
      this.setState({errors: errors});
      return;
  }
    this.setState({ selecteddisplay: key }, () => {
      this.loadCommentsFromServer();
    });
  }
  handleResetClick(){
    this.setState({ selecteddisplay: '' }, () => {
      this.loadCommentsFromServer();
    });
    
  }

  dropdownvalid(dispaly, type){
        
    switch (type) {
        case 'name': {
            return this.setState({ selecteddisplay:dispaly,errors:[] })
        }
    default:    
    }
}
    render(){
      
      var stylescope = {
        splitterStyle: {
            margin: 5
        }
     };
    const {
        StopList,
        stopsDropDown,
        currentPage,
        totalPages
      } = this.state;
      const totalStops = StopList.length;
      let paginationtransaction;
        
        if (this.state.pageCount > 0) {
            paginationtransaction = (
              <Pagination
                totalRecords={totalStops}
                pageLimit={perPage}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            )
          } else {
            paginationtransaction = (
              <div>
                No Data Found...
                </div>
      
            )
          }
          const valueFromId = (opts, id) => opts.find(o => o.value === id);
          if (this.state.isloader) {
            return (
              <BaseLayout>
                <Page.Content>
                
                  <React.Fragment>
                  
                  <Table striped bordered condensed hover >
                    <thead>

                      <tr>
                        <th >Short Name</th>
                        <th >Long Name</th>
                        <th >Latitude</th>
                        <th >Longitude</th>
                        <th >Status</th>
                        <th >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr><td colSpan="6" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                    </tbody>
                    </Table>
                    {paginationtransaction}
                    </React.Fragment>
                </Page.Content>
              </BaseLayout>
            );
      
          } else {
        return(
            <BaseLayout>
              <Page.Content>
                <Page.Header title="Stop List" />
                <Grid>
        <Row className="show-grid">
       
              <Grid.Col md={6} lg={2}>
                  <Form.Group label="SHORT NAME" md={6} lg={4}>
                    <Select options={stopsDropDown}   value={valueFromId(stopsDropDown, this.state.selecteddisplay,this.state.selecteddisplay)} onChange={(item) => this.dropdownvalid(item.label, 'name')}  />
                    <span style={{color: "red"}}>{this.state.errors["selecteddisplay"]}</span>
                  </Form.Group>
              </Grid.Col>
            <Grid.Col md={6} lg={9}>
              <Form.Group  label="&nbsp;" >
                <Button className="btn btn-secondary"  onClick={this.handleSubmitClick} hidden={this.state.isloader}  type="button">
                    <i className="fe fe-search"></i>
                  </Button>
                  {this.state.isloader &&
                    <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                }
                <Button  type="button" className="btn btn-secondary mb-2 mr-2"  onClick={this.handleResetClick} style={stylescope.splitterStyle}  >Reset</Button>
              </Form.Group>
            </Grid.Col>
          <Grid.Col md={6} lg={1}>
              <Form.Group  label="&nbsp;" >
              {!SessionUtils.isChildAgency() && <a href="/create-stop"> <Button type="button"  className="btn btn-primary mb-2 mr-2 float-right"> Add Stop </Button></a>}
              </Form.Group>
          </Grid.Col>
        </Row>
      </Grid>
                <React.Fragment>
                
                  <Table striped bordered condensed hover >
                    <thead>

                      <tr>
                        <th >Short Name</th>
                        <th >Long Name</th>
                        <th >Latitude</th>
                        <th >Longitude</th>
                        <th >Status</th>
                        {!SessionUtils.isChildAgency() && <th >Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.currentStopList.map(function(obj, i){
                        return (
                          <tr key={i}>
                            <td >
                              {obj.shortName}
                            </td>
                            <td >
                              {obj.longName}
                            </td>
                            <td >
                              {obj.latitude}
                            </td>
                            <td >
                              {obj.longitude}
                            </td>
                            <td>
                              {obj.status}
                            </td>
                            {!SessionUtils.isChildAgency() && <td>   
                              <Link to={`/edit-stop/${obj.id}`} className="active"><i className="fe fe-edit"></i></Link>
                            </td>}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {paginationtransaction} {currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                  <span className="font-weight-bold">{totalPages}</span>
                </span>
              )}
                 
              </React.Fragment>
              </Page.Content>
            </BaseLayout>
              )
              }
    };
}
export default Stoplist;