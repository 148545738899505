// @flow
import * as React from "react";
import { Link } from 'react-router-dom';
import { Page,Grid,Button } from "tabler-react";
import { Table, Row, Col } from 'react-bootstrap';
import Pagination from "./Pagination";
import { AgencyAPI } from "../apis/AgencyAPI";
import SessionUtils from "../utils/SessionUtils";

const perPage = 10;

class DepotList extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        currentDepotList:[],
        DepotList: [],
        data: [],
        offset: 0,
        page: 0,
        isLoader: false,
        from: new Date(),
        to: new Date(),
        authkey: localStorage.getItem('authkey')
      };
    }
    loadCommentsFromServer() { 
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        AgencyAPI.getDepot(
            (response) => {
                currentComponent.setState({
                    fetchInProgress: false,
                    DepotList: response.data.data,
                    isLoader: false,
                    pageCount: Math.ceil(response.data.data.length / 20) 
                });
            },
            (error) => {
                currentComponent.setState({isLoader: false  });
            }
        );
    }
    
    componentDidMount() {
        this.loadCommentsFromServer();
    }
    
    onPageChanged = data => {
        const { DepotList } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
      
        const offset = (currentPage - 1) * pageLimit;
        const currentDepotList = DepotList.slice(offset, offset + pageLimit);
      
        this.setState({ currentPage, currentDepotList, totalPages });
    };
      
    render() {
        let paginationtransaction;
        const {
            DepotList,
            currentPage,
            totalPages
        } = this.state;
        const totalroutes = DepotList.length;
        
        if (this.state.pageCount > 0) {
            paginationtransaction = (
              <Pagination
                totalRecords={totalroutes}
                pageLimit={perPage}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            )
        } else {
            paginationtransaction = (
              <div>
                No Data Found...
                </div>

            )
        }
        if (this.state.isLoader) {
            return (
                <Page.Content>
                    <Grid>
                        <Row className="show-grid floatright">
                            <Col xsHidden md={12}>
                                {!SessionUtils.isChildAgency() && <a href="/add-depot"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Add Depot </Button></a>}
                            </Col>
                        </Row>
                    </Grid>
                    <Page.Header title="Depot List" />
                    <React.Suspense fallback={<div>Loader...</div>}>
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr>
                                    <th >Depot Name</th>
                                    <th >Status</th>
                                    <th >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td colSpan="3" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                            </tbody>
                        </Table>
                        {paginationtransaction}
                    </React.Suspense>
                </Page.Content>
            );
          } else {
            return (
                <Page.Content>
                    <Grid>
                        <Row className="show-grid floatright">
                            <Col xsHidden md={12}>
                            {!SessionUtils.isChildAgency() && <a href="/add-depot"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Add Depot </Button></a>}
                            </Col>
                        </Row>
                    </Grid>
                    <Page.Header title="Depot List" />
                    <React.Suspense fallback={<div>Loader...</div>}>
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr>
                                    <th >Depot Name</th>
                                    <th >Status</th>
                                    {!SessionUtils.isChildAgency() && <th >Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.currentDepotList.map(function(obj, i){
                                    return (
                                        <tr key={i}>
                                            <td >
                                                {obj.name}
                                            </td>
                                            <td >
                                                {obj.status}
                                            </td>
                                            {!SessionUtils.isChildAgency() && <td>
                                                <Link to={{ pathname: `/edit-depot/${obj.id}`, state: { cause: obj} }}  className="active"><i className="fe fe-edit"></i></Link>
                                            </td>}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    {paginationtransaction} {currentPage && (
                        <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                        Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                        <span className="font-weight-bold">{totalPages}</span>
                        </span>
                    )}
                    </React.Suspense>
                </Page.Content>
            );
        }
    };
}
export default DepotList;