// @flow
import React from "react";
import { Page, Button } from "tabler-react";
import BaseLayout from "./BaseLayout";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';
import "./alert.js";
import "../App.css";
import { ETIMCommunicator, DeviceTranslator } from '../_components';
import { InventoryAPI } from "../apis/InventoryAPI";
const  deviceTypes = [
    { value: 'RIDLR', label: 'RIDLR' },
    { value: 'AMC', label: 'AMC' }
];
class RegisterDevice extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.type = this.props.location.pathname.split('/')[1]; //this is the name of the route
        this.state = {
            isReadEtmLoader: false,
            etmResponseString: '',
            etmDeviceSerialId: '',
            isSendDataToTranslatorLoader: false,
            isAddDeviceLoader: false,
            authkey: localStorage.getItem('authkey'),
            depotList: [],
            isManufactureRegisterDevice: this.type === 'manufacturer-register-device' ? true : false,
            errors: {},
            depotId: '',
            responseMessage: '',
            showMessage: false,
            phoneNumber: '',
            countryCode: '+91',
            imeiNumber: '',
            simId:'',
            swVersion:'',
            deviceType:'',
            msgHandler: null
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    componentDidMount() {
        if (!this.state.isManufactureRegisterDevice) {
            this.loadDepotFromDb();
        }
    }

    loadDepotFromDb() {
        let thisdepot = this;
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function (event) {
            var db = event.target.result;
            var depots = db.transaction('depot', 'readwrite').objectStore("depot");
            var depotdata = depots.getAll();
            depots.getAll().onsuccess = function (event) {
                let DepotListFromInDB = depotdata.result.map(depots => {
                    return { value: depots.id, label: depots.depotname };
                });
                thisdepot.setState({ depotList: DepotListFromInDB });
            };
        };
    }

    readEtmHardwareDetails() {
        let currentComponent = this;
        currentComponent.setState({ isReadEtmLoader: true, error: '' });
        ETIMCommunicator.postDownloadDataDeviceDetails( 
            (response) => {
                currentComponent.setState({ 
                    etmResponseString: response.data,
                    msgHandler: response.msgHandler,
                    isReadEtmLoader: false
                });
                currentComponent.sendDataToTranslator();
            },
            (error) => {
                currentComponent.setState({ 
                    msgHandler: error.msgHandler,
                    isReadEtmLoader: false
                });
            }
        );
    }

    dropDownSelection(item, type) {
        switch (type) {
            case 'Depot': {
                this.setState({ depotId: item })
                break;
            }
            case 'deviceType': {
                this.setState({ deviceType: item })
                break;
            }
            default:
                break;
        }
    }

    sendDataToTranslator() {
        let currentComponent = this;
        currentComponent.setState({ isSendDataToTranslatorLoader: true, error: '' })
        let command = currentComponent.state.etmResponseString;
        DeviceTranslator.getDeviceDetails(command,
            (response) => {
                let params = response.data.data;
                
                currentComponent.setState({
                    isSendDataToTranslatorLoader: false
                });
                if ("simSerialNumber" in params){
                    let simno = params.simSerialNumber;
                    currentComponent.setState({ simId:  simno.slice(0, -1) });
                }
                if ("osVersion" in params){
                    currentComponent.setState({ swVersion: params.osVersion +","+ params.date });
                }
                if ("imeiNumber" in params){
                    currentComponent.setState({ imeiNumber: params.imeiNumber});
                }
                currentComponent.setState({ etmDeviceSerialId: params.deviceSerialId });
            },
            (error) => {
                currentComponent.setState({
                    isSendDataToTranslatorLoader: false
                });
            }
        );
    }

    addManufacturerDevice() {
        if (this.handleValidation()) {
            let currentComponent = this;
            currentComponent.setState({ isAddDeviceLoader: true });

            let obj = {};
            let method = '';
            if (currentComponent.state.isManufactureRegisterDevice) {
                method = 'POST';
                obj.manufacturer = "BALAJI";
                obj.deviceSerialId = currentComponent.state.etmDeviceSerialId;
                obj.phoneNumber = currentComponent.state.countryCode + currentComponent.state.phoneNumber;
                obj.imei = currentComponent.state.imeiNumber;
                obj.simSerialNumber = currentComponent.state.simId;
                obj.softwareVersion = currentComponent.state.swVersion;
                obj.deviceType = currentComponent.state.deviceType.value
            } else {
                method = 'PUT';
                obj.agencyCode = localStorage.getItem('agencyname');
                obj.depot = currentComponent.state.depotId.value;
                obj.imei = currentComponent.state.imeiNumber;
                obj.simSerialNumber = currentComponent.state.simId;
                obj.softwareVersion = currentComponent.state.swVersion;
            }
            if(method === 'POST') {
                InventoryAPI.postDevice(obj,
                    (response) => {
                        currentComponent.handleAddDeviceResponse(response);
                    }
                );
            } else if(method === 'PUT') {
                InventoryAPI.putDevice(currentComponent.state.etmDeviceSerialId, obj,
                    (response) => {
                        currentComponent.handleAddDeviceResponse(response);
                    }
                );
            }
        }
    }

    handleAddDeviceResponse(response) {
        let currentComponent = this;
        currentComponent.setState({ isAddDeviceLoader: false });
        if (response.data.code === 2000 && response.data.message === 'SUCCESS') {
            currentComponent.setState({
                responseMessage: 'Device added successfully!',
                showMessage: true,
                etmDeviceSerialId: '',
                etmResponseString: ''
            });
        } else {
            currentComponent.setState({ error: response.data.message });
            return;
        }
    }

    handleValidation() {
        let formIsValid = true;
        if (!this.state.etmDeviceSerialId) {
            formIsValid = false;
            this.setState({ error: "Device Serial Id cannot be empty" });
        }
        if (this.state.isManufactureRegisterDevice && !this.state.phoneNumber) {
            formIsValid = false;
            this.setState({ error: "Please enter Phone Number" });
        }
        if (this.state.phoneNumber && (this.state.phoneNumber.length < 10 || this.state.phoneNumber.length > 13)) {
            formIsValid = false;
            this.setState({ error: "Please enter a Number having 10 to 13 Digits" });
        }
        if (this.state.isManufactureRegisterDevice && !this.state.deviceType) {
            formIsValid = false;
            this.setState({ error: "Please select Device Type" });
        }
        /*if (this.state.isManufactureRegisterDevice && !this.state.imeiNumber) {
            formIsValid = false;
            this.setState({ error: "Please enter IMEI Number" });
        }
        if (this.state.isManufactureRegisterDevice && !this.state.simId) {
            formIsValid = false;
            this.setState({ error: "Please enter SIM NO" });
        }
        if (this.state.isManufactureRegisterDevice && !this.state.swVersion) {
            formIsValid = false;
            this.setState({ error: "Please enter SOFTWARE VERSION" });
        }*/
        if (!this.state.isManufactureRegisterDevice && !this.state.depotId) {
            formIsValid = false;
            this.setState({ error: "Please select Depot" });
        }
        return formIsValid;
    }

    handleDismiss() {
        this.setState({ showMessage: false, depotId: '' });
    }

    valid(item, type) {
        switch (type) {
            case 'phoneNumber': {
                const val = item.target.value;
                const re = /^[0-9\b]+$/;
                var newVal = "";
                if (val !== "" && !re.test(val)) {
                    // Filter non-digits from input value.
                    return false;
                } else {
                    newVal = val;
                }
                this.setState({ phoneNumber: newVal });
                break;
            }
            case 'countryCode': {
                this.setState({ countryCode: item.target.value });
                break;
            }
            default:
                break;
        }
    }

    goBack(e) {
        this.props.history.push(this.state.isManufactureRegisterDevice ? '/manufacturer-device-list' : '/device-list');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    render() {

        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showMessage) {
            fadeinout = (
                <Alert bsStyle="success">
                    <h4>{this.state.responseMessage}</h4>
                    <Button onClick={(event) => this.goBack(event)}  className="btn-primary">View</Button>
                    <span> or </span>
                    <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                </Alert>
            )
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                                {fadeinout}
                                {this.state.msgHandler}
                                <h3 className="card-title">Add Device</h3>
                                <React.Suspense fallback={<div>Loader...</div>}>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4">
                                            {this.state.error &&
                                                <div className={'alert alert-danger margin-3'} style={stylescope.splitterStyle}>{this.state.error}</div>
                                            }</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label">Device Serial Id
                                                    <span className="form-required">*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" readOnly className="form-control" value={this.state.etmDeviceSerialId} placeholder="Device Serial Id "></input></div>
                                        </div>
                                        <div className="col-sm-6 col-md-2">
                                            <div className="form-group">
                                                <button type="button" onClick={() => this.readEtmHardwareDetails()} className="btn btn-primary">Read ETM{this.state.isReadEtmLoader &&
                                                    <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                }</button>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.isManufactureRegisterDevice &&
                                        <div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label">SIM Card<span className="form-required">*</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-1">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="+91"
                                                            value={this.state.countryCode}
                                                            onChange={(item) => this.valid(item, 'countryCode')}></input>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-3">
                                                    <div className="form-group">
                                                        <input type="text" value={this.state.phoneNumber}
                                                            onChange={(item) => this.valid(item, 'phoneNumber')}
                                                            className="form-control" placeholder="Phone Number"></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label">SIM ID<span className="form-required">*</span></label>
                                                        <input type="text" value={this.state.simId}
                                                            className="form-control" placeholder="SIM ID" readOnly></input>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label">SW Version<span className="form-required">*</span></label>
                                                        <input type="text" value={this.state.swVersion}
                                                            className="form-control" placeholder="SW Version" readOnly></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label">IMEI Number<span className="form-required">*</span></label>
                                                        <input type="text" value={this.state.imeiNumber}
                                                           className="form-control" placeholder="IMEI Number" readOnly></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Device Type<span className="form-required">*</span></label>
                                                        <Select options={deviceTypes} value={this.state.deviceType} isClearable={true} onChange={(item) => this.dropDownSelection(item, 'deviceType')} />
                                                        <span style={{ color: "red" }}>{this.state.errors["deviceType"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {!this.state.isManufactureRegisterDevice &&
                                        <div className="row">
                                            <div className="col-sm-6 col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label">Depot <span className="form-required">*</span></label>
                                                    <Select options={this.state.depotList} value={this.state.depotId} isClearable={true} onChange={(item) => this.dropDownSelection(item, 'Depot')} />
                                                    <span style={{ color: "red" }}>{this.state.errors["depotid"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="row">
                                        <div className="col-sm-6 col-md-1">
                                            <div className="form-group">
                                                <label className="form-label"> &nbsp;</label>
                                                <button type="button" onClick={() => this.addManufacturerDevice()} className="btn btn-primary">Add
                                    {this.state.isAddDeviceLoader &&
                                                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </React.Suspense>
                            </div>
                        </form>
                    </React.Fragment>

                </Page.Content>
            </BaseLayout>
        );
    }
}

export default RegisterDevice;