import axios from 'axios';
import SessionUtils from '../utils/SessionUtils'
import APIResponse from './APIResponse';
import { ViewUtils } from '../utils/ViewUtils';
import MessageResponse from '../enums/MessageResponse';
import { userService } from '../_services';
import { AFCSLogger } from '../logger/AFCSLogger';
import { Base64 } from 'js-base64';

class API {
  constructor() {
    let service = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Cache-Control':'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      },
      data: {},
    });
    service.defaults.headers.common['Content-Type'] = 'application/json';
    service.defaults.headers.common['Authorization'] = localStorage.getItem('authkey');
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  setDefaultHeaders() {
    this.service.defaults.headers.common['Content-Type'] = 'application/json';
    this.service.defaults.headers.common['Authorization'] = localStorage.getItem('authkey');
    this.service.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    this.service.defaults.headers.common['Pragma'] = 'no-cache';
    this.service.defaults.headers.common['Expires'] = '0';
  }

  updateAuthorizationHeader() {
    this.service.defaults.headers.common['Authorization'] = localStorage.getItem('authkey');
  }

  handleSuccess(response) {
    let apiResponse = new APIResponse();
    apiResponse.setData(response.data);
    apiResponse.setStatus(response.status);
    apiResponse.setStatusText(response.statusText);
    return apiResponse;
  }

  handleError = (error) => {
    let apiResponse = new APIResponse();
    if (error.response !== undefined) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('authkey');
          this.service.defaults.headers.common['Authorization'] = localStorage.getItem('refertoken');
          return this.post(
            "/apis/auth/refresh", "",
            (response) => {
              let authKey = "Bearer " + response.data.data.authToken;
              localStorage.setItem('authkey', authKey);
              SessionUtils.setRole(authKey);
              error.config.headers.Authorization = localStorage.getItem('authkey');
              error.config.__isRetryRequest = true;
              return this.service.request(error.config)
            },
            (error) => {
              userService.logout();
              return this.redirectTo(document, '/login');
            });
        default:
          AFCSLogger.logAPIError(error);
          apiResponse.setStatus(error.response.status);
          apiResponse.setStatusText(error.response.statusText);
          return apiResponse;
      }
    }
    AFCSLogger.logAPIError(error);
    apiResponse.setMessage(error.message);
    apiResponse.setMsgHandler(ViewUtils.createMessageHandler(error.message, MessageResponse.ERROR));
    return apiResponse;
  }

  redirectTo = (document, path) => {
    document.location = path
  }


  getText(path, callbackSuccess, callbackError) {
    return this.service.get(path).then(
      (response) => {
        if (response.status === 200 && response.data !== '') {
          callbackSuccess(response)
        } else {
          if (callbackError !== undefined) {
            if (response.status === 200) {
              response.setMessage(response.data.message);
              response.setMsgHandler(ViewUtils.createMessageHandler(response.data.message, MessageResponse.ERROR));
            }
            callbackError(response)
          }
        }
      }
    );
  }


  get(path, callbackSuccess, callbackError) {
    return this.service.get(path).then(
      (response) => {
        if (response.status === 200 && response.data.code === 2000) {
          callbackSuccess(response)
        } else {
          if (callbackError !== undefined) {
            if (response.status === 200) {
              response.setMessage(response.data.message);
              response.setMsgHandler(ViewUtils.createMessageHandler(response.data.message, MessageResponse.ERROR));
            }
            callbackError(response);
          }
        }
      }
    );
  }

  getBlob(path, callbackSuccess, callbackError) {
    this.service.defaults.headers.common = {};
    return this.service.request({
      method: 'GET',
      url: path,
      //headers: currentComponent.service.defaults.headers,
      responseType: 'blob'
    }).then((response) => {
      if (response.status === 200) {
        let responseEx = new Blob([response.data]);
        //let content = responseEx.headers['content-type'];
        //console.log("res :" + content);
        callbackSuccess(responseEx);
      } else {
        if (callbackError !== undefined) {
          if (response.status === 200) {
            response.setMessage(response.data.message);
            response.setMsgHandler(ViewUtils.createMessageHandler(response.data.message, MessageResponse.ERROR));
          }
          callbackError(response);
        }
      }
      
    });
  }

  postAuthorisation(path, authorization, payload, callbackSuccess, callbackError) {
    this.service.defaults.headers.common['Authorization'] = authorization;
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => {
      if (response.status === 200 && response.data.code === 2000) {
        callbackSuccess(response)
      } else {
        if (callbackError !== undefined) {
          if (response.status === 200) {
            response.setMessage(response.data.message);
            response.setMsgHandler(ViewUtils.createMessageHandler(response.data.message, MessageResponse.ERROR));
          }
          callbackError(response);
        }
      }
    })
  }

  postNoAuthorisation(path, payload, callbackSuccess, callbackError) {
    this.service.defaults.headers.common = {};
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => {
      if (response.status === 200 && response.data.code === 2000) {
        callbackSuccess(response)
      } else {
        if (callbackError !== undefined) {
          if (response.status === 200) {
            response.setMessage(response.data.message);
            response.setMsgHandler(ViewUtils.createMessageHandler(response.data.message, MessageResponse.ERROR));
          }
          callbackError(response);
        }
      }
    });
  }

  post(path, payload, callbackSuccess, callbackError) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => {
      if (response.status === 200 && response.data.code === 2000) {
        return callbackSuccess(response);
      } else {
        if (callbackError !== undefined) {
          if (response.status === 200) {
            response.setMessage(response.data.message);
            response.setMsgHandler(ViewUtils.createMessageHandler(response.data.message, MessageResponse.ERROR));
          }
          return callbackError(response);
        }
      }
    });
  }

  postRidlrAPI(path, payload, callbackSuccess, callbackError) {
     fetch('https://ridlr.freshdesk.com/api/v2/tickets',
       {method: 'post',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : Base64.encode("KwIAcFW76FvpXz5INL:X"),
        }
    }).then(function(response) {
      if(response.status === 200 || response.status === 201) {
        callbackSuccess(response);
      } else {
        callbackError(response);
      }
        
     });
     
  }

  

  put(path, payload, callbackSuccess, callbackError) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => {
      if (response.status === 200 && response.data.code === 2000) {
        callbackSuccess(response)
      } else {
        if (callbackError !== undefined) {
          if (response.status === 200) {
            response.setMsgHandler(ViewUtils.createMessageHandler(response.data.message, MessageResponse.ERROR));
            response.setMessage(response.msgHandler.props.message);
          }
          callbackError(response);
        }
      }
    })
  }

  postText(path, payload, callbackSuccess, callbackError) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'text',
      data: payload
    }).then((response) => {
      if (response.status === 200 && response.data !== '') {
        callbackSuccess(response)
      } else {
        if (callbackError !== undefined) {
          if (response.status === 200) {
            response.setMessage(response.data.message);
            response.setMsgHandler(ViewUtils.createMessageHandler(response.data.message, MessageResponse.ERROR));
          }
          callbackError(response);
        }
      }
    })
  }
}

export default new API();
