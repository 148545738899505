import React from 'react';
import { userService } from '../_services';
import { ClevertapReact } from '../CleverTap/ClevertapReact';
import { EventProperties } from '../CleverTap/EventProperties'; 
import { AgencyAPI } from '../apis/AgencyAPI';
import { isMobile } from 'react-device-detect';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loginDone:false,
            loading: false,
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // Remove all saved data from localStorage
        localStorage.clear();
    }

    componentDidMount() {
        //document.body.classList.add('Login-component');
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { username, password } = this.state;

        // stop here if form is invalid
        if (!(username && password)) {
            return;
        }

        this.setState({ loading: true,error:'' });
        
        userService.login(username, password,
            (response) => {
                this.setState({ loading: false });
                if(response.data){
                    ClevertapReact.onUserLogin(EventProperties.profilePush());
                    this.setState({ loginDone: response.data });
                    this.getSelectedPictures();
                    AgencyAPI.getConfiguration((response) => { 
                        localStorage.setItem('parent', response.data.data.parent);
                        localStorage.setItem('parentAgency', response.data.data.parentAgency);
                        localStorage.setItem('seatNumber', response.data.data.seatNumber);
                        localStorage.setItem('totalDevices', response.data.data.totalDevices);
                        localStorage.setItem('agentCommission', response.data.data.agentCommission);
                        localStorage.setItem('routeSchedule', response.data.data.routeSchedule);
                        localStorage.setItem('upiPayment', response.data.data.upiPayment);
                        localStorage.setItem('businessType', response.data.data.businessType);
                     });
                    const { from } = this.props.location.state || { from: { pathname: "/home" } };
                    this.props.history.push(from);
                }
            },
            (error) => {
                this.setState({ error: error.message, loading: false });
            });
    }
    
    getSelectedPictures() {
        return this.state.loginDone;
    }
    
    
    render() {
       var stylescope = {
            splitterStyle: {
                margin: 5
            }
       };
        const { username, password, submitted, loading, error } = this.state;
        return (
           <div className="page-login page">
                <div className={"page-single"}>
                    <div className={"container"}> 
                            {isMobile && <img src="/images/ridlr_logo.svg" width="425" height="86" alt="Ridlr AFCS" className="logo-login logo-margin-bottom"></img>}
                            {!isMobile && <div className={"row logo-margin-bottom"}>
                            <div className={"col-md-2"}>
                                <img src="/images/ridlr.png" alt="Ridlr AFCS" className="logo-login"></img>
                            </div>
                         </div>}
                        <div className={isMobile?"login":"row login"}>
                        <div className= {isMobile?"col mx-auto":"col col-login mx-auto"}>
                            <form className={isMobile?"":"card"} onSubmit={this.handleSubmit}> 
                                <div className={isMobile?"":"card-body p-6"}> 
                                    <div className={"card-title signincolor "}></div>
                                    <div  className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                        <label className={"form-label login-label "}>User ID</label>
                                        <input type="text" className="form-control logintextfields login-text" name="username" value={username} onChange={this.handleChange} />
                                        {submitted && !username &&
                                            <div className="help-block danger" style={{color: "red"}}>Username is required</div>
                                        }
                                    </div>
                                    <div  className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                        <label className={"form-label login-label "}>Password</label>
                                        <input type="password" className="form-control logintextfields login-text" name="password" value={password} onChange={this.handleChange} />
                                        {submitted && !password &&
                                            <div className="help-block"  style={{color: "red"}}>Password is required</div>
                                        }
                                    </div>
                                    <div className={"form-footer"}>
                                        <div>   
                                            <button className="btn signinbutton"   hidden={loading} >Sign in</button>
                                            {loading &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="download" />
                                            }
                                        </div>
                                        <div className=" col-12 paddingTop16">
                                            <a href="/forgotpassword" className="logintextfields a-color "><p className="text-center logintextfields mt-2  a-color login-forgotpassword" >Forgot password ?</p></a>
                                        </div>
                                    </div>
                                    {error &&
                                        <div className={'alert alert-danger margin-3 form-footer termtext' } style={stylescope.splitterStyle}>{error}</div>
                                    }
                                    <div className={"form-footer termtext mt-1"}>
                                    {/*<p >By signing in you are accepting the  <a href="/terms" className="logintextfields a-color">Terms & Conditions</a>  of this site. </p>*/}
                                    </div>
                                
                                </div>
                            </form>
                        </div>
                    </div>
                        {/*{!isMobile && <div className={"d-flex loginfooter"}>*/}
                        {/*    <div className={"text-left ml-8 mt-2"}><a href="/about-us" className="policy-terms-color logintextfields mr-1"> About us</a></div>*/}
                        {/*    <div className={"d-flex order-lg-2 mt-2 mr-8 ml-auto m-2 "}><a href="/privacy-policy" className="policy-terms-color logintextfields mr-1"> Privacy Policy </a> | <a href="/terms" className="policy-terms-color logintextfields ml-1">Terms & Use</a></div>*/}
                        {/*</div>}*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage; 