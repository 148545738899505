// @flow
import React from "react";
import { Page} from "tabler-react";
import { Alert} from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import { AgencyAPI } from "../apis/AgencyAPI.js";

class AgencyConfig extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showmessage: false,
            responsemessage:'',
            isloading:false,
            fields: {
                header1:'',
                header1Size:'SMALL',
                header2:'',
                header2Size:'SMALL',
                footer1:'',
                footer1Size:'SMALL',
                footer2:'',
                footer2Size:'SMALL'
            },
            errors: {},
            filterText:'',
            agencyaddress:'',
            agencycomment:'',
            isChecked: false,
            authkey: localStorage.getItem('authkey')
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    componentDidMount(){
        let currentComponent = this;
        AgencyAPI.getConfiguration(
            (response) => {
                var header1     = (response.data.data.header1 === null) ? '' : response.data.data.header1;
                var header1Size = (response.data.data.header1Size === null) ? 'SMALL' : response.data.data.header1Size;
                var header2     = (response.data.data.header2 === null) ? '' : response.data.data.header2;
                var header2Size = (response.data.data.header2Size === null) ? 'SMALL' : response.data.data.header2Size;
                var footer1     = (response.data.data.footer1 === null) ? '' : response.data.data.footer1;
                var footer1Size = (response.data.data.footer1Size === null) ? 'SMALL' : response.data.data.footer1Size;
                var footer2     = (response.data.data.footer2 === null) ? '' : response.data.data.footer2;
                var footer2Size = (response.data.data.footer2Size === null) ? 'SMALL' : response.data.data.footer2Size;
                localStorage.setItem('agentCommission', response.data.data.agentCommission);
                localStorage.setItem('parentAgency', response.data.data.parentAgency);
                localStorage.setItem('seatNumber', response.data.data.seatNumber);
                localStorage.setItem('parent', response.data.data.parent);
                localStorage.setItem('routeSchedule', response.data.data.routeSchedule);
                localStorage.setItem('upiPayment', response.data.data.upiPayment);
                currentComponent.setState({
                    fields : {
                        header1     :header1.toUpperCase(),
                        header1Size :header1Size.toUpperCase(),
                        header2     :header2.toUpperCase(),
                        header2Size :header2Size.toUpperCase(),
                        footer1     :footer1.toUpperCase(),
                        footer1Size :footer1Size.toUpperCase(),
                        footer2     :footer2.toUpperCase(),
                        footer2Size :footer2Size.toUpperCase(),
                        agencyId    :response.data.data.id
                    },
                    isloading: false
                });
                currentComponent.props.callbackFromParent(response.data.data.id, response.data.data.expenses);
            }
        );
    }
    
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    
    handleProductTable(evt) {
        let fields = this.state.fields;
        fields[evt.target.name] = evt.target.value.toUpperCase();
        this.setState({ fields: fields })
    };
    
    submitOperator(){
        this.setState({isloading:true});
        let currentComponent = this;
        let obj = this.state.fields;
        let ticketView = {}
        if(obj.header1 !== ""){
            ticketView.header1 = obj.header1.toUpperCase();
        }
        if(obj.header1Size !== ""){
            ticketView.header1Size = obj.header1Size.toUpperCase();
        }
        if(obj.header2 !== ""){
            ticketView.header2 = obj.header2.toUpperCase();
        }
        if(obj.header2Size !== ""){
            ticketView.header2Size = obj.header2Size.toUpperCase();
        }
        if(obj.footer1 !== ""){
            ticketView.footer1 = obj.footer1.toUpperCase();
        }
        if(obj.footer1Size !== ""){
            ticketView.footer1Size = obj.footer1Size.toUpperCase();
        }
        if(obj.footer2 !== ""){
            ticketView.footer2 = obj.footer2.toUpperCase();
        }
        if(obj.footer2Size !== ""){
            ticketView.footer2Size = obj.footer2Size.toUpperCase();
        }

        let obj1 = {};
        obj1.ticketView = ticketView;
        AgencyAPI.putConfiguration(currentComponent.state.fields.agencyId, obj1, 
        (response) => {
            this.handleConfigSave(response);
        });
    }

    handleConfigSave(response) {
        let currentComponent = this;
        if (response.data.code === 2000 && response.data.message === "SUCCESS") {
            localStorage.setItem('agentCommission', response.data.data.agentCommission);
            currentComponent.setState({
                responsemessage:'Operator Config Saved successfully!',
                isloading: false,
                showmessage:true
            });
        } else{ 
            currentComponent.setState({
                isLoader: false,
                responsemessage:response.data.message,
                showmessage:true
            });
        }
    }
    
    render() {
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            )
        }
        return (
            <Page.Content>
                <Page.Header title="Operator Setup" />
                <React.Suspense fallback={<div>Loader...</div>}>
                    <h6>1. Ticket Print Details (These entries will be what prints on the ticket)</h6>
                        {fadeinout}
                    <div className="row">
                        <ProductTable onProductTableUpdate={this.handleProductTable.bind(this)} submitOperator={this.submitOperator.bind(this)} product={this.state.fields} filterText={this.state.filterText} errors={this.state.errors} isloading={this.state.isloading}/>
                    </div>
                </React.Suspense>
            </Page.Content>
        );
    }
};

class ProductTable extends React.Component {

    render() {
       
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
       
        return (
            <div className="col col-md-12 col-lg-12">
                <div className="col col-md-6 col-lg-6">
                    <div className="form-group">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Text</th>
                                    <th>Font Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="eachRow" >
                                    <td>Header 1</td>
                                    <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                                        type: "header1",
                                        value: this.props.product.header1,
                                        id: "header1",
                                        error: this.props.errors["header1"]
                                    }}/>
                                    <EditableDropdown onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                                        type: "header1Size",
                                        value: this.props.product.header1Size,
                                        id: "header1Size",
                                        error: this.props.errors["header1Size"]
                                    }}/>
                                </tr>
                                <tr>
                                    <td>Header 2</td>
                                    <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                                        type: "header2",
                                        value: this.props.product.header2,
                                        id: "header2",
                                        error: this.props.errors["header2"]
                                    }}/>
                                    <EditableDropdown onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                                        type: "header2Size",
                                        value: this.props.product.header2Size,
                                        id: "header2Size",
                                        error: this.props.errors["header2Size"]
                                    }}/>
                                </tr>
                                <tr>
                                    <td>Footer 1</td>
                                    <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                                        type: "footer1",
                                        value: this.props.product.footer1,
                                        id: "footer1",
                                        error: this.props.errors["footer1"]
                                    }}/>
                                    <EditableDropdown onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                                        type: "footer1Size",
                                        value: this.props.product.footer1Size,
                                        id: "footer1Size",
                                        error: this.props.errors["footer1Size"]
                                    }}/>
                                </tr>
                                <tr>
                                    <td>Footer 2</td>
                                    <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                                        type: "footer2",
                                        value: this.props.product.footer2,
                                        id: "footer2",
                                        error: this.props.errors["footer2"]
                                    }}/>
                                    <EditableDropdown onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                                        type: "footer2Size",
                                        value: this.props.product.footer2Size,
                                        id: "footer2Size",
                                        error: this.props.errors["footer2Size"]
                                    }}/>
                                </tr>
                                <tr>
                                    <td colSpan="3" align="right">
                                        <button type="button" onClick={() => this.props.submitOperator()} hidden={this.props.isloading} className="btn btn-primary">Save
                                        </button>
                                        {this.props.isloading &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        } 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col col-md-6 col-lg-6">
                    <div className="col col-md-3 col-lg-3"></div>
                    <div className="col col-md-5 col-lg-5" style={{'padding': '0', 'background': '#fbf9f9', 'border': '#ccc 1px solid'}}>
                        <div style={{'textAlign': 'center', 'textTransform': 'uppercase', 'fontWeight': 'bold', 'color': '#000', 'height': 'auto', 'minHeight': '230px'}}>
                            <span style={{'fontSize': this.props.product.header1Size}}>{this.props.product.header1}</span><br/>
                            <span style={{'fontSize': this.props.product.header2Size}}>{this.props.product.header2}</span><br/>
                            <br/><br/><br/><br/><br/><br/>
                            <span style={{'fontSize': this.props.product.footer1Size}}>{this.props.product.footer1}</span><br/>
                            <span style={{'fontSize': this.props.product.footer2Size}}>{this.props.product.footer2}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

class EditableCell extends React.Component {

    render() {
        return (
            <td>
                <input className="form-control" type='text' style={{width: '100%'}} name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value || ''} onChange={this.props.onProductTableUpdate} />
                <span className="error">{this.props.cellData.error}</span>
            </td>
        );
    }
};

class EditableDropdown extends React.Component {

    render() {
        return (
            <td>
                <select className="form-control" style={{width: '100%'}} name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value || ''} onChange={this.props.onProductTableUpdate}>
                    <option value="SMALL">SMALL</option>
                    <option value="MEDIUM">MEDIUM</option>
                    <option value="LARGE">LARGE</option>
                </select>
                <span className="error">{this.props.cellData.error}</span>
            </td>
        );
    }
};

export default AgencyConfig;
