import { ETIMAPI } from '../apis/ETIMAPI';

export const DeviceTranslator = {
    getDeviceDetails,
    getMasterData,
    convertCollections,
    convertDuty
};
 

function getDeviceDetails(command, callbackSuccess, callbackError) {
    ETIMAPI.postConvertDeviceHardwareDetails(command, callbackSuccess, callbackError);
}

function getMasterData(url, callbackSuccess, callbackError) {
    ETIMAPI.getEtimMaster(url, callbackSuccess, callbackError);
}

function convertCollections(waybillNumber, collectionsData, callbackSuccess, callbackError) {
    ETIMAPI.postConvertCollections(waybillNumber, collectionsData, callbackSuccess, callbackError);
}

function convertDuty(conductorId, deviceSerialId, dutyDate, callbackSuccess, callbackError) {
    ETIMAPI.getConvertDuty(conductorId, deviceSerialId, dutyDate, callbackSuccess, callbackError);
}