import React from 'react';
import { userService } from '../_services';

class ForgotPasswordPage extends React.Component {
    constructor(props) {
      super(props);

      this.state = {        
        email: "",        
        submitted: false,
        requestDone: false,
        loading: false,
        error: ''
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);      
    }

    validateEmailForm() {
        return this.state.email.length > 0;
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ error :'', submitted: true });
        const { email } = this.state;

        // stop here if form is invalid
        if (!this.validateEmailForm()) {
            return;
        }

        this.setState({ loading: true });
        
        //Forgot password function call.
        userService.forgotPassword(email,
            (response) => {
                this.setState({ loading: false });
                this.setState({ requestDone: true });                
            },
            (error) => {
                this.setState({ error:error.message, loading: false , requestDone: false });
            }
        );
    }

    
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
       };
        const { email, submitted, requestDone, loading, error } = this.state;

        return (
            <div className={"page"}>
                <div className={"page-single"}>
                    <div className={"container"}> 
                    <div className={"row logo-margin-bottom"}>
                        <div className={"col-md-2"}>
                            <img src="/images/ridlr.png" alt="Ridlr AFCS" className="logo-login"></img>
                        </div>
                    </div>
                    <div className={"row"}>
                    <div className={"col col-login mx-auto"}>
                        <form className={"card m-b-forgot"} onSubmit={this.handleSubmit}> 
                            <div className={"card-body p-6"}> 
                                    <div className={"card-title signincolor "}>Forgot Password </div>
                                    {!requestDone &&
                                <div  className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                
                                    <label className={"form-label"}>Email Id</label>
                                    <input type="email" className="form-control logintextfields" name="email" placeholder="Enter your registered email" value={email} onChange={this.handleChange} />
                                    {submitted && !email &&
                                        <div className="help-block danger" >Please enter registered email</div>
                                    }
                                
                                </div>
                                }
                                
                                {!requestDone &&                                
                                <div className={"form-footer"}>                                
                                    <button className="btn btn-block submit-btn-bg" hidden={loading} >Submit</button> 
                                    {loading &&
                                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="download" />
                                    }                                                                
                                </div>
                                } 

                                {error &&
                                    <div className={'alert alert-danger margin-3' } style={stylescope.splitterStyle}>{error}</div>
                                }
                                
                                {requestDone && 
                                <div  className={"form-footer" } >
                                    
                                    <label className={"form-label logintextfields"} >                                        
                                        Password change link has been sent to the email address you provided.
                                    </label>
                                    <br></br>                                    
                                    <label className={"form-label logintextfields"} > 
                                    <p className="text-center forgotnote">Note: Sometimes verification email may end up in your spam folder - please check</p>
                                    </label> 
                                    
                                </div>
                                }
                            </div>
                           
                            
                        </form>
                        <div className={"form-footer mt-1 text-center"}>
                            <p ><a href="/login" className="logintextfields a-color ">Go back to login</a> </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
        );

    }
}

export default ForgotPasswordPage; 