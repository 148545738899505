import API from './API';
import MessageResponse from '../enums/MessageResponse';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';

export const ETIMAPI = {
    getEtimMaster,
    postConvertDeviceHardwareDetails,
    postConvertCollections,
    getConvertDuty
}

function getEtimMaster(url, callbackSuccess, callbackError) {
    API.getText("/apis/etim/master/" + url,
        (response) => {
            if (response.data !== null) {
                callbackSuccess(response)
            } else {
                response.setMsgHandler(ViewUtils.createMessageHandler("No Response from Translator", MessageResponse.ERROR));
                response.setMessage(response.msgHandler.props.message);
                callbackError(response);
            }
        },
        (error) => {
            callbackError(error);
        }
    );
}

function postConvertDeviceHardwareDetails(command, callbackSuccess, callbackError) {
    let url = "/apis/etim/convert/device/hardware/details";
    API.post(url, command, 
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            error.setMsgHandler(ViewUtils.createMessageHandler("Translator API is Down, Please try after some time!", MessageResponse.ERROR));
            error.setMessage(error.msgHandler.props.message);
            callbackError(error);
        }
    );
}

function postConvertCollections(id, command, callbackSuccess, callbackError) {
    let url = "/apis/etim/convert/collections?agency="+localStorage.getItem('agencyname') + "&waybill="+ id;
    API.post(url, command, 
        (response) => {
            callbackSuccess(response)
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Translator API is Down, Please try after some time!", MessageResponse.ERROR));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    )
}

function getConvertDuty(conductorId, deviceSerialId, dutyDate, callbackSuccess, callbackError) {
    API.getText("/apis/etim/convert/duty?conductor_id="+conductorId+"&agency="+localStorage.getItem('agencyname')+"&device_serial_id="+deviceSerialId+"&dutyDate="+dutyDate,
    (response) => {
        var text = response.data;
        if(text !== ""){
            if(text === "Mismatched Device" || text === "No Duty Found"){
                response.setMsgHandler(ViewUtils.createMessageHandler(text, MessageResponse.ERROR));
                callbackError(response);
            } else {
                callbackSuccess(response);
            }
        } else {
            response.setMsgHandler(ViewUtils.createMessageHandler("No Response from Translator", MessageResponse.ERROR));
            callbackError(response);
        }
    },
    (error) => {
        error.setMsgHandler(ViewUtils.createMessageHandler("Translator API is Down, Please try after some time!", MessageResponse.ERROR));
        error.setMessage(error.msgHandler.props.message);
        callbackError(error);
    }
);
}
