import * as Sentry from '@sentry/browser';

export const AFCSLogger = {
    logAPIError,
    logErrorBoundaryError
}

function logAPIError(error) {
    Sentry.withScope(scope => {
        scope.setExtras({
            error,
            agencyCode: localStorage.getItem("agencyname")
        });
        Sentry.captureException(error);
    });
}

function logErrorBoundaryError(error, info) {
    Sentry.withScope(scope => {
        scope.setExtras({
            error,
            info,
            agencyCode: localStorage.getItem("agencyname")
        });
        Sentry.captureException(error);
    });
}