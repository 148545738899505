import jwt from 'jsonwebtoken';

const SessionUtils = {
    setRole: function(authorization){
        var n = authorization.indexOf(' ');
        var result = authorization.substring(n + 1);
        var decodedToken = jwt.decode(result);
        localStorage.setItem('role', decodedToken.authorities);
    },

    getRole: function() {
        return localStorage.getItem('role');
    },

    isChildAgency: function() {
        let tmpParentAgency = localStorage.getItem('parentAgency');
        if(tmpParentAgency !== 'null' && tmpParentAgency !== undefined && tmpParentAgency.length > 0) {
            return true;
        } else {
            return false;
        }
    },

    isSeatNumbersEnabled: function() {
        let seatNumber = localStorage.getItem('seatNumber');
        if(seatNumber !== 'null' && seatNumber === 'true') {
            return true;
        } else {
            return false;
        }
    },

    isUpiPaymentEnabled: function() {
        let upiPayment = localStorage.getItem('upiPayment');
        if(upiPayment !== 'null' && upiPayment === 'true') {
            return true;
        } else {
            return false;
        }
    },

    isRouteScheduleEnabled: function() {
        let routeSchedule = localStorage.getItem('routeSchedule');
        if(routeSchedule !== 'null' && routeSchedule === 'true') {
            return true;
        } else {
            return false;
        }
    },

    getPaymentRidlrTransactionId: function() {
        return localStorage.getItem('paymentId');
    },

    hasNewNotification: function() {
        let newNotification = localStorage.getItem('hasNewNotification');
        if(newNotification !== 'null' && newNotification === 'true') {
            return true;
        } else {
            return false;
        }
    },
}

export default SessionUtils;

