// @flow
import React from "react";
import { Page, Button } from "tabler-react";
import BaseLayout from "./BaseLayout";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';
import "./alert.js";
import "../App.css";
import { InventoryAPI } from "../apis/InventoryAPI";
import { DutyAPI } from "../apis/DutyAPI";
class RegisterAPCSDevice extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.type = this.props.location.pathname.split('/')[1]; //this is the name of the route
        this.state = {
            deviceSerialId: '',
            isAddDeviceLoader: false,
            vehicleList: [],
            errors: {},
            vehicleId: '',
            responseMessage: '',
            showMessage: false,
            msgHandler: null
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    componentDidMount() {
        this.loadVehicleList();
    }

    loadVehicleList() {
        let currentComponent = this;
        DutyAPI.getVehicle(
            (response) => {
                let deviceIdFromApi = response.data.data.map(device => { return { value: device.id, label: device.vehicleNumber }; });
                currentComponent.setState({ vehicleList: [{ value: '', label: 'Select vehicle Number' }].concat(deviceIdFromApi) });
            }
        );
    }

    
    dropDownSelection(item, type) {
        switch (type) {
            case 'vehicle': {
                this.setState({ vehicleId: item })
                break;
            }
            default:
                break;
        }
    }

    addDevice() {
        if (this.handleValidation()) {
            let currentComponent = this;
            currentComponent.setState({ isAddDeviceLoader: true });

            let obj = {};
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.vehicle = currentComponent.state.vehicleId.value;
            InventoryAPI.putAPCSDevice(currentComponent.state.deviceSerialId, obj,
                (response) => {
                    currentComponent.handleAddDeviceResponse(response);
                }
            );
        }
    }

    handleAddDeviceResponse(response) {
        let currentComponent = this;
        currentComponent.setState({ isAddDeviceLoader: false });
        if (response.data.code === 2000 && response.data.message === 'SUCCESS') {
            currentComponent.setState({
                responseMessage: 'APCS Device added successfully!',
                showMessage: true,
                deviceSerialId: '',
                vehicleId: '',
            });
        } else {
            currentComponent.setState({ error: response.data.message });
            return;
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.deviceSerialId) {
            formIsValid = false;
            errors['deviceSerialId'] = "Device Serial Id cannot be empty";
        }
        if (this.state.deviceSerialId && (this.state.deviceSerialId.length < 12 || this.state.deviceSerialId.length > 12)) {
            formIsValid = false;
            errors['deviceSerialId'] = "Please enter a value having 12 characters";
        }
        if (!this.state.vehicleId) {
            formIsValid = false;
            errors['vehicle'] = "Please select vehicle number";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleDismiss() {
        this.setState({ showMessage: false, vehicleId: '' });
    }

    valid(item, type) {
        switch (type) {
            case 'deviceSerialId': {
                const val = item.target.value;
                const re = /^[0-9A-Za-z\b]+$/;
                var newVal = "";
                if (val !== "" && !re.test(val)) {
                    // Filter non-digits from input value.
                    return false;
                } else {
                    newVal = val;
                }
                this.setState({ deviceSerialId: newVal });
                break;
            }
            default:
                break;
        }
    }

    goBack(e) {
        this.props.history.push('/apcs-device-list');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    render() {

        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showMessage) {
            fadeinout = (
                <Alert bsStyle="success">
                    <h4>{this.state.responseMessage}</h4>
                    <Button onClick={(event) => this.goBack(event)}  className="btn-primary">View</Button>
                    <span> or </span>
                    <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                </Alert>
            )
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                                {fadeinout}
                                {this.state.msgHandler}
                                <h3 className="card-title">Add APCS Device</h3>
                                <React.Suspense fallback={<div>Loader...</div>}>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4">
                                            {this.state.error &&
                                                <div className={'alert alert-danger margin-3'} style={stylescope.splitterStyle}>{this.state.error}</div>
                                            }</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label">Device Serial Id
                                                    <span className="form-required">*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" onChange={(item) => this.valid(item, 'deviceSerialId')} value={this.state.deviceSerialId} placeholder="Device Serial Id "></input></div>
                                                <span style={{ color: "red" }}>{this.state.errors["deviceSerialId"]}</span>
                                             </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Vehicle <span className="form-required">*</span></label>
                                                <Select options={this.state.vehicleList} value={this.state.vehicleId} isClearable={true} onChange={(item) => this.dropDownSelection(item, 'vehicle')} />
                                                <span style={{ color: "red" }}>{this.state.errors["vehicle"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="row">
                                        <div className="col-sm-6 col-md-1">
                                            <div className="form-group">
                                                <label className="form-label"> &nbsp;</label>
                                                <button type="button" onClick={() => this.addDevice()} className="btn btn-primary">Add
                                    {this.state.isAddDeviceLoader &&
                                                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </React.Suspense>
                            </div>
                        </form>
                    </React.Fragment>

                </Page.Content>
            </BaseLayout>
        );
    }
}

export default RegisterAPCSDevice;