// @flow
import React from "react";
import { Page, Button } from "tabler-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import Dutys from "./Dutys";
import Select from 'react-select';
import Switch from 'react-switch';
import { DutyAPI } from "../apis/DutyAPI";
import { InventoryAPI } from "../apis/InventoryAPI";
import MessageResponse from '../enums/MessageResponse';
import { ViewUtils } from '../utils/ViewUtils';
import {isMobile} from 'react-device-detect';
import { AgencyAPI } from "../apis/AgencyAPI.js";
import SessionUtils from "../utils/SessionUtils";

class AssignDuty extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            ConductorList: [],
            DriverList: [],
            DeviceIDs: [],
            route:'',
            routeList: [],
            deviceid: '',
            selectedMemos: "",
            selectedConductor: "",
            validationError: "",
            validationErrorConductor: "",
            memoid: '',
            name: '',
            conductorid: '',
            driverid: '',
            agencyName: '',
            redirect: false,
            machinemsg: '',
            fields: {},
            errors: {},
            authkey: localStorage.getItem('authkey'),
            msgHandler: null,
            isManualFareChecked: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleShowMessageHandler = this.handleShowMessageHandler.bind(this);
        this.manualFareChanged = this.manualFareChanged.bind(this);
        this.closeDuty = this.closeDuty.bind(this);
        this.loadDutys = this.loadDutys.bind(this);
    }

    dropdownvalid(item, type) {
        switch (type) {
            case 'conductorid': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ conductorid: item, fields });
            }
            case 'driverid': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ driverid: item, fields });
            }
            case 'deviceid': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ deviceid: item, fields });
            }
            case 'vehicle': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ vehicle: item, fields });
            }
            case 'route': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ route: item, fields });
            }
            default:
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["conductorid"] || fields["conductorid"].value === '') {
            formIsValid = false;
            errors["conductorid"] = "Cannot be empty";
        }
        if (!fields["driverid"] || fields["driverid"].value === '') {
            formIsValid = false;
            errors["driverid"] = "Cannot be empty";
        }
        if (!fields["deviceid"] || fields["deviceid"].value === '') {
            formIsValid = false;
            errors["deviceid"] = "Cannot be empty";
        }
        if (!fields["vehicle"] || fields["vehicle"].value === '') {
            formIsValid = false;
            errors["vehicle"] = "Cannot be empty";
        }
        if (SessionUtils.isRouteScheduleEnabled() && (!fields["route"] || fields["route"].value === '')) {
            formIsValid = false;
            errors["route"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let assignDuty = this;
        //Employee Unassigned
        DutyAPI.getUnassignedConductors(
            (response) => {
                let empFromApi = response.data.data.map(emp => {
                    return { value: emp.employeeId, label: emp.name + "/" + emp.employeeCode, };
                });
                assignDuty.setState({ ConductorList: [{ value: '', label: 'Select Conductor' }].concat(empFromApi) });
            }
        );
        //Driver Unassigned
        DutyAPI.getUnassignedDrivers(
            (response) => {
                let empFromApi = response.data.data.map(emp => {
                    return { value: emp.employeeId, label: emp.name + "/" + emp.employeeCode };
                });
                assignDuty.setState({ DriverList: [{ value: '', label: 'Select Driver' }].concat(empFromApi) });
            }
        );

        //Device 
        InventoryAPI.getDeviceSerialIdAscList(
            (response) => {
                let deviceIdFromApi = response.data.data.content.map(device => {
                    return { value: device.deviceSerialId, label: device.deviceSerialId };
                });
                assignDuty.setState({ DeviceIDs: [{ value: '', label: 'Select Device Id' }].concat(deviceIdFromApi) });
            }
        );

        //Vehicle
        DutyAPI.getVehicle(
            (response) => {
                let deviceIdFromApi = response.data.data.map(device => { return { value: device.id, label: device.vehicleNumber }; });
                assignDuty.setState({ vehicleNumbers: [{ value: '', label: 'Select vehicle Number' }].concat(deviceIdFromApi) });
            }
        );

        AgencyAPI.getActiveMemos(
            (response) => {
                let scheduleFromApi = response.data.data.map(schedule => { return { value: schedule.id, label: schedule.agencyMemoId }; });
                assignDuty.setState({ routeList: [{ value: '', label: 'Select Route Schedule' }].concat(scheduleFromApi) });
            }
        );
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    loadDutys() {
        let currentComponent = this;
        currentComponent.dutys.componentDidMount();
    }

    GetDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    handleShowMessageHandler(msgHandler) {
        this.setState({ msgHandler: msgHandler });
    }

    manualFareChanged(checked) {
        this.setState({ isManualFareChecked: checked });
    }

    viewDuty(e) {
        this.props.history.push('/view-duty');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    closeDuty() {
        this.setState({ msgHandler: null,
            conductorid: '',
            driverid: '',
            deviceid: '',
            vehicle: ''
             });
        this.loadData();
    }

    submit() {
        if (this.handleValidation()) {
            let obj = {};
            let satus = this;
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.memoId = "";
            obj.conductorId = this.state.conductorid.value;
            obj.driverId = this.state.driverid.value;
            obj.deviceSerialId = this.state.deviceid.value;
            obj.vehicleId = this.state.vehicle.value;
            obj.dutyDate = this.GetDateFormat(this.state.startDate);
            obj.manualFare = this.state.isManualFareChecked ? 1 : 0;
            if(SessionUtils.isRouteScheduleEnabled() === true) {
                obj.memoId = this.state.route.value;
            }
            satus.setState({ isloading: true });
            //Create Duty 
            DutyAPI.postDuty(obj,
                (response) => {
                    if(!isMobile) {
                        satus.dutys.componentDidMount();
                    }
                    let viewDutyCloseView = (
                        <p>
                            <Button onClick={(event)=>this.viewDuty(event)} className="btn-primary">View</Button>
                                <span> or </span>
                            <Button className="btn-info" onClick={this.closeDuty}>Close</Button>
                        </p>
                    );
                    satus.setState({
                        isloading: false,
                        msgHandler: isMobile ? ViewUtils.createMsgHandlerCustomView('Duty created successfully WayBill- ' + response.data.data.waybillNumber, MessageResponse.SUCCESS, viewDutyCloseView) :
                            ViewUtils.createMessageHandler('Duty created successfully WayBill- ' + response.data.data.waybillNumber, MessageResponse.SUCCESS)
                    });
                    return;
                },
                (error) => {
                    satus.setState({
                        isloading: false,
                        msgHandler: error.msgHandler
                    });
                    return;
                }
            );
        }
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Assign Duty" />
                    {this.state.msgHandler}
                    <React.Fragment>
                        <div className="row row-cards">
                            <div className="col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="form-label">Badge Number</label>
                                    <Select value={this.state.conductorid} menuPlacement={isMobile?"top":"bottom"} options={this.state.ConductorList} onChange={(item) => this.dropdownvalid(item, 'conductorid')} />
                                    <span style={{ color: "red" }}>{this.state.errors["conductorid"]}</span>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="form-label">Driver</label>
                                    <Select value={this.state.driverid} menuPlacement={isMobile?"top":"bottom"} options={this.state.DriverList} onChange={(item) => this.dropdownvalid(item, 'driverid')} />
                                    <span style={{ color: "red" }}>{this.state.errors["driverid"]}</span>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="form-label">Device Number</label>
                                    <Select value={this.state.deviceid} menuPlacement={isMobile?"top":"bottom"}  options={this.state.DeviceIDs} onChange={(item) => this.dropdownvalid(item, 'deviceid')} />
                                    <span style={{ color: "red" }}>{this.state.errors["deviceid"]}</span>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="form-label">Vehicle Number</label>
                                    <Select value={this.state.vehicle}  menuPlacement={isMobile?"top":"bottom"} options={this.state.vehicleNumbers} onChange={(item) => this.dropdownvalid(item, 'vehicle')} />
                                    <span style={{ color: "red" }}>{this.state.errors["vehicle"]}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row row-cards">
                            {SessionUtils.isRouteScheduleEnabled() && <div className="col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label className="form-label">Schedule</label>
                                    <Select value={this.state.route}  menuPlacement={isMobile?"top":"bottom"} options={this.state.routeList} onChange={(item) => this.dropdownvalid(item, 'route')} />
                                    <span style={{ color: "red" }}>{this.state.errors["route"]}</span>
                                </div>
                            </div>}
                            <div className="col-md-4 col-lg-2">
                                <div className="form-group">
                                    <label className="form-label">Duty Date</label>
                                    <DatePicker name="dutyDate"
                                        minDate={new Date()}
                                        dateFormat="yyyy/MM/dd"
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        isClearable={true}
                                        placeholderText="I have been cleared!"
                                        className="form-control mb-2"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-2">
                                <div className="form-group">
                                    <label className="form-label">Allow Manual Fare</label>
                                    <Switch onChange={this.manualFareChanged} checked={this.state.isManualFareChecked}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="material-switch" />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-2">
                                <div className="form-group">
                                    <label className="form-label">&nbsp;&nbsp;</label>
                                    {!this.state.isloading && <Button type="button" onClick={() => this.submit()} className="btn btn-info">
                                        Create
                                    </Button>}
                                    {this.state.isloading &&
                                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row"> </div>
                        </div>
                        {!isMobile && 
                            <div className="col-12">
                                <Dutys ref={dutys => this.dutys = dutys} handleShow={this.handleShow} handleShowMessageHandler={this.handleShowMessageHandler} repeatWaybill={this.repeatWaybill} loadDutys={this.loadDutys} />
                            </div>}
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default AssignDuty;