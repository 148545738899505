import React from "react";
import ReactDOM from 'react-dom';
import { Button,Page } from "tabler-react";
import { Alert } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import AgencyConfig from "./AgencyConfig";
import DepotList from "./DepotList";
import Routetypelist from "./Routetypelist";
import Concession from "./Concession";
import Expenses from "./Expenses";
import Causes from "./Causes";
import AddFare from "./AddFare";
import "./alert.js";
import "../App.css";

class Operatorsetup extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showmessage: false,
            responsemessage:'',
            isloading:false,
            fields: {},
            errors: {},
            filterText:'',
            expenses: [],
            configId: '',
            authkey: localStorage.getItem('authkey'),
            businessType: localStorage.getItem('businessType')
        };
        this.onExpensesLoaded = this.onExpensesLoaded.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    handleDismiss() {
        this.setState({ showmessage: false });
    }

    onExpensesLoaded = (conId, expenseList) => {
        this.setState({expenses: expenseList,
        configId: conId});
    }

    componentDidUpdate() {
        let hash = this.props.location.hash.replace('#', '');
        if (hash) {
            let node = ReactDOM.findDOMNode(this.refs[hash]);
            if (node) {
                node.scrollIntoView();
            }
        }
    }
    
    render() {
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                   <p>
                        <h4>{this.state.responsemessage}</h4>
                        <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                    </p>
                </Alert>
            )
        }
        return (
            <BaseLayout>
                <Page.Content>
                    {fadeinout}
                    <React.Fragment>
                        <div className="card">
                            <div className="card-footer">
                                {this.state.businessType !== 'APCS' && <AgencyConfig callbackFromParent={this.onExpensesLoaded}/>}
                                <DepotList/> 
                                <Routetypelist ref="servicetype"/>
                                {this.state.businessType !== 'APCS' && <Concession ref="concession"/>}
                                {this.state.businessType !== 'APCS' && <Expenses ref="expenses" expenses={this.state.expenses} configId={this.state.configId} />}
                                {this.state.businessType !== 'APCS' && <Causes ref="causes"/>}
                                {this.state.businessType !== 'APCS' && <AddFare/>}
                            </div>
                        </div>
                    </React.Fragment>  
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default Operatorsetup;