// @flow
import React from "react";
import { Page, Button } from "tabler-react";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI.js";
import { ViewUtils } from "../utils/ViewUtils.js";
import MessageResponse from "../enums/MessageResponse.js";
import SessionUtils from "../utils/SessionUtils.js";

const vehicleTypes = [
    { value: 'SD', label: 'Standard' },
    { value: 'DD', label: 'Double Decker' },
    { value: 'MD', label: 'Mini Bus', },
    { value: 'AC', label: 'AC Bus' }
];
class EditVehicle extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            vehicleNumber: this.props.location.state.vehicle.vehicleNumber,
            depot: this.props.location.state.vehicle.depot,
            vehicleType: this.props.location.state.vehicle.vehicleType,
            seatingCapacity:this.props.location.state.vehicle.seatingCapacity,
            depotList: [],
            isloading: false,
            fields: {},
            errors: {},
            msgHandler: null,
        };
    }

    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {
            case 'vehicleNumber': {
                let fields = this.state.fields;
                fields[type] = itemValue;
                const re = /^[0-9a-zA-Z\b]+$/;
                if (itemValue !== "" && !re.test(itemValue)) {
                    return false;
                } else {
                    return this.setState({ vehicleNumber: itemValue, fields })
                }
            }
            case 'seatingCapacity': {
                let { value, min, max } = item.target;
                value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                return this.setState({ seatingCapacity: value })
           }
            default:
                break;
        }
    }
    
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.vehicleNumber) {
            formIsValid = false;
            errors["vehicleNumber"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    editVehicle() {
        if (this.handleValidation()) {
            this.setState({ isLoader: true });
            let obj = {}
            obj.vehicleNumber = this.state.vehicleNumber;
            obj.seatingCapacity = this.state.seatingCapacity;
            let currentComponent = this;
            currentComponent.setState({ isLoader: true });
            AgencyAPI.putVehicle(this.props.location.state.vehicle.id, obj,
                (response) => {
                    currentComponent.setState({
                        vehicleNumber: '',
                        isLoader: false,
                        msgHandler: ViewUtils.createMessageHandler('Vehicle edited successfully!', MessageResponse.SUCCESS)
                    });
                },
                (error) => {
                    currentComponent.setState({ isLoader: false, msgHandler: error.msgHandler });
                }
            );
        } return;
    }

    componentDidMount() {
        let currentComponent = this;
        currentComponent.loadDepotFromDb();
        
    }
    
    loadDepotFromDb(){
        let currentComponent = this;
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function(event) {
              var db          = event.target.result;
              var depots      = db.transaction('depot', 'readwrite').objectStore("depot");
              var depotdata  = depots.getAll();
              depots.getAll().onsuccess = function(event) {
                    let DepotListFromInDB = depotdata.result.map(depots => { return { value: depots.id, label: depots.depotname } })
                    currentComponent.setState({ depotList: DepotListFromInDB });
                };
            }
    }

    goBack(e) {
        this.props.history.push('/vehicle-list');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        return (
            <BaseLayout>
                <Page.Content>
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                                {this.state.msgHandler}
                                <h3 className="card-title">Edit Vehicle </h3>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Vehicle Number<span className="form-required">*</span></label>
                                            <input type="text" className="form-control" value={this.state.vehicleNumber} maxLength="10" onChange={(item) => this.valid(item, 'vehicleNumber')} placeholder="Vehicle Number"></input>
                                            <span style={{ color: "red" }}>{this.state.errors["vehicleNumber"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Depot<span className="form-required">*</span></label>
                                            <Select isDisabled options={this.state.depotList} value={this.state.depotList.filter(option => option.value === this.state.depot)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Vehicle Types<span className="form-required">*</span></label>
                                            <Select isDisabled options={vehicleTypes} value={vehicleTypes.filter(option => option.value === this.state.vehicleType)} />
                                        </div>
                                    </div>
                                    {SessionUtils.isSeatNumbersEnabled() === true &&
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Seating Capacity<span className="form-required">*</span></label>
                                            <input type="number" className="form-control" min="1" max="255" value={this.state.seatingCapacity} maxLength="3" onChange={(item) => this.valid(item, 'seatingCapacity')} placeholder="Seating Capacity"></input>
                                            <span style={{color: "red"}}>{this.state.errors["seatingCapacity"]}</span>
                                        </div>
                                    </div>}
                                    
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label"> &nbsp; </label>
                                            <button type="button" onClick={() => this.editVehicle()} className="btn btn-primary">Save
                                        {this.state.isloading &&
                                                    <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                }
                                            </button>
                                            <a href="/vehicle-list"> <Button type="button" color="primary mb-2 mr-2 float-right">View </Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default EditVehicle;