// @flow
import * as React from "react";
import { Page, Grid, Button } from "tabler-react";
import { Row, Col, Alert } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DateUtils from "../utils/DateUtils";
import { InventoryAPI } from "../apis/InventoryAPI";
import { DutyAPI } from "../apis/DutyAPI";
import "../App.css";
const { ExportCSVButton } = CSVExport;
const options = {
  sizePerPage: 10,
  hideSizePerPage: false,
  hidePageListOnlyOnePage: true
};

const selectOptions = {
  'UNASSIGNED': 'UNASSIGNED',
  'ASSIGNED': 'ASSIGNED',
};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filterElement}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
        {column.text}
        {sortElement}
      </div>
    </div>
  );
}

class APCSDeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.type = this.props.location.pathname.split('/')[1]; //this is the name of the route
    this.state = {
      vehicleList: [],
      isLoader: false,
      data: [],
    };
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    this.loadVehicleList();
  }

  handleDismiss() {
    this.setState({ showMessage: false });
  }


  loadDeviceList() {
    let currentComponent = this;
    currentComponent.setState({ isLoader: true });
    InventoryAPI.getAPCSDeviceList(
      (response) => {
        let deviceListFromAPI = [];
        response.data.data.content.map((device) => {
          this.state.vehicleList.find(function (vehicle) {
            console.log("vehicle: " + JSON.stringify(vehicle));
            if (device.vehicle === vehicle.value) {
              device.vehicleNumber = vehicle.label;
            }
            return "";
          });
          deviceListFromAPI.push(device);
          return "";
        });
        currentComponent.setState({
          data: deviceListFromAPI,
          isLoader: false,
        });
      },
      (error) => {
        currentComponent.setState({ isLoader: false });
      }
    );
  }

  loadVehicleList() {
    let currentComponent = this;
    DutyAPI.getVehicle(
      (response) => {
          let vehicleListFromApi = response.data.data.map(device => { return { value: device.id, label: device.vehicleNumber }; });
          currentComponent.setState({ vehicleList: [{ value: '', label: 'Select vehicle Number' }].concat(vehicleListFromApi) });
          currentComponent.loadDeviceList();
      }
    );
  }

  render() {
    let fadeinout;
    if (this.state.showMessage) {
      fadeinout = (
        <Alert bsStyle="success" >
          <h4>{this.state.responseMessage}</h4>
          <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
        </Alert>
      )
    }

    const { data } = this.state;
    const { agencyColumns = [{
      dataField: 'deviceSerialId',
      text: 'DEVICE SERIAL ID',
      filter: textFilter(),
      headerFormatter: headerFormatter
    }, {
      dataField: 'vehicleNumber',
      text: 'VEHICLE NUMBER',
      filter: textFilter(),
      headerFormatter: headerFormatter
    }, {
      dataField: 'currentState',
      text: 'DEVICE STATE',
      headerStyle: { width: '120px' },
      formatter: cell => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions
      }),
      headerFormatter: headerFormatter
    }] } = this.state;

    let addDeviceButton = (<Grid>
      <Row className="show-grid">
        <Col xsHidden md={12}>
            <a href="/apcs-register-device"><Button type="button" color="primary mb-2 mr-2 float-right"> Add Device </Button></a>
        </Col>
      </Row>
    </Grid>
    );

    return (
      <BaseLayout>
        <Page.Content>
          <Page.Header title="APCS Device List" />
          {fadeinout}
          {addDeviceButton}
          <div className="card paddingTop15">
            <div className="card-body" >
              <div>
                <ToolkitProvider
                  keyField="deviceSerialId"
                  data={data}
                  columns={agencyColumns}
                  exportCSV={{ fileName: "APCS-DEVICE-LIST".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
                >
                  {
                    props => (
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="deviceSerialId"
                          data={data}
                          bordered={false}
                          columns={agencyColumns}
                          filter={filterFactory()}
                          pagination={paginationFactory(options)}
                        />
                        <hr />
                        <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                      </div>
                    )
                  }
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </Page.Content>
      </BaseLayout>
    );
  }
}
export default APCSDeviceList;