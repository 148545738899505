// @flow
import * as React from "react";
import { Page } from "tabler-react";
import { Button, Alert } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import { Link } from 'react-router-dom'
import "./alert.js";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DateUtils from "../utils/DateUtils";
import { RFIDCardCommunicator } from "../_components";
import { ViewUtils } from "../utils/ViewUtils";
import MessageResponse from "../enums/MessageResponse";
import { EmployeesAPI } from "../apis/EmployeesAPI";
import { PassAPI } from "../apis/PassAPI";

const { ExportCSVButton } = CSVExport;

const options = {
    sizePerPage: 20,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true
};
const statusFilterOptions = {
    'ACTIVE': 'ACTIVE',
    'INACTIVE': 'INACTIVE'
};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {filterElement}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold',color:'#495057', fontSize:'11px' }}>
                {column.text}
                {sortElement}
            </div>
        </div>
    );
}

function actionFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div style={{ fontWeight: 'bold',color:'#495057', fontSize:'11px' }}>
                {column.text}
            </div>
        </div>
    );
}


class EmployeesList extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            conductorList: [],
            isLoader: false,
            depotList: [],
            depotFilterList: [],
            showmessage: false,
            isloadingwrite:false,
            msgHandler: null,
            selectOptions:[],
            isAgentCommission:localStorage.getItem('agentCommission'),
            authkey: localStorage.getItem('authkey')
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    handleDismiss() {
        this.setState({ showmessage: false });
    }

    loadDepotFromDb() {
        let currentComponent = this;
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function (event) {
            var db = event.target.result;
            var depots = db.transaction('depot', 'readwrite').objectStore("depot");
            var depotdata = depots.getAll();
            depots.getAll().onsuccess = function (event) {
                let DepotListFromInDB = depotdata.result.map(depots => { return { value: depots.id, label: depots.depotname }; });
                currentComponent.setState({ depotList: DepotListFromInDB });
                let depotFilterList1 = depotdata.result.map(depots => { return { value: depots.depotname, label: depots.depotname }; });
                currentComponent.setState({ depotFilterList: depotFilterList1 });
            };
        };
    }

    loadCommentsFromServer() {
        let currentComponent = this;
        let conductordata = '';
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        currentComponent.setState({ isLoader: true });
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function (event) {
            var db = event.target.result;
            var conductor = db.transaction('conductor', 'readwrite').objectStore("conductor");
            conductordata = conductor.getAll();
            let ConductorListFromApi = [];
            let condata;
            conductor.getAll().onsuccess = function (event) {
                conductordata.result.forEach(conductor => {
                    if (conductor.designation === 'CONDUCTOR' || conductor.designation === 'DRIVER' || conductor.designation === 'INSPECTOR' || conductor.designation === 'AGENT') {
                        let depotName = '';
                        currentComponent.state.depotList.find(function (element) {
                            if (element.value === conductor.depot[0]) {
                                depotName = element.label;
                            }
                            return depotName;
                        });
                        condata = {
                            value: conductor.employeeId,
                            name: conductor.name,
                            designation: conductor.designation,
                            employeeCode: conductor.employeeCode,
                            status: conductor.status,
                            depot: conductor.depot,
                            depotName: depotName,
                            label: conductor.name
                        };
                        ConductorListFromApi.push(condata);
                    }
                });
                currentComponent.setState({ conductorList: ConductorListFromApi, isLoader: false });
            };
            currentComponent.setState({ data: ConductorListFromApi });
        };
    }

    dropdownvalid(item, dispaly, type) {
        switch (type) {
            case 'conductorid': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ name: item, fields, selecteddisplay: dispaly });
            }
            default:
        }
    }
    
    componentDidMount() {
        this.loadDepotFromDb();
        this.loadCommentsFromServer();
        var selectOptions={};
        if(this.state.isAgentCommission === "true"){
            selectOptions = {
                'CONDUCTOR': 'CONDUCTOR',
                'DRIVER': 'DRIVER',
                'INSPECTOR': 'INSPECTOR',
                'AGENT':'AGENT'
            };
        }else{
            selectOptions = {
                'CONDUCTOR': 'CONDUCTOR',
                'DRIVER': 'DRIVER',
                'INSPECTOR': 'INSPECTOR'
            };
        }
       this.setState({selectOptions:selectOptions});
       
    }
    
    readCardData(){
        let currentComponent = this;
        currentComponent.setState({ isloadingreadcard: true });
        PassAPI.getReadCardCommands(
            (response) => {
                var cardwriteCommand = response.data.data;
                let string = cardwriteCommand.toString();
                let outputcommand = string.replace(/,/g , '|');
                RFIDCardCommunicator.postRFIDData(outputcommand,
                    (response) => {
                        var blocks = response.data.split('|').filter(function(v){return v !== '' && v !== '#xFF\n';});
                        return currentComponent.interpretCardDetails(blocks);
                    },
                    (error) => {
                        currentComponent.setState({ msgHandler: error.msgHandler });
                    }
                );
            },
            (error) => {
                currentComponent.setState({
                    isloadingreadcard : false,
                    msgHandler: error.msgHandler
                });
                return;           
            }
        );
    }
    
    interpretCardDetails(cardData){
        let currentComponent = this;
        currentComponent.setState({
            isloadingreadcard   : true,
            showmessage         : true,
            responsemessage     : "Fetching Card Details"
        });
        let obj = {};
        obj.agencyCode  = localStorage.getItem('agencyname');
        obj.cardType    = "INSPECTOR";
        obj.cardData    = cardData;

        PassAPI.postCardInterpret(obj,
            (response) => {
                currentComponent.setState({
                    isloadingreadcard   : false,
                    msgHandler: ViewUtils.createMessageHandler("Read Card Data Successfully!", MessageResponse.SUCCESS)
                });
            },
            (error) => {
                currentComponent.setState({
                    isloadingreadcard   : false,
                });

                if(error.status === 200) {
                    currentComponent.setState({
                        msgHandler: ViewUtils.createMessageHandler(error.data.message, MessageResponse.ERROR)
                    });
                } else {
                    currentComponent.setState({
                        msgHandler: ViewUtils.createMessageHandler("Interpret API is down. Please try after some time", MessageResponse.ERROR)
                    });
                }
            }
        );
    }

    writeToCard(obj) {
        let currentComponent = this;
        currentComponent.setState({ isloadingwrite: true });
        let employeeCode = obj.employeeCode;
        EmployeesAPI.getEmployeeInspectorCard(employeeCode,
            (response) => {
                var cardwriteCommand = response.data.data.commands;
                let string = cardwriteCommand.toString();
                let outputcommand = string.replace(/,/g , '|');
                RFIDCardCommunicator.postRFIDData(outputcommand,
                    (response) => {
                        currentComponent.setState({
                            isloadingwrite  : false,
                            msgHandler: ViewUtils.createMessageHandler("Card write successfully.", MessageResponse.SUCCESS)
                        });
                    },
                    (error) => {
                        currentComponent.setState({
                            isloadingwrite : false,
                            msgHandler: error.msgHandler
                        });
                    }
                );
            },
            (error) => {
                currentComponent.setState({
                    isloadingwrite : false,
                    msgHandler: error.msgHandler
                });
            }
        );
    }

    render() {
        
        const { columns = [{
            dataField: 'employeeCode',
            text: 'BADGE NUMBER',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'name',
            text: 'NAME',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'designation',
            text: 'DESIGNATION',
            formatter: cell => this.state.selectOptions[cell],
            filter: selectFilter({
                options: this.state.selectOptions
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'depotName',
            text: 'DEPOT',
            filter: selectFilter({
                options: this.state.depotFilterList
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'status',
            text: 'STATUS',
            filter: selectFilter({
                options: statusFilterOptions
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'action',
            text: 'ACTION',
            csvExport: false,
            headerFormatter: actionFormatter,
            formatExtraData: this.state.isloadingwrite,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row.designation === 'INSPECTOR') {
                    return (
                        <div>
                            <Link to={`/edit-employee/${row.value}`} className="active"><i className="fe fe-edit"></i></Link>
                            <Button type="button" onClick={() => this.writeToCard(row)} hidden={formatExtraData} className="btn-primary paddingLeft15">
                                Write To Card
                            </Button>
                            {formatExtraData &&
                                <img src="./images/download.gif" alt="Loading..." />
                            }
                        </div>
                    );
                }
                return (
                    <Link to={`/edit-employee/${row.value}`} className="active"><i className="fe fe-edit"></i></Link>
                );
            }
        }]} = this.state;
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="info" className="col-lg-12 m-2" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            );
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Employee List" />
                    {fadeinout}
                    {this.state.msgHandler}
                    <div className="floatright marginBottom15">
                        <Button type="button"  onClick={() => this.readCardData()} hidden={this.state.isloadingreadcard} className="btn btn-primary float-left"> 
                            Read Card Data
                        </Button>
                        {this.state.isloadingreadcard &&
                            <img src="./images/download.gif" alt="Loading..." />
                        }
                        <a href="/create-employee"> <Button type="button" className="btn btn-primary float-right paddingLeft15"> Add New Employee </Button></a>
                    </div>
                    <div className="card">
                        <div className="card-body" >
                            <ToolkitProvider
                                keyField="employeeCode"
                                data={this.state.data}
                                columns={columns}
                                exportCSV={{ fileName: "EMPLOYEE-LIST".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
                            >
                            {
                                props => (
                                    <div>
                                        <BootstrapTable
                                            {...props.baseProps} bootstrap4
                                            keyField="employeeCode"
                                            data={this.state.data}
                                            columns={columns}
                                            bordered={false}
                                            pagination={paginationFactory(options)}
                                            filter={filterFactory()}
                                            noDataIndication="No Data Found" >
                                        </BootstrapTable>
                                        <hr />
                                        <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                                    </div>
                                )
                            }
                            </ToolkitProvider>
                        </div>
                    </div>
                </Page.Content>
            </BaseLayout>
        );
    }


}

export default EmployeesList;