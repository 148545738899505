// @flow
import React from "react";
import { Page } from "tabler-react";
import BaseLayout from "./BaseLayout";
import { Alert,Checkbox } from 'react-bootstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "./alert.js";
import "../App.css";
import { InventoryAPI } from "../apis/InventoryAPI";

class EditDevice extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.type = this.props.location.pathname.split('/')[1]; //this is the name of the route
        this.state = {
            isEditDeviceLoader: false,
            isApnMsgLoader:false,
            authkey: localStorage.getItem('authkey'),
            errors: {},
            responseMessage: '',
            showMessage: false,
            phoneNumber: this.props.location.state.device.phoneNumber.substring(3),
            countryCode: this.props.location.state.device.phoneNumber.substring(0,3),
            imeiNumber: this.props.location.state.device.imei,
            simId:this.props.location.state.device.simSerialNumber,
            swVersion:this.props.location.state.device.softwareVersion,
            UntagText:'',
            unTagCheckboxview:false,
            viewApn:false,
            apntext:'',
            apnMsg:''
        };
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.untagDevice = this.untagDevice.bind(this);
    }
    handleClose() {
        this.setState({ addModal: false });
    }
    componentDidMount() {
        if(this.props.location.state.device.currentState!=='REGISTERED'){
            this.setState({ unTagCheckboxview: true });
        }
    }
    editManufacturerDevice() {
        if (this.handleValidation()) {
            let currentComponent = this;
            currentComponent.setState({ isEditDeviceLoader: true });

            let obj = {};
            obj.phoneNumber = currentComponent.state.countryCode + currentComponent.state.phoneNumber;
            if(currentComponent.state.imeiNumber !== null){
                obj.imei = currentComponent.state.imeiNumber;
            }
            if(currentComponent.state.simId !== null){
                obj.simSerialNumber = currentComponent.state.simId;
            }
            if(currentComponent.state.swVersion !== null){
                obj.softwareVersion = currentComponent.state.swVersion;
            }
            InventoryAPI.putDevice(this.props.location.state.device.deviceSerialId, obj,
                (response) => {
                    currentComponent.setState({
                        isEditDeviceLoader: false,
                        responseMessage: 'Device edited successfully!',
                        showMessage: true
                    });
                },
                (error) => {
                    currentComponent.setState({ error: error.message });
                    return;
                }
            );
        }
    }


    handleValidation() {
        let formIsValid = true;
        if(!this.state.phoneNumber) {
            formIsValid = false;
            this.setState({error: "Please enter Phone Number"});
        }
        if(this.state.phoneNumber && (this.state.phoneNumber.length < 10 || this.state.phoneNumber.length > 13)) {
            formIsValid = false;
            this.setState({error: "Please enter a Number having 10 to 13 Digits"});
        }
        /*
        if(!this.state.imeiNumber) {
            formIsValid = false;
            this.setState({error: "Please enter IMEI Number"});
        }*/
        return formIsValid;
    }

    handleDismiss() {
        this.setState({ showMessage: false });
    }

    valid(item, type) { 
        switch (type) {
            case 'phoneNumber': {
                const val = item.target.value;
                const re = /^[0-9\b]+$/;
                var newVal = "";
                if (val !== "" && !re.test(val)) {
                    // Filter non-digits from input value.
                    return false;
                } else {
                    newVal = val;
                }
                this.setState({phoneNumber: newVal});
                break;
            }
            case 'countryCode': {
                this.setState({ countryCode: item.target.value });
                break;
            }
            case 'imeiNumber': {
                this.setState({ imeiNumber: item.target.value });
                break;
            }
            case 'apntext' :{
                this.setState({ apntext: item.target.value });
                break;
            }
            default: 
                break;   
        }
    }
    
    goBack(e) {
        this.props.history.push('/manufacturer-device-list');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }
    handleChange(e){
        let isChecked = e.target.checked;
        if(isChecked === true){
            this.setState(prevState => ({addModal: !prevState.addModal,UntagText:"Are you sure you want to Untag this device? "}));
        }
        //this.props.location.state.device.deviceSerialId
    }
    untagDevice() {
        let currentComponent = this;
        let requestBody = {};
        
        /* eslint no-restricted-globals:0 */
          requestBody.deviceState = 'UNTAG';
          InventoryAPI.putDeviceState(this.props.location.state.device.deviceSerialId, requestBody,
            (response) => {
              currentComponent.setState({
                unTagCheckboxview:false,
                UntagText:'Device UNTAG Successfully'
              });
            }
          );
        
      }
    viewUpdateApn(){
        this.setState({viewApn:true});
      }

    sendApnData(){
        
        this.setState({isApnMsgLoader: true});
        if(!this.state.apntext) {
            this.setState({error: "Please enter text message",isApnMsgLoader:false});
        }else if(!this.state.phoneNumber) {
            this.setState({error: "Please enter Phone Number",isApnMsgLoader:false});
        }else{
            
            /* eslint no-restricted-globals:0 */
            let requestBody = {};
            requestBody.eventType = 'UPDATE_DEVICE_APN';
            requestBody.agencyCode = this.props.location.state.device.agencyCode;
            requestBody.agencyName = this.props.location.state.device.agencyCode;
            requestBody.body = this.state.apntext;
            requestBody.phoneNumber = this.state.countryCode + this.state.phoneNumber;
            InventoryAPI.postApnMsg(requestBody,
            (response) => {
                this.setState({
                apnMsg:response.data.data,
                isApnMsgLoader:false
                });
            }
            );
        }
    }
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showMessage) {
            fadeinout = (
                <Alert bsStyle="success">
                    <h4>{this.state.responseMessage}</h4>
                    <Button  onClick={(event)=>this.goBack(event)} className="btn-primary">View</Button>
                    <span> or </span>
                    <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                </Alert>
            )
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                                {fadeinout}
                                <h3 className="card-title">Edit Device</h3>
                                <React.Suspense fallback={<div>Loader...</div>}>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4">
                                            {this.state.error &&
                                                <div className={'alert alert-danger margin-3'} style={stylescope.splitterStyle}>{this.state.error}</div>
                                            }</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label">Device Serial Id
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" readOnly value={this.props.location.state.device.deviceSerialId} className="form-control" placeholder="Device Serial Id "></input></div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label">SIM Card<span className="form-required">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="row">
                                        <div className="col-md-1">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="+91" 
                                                value={this.state.countryCode}
                                                onChange={(item) => this.valid(item, 'countryCode')}></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <input type="text" value={this.state.phoneNumber}
                                                onChange={(item) => this.valid(item, 'phoneNumber')} 
                                                className="form-control" placeholder="SIM Number"></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label">SIM ID</label>
                                                        <input type="text" value={this.state.simId}
                                                            onChange={(item) => this.valid(item, 'simId')}
                                                            className="form-control" placeholder="SIM ID" readOnly></input>
                                                    </div>
                                                </div>
                                              
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label">SW Version</label>
                                                        <input type="text" value={this.state.swVersion}
                                                            onChange={(item) => this.valid(item, 'swVersion')}
                                                            className="form-control" placeholder="SW Version" readOnly></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="form-label">IMEI Number</label>
                                                        <input type="text" value={this.state.imeiNumber}
                                                            onChange={(item) => this.valid(item, 'imeiNumber')}
                                                            className="form-control" placeholder="IMEI Number" readOnly></input>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                    <div className="row">
                                        {this.state.unTagCheckboxview &&
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="form-label">Un-Tag Device</label>
                                                        <Checkbox className="" onChange={e => this.handleChange(e)} ></Checkbox>
                                                </div>
                                            </div> 
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label"> &nbsp;</label>
                                                <button type="button" onClick={() => this.viewUpdateApn()} className="btn btn-primary">Update APN</button> 
                                            </div>
                                        </div> 
                                        {this.state.viewApn &&
                                        <div className="col-sm-4 col-md-3">
                                            <div className="form-group">
                                            <label className="form-label"> APN Text <span className="form-required">*</span></label>
                                                <input type="text" value={this.state.apntext}
                                                onChange={(item) => this.valid(item, 'apntext')} 
                                                className="form-control logintextfields " placeholder="APN Text"></input>
                                                <span style={{color: "green"}}>{this.state.apnMsg}</span>
                                            </div>
                                        </div>
                                        }
                                        {this.state.viewApn &&
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label"> &nbsp;</label>
                                                <button type="button" onClick={() => this.sendApnData()} className="btn btn-lm btn-outline-primary">Send
                                                {this.state.isApnMsgLoader &&
                                                        <img src="../images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                    }
                                                </button> 
                                            </div>
                                        </div>
                                        }
                                    </div>
                                        <div className="row">
                                        <div className="col-sm-6 col-md-1">
                                            <div className="form-group">
                                                <label className="form-label"> &nbsp;</label>
                                                <button type="button" onClick={() => this.editManufacturerDevice()} className="btn btn-primary">Save
                                                    {this.state.isEditDeviceLoader &&
                                                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal size="lg" onHide={this.handleClose}   aria-labelledby="contained-modal-title-vcenter" centered isOpen={this.state.addModal}  toggle={this.toggleAddModal}>
                                        <ModalHeader toggle={this.toggleAddModal}>
                                            Alert!.
                                        </ModalHeader>
                                        <ModalBody>
                                            {this.state.UntagText}
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="btn btn-secondary" onClick={this.handleClose}>Close</Button>
                                            {this.state.unTagCheckboxview &&  
                                                <Button color="btn btn-primary" onClick={this.untagDevice}>Yes</Button> 
                                            }
                                        </ModalFooter>
                                    </Modal>
                                </React.Suspense>
                            </div>
                        </form>
                    </React.Fragment>

                </Page.Content>
            </BaseLayout>
        );
    }
}

export default EditDevice;