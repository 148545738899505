import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';

export const NotificationAPI = {
    getNotifications
}

function getNotifications(callbackSuccess, callbackError) {
    API.get("/apis/notification?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch notifications. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}
