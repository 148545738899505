import React from 'react';
import { Link } from "react-router-dom";
import queryString from 'query-string'
import { userService } from '../_services';

class ResetPasswordPage extends React.Component {

    constructor(props) {
      super(props);

      this.state = { 
        resetPwdToken: queryString.parse(this.props.location.search).resetToken,
        password: '',
        confirmpassword: '',       
        submitted: false,
        requestDone: false,
        loading: false,
        passwordMismatch:false,
        error: ''
      };      
      
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);          
    }

    handleChange(e) {
        const { name, value } = e.target;        
        this.setState({ [name]: value });          
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { password, confirmpassword } = this.state;

        // stop here if form is invalid
        if (!(password && confirmpassword)) {                                 
            return;
        }else if (password !==confirmpassword) {
            this.setState({ passwordMismatch: true });                      
            return;
        }        

        this.setState({ passwordMismatch: false });
        this.setState({ loading: true });            
        
        // Reset Pwd API request
        userService.resetPassword(this.state.resetPwdToken, password,
            (response) => {
                this.setState({ loading: false });
                this.setState({ requestDone: true ,resetPwdToken:""});                
            },
            (error) => {
                this.setState({ error:error.message, loading: false , requestDone: false })
            }
        );
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
       };
        const {password, confirmpassword,submitted, requestDone, loading,passwordMismatch, error } = this.state;

        return(
            <div className={"page"}>
            <div className={"page-single"}>
            <div className={"container"}> 
            <div className={"row logo-margin-bottom"}>
                <div className={"col-md-2"}>
                    <img src="/images/ridlr.png" alt="Ridlr AFCS" className="logo-login"></img>
                </div>
            </div>
            <div className={"row"}>
            <div className={"col col-login mx-auto"}>
                
                <form className={"card"} onSubmit={this.handleSubmit}> 
                    <div className={"card-body p-6"}> 
                        
                        <div className={"card-title signincolor "}>Reset Password</div>

                        {!requestDone &&
                        <div  className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                            <label className={"form-label"}>New Password</label>
                            <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                            {submitted && !password &&
                                <div className="help-block danger">Please enter new Password</div>
                            }
                        </div>
                        }
                        {!requestDone &&
                        <div  className={'form-group' + (submitted && !confirmpassword ? ' has-error' : '')}>
                            <label className={"form-label"}>Confirm New Password</label>
                            <input type="password" className="form-control" name="confirmpassword" value={confirmpassword} onChange={this.handleChange} />
                            {submitted && !confirmpassword &&
                                <div className="help-block danger">Please confirm new Password</div>
                            }
                        </div>
                        }
                        {!requestDone &&
                        <div className={"form-footer"}>
                            <button className="btn btn-block submit-btn-bg" hidden={loading} >Reset Password </button> 
                            {loading &&
                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="download" />
                            }                           
                        </div>
                        }
                        {error &&
                            <div className={'alert alert-danger margin-3' } style={stylescope.splitterStyle}>{error}</div>
                        }

                        {!requestDone && passwordMismatch &&
                            <div  className={"form-footer" } >
                                
                                <label className={"form-label"} >                                        
                                    New password and Confirm password values are not same.
                                </label>
                                <br></br>                   
                                
                            </div>
                        }

                        {requestDone && 
                                <div  className={"form-footer" } >
                                    
                                    <label className={"form-label"} >                                        
                                        Password successfully reset.Please relogin to dashboard using new password to continue.
                                    </label>
                                    <br></br>
                                    <Link to="/login"><p className="text-center">Afcs.ridlr.in</p></Link>
                                    
                                </div>
                                }
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</div>
);
}
}

export default ResetPasswordPage; 