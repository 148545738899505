// @flow
import * as React from "react";
import { Page,Grid,Button } from "tabler-react";
import { Table, Row, Col } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import ReactPaginate from "react-paginate";
import { PassAPI } from "../apis/PassAPI";
import { AgencyAPI } from "../apis/AgencyAPI";

const perPage = 20;
class PassproductList extends React.Component {
    constructor() {
        super();
        this.state = {
            data:  [],
            PassproductList: [],
            currentStopList: [],
            concessionList:[],
            currentPage: null,
            totalPages: null,
            offset: 0,
            status:'',
            name:'',
            isLoader: false,
            authkey: localStorage.getItem('authkey')
        }
        
    }
    loadCommentsFromServer() {
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        PassAPI.getProduct(
          (response) => {
            currentComponent.setState({
              fetchInProgress: false,
              data: response.data.data.content,
              pageCount: Math.ceil(response.data.data.totalElements / response.data.data.size),
              isLoader: false
            });
          },
          (error) => {
            currentComponent.setState({isLoader: false  });
          }
        );

        /* Concession data fetch here */
        AgencyAPI.getPassConcession(
          (response) => {
            let deviceIdFromApi = response.data.data.map(device => { return { value: device.id, label: device.name } });
            currentComponent.setState({ concessionList: deviceIdFromApi });
          }
        );
    }

    componentDidMount() {
        this.loadCommentsFromServer();
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * perPage);
        this.setState({ offset: offset, page: selected }, () => {
          this.loadCommentsFromServer();
        });
      };

  

    render(){

        let paginationtransaction;
        let addrow;
        addrow=(<Grid>
            <Row className="show-grid">
              <Col xsHidden md={12}>
                <a href="/create-pass"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Create Pass</Button></a>
              </Col>
            </Row>
          </Grid>
          );

        if (this.state.pageCount > 0) {
            paginationtransaction = (
      
              <ReactPaginate
                previousButton={"previous"}
                previousClassName={"page-link"}
                nextClassName={"page-link"}
                nextButton={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                onPageChange={this.handlePageClick}
                forcePage={this.state.currentPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={"pagination "}
                subContainerClassName={"Page navigation example"}
                activeClassName={"active page-item"}
                pageLinkClassName={"page-link"}
                currentLinkClassName={"page-link"}
              />
            )
          } else {
            paginationtransaction = (
              <div>
                No Data Found...
              </div>
      
            )
          }
          
          if (this.state.isLoader) {
            return (
              <BaseLayout>
                <Page.Content>
                <Page.Header title="Pass List" />
                 {addrow}
                 <React.Fragment>
                  <Table striped bordered condensed hover >
                    <thead>

                      <tr>
                        <th >Pass Name</th>
                        <th >Pass Type</th>
                        <th >Concession Type</th>
                        <th >Pass Status</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr><td colSpan="5" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                    </tbody>
                    </Table>
                    {paginationtransaction}
                    </React.Fragment>
                </Page.Content>
              </BaseLayout>
            );
      
          } else {
        return(
            <BaseLayout>
              <Page.Content>
                <Page.Header title="Pass List" />
                {addrow}
                <React.Fragment>
                
                  <Table striped bordered condensed hover >
                    <thead>

                      <tr>
                        <th >Pass Name</th>
                        <th >Pass Type</th>
                        <th >Concession Type</th>
                        <th >Pass Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      { 
                      this.state.data.map(function(obj, i){
                       return (
                          <tr key={i}>
                            <td>
                              {obj.passName}
                            </td>
                            <td >
                              {obj.passType}
                            </td>
                            <td >
                              {obj.concessionTypeName}
                            </td>
                            <td >
                              {obj.passProductStatus}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {paginationtransaction}
               </React.Fragment>
              </Page.Content>
            </BaseLayout>
            )
        }
    };
}
export default PassproductList;