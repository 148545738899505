// @flow
import * as React from "react";
import { Page,Grid,Button } from "tabler-react";
import { Table, Row, Col } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import Pagination from "./Pagination";
import { AgencyAPI } from "../apis/AgencyAPI";
import { Link } from 'react-router-dom';
const perPage = 20;
class VehicleList extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        DepotList: [],
        VehicleList: [],
        currentVehicleList:[],
        offset: 0,
        page: 0,
        isLoader: false,
        currentPage: null,
        totalPages: null,
        authkey: localStorage.getItem('authkey')
      };
    }
    loadCommentsFromServer() {
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        AgencyAPI.getVehicle(
          (response) => {
            currentComponent.setState({
              fetchInProgress: false,
              VehicleList: response.data.data,
              pageCount: Math.ceil(response.data.data.length / 20),
              isLoader: false
            });
          },
          (error) => {
            currentComponent.setState({isLoader: false  });
          }
        );
      }
    
      componentDidMount() {
        let thisstop = this;
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function(event) {
              var db          = event.target.result;
              var depots      = db.transaction('depot', 'readwrite').objectStore("depot");
              var depotdata  = depots.getAll();
              depots.getAll().onsuccess = function(event) {
                    let DepotListFromInDB = depotdata.result.map(depots => { return { value: depots.id, display: depots.depotname } })
                    thisstop.setState({ DepotList: [{ value: '', display: 'Select Depot' }].concat(DepotListFromInDB) });
                };
            }
        this.loadCommentsFromServer()
      }
      onPageChanged = data => {
        const { VehicleList } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
      
        const offset = (currentPage - 1) * pageLimit;
        const currentVehicleList = VehicleList.slice(offset, offset + pageLimit);
      
        this.setState({ currentPage, currentVehicleList, totalPages });
      };
      handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * perPage);
        this.setState({ offset: offset, page: selected }, () => {
          this.loadCommentsFromServer();
        });
      };
      render() {
        let paginationtransaction;
        const {
          VehicleList,
          currentPage,
          totalPages,
          DepotList
        } = this.state;
        const totalvehicle = VehicleList.length;
        if (this.state.pageCount > 0) {
            paginationtransaction = (
              <Pagination
                totalRecords={totalvehicle}
                pageLimit={perPage}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            )
          } else {
            paginationtransaction = (
              <div>
                No Data Found...
                </div>
      
            )
          }
        if (this.state.isLoader) {
            return (
              <BaseLayout>
                <Page.Content>
                  <Page.Header title="Vehicle List" />
                  <Grid>
                    <Row className="show-grid">
                    <Col xsHidden md={12}>
                        <a href="/add-vehicle"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Add concession </Button></a>
                    </Col>
                    </Row>
                </Grid>
                  <React.Suspense fallback={<div>Loader...</div>}>
                   
                    <Table striped bordered condensed hover>
                      <thead>
                      <tr>
                            <th >Vehicle Number</th>
                            <th >Depot Name</th>
                            <th >Vehicle Type Name</th>
                            
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td colSpan="3" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                    </tbody>
                    </Table>
                    {paginationtransaction}
                  </React.Suspense>
                </Page.Content>
              </BaseLayout>
            );
      
          } else {
            return (
              <BaseLayout>
                <Page.Content>
                  <Page.Header title="Vehicle List" />
                  <Grid>
                    <Row className="show-grid">
                    <Col xsHidden md={12}>
                        <a href="/add-vehicle"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Add Vehicle </Button></a>
                       
                    </Col>
                    </Row>
                </Grid>
                  <React.Suspense fallback={<div>Loader...</div>}>
                  
      
                    <Table striped bordered condensed hover>
                      <thead>
      
                        <tr>
                            <th >Vehicle Number</th>
                            <th >Depot Name</th>
                            <th >Vehicle Type Name</th>
                            <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
      
                        {
                          this.state.currentVehicleList.map(obj => {
      
                            let deportname = '';
                            
                            DepotList.find(function (element) {
                              if (element.value === obj.depot) {
                                    deportname = element.display;
                              }
                              return deportname;
                            });
                            return (
                              <tr key={obj.vehicleNumber}>
                                <td >
                                  {obj.vehicleNumber}
                                </td>
                                <td >
                                  {deportname}
                                </td>
                                <td >
                                  {obj.vehicleTypeName}
                                </td>
                                <td>
                                  <Link to={{ pathname: `/edit-vehicle/${obj.id}`, state: { vehicle: obj} }}  className="active"><i className="fe fe-edit"></i></Link>
                                </td>
                              </tr>
                            );
                          })}
      
                      </tbody>
                    </Table>
                    {paginationtransaction} {currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                  <span className="font-weight-bold">{totalPages}</span>
                </span>
                )}
                  </React.Suspense>
                </Page.Content>
              </BaseLayout>
            );
          }
    }
}
export default VehicleList;