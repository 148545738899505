// @flow
import React from "react";
import { Button } from "tabler-react";
import { Modal } from 'react-bootstrap';
import "./alert.js";
import Select from 'react-select';
import SessionUtils from "../utils/SessionUtils.js";
import Role from '../enums/Role';
import { ETIMCommunicator } from '../_components';
import MessageResponse from "../enums/MessageResponse.js";
import { ViewUtils } from "../utils/ViewUtils.js";

class COMConnection extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoader: false,
            fields: [],
            errors: [],
            showPortSelectionDialog: false,
            ports: [],
            portName: '',
            isConnectLoader: false,
            testConnectionEnabled: false,
            isTestConnectionLoader: false,
            authkey: localStorage.getItem('authkey'),
            msgHandler: null
        };
        this.handleTestConnection = this.handleTestConnection.bind(this);
        this.handleConnectPort = this.handleConnectPort.bind(this);
        this.handleShowPortDialog = this.handleShowPortDialog.bind(this);
        this.handleClosePortDialog = this.handleClosePortDialog.bind(this);
    }

    dropdownvalid(item, type) {
        switch (type) {
            case 'port': {
                let fields = this.state.fields;
                fields[type] = item;
                this.setState({ portName: item, fields });
                break;
            }
            default:
                break;
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.fields["port"]) {
            formIsValid = false;
            errors["port"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    getCOMList() {
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        ETIMCommunicator.getCOMList(
            (response) => {
                currentComponent.clearAllStateVariables();
                currentComponent.handleShowPortDialog();
                currentComponent.setState({ ports: response.data, msgHandler: response.msgHandler });
            }, 
            (error) => {
                currentComponent.clearAllStateVariables();
                currentComponent.handleShowPortDialog(); 
                currentComponent.setState({msgHandler: error.msgHandler});               
            });

    }

    handleClosePortDialog() {
        this.setState({ showPortSelectionDialog: false });
        this.clearAllStateVariables();
    }

    handleSuccessMsgDismiss() {
        this.setState({ showSuccessMessage: false });
    }

    handleErrorMsgDismiss() {
        this.setState({ showErrorMessage: false });
    }

    clearAllStateVariables() {
        this.setState({
            isLoader: false,
            fields: [],
            errors: [],
            showPortSelectionDialog: false,
            ports: [],
            portName: '',
            isConnectLoader: false,
            testConnectionEnabled: false,
            isTestConnectionLoader: false,
            showErrorMessage: false,
            showSuccessMessage: false,
            errorMessage: '',
            successMessage: ''
        });
    }

    handleShowPortDialog() {
        this.setState({ showPortSelectionDialog: true });
    }

    handleConnectPort() {
        if (this.handleValidation()) {
            let currentComponent = this;
            currentComponent.setState({ isConnectLoader: true, showErrorMessage: false, showSuccessMessage: false });
            ETIMCommunicator.postConnectOn(currentComponent.state.portName, 
                (response) => {
                    currentComponent.setState({
                        isConnectLoader: false,
                        testConnectionEnabled: true,
                        msgHandler: response.msgHandler
                    });
                },
                (error) => {
                    currentComponent.setState({
                        isConnectLoader: false,
                        testConnectionEnabled: false,
                        msgHandler: error.msgHandler
                    });
                    
                }
            );
        }
        return;
    }

    handleTestConnection() {
        let currentComponent = this;
        currentComponent.setState({ isTestConnectionLoader: true, showErrorMessage: false, showSuccessMessage: false });
        ETIMCommunicator.postDownloadDataDeviceDetails(
            (response) => {
                currentComponent.setState({
                    isTestConnectionLoader: false,
                    testConnectionEnabled: false,
                    msgHandler: ViewUtils.createMessageHandler('Test Connection Successful', MessageResponse.SUCCESS)
                });
            },
            (error) => {
                currentComponent.setState({
                    isTestConnectionLoader: false,
                    testConnectionEnabled: false,
                    msgHandler: error.msgHandler
                });
            }
        );

    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        return (
            <React.Fragment>
                <Modal animation={false} show={this.state.showPortSelectionDialog} onHide={this.handleClosePortDialog}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select COM Port</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {this.state.msgHandler}

                        Please select the port for the ETM device communication.
                                                        <label className="form-label">Ports<span className="form-required">*</span></label>
                        <Select options={this.state.ports} onChange={(item) => this.dropdownvalid(item.value, 'port')} />
                        <span style={{ color: "red" }}>{this.state.errors["port"]}</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn btn-primary" onClick={this.handleClosePortDialog}>
                            Close</Button>

                        {this.state.testConnectionEnabled && !this.state.isTestConnectionLoader
                            && <Button variant="primary" onClick={this.handleTestConnection} className="btn btn-primary">
                                Test Connection</Button>}
                        {this.state.isTestConnectionLoader &&
                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                        }

                        {!this.state.testConnectionEnabled && !this.state.isConnectLoader
                            && <Button variant="primary" onClick={this.handleConnectPort} className="btn btn-primary">
                                Connect</Button>}
                        {this.state.isConnectLoader &&
                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                        }
                    </Modal.Footer>
                </Modal>
                {(SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
                SessionUtils.getRole() === Role.ADMINISTRATOR ||
                SessionUtils.getRole() === Role.OPERATIONS)
                &&

                <div>
                    {this.state.isLoader &&
                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />}
                    <button type="button" hidden={this.state.isLoader} onClick={() => this.getCOMList()} className="btn btn-primary floatright">Connect ETIM</button>
                </div>
                }
            </React.Fragment>
        );
    }
}

export default COMConnection;
