// @flow
import React from "react";
import { Page, Form, Grid } from "tabler-react";
import { Button } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import "./alert.js";
import "../App.css";
import { ETIMCommunicator, DeviceTranslator } from '../_components';
import MessageResponse from "../enums/MessageResponse";
import { ViewUtils } from "../utils/ViewUtils";
import { ClevertapReact } from '../CleverTap/ClevertapReact';
import { EventProperties } from '../CleverTap/EventProperties';

class Master extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

            isloadingServiceOperator: false,
            isloadingServiceTypes: false,
            isloadingConcessions: false,
            isloadingLuggage: false,
            isloadingRoutesFares: false,
            isloadingCauses: false,
            isloadingExpenseCollection: false,
            isloadingUrl: false,
            isloadingPass: false,
            isloadingKeys: false,
            isloadingBonus: false,
            isloadingAgent:false,
            isAgentButton:false,
            msgHandler: null
        };
    }
    componentDidMount() {
        if(localStorage.getItem('agentCommission') === "true"){
            this.setState({isAgentButton:true});
        }
    }
    showLoader(type) {
        switch (type) {
            case 'serviceOperator':
                this.setState({ isloadingServiceOperator: true });
                break;
            case 'serviceTypes':
                this.setState({ isloadingServiceTypes: true });
                break;
            case 'concessions':
                this.setState({ isloadingConcessions: true });
                break;
            case 'luggage':
                this.setState({ isloadingLuggage: true });
                break;
            case 'routes':
                this.setState({ isloadingRoutesFares: true });
                break;
            case 'causes':
                this.setState({ isloadingCauses: true });
                break;
            case 'expenseCollection':
                this.setState({ isloadingExpenseCollection: true });
                break;
            case 'url':
                this.setState({ isloadingUrl: true });
                break;
            case 'pass':
                this.setState({ isloadingPass: true });
                break;
            case 'keys':
                this.setState({ isloadingKeys: true });
                break;
            case 'bonus':
                this.setState({ isloadingBonus: true });
                break;
            case 'agent':
                this.setState({ isloadingAgent: true });
                break;
            default:
                break;

        }
    }

    hideLoader(type) {
        switch (type) {
            case 'serviceOperator':
                this.setState({ isloadingServiceOperator: false });
                break;
            case 'serviceTypes':
                this.setState({ isloadingServiceTypes: false });
                break;
            case 'concessions':
                this.setState({ isloadingConcessions: false });
                break;
            case 'luggage':
                this.setState({ isloadingLuggage: false });
                break;
            case 'routes':
                this.setState({ isloadingRoutesFares: false });
                break;
            case 'causes':
                this.setState({ isloadingCauses: false });
                break;
            case 'expenseCollection':
                this.setState({ isloadingExpenseCollection: false });
                break;
            case 'url':
                this.setState({ isloadingUrl: false });
                break;
            case 'pass':
                this.setState({ isloadingPass: false });
                break;
            case 'keys':
                this.setState({ isloadingKeys: false });
                break;
            case 'bonus':
                this.setState({ isloadingBonus: false });
                break;
            case 'agent':
                this.setState({ isloadingAgent: false });
                break;
                
            default:
                break;
        }
    }

    submit(type) {
        
        this.setState({msgHandler: null});
        this.showLoader(type);
        let url = '';
        let caseName='';
        switch (type) {
            case 'serviceOperator':
                url = 'service_operator';
                caseName='SERVICE OPERATOR';
                break;
            case 'serviceTypes':
                url = 'service_types';
                caseName='SERVICE TYPES';
                break;
            case 'concessions':
                url = 'concessions';
                caseName='CONCESSIONS';
                break;
            case 'luggage':
                url = 'luggage';
                caseName='LUGGAGE';
                break;
            case 'routes':
                url = 'routes';
                caseName='ROUTES AND FARES';
                break;
            case 'causes':
                url = 'causes';
                caseName='CAUSES';
                break;
            case 'expenseCollection':
                url = 'expense_collection';
                caseName='EXPENSE AND COLLECTION';
                break;
            case 'url':
                url = 'url';
                caseName='URL';
                break;
            case 'pass':
                url = 'pass';
                caseName='PASS';
                break;
            case 'keys':
                url = 'keys';
                caseName='KEYS';
                break;
            case 'bonus':
                url = 'pass/bonus';
                caseName='BONUS';
                break;
                case 'agent':
                    url = 'agent/commission';
                    caseName='AGENT';
                    break;
            default:
                break;
        }
        url = url + '?agency=' + localStorage.getItem('agencyname');
        ClevertapReact.event("CommunicationRequest",EventProperties.eventPropertiesCommunicationRequest(caseName));
        let currentComponent = this;
        DeviceTranslator.getMasterData(url,
            (response) => {
                ETIMCommunicator.postUploadData(response.data, 
                    (response) => {
                        currentComponent.hideLoader(type);
                        if(response.data === 'O') {
                            ClevertapReact.event("CommunicationResponse",EventProperties.eventPropertiesCommunicationResponse(caseName,'SUCCESS',''));
                           currentComponent.setState({
                                msgHandler: ViewUtils.createMessageHandler(this.successMessage(type), MessageResponse.SUCCESS)
                            });
                        }
                    },
                    (error) => {
                        ClevertapReact.event("CommunicationResponse",EventProperties.eventPropertiesCommunicationResponse(caseName,'FAILED',error.msgHandler.props.message));
                        currentComponent.hideLoader(type);
                        currentComponent.setState({
                            msgHandler: error.msgHandler
                        });
                    }
                );
            },
            (error) => {
                ClevertapReact.event("CommunicationResponse",EventProperties.eventPropertiesCommunicationResponse(caseName,'FAILED',error.msgHandler.props.message));
                currentComponent.hideLoader(type);
                currentComponent.setState({
                    msgHandler: error.msgHandler
                });
            
            }
        );
    }

    successMessage(type) {
        switch (type) {
            case 'serviceOperator':
                return "Service Operator Uploaded Successfully";
            case 'serviceTypes':
                return "Service Types Uploaded Successfully";
            case 'concessions':
                return "Concessions Uploaded Successfully";
            case 'luggage':
                return "Luggage Uploaded Successfully";
            case 'routes':
                return "Routes And Fares Uploaded Successfully";
            case 'causes':
                return "Causes Uploaded Successfully";
            case 'expenseCollection':
                return "Expense And Collection Uploaded Successfully";
            case 'url':
                return "URL Uploaded Successfully";
            case 'pass':
                return "Pass Uploaded Successfully";
            case 'keys':
                return "Keys Uploaded Successfully";
            case 'bonus':
                return "Recharge Uploaded Successfully";
            case 'agent':
                return "Agent Uploaded Successfully";
            default:
                break;

        }
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Communication" />
                    <React.Fragment>
                        <form>
                            {this.state.msgHandler}
                            <Grid.Row md={12} lg={12}>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Service Operator">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingServiceOperator} onClick={() => this.submit('serviceOperator')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingServiceOperator &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Service Types">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingServiceTypes} onClick={() => this.submit('serviceTypes')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingServiceTypes &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Concessions">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingConcessions} onClick={() => this.submit('concessions')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingConcessions &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Luggage">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingLuggage} onClick={() => this.submit('luggage')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingLuggage &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Routes and Fares">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingRoutesFares} onClick={() => this.submit('routes')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingRoutesFares &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Causes">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingCauses} onClick={() => this.submit('causes')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingCauses &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Expense and Collection">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingExpenseCollection} onClick={() => this.submit('expenseCollection')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingExpenseCollection &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Url">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingUrl} onClick={() => this.submit('url')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingUrl &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Keys">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingKeys} onClick={() => this.submit('keys')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingKeys &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Recharge">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingBonus} onClick={() => this.submit('bonus')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingBonus &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                { this.state.isAgentButton &&
                                <Grid.Col md={3} lg={3} className="mb-5">
                                    <Form.Group label="Agent">
                                        <Button type="button" bsStyle="info" className="outline-info " hidden={this.state.isloadingAgent} onClick={() => this.submit('agent')} >
                                            Send
                                    </Button>
                                        {this.state.isloadingAgent &&
                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </Form.Group>
                                </Grid.Col>
                                }
                            </Grid.Row>
                        </form>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default Master;