import React, { Component } from 'react';
import { AFCSLogger } from '../logger/AFCSLogger';
import { Page } from "tabler-react";
import BaseLayout from "../pages/BaseLayout";


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    AFCSLogger.logErrorBoundaryError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <BaseLayout>
          <Page.Content>
            <Page.Header title="Error" />
            <div className="card">
              <div className="card-body" >
                <div>
                  <p>Something went wrong. Please try again</p>
                </div>
              </div>
            </div>
          </Page.Content>
        </BaseLayout>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;