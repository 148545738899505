// @flow
import React from "react";
import { Page, Grid } from "tabler-react";
import { Button, Alert } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import "./alert.js";
import "../App.css";
import { AgencyAPI } from "../apis/AgencyAPI";

class CreateStop extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            validationError: "",
            redirect: false,
            responsemessage: '',
            addstatusclass: 'none',
            stopid: '',
            shortname: '',
            longname: '',
            latitude: '',
            longitude: '',
            hidesubmit: 'hidesubmitbtn',
            errors: {},
            isloading: false,
            showmessage: false,
            authkey: localStorage.getItem('authkey')
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);

    }
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    valid(item, type) {
        let itemValue = item.target.value;
        var errors = {};
        switch (type) {

            case 'shortname': {
                errors['shortname'] = '';
                return this.setState({ shortname: itemValue, errors: errors['shortname'] })
            }
            case 'longname': {
                errors['longname'] = '';
                return this.setState({ longname: itemValue, errors: errors['longname'] })
            }
            case 'latitude': {
                errors['latitude'] = '';
                return this.setState({ latitude: itemValue })
            }
            case 'longitude': {
                errors['longitude'] = '';
                return this.setState({ longitude: itemValue })
            }
            case 'status': {
                errors['status'] = '';
                return this.setState({ status: itemValue })
            }

            default:
        }
    }
    GetDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    handleDismiss() {
        this.setState({ showmessage: false });
    }

    handleShow() {
        this.setState({ showmessage: true });
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.shortname) {
            formIsValid = false;
            errors["shortname"] = "Cannot be empty";
        }
        if (!this.state.longname) {
            formIsValid = false;
            errors["longname"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    submitstop() {
        if (this.handleValidation()) {
            var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
            this.setState({ isloading: true, stopid: id });
            let obj = {}
            let satus = this;

            obj.agencyStopId = id;
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.shortName = this.state.shortname;
            obj.longName = this.state.longname;
            obj.latitude = this.state.latitude;
            obj.longitude = this.state.longitude;

            AgencyAPI.postStops(obj,
                (response) => {
                    if (response.data.message === 'SUCCESS') {
                        id = '';
                        const dbName = "RIDLR AFCS";
                        const version = 1; //versions start at 1
                        var request = indexedDB.open(dbName, version);
                        request.onsuccess = function (event) {
                            var db = event.target.result;
                            var stops = db.transaction('stops', 'readwrite').objectStore("stops");
                            var clear = stops.clear();
                            clear.onsuccess = function (event) {
                                //  stopsData.transaction.oncomplete = function(event) {
                                AgencyAPI.getStops(
                                    (response) => {
                                        let stopsFromApi = response.data.data.map((stops) => {
                                            return { id: stops.id, agencyStopId: stops.agencyStopId, shortName: stops.shortName, longName: stops.longName, latitude: stops.latitude, longitude: stops.longitude, createdAt: stops.createdAt, status: stops.status, parent: stops.parent }
                                        })
                                        // Store values in the newly created objectStore.
                                        var stopObjectStore = db.transaction("stops", "readwrite").objectStore("stops");
                                        stopObjectStore.clear();
                                        stopsFromApi.forEach(function (stops) {
                                            stopObjectStore.add(stops);
                                        });
                                    }
                                );
                            }
                        }

                        satus.setState({
                            isloading: false,
                            showmessage: true,
                            conductorName: '',
                            stopid: '',
                            shortname: '',
                            longname: '',
                            latitude: '',
                            longitude: '',
                            responsemessage: "Stop Create Successfully. "
                        });
                    } else {
                        satus.setState({
                            isloading: false,
                            showmessage: true,
                            responsemessage: response.data.message
                        });
                        return;
                    }
                }
            );
        }
    }

    goBack(e) {
        this.props.history.push('/stop-list');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };

        var opts = {};
        if (this.state.readonly === true) {
            opts['readOnly'] = 'readOnly';
        }
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success" className="col-lg-4 m-2" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                    <p>
                        <Button onClick={(event) => this.goBack(event)} className="btn-primary">View</Button>
                        <span> or </span>
                        <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                    </p></Alert>
            )
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="Create New Stop" />
                    <React.Fragment>
                        <form>
                            {fadeinout}
                            <Grid.Row md={12} lg={12}>
                                <Grid.Col lg={6} className="bgdetails">

                                    <Grid.Row lg={4} className="detailschild"  >
                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Short name</label></Grid.Col>
                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input {...opts} type="text" className="form-control" value={this.state.shortname} onChange={(item) => this.valid(item, 'shortname')} ></input>
                                            <span style={{ color: "red" }}>{this.state.errors["shortname"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row lg={4} className="detailschild"  >
                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Long name</label></Grid.Col>
                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input {...opts} type="text" className="form-control" value={this.state.longname} onChange={(item) => this.valid(item, 'longname')} ></input>
                                            <span style={{ color: "red" }}>{this.state.errors["longname"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row lg={4} className="detailschild"  >
                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Latitude</label></Grid.Col>
                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input {...opts} type="text" className="form-control" value={this.state.latitude} onChange={(item) => this.valid(item, 'latitude')} ></input>
                                            <span style={{ color: "red" }}>{this.state.errors["latitude"]}</span>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row lg={4} className="detailschild"  >
                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label className="floatright" >Longitude</label></Grid.Col>
                                        <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input {...opts} type="text" className="form-control" value={this.state.longitude} onChange={(item) => this.valid(item, 'longitude')} ></input>

                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row lg={4} className="detailschild" >
                                        <Grid.Col lg={4} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                        <Grid.Col lg={6} className="paddingnull">
                                            <Button type="button" onClick={() => this.submitstop()} hidden={this.state.isloader} className="btn btn-primary" >
                                                Submit

                                                </Button>
                                            {this.state.isloading &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            <a href="/stop-list"> <Button type="button" color="secondary mb-2 mr-2"   >Back</Button></a>
                                        </Grid.Col>
                                    </Grid.Row>

                                </Grid.Col>
                            </Grid.Row>
                        </form>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}
export default CreateStop;