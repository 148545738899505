// @flow
import * as React from "react";
import { Page,Grid,Button } from "tabler-react";
import { Table, Row, Col } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import ReactDropdownAutoComplete from "./autocomplete";
import { Link } from 'react-router-dom'
import Pagination from "./Pagination"
const perPage = 20;
class Passlist extends React.Component {
    constructor() {
        super();
        this.state = {
            data:  [],
            StopList: [],
            currentStopList: [],
            currentPage: null,
            totalPages: null,
            offset: 0,
            status:'',
            name:'',
            isLoader: false,
            authkey: localStorage.getItem('authkey')
        }
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
    }
    loadCommentsFromServer() {
        let currentComponent = this;
        let stopsdata ='';
        currentComponent.setState({ isLoader: true });
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        let assignDuty = this;
        request.onsuccess = function(event) {
            var db          = event.target.result;
            var stops      = db.transaction('stops', 'readwrite').objectStore("stops");
            if(currentComponent.state.name !== ''){
                stopsdata  = stops.getAll(currentComponent.state.name);
                stops.getAll().onsuccess = function(event) {
                    assignDuty.setState({
                        StopList:stopsdata.result,
                        isLoader: false,
                        pageCount: Math.ceil(stopsdata.result.length / 20)
                    });
                };
            } else {
                stopsdata  = stops.getAll();
                // get data from Stops object
                stops.getAll().onsuccess = function(event) {
                    assignDuty.setState({
                        StopList:stopsdata.result,
                        isLoader: false,
                        pageCount: Math.ceil(stopsdata.result.length / 20)
                    });
                };
            }
        }     
    }
    componentDidMount() {
        this.loadCommentsFromServer();
    }
    onPageChanged = data => {
        const { StopList } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;
        const currentStopList = StopList.slice(offset, offset + pageLimit);
        this.setState({ currentPage, currentStopList, totalPages });
    };

    handleSubmitClick() {
        let key = this.state.name;
        if (key === null || key === '') {
            alert("Please enter StopId!");
            return;
        }
        this.setState({ name: key }, () => {
            this.loadCommentsFromServer();
        });
    }
    handleResetClick(){
        this.setState({ name: '' }, () => {
            this.loadCommentsFromServer();
        });
    }
    render(){
     // if (totalCountries === 0) return null;
      const {
        StopList,
        currentPage,
        totalPages
      } = this.state;
      const totalStops = StopList.length;
      const { editFields } = this.state; // you can get your input value by other ways
        let paginationtransaction;
        
        if (this.state.pageCount > 0) {
            paginationtransaction = (
              <Pagination
                totalRecords={totalStops}
                pageLimit={perPage}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            )
          } else {
            paginationtransaction = (
              <div>
                No Data Found...
                </div>
      
            )
          }
          if (this.state.isLoader) {
            return (
              <BaseLayout>
                <Page.Content>
                
                  <React.Fragment>
                  
                  <Table striped bordered condensed hover >
                    <thead>

                      <tr>
                        <th >Pass Type</th>
                        <th >Pass Sub Type</th>
                        <th >Long Name</th>
                        <th >Latitude</th>
                        <th >Longitude</th>
                        <th >Status</th>
                        <th >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr><td colSpan="7" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                    </tbody>
                    </Table>
                    {paginationtransaction}
                    </React.Fragment>
                </Page.Content>
              </BaseLayout>
            );
      
          } else {
        return(
            <BaseLayout>
              <Page.Content>
                <Page.Header title="Pass List" />
                <Grid>
        <Row className="show-grid">
                       
          <Col xs={3} md={3}>
            <div className="row gutters-xs">
              <div className="col">
                <ReactDropdownAutoComplete
                    getItemValue={item => item.agencyStopId}
                    className="form-control"
                    id="conductor"
                    name="conductor"
                    placeholder="Enter Pass Id"
                    data={this.state.StopList || []}
                    renderItem={item => (
                        <div
                        role="button"
                        tabIndex="-1"
                        onClick={(val) => { editFields.agencyStopId = val; }}
                        >{item.agencyStopId}</div>
                    )}
                    icon="search"
                    iconColor="#ff000"
                    iconClick={() => { /* TODO */ }}
                    value={this.state.name}
                    onChange={(val) => { this.setState({ name: val }); }}
                    onEnter={() => { }}
                /> 
                
              </div>
              <span className="col-auto">
                <button className="btn btn-secondary"  onClick={this.handleSubmitClick} type="button"><i className="fe fe-search"></i></button>
              </span>
            </div>
          </Col>
          <Col xsHidden md={7}>
          <Button  type="button" color="secondary mb-2 mr-2"  onClick={this.handleResetClick} >Reset</Button>
          </Col>
          <Col xsHidden md={2}>
            <a href="/create-pass"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Add Pass </Button></a>
          </Col>
         
        </Row>
      </Grid>
                <React.Fragment>
                
                  <Table striped bordered condensed hover >
                    <thead>

                      <tr>
                        <th >Pass Type</th>
                        <th >Pass Sub Type</th>
                        <th >Long Name</th>
                        <th >Latitude</th>
                        <th >Longitude</th>
                        <th >Status</th>
                        <th >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.currentStopList.map(function(obj, i){
                        return (
                          <tr key={i}>
                            <td>
                              {obj.agencyStopId}
                            </td>
                            <td >
                              {obj.shortName}
                            </td>
                            <td >
                              {obj.longName}
                            </td>
                            <td >
                              {obj.latitude}
                            </td>
                            <td >
                              {obj.longitude}
                            </td>
                            <td>
                              {obj.status}
                            </td>
                            <td>   
                            <Link to={`/edit-stop/${obj.agencyStopId}`} className="active"><Button color="primary">Edit</Button></Link>
                            </td>
                          </tr>
                        );
                      })}  
                    </tbody>
                  </Table>
                  {paginationtransaction} {currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                  <span className="font-weight-bold">{totalPages}</span>
                </span>
              )}
                 
              </React.Fragment>
              </Page.Content>
            </BaseLayout>
            )
        }
    };
}
export default Passlist;