// @flow
import * as React from "react";
import { Page,colors } from "tabler-react";
import C3Chart from "react-c3js";
import BaseLayout from "./BaseLayout";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SessionUtils from '../utils/SessionUtils';
import Role from '../enums/Role';
import { AnalyticsAPI } from "../apis/AnalyticsAPI";
import { AppAPI } from  "../apis/AppAPI";
import moment from 'moment';
import DateUtils from "../utils/DateUtils";
import "../plugin.css";
import getSymbolFromCurrency from 'currency-symbol-map'
import { isMobile } from "react-device-detect";
import { messaging } from "../pushnotifications/initializePush";

const pkg = require('../../package.json');
const { detect } = require('detect-browser');
const browser = detect();

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      currentDate: new Date(),
      //activeconductorcount:0,
      //activeVehiclesCount:0,
      revenue:0,
      passanger:0,
      passangerdata:'',
      revenuedata:'',
      routedata:'',
      routeTotalRevenue:0,
      todayLuggageCount:0,
      todayRevenue:0,
      todayPassenger:0,
      todayConductor:0,
      todayVehicles:0,
      totalVehicles:0,
      totalConductor:0,
      revenueGraphData:[],
      passengerGraphData:[],
      conductorGraphData:[],
      vehiclesGraphData:[],
      routeGraphData:[],
      isloader:false,
      isLoading:false,
      isNextEnabled: true,
      authkey: sessionStorage.getItem('authkey'),
      businessType: localStorage.getItem('businessType')
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.prevDateClick = this.prevDateClick.bind(this);
    this.nextDateClick = this.nextDateClick.bind(this);
  }
  handleChange(date) {
    this.setState({
      startDate: date
    });
  }
  handleChangeEnd(date) {
    this.setState({
      endDate: date
    });
  }
  loadCommentsFromServer() {
    let currentComponent = this;
    currentComponent.setState({ isloader: true, revenueGraphData: [],passengerGraphData:[],vehiclesGraphData:[],conductorGraphData:[],routeGraphData:[]});
    let to = DateUtils.getDateFormatted(this.state.startDate);
    let from = DateUtils.getDateRangeLastSevenDays(this.state.startDate, 6);
    AnalyticsAPI.getReportsRoute(to, to,
      (response) => {
            var data6 = ['data6'];
            var data7 = ['data7'];
            var totalEarningsIndex = response.data.data.summary.columns.findIndex(obj => obj === "totalEarnings");
            var totalrevenue = currentComponent.numberWithCommas(response.data.data.summary.data[totalEarningsIndex]);
            var routePassangers = response.data.data.report.data.map(s => s.totalRidership) ; 
            var routeRevenue = response.data.data.report.data.map(s => s.totalEarnings);
            var routes = response.data.data.report.data.map(s => s.routeName.toString());
            var passangerData = data7.concat(routePassangers);
            var revenueData = data6.concat(routeRevenue);
            
            const routecards = [
              {
                title: "Route Summary ",
                axis: {
                  x: {
                    type: "category",
                    // name of each category
                    categories: routes
                  },
                },
                data: {
                  columns: [
                    // each columns data
                    revenueData.map(d => d),
                    passangerData.map(d => d),
                  ],
                  labels: true,
                  type: "bar", // default type of chart
                  colors: {
                    'data6': colors["green"],
                    'data7': colors["blue"],
                  },
                  empty: {
                    label: {
                      text: "No Data Available"
                    }
                  },
                  names: {
                    // name of each serie
                    'data6': "Revenue",
                    'data7': "Passenger",
                  },
                },
                
              },
            ];
            currentComponent.setState({routeGraphData:routecards, isLoading: false, routeTotalRevenue:totalrevenue });
        },
        (error) => {
          currentComponent.setState({ data: [], isLoader: false, isLoading:false, pageCount: '' });
        }
    );
    AnalyticsAPI.getReportsRevenue(from, to,
      (response) => {
         if(response.data.data.summary.data.length > 0){
          currentComponent.setState({
            //activeconductorcount: currentComponent.numberWithCommas(response.data.data.summary.data[2]),
            passanger: currentComponent.numberWithCommas(response.data.data.summary.data[8]),
            revenue: currentComponent.numberWithCommas(response.data.data.summary.data[14]),
            //activeVehiclesCount: currentComponent.numberWithCommas(response.data.data.summary.data[4]),
            totalVehicles:currentComponent.numberWithCommas(response.data.data.summary.data[2]),
            totalConductor:currentComponent.numberWithCommas(response.data.data.summary.data[1]),
            isloader: false
            });
        }
        
         // console.log(response.data.data.report); return;
          var data1 = ['data1'];
          var data2 = ['data2'];
         // var data3 = ['data3'];
          var data4 = ['data4'];
          //var data5 = ['data5'];
          var data6 = ['data6'];
          var passengers = response.data.data.report.data.map(s => s.totalTickets);
          var revenues = response.data.data.report.data.map(s => s.totalAmount);
          var luggagedata = response.data.data.report.data.map(s => s.luggageTickets);
         // var totalConductor = response.data.data.report.data.map(s => s.totalConductors);
          var activeConductor = response.data.data.report.data.map(s => s.totalConductors);
         // var totalVehicles = response.data.data.report.data.map(s => s.totalVehicles);
          var activeVehicles = response.data.data.report.data.map(s => s.totalVehicles);
          var dates = response.data.data.report.data.map(s => moment(s.date).format("DD-MM-YY"));
          var passdata = data2.concat(passengers); 
          var revdata = data1.concat(revenues);
          
         // var totalConductordata = data3.concat(totalConductor); 
          var activeConductordata = data4.concat(activeConductor);
         // var totalVehiclesdata = data5.concat(totalVehicles); 
          var activeVehiclesdata = data6.concat(activeVehicles);
          const revenuesCards = [
            {
              axis: {
                x: {
                  type: "category",
                  // name of each category
                  categories: dates
                 
                },
              },
              data: {
                columns: [
                  // each columns data
                  revdata.map(d => d),
                ],
                labels: true,
                type: "bar", // default type of chart
                colors: {
                  data1: colors["teal"],
                },
                empty: {
                  label: {
                    text: "No Data Available"
                  }
                },
                names: {
                  // name of each serie
                  data1: "Revenue",
                },
              },
              
            },
          ];
          const passengersCards = [
            {
             axis: {
                x: {
                  type: "category",
                  // name of each category
                  categories: dates
                 
                },
              },
              data: {
                columns: [
                  // each columns data
                  passdata.map(d => d),
                ],
                labels: true,
                type: "bar", // default type of chart
                colors: {
                  data1: colors["blue"],
                },
                empty: {
                  label: {
                    text: "No Data Available"
                  }
                },
                names: {
                  // name of each serie
                  data2: "Passengers",
                },
              },
              
            },
          ];
          const conductorCards = [
            {
              axis: {
                x: {
                  type: "category",
                  // name of each category
                  categories: dates
                 
                },
              },
              data: {
                columns: [
                  // each columns data
                 // totalConductordata.map(d => d),
                  activeConductordata.map(d => d),
                ],
                labels: true,
                type: "bar", // default type of chart
                colors: {
                  //data3: colors["azure"],
                  data4: colors["azure"]
                },
                empty: {
                  label: {
                    text: "No Data Available"
                  }
                },
                names: {
                  // name of each serie
                  //data3: "Registered Conductors",
                  data4: "Active Conductors"
                },
              },
              
            },
          ];

          const vehiclesCards = [
            {
              axis: {
                x: {
                  type: "category",
                  // name of each category
                  categories: dates
                 
                },
              },
              data: {
                columns: [
                  // each columns data
                  //totalVehiclesdata.map(d => d),
                  activeVehiclesdata.map(d => d),
                ],
                labels: true,
                type: "bar", // default type of chart
                colors: {
                 // data5: colors["pink"],
                  data6: colors["purple"]
                },
                empty: {
                  label: {
                    text: "No Data Available"
                  }
                },
                names: {
                  // name of each serie
                //  data5: "Registered Vehicles",
                  data6: "Active Vehicles"
                },
              },
              
            },
          ];

          if(dates[dates.length - 1] === moment(this.state.startDate).format("DD-MM-YY")){
            currentComponent.setState({todayLuggageCount: luggagedata[luggagedata.length - 1],todayRevenue:revdata[revdata.length - 1] ,todayPassenger:passdata[passdata.length - 1] ,todayConductor:activeConductordata[activeConductordata.length - 1] ,todayVehicles:activeVehiclesdata[activeVehiclesdata.length - 1] });
          }
          currentComponent.setState({revenueGraphData:revenuesCards,passengerGraphData:passengersCards,conductorGraphData:conductorCards,vehiclesGraphData:vehiclesCards });
      },
      (error) => {
        currentComponent.setState({ data: [], isLoader: false, pageCount: '' });
      }
    );
    
  }

  handleSubmitClick() {
    if (this.state.startDate == null || this.state.endDate == null) {
      alert("Please select date range!");
      return;
    } else {
      this.setState({ from: this.state.startDate, to: this.state.endDate }, () => {
        this.loadCommentsFromServer();
        //onClick={this.handleSubmitClick}
      });
    }
  }
  
  numberWithCommas(x) {
    if (x !== undefined) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  }

  prevDateClick() {
    let currentComponent = this;
    currentComponent.setState({isLoading:true});
    let prevDate = DateUtils.getPrevDate(currentComponent.state.startDate);
    currentComponent.setState(
      {
        startDate: prevDate,
        endDate: prevDate
      }, function() {
        currentComponent.enableNextButton();
        currentComponent.loadCommentsFromServer();
      }
    );
  }

  nextDateClick() {
    let currentComponent = this;
    currentComponent.setState({isLoading:true});
    let nextDate = DateUtils.getNextDate(currentComponent.state.startDate);
    currentComponent.setState(
      {
        startDate: nextDate,
        endDate: nextDate
      }, function() {
        currentComponent.enableNextButton();
        currentComponent.loadCommentsFromServer();
      }
    );
  }

  enableNextButton() {
    let currentComponent = this;
    currentComponent.setState({
      isNextEnabled: DateUtils.isCurrentDate(currentComponent.state.startDate)
    });
  }

  componentDidMount() {
    this.initPushNotification();
    document.body.classList.remove('Login-component');
    this.loadCommentsFromServer();
    //this.toggleAddModal();
  }

  updateFromAndroid(){
    this.sendTokenToServer(localStorage.getItem("androidFCMToken"), 
      'ANDROID', localStorage.getItem("androidAppVersion"), localStorage.getItem("androidOSVersion"));
  }

  initPushNotification() {
    if(messaging !== null) {
      messaging
      .requestPermission()
      .then(() => {
        messaging.getToken().then(token => {
          this.sendTokenToServer(token, "WEB", pkg.version, browser.name + "-" + browser.version + "-" + browser.os);
        })
       .catch(error => {
          if (error.code === "messaging/permission-blocked") {
       //      console.log("Please Unblock Notification Request Manually");
          } else {
        //     console.log("Error Occurred", error);
          }
         });
         messaging.onTokenRefresh(() => {
          messaging.getToken().then((refreshedToken) => {
            this.sendTokenToServer(refreshedToken, "WEB", pkg.version, browser.name + "-" + browser.version + "-" + browser.os);
          }).catch((err) => {
          //  console.log('Unable to retrieve refreshed token ', err);
          });
        });
     
       }).catch(error => {
        // console.log("permission error:" + JSON.stringify(error));
       });
    }
  }

  sendTokenToServer(token, platform, appVersion, osVersion) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let obj = {};
    obj.userId = currentUser.employeeId;
    obj.agencyCode = currentUser.agencyCode;
    obj.platform = platform;
    obj.appVersion = appVersion;
    obj.osVersion = osVersion;
    obj.registrationToken = token;
    AppAPI.postApp(obj,
      (response) => {
          console.log(response);
      },
      (error) => {
          console.log(error);
      }
    );

  }

  toggleAddModal = () => {
    this.setState(prevState => ({
      addModal: !prevState.addModal,
    }));
  }

  render() {
    var stylescope = {
      splitterStyle: {
          margin: 5
      }
    };
    return (
      <BaseLayout>
        <Page.Content>
        <div className="my-3 my-md-5">
          <div className="container">
          <div className="page-header">
          <h1 className="page-title">
                    Dashboard
                  </h1>
          </div>
          <div id="androidToken" hidden={true}></div>
          <button type="button" hidden={true} id="androidTokenClick" onClick={() => this.updateFromAndroid()} className="btn btn-icon btn-primary btn-secondary arrowbtn"><i className="fe fe-chevron-left"></i></button>
          {isMobile && <div className="date-box">
            <button type="button" onClick={this.prevDateClick} className="btn btn-icon btn-primary btn-secondary arrowbtn"><i className="fe fe-chevron-left"></i></button>
            <div className="todayDate">{DateUtils.isCurrentDate(this.state.startDate)?"TODAY - ":""}{DateUtils.getDateToDDMMMYYYYFormattedString(this.state.startDate)}</div>
            <button type="button" disabled={this.state.isNextEnabled} onClick={this.nextDateClick} className="btn btn-icon  btn-primary  btn-secondary arrowbtn"><i className="fe fe-chevron-right"></i></button>
          </div>}
          {(SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
            SessionUtils.getRole() === Role.ADMINISTRATOR ||
            SessionUtils.getRole() === Role.FINANCE ||
            SessionUtils.getRole() === Role.OPERATIONS) &&
              <div className="row row-cards1">
                
                {this.state.businessType !== 'APCS'  && <div className="col-12 col-lg-2">
                  <div className="card homepage-card">
                    <div className="card-body p-3 text-center">
                      <div className="page-title">{"Revenue (" + getSymbolFromCurrency('INR') + ")"}</div>
                      {this.state.isLoading && <div className="marginTop16"><img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." /></div>}
                      {!this.state.isLoading && <div className="display-4 font-weight-bold text-teal">{this.state.todayRevenue}</div> }
                    </div>
                  </div>
                </div>}
                <div className="col-12 col-lg-2"> 
                  <div className="card homepage-card">
                    <div className="card-body p-3 text-center">
                      <div className="page-title">Passengers</div>
                      {this.state.isLoading && <div className="marginTop16"><img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." /></div>}
                      {!this.state.isLoading && <div className="text-blue display-4 font-weight-bold">{this.state.todayPassenger}</div>}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-3 homepage-col">
                  <div className="card homepage-card">
                    <div className="card-body p-3 text-center">
                      <div className="page-title">Active Conductors</div>
                      {this.state.isLoading && <div className="marginTop16"><img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." /></div>}
                      {!this.state.isLoading && <div className="display-4 font-weight-bold text-azure">{this.state.todayConductor}</div>}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-3 homepage-col">
                  <div className="card homepage-card">
                    <div className="card-body p-3 text-center">
                      <div className="page-title">Active Vehicles</div>
                      {this.state.isLoading && <div className="marginTop16"><img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." /></div>}
                      {!this.state.isLoading && <div className="display-4 font-weight-bold text-purple">{this.state.todayVehicles}</div>}
                    </div>
                  </div>
                </div>
                {this.state.businessType !== 'APCS' && <div className="col-12 col-lg-2 homepage-col">
                  <div className="card homepage-card">
                    <div className="card-body p-3 text-center">
                      <div className="page-title">Luggage</div>
                      {this.state.isLoading && <div className="marginTop16"><img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." /></div>}
                      {!this.state.isLoading && <div className="display-4 font-weight-bold text-secondary">{this.state.todayLuggageCount}</div>}
                    </div>
                  </div>
                </div>}
              </div>

          }
          {(SessionUtils.getRole() === Role.INVENTORY ||
            SessionUtils.getRole() === Role.DEVICE_PROVIDER || SessionUtils.getRole() === Role.EMPLOYEE) &&
            <div className="row row-cards card-body"></div>
          }
          {!isMobile && (SessionUtils.getRole() === Role.AFCS_ADMINISTRATOR ||
            SessionUtils.getRole() === Role.ADMINISTRATOR ||
            SessionUtils.getRole() === Role.FINANCE ||
            SessionUtils.getRole() === Role.OPERATIONS) &&
            <React.Fragment>
              <div className="row row-cards">
                  
              {this.state.businessType !== 'APCS' && <div className="col-lg-6 col-xl-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="col-md-8 ">
                          <h2 className="card-title logintextfields"><b>Cumulative Revenue</b> for last 7 days</h2>
                        </div>
                        <div className="col-md-4">
                        <h2 className="card-title float-right"><b className="btn btn-sm btn-outline-teal">{ getSymbolFromCurrency('INR').concat(this.state.revenue) }</b></h2>
                        </div>
                      </div>
                      <div className="card-body">
                      {this.state.revenueGraphData.map((chart, i) => (
                          <C3Chart key={i}
                          data={chart.data}
                          axis={chart.axis}
                          legend={{
                            show: true, //hide legend
                          }}
                          padding={{
                            bottom: 0,
                            top: 0,
                          }}
                        />
                        ))
                      }
                      </div>
                    </div>
                  </div>}
                  <div className="col-lg-6 col-xl-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="col-md-9 ">
                          <h2 className="card-title logintextfields"><b>Cumulative Passengers</b> for last 7 days</h2>
                        </div>
                        <div className="col-md-3">
                        <h2 className="card-title float-right"><b className="btn btn-sm btn-outline-blue">{this.state.passanger}</b></h2>
                        </div>
                      </div>
                      <div className="card-body">
                      {this.state.passengerGraphData.map((chart, i) => (
                        <C3Chart key={i}
                        data={chart.data}
                        axis={chart.axis}
                        legend={{
                          show: true, //hide legend
                        }}
                        padding={{
                          bottom: 0,
                          top: 0,
                        }}
                      />
                      ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="col-md-8 ">
                          <h2 className="card-title logintextfields"><b>Total Conductors</b></h2>
                        </div>
                        <div className="col-md-4">
                        <h2 className="card-title float-right"><b className="btn btn-sm btn-outline-azure">{this.state.totalConductor}</b></h2>
                        </div>
                      </div>
                      <div className="card-body">
                      {this.state.conductorGraphData.map((chart, i) => (
                        <C3Chart key={i}
                        data={chart.data}
                        axis={chart.axis}
                        legend={{
                          show: true, //hide legend
                        }}
                        padding={{
                          bottom: 0,
                          top: 0,
                        }}
                      />
                      ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="col-md-8 ">
                          <h2 className="card-title logintextfields"><b>Total Vehicles </b></h2>
                        </div>
                        <div className="col-md-4">
                        <h2 className="card-title float-right"><b className="btn btn-sm btn-outline-purple">{this.state.totalVehicles}</b></h2>
                        </div>
                      </div>
                      <div className="card-body">
                      {this.state.vehiclesGraphData.map((chart, i) => (
                        <C3Chart key={i}
                        data={chart.data}
                        axis={chart.axis}
                        legend={{
                          show: true, //hide legend
                        }}
                        padding={{
                          bottom: 0,
                          top: 0,
                        }}
                      />
                      ))}
                      </div>
                    </div>
                  </div>
                  {this.state.businessType !== 'APCS' && <div className="col-lg-12 col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="col-md-8 ">
                        <h2 className="card-title logintextfields"><b>Today's Route based Revenue</b> </h2>
                        </div>
                        <div className="col-md-4">
                          <h2 className="card-title float-right"><b className="btn btn-sm btn-outline-teal">{ getSymbolFromCurrency('INR').concat(this.state.routeTotalRevenue) }</b></h2>
                        </div>
                      </div>
                      <div className="card-body">
                      {this.state.routeGraphData.map((chart, i) => (
                          <C3Chart key={i}
                          data={chart.data}
                          axis={chart.axis}
                          legend={{
                            show: true, //hide legend
                          }}
                          padding={{
                            bottom: 0,
                            top: 0,
                          }}
                        />
                      ))}
                      </div>
                    </div>
                  </div>}
                </div>
                <Modal isOpen={this.state.addModal} toggle={this.toggleAddModal}>
                  
                  <ModalHeader toggle={this.toggleAddModal}>
                    Add a new Manufacturer
                  </ModalHeader>
                  <ModalBody>
                    
                  </ModalBody>
                  <ModalFooter>
                    <Button>Save</Button>
                  </ModalFooter>
                  
                </Modal>

            </React.Fragment>
          }
        </div>
      </div>
          {isMobile && <footer className="footer stickybottom">
            <div className="container">
              <div className="text-center"><a href="/report-issue" className="btn btn-sm btn-outline-primary text-center">Report Issue</a></div>
              <div className="col col-12 col-lg-auto mt-3 mt-lg-0 text-uppercase text-center">Copyright &copy; 2021 BusTech. All rights reserved.</div>
            </div>
          </footer>}
    </Page.Content>
      </BaseLayout>
    )
  };
}

export default HomePage;
