// @flow
import React from "react";
import { Page} from "tabler-react";
import { Alert,Button } from 'react-bootstrap';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI.js";

const producttypelist = [
    { value: 'TICKET', label: 'TICKET' },
    { value: 'PASS', label: 'PASS' }
];

class Addconcession extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            concessionname:'',
            percentage:'',
            producttype:'',
            routetypeselected:[],
            producttypeselected:[],
            routetypelist:[],
            multipleroutetype:[],
            isChecked: false,
            showmessage: false,
            responsemessage:'',
            isloading:false,
            fields: {},
            errors: {},
            agencyId:'',
            authkey: localStorage.getItem('authkey')
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }
    
    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {
            case 'concessionname': {
                let fields = this.state.fields;
                fields[type] =itemValue; 
                return this.setState({ concessionname: itemValue,fields });
            }
            case 'percentage': {
                let fields = this.state.fields;
                fields[type] =itemValue; 
                return this.setState({ percentage: itemValue,fields });
            }
            default:    
        }
    }
    
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    
    handleValidation(){
        let errors = {};
        let formIsValid = true;
        if(!this.state.concessionname){
            formIsValid = false;
            errors["concessionname"] = "Cannot be empty";
        }
        if(!this.state.percentage){
            formIsValid = false;
            errors["percentage"] = "Cannot be empty";
        }
        if(!this.state.multipleroutetype){
            formIsValid = false;
            errors["routetype"] = "Cannot be empty";
        }
        if(!this.state.producttype){
            formIsValid = false;
            errors["producttype"] = "Cannot be empty";
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    
    SubmitConcession() {
        if(this.handleValidation()){
            let obj = {};
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.name = this.state.concessionname;
            obj.percentageAmountOff = this.state.percentage;
            obj.productType = this.state.producttype;
            obj.routeTypes = this.state.routetypeselected;
            let currentComponent = this;
            currentComponent.setState({ isloading: true });

            AgencyAPI.postConcession(obj,
                (response) => {
                    currentComponent.setState({
                        responsemessage:'Concession added successfully!',
                        concessionname:'',
                        percentage:'',
                        routetypeselected:'',
                        multipleroutetype:'',
                        producttype:'',
                        isloading: false,
                        showmessage:true
                    });
                    currentComponent.loadCommentsFromServer();
                },
                (error) => {
                    currentComponent.setState({isloading: false,responsemessage:error.message,showmessage:true  });
                }
            );
        }
        return;
    }
    
    loadCommentsFromServer(){
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        AgencyAPI.getRouteType(
            (response) => {
                if (response.data.code === 2000 && response.data.message === "SUCCESS") {
                    let RoutetypeFromApi = response.data.data.map(routetype => { 
                        return { value: routetype.id, label: routetype.name };
                    });
                    currentComponent.setState({ routetypelist: RoutetypeFromApi,isLoader: false });
                } else{
                    currentComponent.setState({isLoader: false  });
                }
            }
        );
    }

    getDropdownValue(type, data){
        if(type !== null){
            if(data === 'routetype'){
                var routetypeselected = [];
                for(var i in type){
                    var element = type[i];
                    routetypeselected.push(element.value);
                }
                this.setState({multipleroutetype:type, routetypeselected: routetypeselected});
            } else if(data === 'producttype'){
                this.setState({producttype:type.value, producttypeselected: type});
            }
        } else {
            if(data === 'routetype'){
                this.setState({multipleroutetype:[], routetypeselected: []});
            } else if(data === 'producttype'){
                this.setState({producttype:"", producttypeselected: []});
            }
        }
    }

    componentDidMount() { 
        this.loadCommentsFromServer();
    }

    goBack(e) {
        this.props.history.push('/operator-setup#concession');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <div className="col-md-5">
                    <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                        <h4>{this.state.responsemessage}</h4>
                    </Alert>
                </div>
            );
        }
        
        return (
            <BaseLayout>
                <Page.Content>
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                            {fadeinout }
                            <h3 className="card-title">Concession Configration</h3>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Concession Name<span className="form-required">*</span></label>
                                                <input type="text" className="form-control" value={this.state.concessionname}   onChange={(item) => this.valid(item, 'concessionname')} placeholder="Concession name"></input>
                                                <span style={{color: "red"}}>{this.state.errors["concessionname"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Percentage<span className="form-required">*</span></label>
                                            <input type="text" className="form-control" value={this.state.percentage} onChange={(item) => this.valid(item, 'percentage')} placeholder="Percentage"></input>
                                            <span style={{color: "red"}}>{this.state.errors["percentage"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Route Types<span className="form-required">*</span></label>
                                            <Select options={ this.state.routetypelist} isMulti onChange={(item) => this.getDropdownValue(item,'routetype')} />
                                            <span style={{color: "red"}}>{this.state.errors["routetype"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Product Types<span className="form-required">*</span></label>
                                            <Select options={producttypelist} onChange={(item) => this.getDropdownValue(item, 'producttype')}  />
                                            <span style={{color: "red"}}>{this.state.errors["producttype"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label"> &nbsp; </label>
                                            <button type="button" onClick={() => this.SubmitConcession()} hidden={this.state.isloading} className="btn btn-primary">Add</button>
                                            {this.state.isloading &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            <a href="/operator-setup"> <Button type="button"  color="primary mb-2 mr-2 float-right"> View </Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default Addconcession;
