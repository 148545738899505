import API from './API';

export const FreshdeskAPI = {
    postFreshdeskTicket
}

function postFreshdeskTicket(id, callbackSuccess, callbackError) {
    API.postRidlrAPI('https://tomcat.ridlr.in/traffline-api/list/recordFeedback', id,
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            callbackError(error);
        }
    }
);
}
