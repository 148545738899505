// @flow
import * as React from "react";
import DatePicker from "react-datepicker";
import { Page,Grid} from "tabler-react";
import { Button} from 'react-bootstrap';
import BaseLayout from "../BaseLayout";
import './Formstyle.css';
import { CustomerAPI } from "../../apis/CustomerAPI";

class Adduserlist extends React.Component{

    constructor(props){
        super(props);
       
         this.state = {  
            startDate: new Date(), 
            first_name: '', 
            last_name : '',
            transToDate :'',
            Gender  :'',
            mobile_number:'',
            email :'',
            address :'',
            country :'',
            states :'',
            zip_Code : '',
            gender :'',
            search_number :'',
            responsemessage :'',
            agencyCode :'',
            idType: 'AADHAR',
            idNumber: '',
            isloading:false,
            errors:{},
            authkey: localStorage.getItem('authkey')
            
            }
        this.handlesearch = this.handlesearch.bind(this);   
        this.SearchDetails = this.SearchDetails.bind(this);   
        this.handlesubmit = this.handlesubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setGender = this.setGender.bind(this)
       }
   

    

       handlesubmit(){
        let obj = {}
        let satus = this;
        let currentComponent = this;
        currentComponent.setState({ isloading: true});
        let errors = {};
        obj.agencyCode  = localStorage.getItem('agencyname');
        obj.firstName   =  this.state.first_name;
        obj.lastName    =  this.state.last_name;
        obj.phone       =  this.state.mobile_number;
        obj.email       =  this.state.email;
        obj.dateOfBirth =  this.GetDateFormat(this.state.startDate);
        obj.address     =  this.state.address;
        obj.country     =  this.state.country;
        obj.state       =  this.state.states;
        obj.zip         =  this.state.zip_Code;
        obj.gender      =  this.state.gender;
        if (this.state.idNumber!=="") {
            obj.documentList = [
                {
                    "idType": this.state.idType,
                    "idNumber": this.state.idNumber,
                }
            ];
        }else {
            obj.documentList = [];
        }

        
        if(this.state.first_name === ''){
            errors["First_name"] = "Cannot be first_name";
            this.setState({errors: errors});
            return false;
       }else{
           CustomerAPI.postCustomer(obj,
            (response) => {
                satus.setState({
                    iscashsubmit: false,
                    showmessage :true,
                    hidesubmit:'hidesubmitbtn',
                    first_name : '',
                    last_name:'',
                    mobile_number :'',
                    email:'',
                    startDate:'',
                    address:'',
                    country:'',
                    states:'',
                    zip_Code:'',
                    idType:'',
                    idNumber:'',
                    isloading:false,
                    responsemessage:"Create User successfully. "
                });
            },
            (error) => {
                satus.setState({
                    iscashsubmit: false,
                    showmessage :true,
                    hidesubmit:'hidesubmitbtn',
                    responsemessage: error.message
                });
               return;
           
            }
           );
      }
    }

       handlesearch(data) {
        this.setState({
            search_number: data
        });

    }

    handleChange(date) {
        this.setState({
          startDate: date
        });
      }

      GetDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
     }
     setGender(event) {
     this.setState({
            gender: event.target.value
          });

        
      }

      resetForm = () => {
        this.setState({first_name:"",last_name:"",transToDate:"",gender:"",mobile_number:"",email:"",address:"",states:"",zip_Code:""});
      }

      

    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {
            case 'search_number': {
               this.setState({ search_number: itemValue })
                 break
            }
            case 'first_name': {
                this.setState({ first_name: itemValue })
                  break
             }
             case 'last_name': {
                this.setState({ last_name: itemValue })
                  break
             }
             case 'transToDate': {
                this.setState({ transToDate: itemValue })
                  break
             }
             case 'gender': {
                this.setState({ gender: itemValue })
                  break
             }
             case 'mobile_number': {
                this.setState({ mobile_number: itemValue })
                  break
             }
             case 'email': {
                this.setState({ email: itemValue })
                  break
             }
             case 'address': {
                this.setState({ address: itemValue })
                  break
             }
             case 'country': {
                this.setState({ country: itemValue })
                  break
             }
             case 'states': {
                this.setState({ states: itemValue })
                  break
             }
             case 'zip_Code': {
                this.setState({ zip_Code: itemValue })
                  break
             }
            case 'id_Type': {
                this.setState({idType: itemValue});
                break;
            }
            case 'id_Number': {
                this.setState({idNumber: itemValue});
                break;
            }
            
           
          default:    
        }
    }
    SearchDetails(){
        let errors = {};
        let searchNumber = this.state.search_number;
        let currentComponent = this;
        if(searchNumber === ''){
            errors["searchnumber"] = "Cannot be empty";
            this.setState({errors: errors});
            return false;
        }else{
            CustomerAPI.getPhoneSearch(searchNumber,
                (response) => {
                    currentComponent.setState({
                        first_name     : response.data.data.firstName,
                        last_name      : response.data.data.lastName,
                        transToDate    : response.data.data.dateOfBirth,
                        gender         : response.data.data.gender,
                        mobile_number  : response.data.data.phone,
                        email          : response.data.data.email,
                        address        : response.data.data.address,
                        country        : response.data.data.country,
                        states         : response.data.data.state,
                        zip_Code       : response.data.data.zip,
                        responsemessage :'',
                        });
                },
                (error) => {
                    return currentComponent.setState({
                        first_name     : '',
                        last_name      : '',
                        transToDate    : '',
                        gender         : '',
                        mobile_number  : '',
                        email          : '',
                        address        : '',
                        country        : '',
                        states         : '',
                        zip_Code       : '',
                        responsemessage: error.message,
                        
                        });
                
                }
            );
       }
        
    }


    render(){
        var opts = {};
        //const {gender} = this.state
        return (
            <BaseLayout>
            <Page.Content>
              <Page.Header title="User Registration" />
               
                  <div>
                  <label>Search Mobile No</label>    
                  <input type="text" name="search_number" value={this.state.search_number}   onChange={(item) => this.valid(item, 'search_number')} placeholder="Search Mobile no"  maxLength="500"/>
                  <span style={{color: "red"}}>{this.state.errors["searchnumber"]}</span>
                  <input type="submit" value="search" className="m-5" onClick={() => this.SearchDetails()}  />
                   
                   </div>
                   <h4>{this.state.responsemessage}</h4>      
                  <form>


                  <Grid.Row md={12} lg={12}>  
                                    <Grid.Col  lg={6} className="bgdetails">  
                                       
                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> 
                                            <label className="floatright" >FIRST NAME</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                            <input { ...opts} type="text" className="form-control" name="first_name" value={this.state.first_name} onChange={(item) => this.valid(item, 'first_name')} maxLength="30" ></input> </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >LAST NAME</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={(item) => this.valid(item, 'last_name')} maxLength="30" ></input> </Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Date of Birth</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"> 
                                            <DatePicker name="transFromDate"
                                            dateFormat="yyyy/MM/dd 00:00:01"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                            isClearable={true}
                                            placeholderText="I have been cleared!"
                                            className="form-control mb-2"
                                        /></Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >GENDER</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">  
                                            <label className="" >MALE</label>
                                             <input type="radio"  onChange={this.setGender} value="MALE"  ></input>
                                             <label className="" >FEMALE</label>
                                             <input type="radio" onChange={this.setGender} value="FEMALE"></input>
                                            
                                            </Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >MOBILE NUMBER</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="text" className="form-control" name="mobile_number" value={this.state.mobile_number} onChange={(item) => this.valid(item, 'mobile_number')} maxLength="10" ></input> </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Email Id</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="text" className="form-control" name="email" value={this.state.email} onChange={(item) => this.valid(item, 'email')} maxLength="50" ></input> </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >ADDRESS</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="text" className="form-control" name="address" value={this.state.address} onChange={(item) => this.valid(item, 'address')} ></input> </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >COUNTRY</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="text" className="form-control" name="country" value="INDIA" onChange={(item) => this.valid(item, 'country')} ></input> </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >STATE</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="text" className="form-control" name="states" value={this.state.states} onChange={(item) => this.valid(item, 'states')} maxLength="30" ></input> </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Zip CODE</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="text" className="form-control" name="zip_Code" value={this.state.zip_Code}  onChange={(item) => this.valid(item, 'zip_Code')} maxLength="6" ></input> </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Id Type</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px">
                                                <select { ...opts} type="text" className="form-control" name="id_Type" value={this.state.idType}  onChange={(item) => this.valid(item, 'id_Type')} >
                                                    <option>AADHAR</option>
                                                    <option>PAN</option>
                                                    <option>DRIVING_LICENSE</option>
                                                </select>
                                            </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row  lg={4} className="detailschild"  >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label className="floatright" >Id Number</label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull" bsStyle="padding:0px"><input { ...opts} type="text" className="form-control" name="id_Number" value={this.state.idNumber}  onChange={(item) => this.valid(item, 'id_Number')} maxLength="20" ></input> </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row  lg={4} className="detailschild" >
                                            <Grid.Col  lg={4} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                            <Grid.Col  lg={6} className="paddingnull">
                                                <Button type="button"  onClick={() => this.handlesubmit()}  hidden={this.state.isloading} className="btn btn-primary" >
                                                    Submit
                                                </Button> 
                                                {this.state.isloading &&
                                                    <img src="./images/download.gif"  alt="Loading..." />
                                                }
                                               <Button  type="button" color="secondary mb-2 mr-2"  onClick={this.resetForm}  >RESET</Button>
                                            </Grid.Col>
                                        </Grid.Row>

                                    </Grid.Col>       
                                </Grid.Row>
                   
                    </form>
                    </Page.Content>
                  </BaseLayout>
                  
    
             )
         
    


    }

}

 


export default Adduserlist;

