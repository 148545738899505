import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';

export const AgencyAPI = {
    getStops,
    getRoutes,
    getMemos,
    getActiveMemos,
    getDepot,
    getRouteType,
    getFareRules,
    getConfiguration,
    getVehicle,
    getCauses,
    getConcession,
    getPassConcession,
    getConcessionId,
    getDepotId,
    getRouteId,
    getFaresForRouteId,
    getRouteTypeId,
    getFareRulesLuggage,
    getFaresCardBonus,
    getAgreement,
    getAgreementFile,
    postCause,
    postConcession,
    postDepot,
    postFaresRules,
    postVehicle,
    postConfiguration,
    postStopsCreateOrFetch,
    postRoutes,
    postFares,
    postMemo,
    postFaresSuggestionMatrix,
    postRouteType,
    postStops,
    postFaresRulesLuggage,
    postFaresCardBonus,
    putConfiguration,
    putCause,
    putConcession,
    putDepot,
    putVehicle,
    putMemo,
    putRoutes,
    putFares,
    putRouteType,
    putStops,
};

function getStops(callbackSuccess, callbackError) {
    API.get('/apis/agency/stops?agencyCode=' + localStorage.getItem('agencyname') + '&limit=10000',
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch stops. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getRoutes(callbackSuccess, callbackError) {
    API.get("/apis/agency/routes?agencyCode=" + localStorage.getItem('agencyname') + "&limit=10000",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch routes. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getMemos(callbackSuccess, callbackError) {
    API.get("/apis/agency/memos?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch memos. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getActiveMemos(callbackSuccess, callbackError) {
    API.get("/apis/agency/memos?status=ACTIVE&agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch memos. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}


function getDepot(callbackSuccess, callbackError) {
    API.get("/apis/agency/depot?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch depot. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getRouteType(callbackSuccess, callbackError) {
    API.get("/apis/agency/routeType?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch route types. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getFareRules(routetypename, callbackSuccess, callbackError) {
    API.get('/apis/agency/fares/rules/' + routetypename,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch fare rules. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getConfiguration(callbackSuccess, callbackError) {
    API.get('apis/agency/configuration?agencyCode=' + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch agency configuration. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getVehicle(callbackSuccess, callbackError) {
    API.get("/apis/agency/vehicle?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch vehicles. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getCauses(callbackSuccess, callbackError) {
    API.get("/apis/agency/cause?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch causes. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getConcession(callbackSuccess, callbackError) {
    API.get("/apis/agency/concession?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch concessions. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getPassConcession(callbackSuccess, callbackError) {
    API.get("/apis/agency/concession?agencyCode=" + localStorage.getItem('agencyname') + "&productType=PASS",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch concessions for pass. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getConcessionId(id, callbackSuccess, callbackError) {
    API.get("/apis/agency/concession/" + id,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch concession. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getDepotId(id, callbackSuccess, callbackError) {
    API.get("/apis/agency/depot/" + id,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch depot. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getRouteId(id, callbackSuccess, callbackError) {
    API.get("/apis/agency/routes/" + id,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch route. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getFaresForRouteId(id, callbackSuccess, callbackError) {
    API.get("/apis/agency/fares?routeId=" + id,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch fares for route. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getRouteTypeId(id, callbackSuccess, callbackError) {
    API.get("/apis/agency/routeType/" + id,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch fares for route type. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getFareRulesLuggage(routetypename, callbackSuccess, callbackError) {
    API.get('/apis/agency/fares/rules/luggage/' + routetypename,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch fare rules for luggage. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getAgreement(callbackSuccess, callbackError) {
    API.get('/apis/agency/configuration/agreement/download/url?agencyCode=' + localStorage.getItem('agencyname') + '&agencyName='+localStorage.getItem('agencydisplayname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch File. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getAgreementFile(url, callbackSuccess, callbackError) {
    API.getBlob(url,
        (response) => {
            callbackSuccess(response);
            API.setDefaultHeaders();
        },
        (error) => {
            API.setDefaultHeaders();
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch File. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getFaresCardBonus(callbackSuccess, callbackError) {
    API.get('/apis/agency/fares/card/bonus?agencyCode=' + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch fare card bonus. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postCause(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/cause', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add cause. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postConcession(obj, callbackSuccess, callbackError) {
    API.post("/apis/agency/concession", JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add concession. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postDepot(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/depot', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add depot. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postFaresRules(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/fares/rules', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add fare rules. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postVehicle(obj, callbackSuccess, callbackError) {
    API.post("/apis/agency/vehicle", JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add vehicle. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postMemo(obj, callbackSuccess, callbackError) {
    API.post("/apis/agency/memos", JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add schedule. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}


function postConfiguration(obj, callbackSuccess, callbackError) {
    API.post("/apis/agency/configuration", JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add configuration. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postStopsCreateOrFetch(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/stops/createOrFetch', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add stops. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postRoutes(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/routes', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add routes. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postFares(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/fares', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add fares. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postFaresSuggestionMatrix(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/fares/suggestion/matrix', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add fares suggestion matrix. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postRouteType(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/routeType', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add route types. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postStops(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/stops', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add stops. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postFaresRulesLuggage(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/fares/rules/luggage/', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add fare rules luggage. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function postFaresCardBonus(obj, callbackSuccess, callbackError) {
    API.post('/apis/agency/fares/card/bonus', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add fares card bonus. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putConfiguration(id, obj, callbackSuccess, callbackError) {
    API.put("/apis/agency/configuration/" + id, JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit configuration. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putCause(id, obj, callbackSuccess, callbackError) {
    API.put("/apis/agency/cause/" + id, JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit cause. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putConcession(id, obj, callbackSuccess, callbackError) {
    
    API.put("/apis/agency/concession/"+id, JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit concession. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putDepot(id, obj, callbackSuccess, callbackError) {
   
    API.put('/apis/agency/depot/'+id, JSON.stringify(obj),
     (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit depot. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putVehicle(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/agency/vehicle/'+id, JSON.stringify(obj),
     (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit vehicle. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putMemo(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/agency/memos/'+id, JSON.stringify(obj),
     (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit schedule. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putRoutes(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/agency/routes/'+id, JSON.stringify(obj),
        (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit route. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putFares(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/agency/fares/' + id, JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit fares. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putRouteType(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/agency/routeType/'+ id, JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit routetype. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putStops(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/agency/stops/'+id, JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit stops. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}