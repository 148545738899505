import React from "react";
import { Link } from 'react-router-dom';
import { Page, Grid, Button } from "tabler-react";
import { Table, Row, Col } from 'react-bootstrap';
import Pagination from "./Pagination"
import "react-datepicker/dist/react-datepicker.css";
import { AgencyAPI } from "../apis/AgencyAPI";

const perPage = 20;
class Concession extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
      currentConcessionList: [],
      ConcessionList: [],
      RouteTypeList: [],
      concessionname: '',
      authkey: localStorage.getItem('authkey')
    };
  }

  componentDidMount() {
    let currentComponent = this;
    currentComponent.setState({ isLoader: true });
    AgencyAPI.getRouteType(
      (response) => {
        currentComponent.setState({
          RouteTypeList: response.data.data
        });
        AgencyAPI.getConcession(
          (response) => {
              currentComponent.setState({ ConcessionList: response.data.data, isLoader: false, pageCount: Math.ceil(response.data.data.length / 20) });
          },
          (error) => {
            currentComponent.setState({ isLoader: false });
          }
        );
      },
      (error) => {
        currentComponent.setState({
          isLoader: false
        });
      }
    );

  }

  onPageChanged = data => {
    const { ConcessionList } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentConcessionList = ConcessionList.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentConcessionList, totalPages });
  };

  getroutetype(type) {
    this.setState({ multipleroutetype: type });
  }

  render() {
    let paginationtransaction;
    const {
      ConcessionList,
      currentPage,
      totalPages
    } = this.state;
    const totalroutes = ConcessionList.length;
    if (this.state.pageCount > 0) {
      paginationtransaction = (
        <Pagination
          totalRecords={totalroutes}
          pageLimit={perPage}
          pageNeighbours={1}
          onPageChanged={this.onPageChanged}
        />
      );
    } else {
      paginationtransaction = (
        <div>
          No Data Found...
                </div>
      );
    }
    if (this.state.isLoader) {
      return (
        <Page.Content>
          <Grid>
            <Row className="show-grid floatright">
              <Col xsHidden md={12}>
                <a href="/add-concession"> <Button type="button" color="primary mb-2 mr-2 float-right"> Add concession </Button></a>
              </Col>
            </Row>
          </Grid>
          <Page.Header title="Concession List" />
          <React.Fragment>
            <Table striped bordered condensed hover >
              <thead>
                <tr>
                  <th >Concession Name</th>
                  <th >Percentage</th>
                  <th >Product Type</th>
                  <th >Route Types</th>
                  <th >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr><td colSpan="5" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
              </tbody>
            </Table>
            {paginationtransaction}
          </React.Fragment>
        </Page.Content>
      );
    } else {
      let currentComponent = this;
      return (
        <Page.Content>
          <Grid>
            <Row className="show-grid floatright">
              <Col xsHidden md={12}>
                <a href="/add-concession"> <Button type="button" color="primary mb-2 mr-2 float-right"> Add concession </Button></a>
              </Col>
            </Row>
          </Grid>
          <Page.Header title="Concession List" />
          <React.Fragment>
            <Table striped bordered condensed hover >
              <thead>
                <tr>
                  <th >Concession Name</th>
                  <th >Percentage</th>
                  <th >Product Type</th>
                  <th >Route Types</th>
                  <th >Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.currentConcessionList.map(function (obj, i) {
                  let routetypename = '';
                  let routetypearr = [];
                  if (obj.routeTypes !== null) {
                    for (var j = 0; j < obj.routeTypes.length; j++) {
                      var objs = obj.routeTypes[j];
                      for (var k = 0; k < currentComponent.state.RouteTypeList.length; k++) {
                        var element = currentComponent.state.RouteTypeList[k];
                        if (element.id === objs) {
                          routetypearr.push(element.name);
                        }
                      }
                    };
                  }
                  routetypename = routetypearr.join(", ");
                  return (
                    <tr key={i}>
                      <td>
                        {obj.name}
                      </td>
                      <td >
                        {obj.percentageAmountOff}
                      </td>
                      <td >
                        {obj.productType}
                      </td>
                      <td >
                        {routetypename}
                      </td>
                      <td>
                        <Link to={{ pathname: `/edit-concession/${obj.id}`, state: { cause: obj } }} className="active"><i className="fe fe-edit"></i></Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {paginationtransaction} {currentPage && (
              <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                <span className="font-weight-bold">{totalPages}</span>
              </span>
            )}
          </React.Fragment>
        </Page.Content>
      );
    }
  }
}
export default Concession;
