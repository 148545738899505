// @flow
import React from "react";
import { Page } from "tabler-react";
import BaseLayout from "./BaseLayout";
import { Alert } from 'react-bootstrap';
import "./alert.js";
import "../App.css";
import { AgencyAPI } from "../apis/AgencyAPI";

class PassBonusList extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showMessage: false,
            responseMessage :'',
            isLoading:false,
            filterText: '',
            bonusList: [],
            fields: {},
            errors: {},
            authkey: localStorage.getItem('authkey'),
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }
   
    componentDidMount(){
        this.fetchBonusList();
    }
    
    fetchBonusList(){
        let currentComponent = this;
        AgencyAPI.getFaresCardBonus(
            (response) => {
                currentComponent.fillBonusDetails(response.data.data.fareList);
            }
        );
    }
  
    fillBonusDetails(bonusData){
        let item;
        // eslint-disable-next-line
        this.state.bonusList = [];
        for (var i=0; i < bonusData.length; i++) {
            var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
            item={
                id      : id,
                passAmount : bonusData[i].amount,
                passBonusAmount   : bonusData[i].bonusAmount,
                expirationDays    : bonusData[i].expirationDays
            };
            this.state.bonusList.push(item);
        }
        return this.setState({bonusList: this.state.bonusList});
    }

    submit(){
        if(this.handleValidation()){
            this.setState({isLoading: true});
            let item;
            // eslint-disable-next-line
            let rows = [];
            this.state.bonusList.map(element => {
                //Set data in array wchich api want
                item = {
                  amount :  parseInt(element.passAmount, 10),
                  bonusAmount : parseInt(element.passBonusAmount, 10),
                  expirationDays : parseInt(element.expirationDays, 10)
                };
                return rows.push(item);
            });
            let obj = {};
            let currentComponent = this;
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.fareList = rows;
            
            //Create Fare Rules 
            AgencyAPI.postFaresCardBonus(obj,
                (response) => {
                        currentComponent.setState({
                            showMessage: true,
                            isLoading: false,
                            bonusList:response.data.data.fareList,
                            responseMessage:'Bonus Updated!'
                        });
                        currentComponent.fetchBonusList();
                    
                },
                (error) => {
                    currentComponent.setState({
                        showMessage: true,
                        isLoading: false,
                        bonusList:[],
                        responseMessage:error.message
                    });
                    currentComponent.fetchBonusList();
                    return;
                
                }
            );
        }
        return;
    }
    
    handleValidation(){
        let formIsValid = true;
        if(Object.keys(this.state.errors).length !== 0){
            if(this.state.errors["passAmount"] !== "" || this.state.errors["passBonusAmount"] !== "" || this.state.errors["expirationDays"] !== ""){
                formIsValid = false;
            }
        }
        return formIsValid;
    }

    handleUserInput(filterText) {
        this.setState({filterText: filterText});
    };
    
    handleRowDel(product) {
        var index = this.state.bonusList.indexOf(product);
        this.state.bonusList.splice(index, 1);
        this.setState({bonusList: this.state.bonusList});
    };
    
    handleAddEvent(evt) {
        let product;
        let errors = {};
        if(this.handleValidation()){
            errors["passAmount"] = "";
            errors["passBonusAmount"] = "";
            errors["expirationDays"] = "";
            var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
            product = {
              id: id,
              passAmount: "",
              passBonusAmount: "",
              expirationDays: ""
            };
            this.state.bonusList.push(product);
            this.setState({bonusList: this.state.bonusList, errors: errors});
        }
    }
    
    handleDismiss() {
        this.setState({ showMessage: false });
    }
    
    handleProductTable(evt) {
        var item;
        item = {
            id: evt.target.id,
            name: evt.target.name,
            value: evt.target.value
        };
        let errors = {};
        errors["id"] = item.id;
        errors["passAmount"] = "";
        errors["passBonusAmount"] = "";
        errors["expirationDays"] = "";
        const re = /^[0-9\b]+$/;
        if((item.name === 'passBonusAmount' || item.name === 'expirationDays') && !re.test(item.value))  {
          return false;
        }              
        var products = this.state.bonusList.slice();
        var newProducts = products.map((product, index) => {
            for (var key in product) {
                if (key === item.name && product.id === item.id) {
                    if(key === "passAmount" && item.value.length >= 3) {
                      item.value = Math.floor(item.value/100) * 100;
                      product[key] = item.value;
                    }
                    if(item.value !== ""){
                        item.value = parseInt(item.value, 10);
                    }
                    product[key] =  item.value;
                }
                
            }
            return product;
        });
        this.setState({products:newProducts, errors: errors});
    };
    
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showMessage) {
            fadeinout = (
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responseMessage}</h4>
                </Alert>
            )
        }
        return (
          <BaseLayout>
        <Page.Content>
                {fadeinout}
                <Page.Header title="Cash Card Pass Bonus" />
                <React.Suspense fallback={<div>Loader...</div>}>
                    <div className="card">
                        <div className="card-footer">
                            <div className="row">
                                <ProductTable errors={this.state.errors} onProductTableUpdate={this.handleProductTable.bind(this)} onRowAdd={this.handleAddEvent.bind(this)} onRowDel={this.handleRowDel.bind(this)} products={this.state.bonusList} filterText={this.state.filterText}/>
                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <button type="button" onClick={() => this.submit()} hidden={this.state.isLoading} className="btn btn-primary">Save & Update
                            
                          </button>
                          {this.state.isLoading &&
                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                            }
                        </div>
                    </div>
                </React.Suspense>  
            </Page.Content>
            </BaseLayout>
        );
    }
}

class ProductTable extends React.Component {

    render() {

        var onProductTableUpdate = this.props.onProductTableUpdate;
        var rowDel = this.props.onRowDel;
        var errors = this.props.errors;
        var product = this.props.products.map(function(product) {
            return (<ProductRow onProductTableUpdate={onProductTableUpdate} product={product} onDelEvent={rowDel.bind(this)} errors={errors} key={product.id}/>);
        });
        return (
            <div>
                <table  className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Pass Amount</th>
                            <th>Bonus Amount </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product}
                    </tbody>
                </table>
                <button type="button" onClick={this.props.onRowAdd} className="btn btn-primary pull-right">Add Bonus</button>
            </div>
        );
    }
}

class ProductRow extends React.Component {
  
    onDelEvent() {
        this.props.onDelEvent(this.props.product);
    }
    
    render() {
      
        return (
            <tr className="eachRow">
                <MultiplesOf100EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "passAmount",
                    value: this.props.product.passAmount,
                    id: this.props.product.id,
                    readonly: false
                }}/>
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "passBonusAmount",
                    value: this.props.product.passBonusAmount,
                    id: this.props.product.id,
                    readonly: false
                }}/>
                <td className="del-cell">
                    <i onClick={this.onDelEvent.bind(this)} className="icon fe fe-trash"></i>
                </td>
            </tr>
        );
    }
}

class EditableCell extends React.Component {

    render() {
        return (
            <td>
                <input className="form-control" type='number' min="0" readOnly={this.props.cellData.readonly} apistopid ={this.props.cellData.apistopid} name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} required /><br/>
                <span style={{color: "red"}}>
                    {this.props.errors.id === this.props.cellData.id && 
                        this.props.errors[this.props.cellData.type]
                    }
                </span>
            </td>
        );
    }
}

class MultiplesOf100EditableCell extends React.Component {

  render() {
      return (
          <td>
              <input className="form-control" type='number' min="0" step="100" readOnly={this.props.cellData.readonly} apistopid ={this.props.cellData.apistopid} name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.props.onProductTableUpdate} required /><br/>
              <span style={{color: "red"}}>
                  {this.props.errors.id === this.props.cellData.id && 
                      this.props.errors[this.props.cellData.type]
                  }
              </span>
          </td>
      );
  }
}

export default PassBonusList;