import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import { ClevertapReact } from './CleverTap/ClevertapReact';
ReactGA.initialize('UA-144027048-1'); // Here we should use our GA id
if(window.location.origin === 'http://afcs.ridlr.in'){
    ReactGA.pageview(window.location.pathname, [localStorage.getItem("totalDevices")+""]);
    ClevertapReact.initialize("4R4-95Z-765Z");
    Sentry.init({dsn: "https://c9d522c6c8fe4c20bc574109a4798506@sentry.io/1543123"});
} else {
    ClevertapReact.initialize("TEST-5R4-95Z-765Z");
    Sentry.init({dsn: "https://4a2e828b918f4edaa7bbb958b22fe273@sentry.io/1541875"});
}
if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function(registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function(err) {
        console.log("Service worker registration failed, error:", err);
      });
  }
ReactDOM.render(<App /> , document.getElementById('root'));
registerServiceWorker();
