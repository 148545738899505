import API from '../apis/API'
import SessionUtils from "../utils/SessionUtils";
import { BehaviorSubject } from 'rxjs';
import { ClevertapReact } from '../CleverTap/ClevertapReact';
import { AgencyAPI } from '../apis/AgencyAPI';
import { EmployeesAPI } from '../apis/EmployeesAPI';
import { AuthAPI } from '../apis/AuthAPI';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const userService = {
  login,
  register,
  createAFCSDatabase,
  forgotPassword,
  resetPassword,
  changePassword,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue () { return currentUserSubject.value }
};

function createAFCSDatabase(db) {
  const dbName = "RIDLR AFCS";
  // Remove all saved data from database
  indexedDB.deleteDatabase(dbName);
  var request = indexedDB.open(dbName, 1);
  // these two event handlers act on the IDBDatabase object,
  // when the database is opened successfully, or not
  request.onerror = function (event) {
    console.log(event);
  };
  request.onupgradeneeded = function (event) {
    db = event.target.result;
    db.onerror = function (event) {
      console.log(event);
    };

    db.createObjectStore("memos", { keyPath: "id" });
    db.createObjectStore("routes", { keyPath: "agencyRouteId" });
    var objectStorestop = db.createObjectStore("stops", { keyPath: "id" });
    objectStorestop.createIndex("shortName", "shortName", { unique: false, multiEntry: true });
    db.createObjectStore("depot", { keyPath: "id" });
    db.createObjectStore("conductor", { keyPath: "employeeId" });
    db.createObjectStore("passes", { keyPath: "employeeId" });

  };
  request.onsuccess = function (event) {
    db = event.target.result;
    fetchStopsToDb(db);
    fetchEmployeesToDb(db);
    fetchRoutesToDb(db);
    fetchMemosToDb(db);
    fetchDepotToDb(db);
  };
}

function fetchStopsToDb(db) {
  AgencyAPI.getStops(
    (response) => {
      let stopsFromApi = response.data.data.map((stops) => {
        return { id: stops.id, agencyStopId: stops.agencyStopId, shortName: stops.shortName, longName: stops.longName, latitude: stops.latitude, longitude: stops.longitude, createdAt: stops.createdAt, status: stops.status, parent: stops.parent };
      });
      // Store values in the newly created objectStore.
      var stopObjectStore = db.transaction("stops", "readwrite").objectStore("stops");
      stopObjectStore.clear();
      stopsFromApi.forEach(function (stops) {
        stopObjectStore.add(stops);
      });
    }
  );
}

function fetchEmployeesToDb(db) {
  EmployeesAPI.getEmployees(
    (response) => {
      let ConductorDataFromApi = response.data.data.content.map((conductor) => {
        return { employeeId: conductor.employeeId, employeeCode: conductor.employeeCode, name: conductor.name, depot: conductor.depots, designation: conductor.designation, commissionPercentage: conductor.commissionPercentage, role: conductor.role, status: conductor.status };
      });

      // Store values in the newly created objectStore.
      var conductorObjectStore = db.transaction("conductor", "readwrite").objectStore("conductor");
      conductorObjectStore.clear();
      ConductorDataFromApi.forEach(function (conductor) {
        conductorObjectStore.add(conductor);
      });
      return;
    }
  );
}

function fetchRoutesToDb(db) {
  AgencyAPI.getRoutes(
  (response) => {
      let RouteDataFromApi = response.data.data.map((route) => {
        return { id: route.id, agencyRouteId: route.agencyRouteId, shortName: route.shortName, stops: route.stops, status: route.status, createdAt: route.createdAt, depot: route.depot, parent: route.parent };
      });
      // Store values in the newly created objectStore.
      var routeObjectStore = db.transaction("routes", "readwrite").objectStore("routes");
      routeObjectStore.clear();
      RouteDataFromApi.forEach(function (route) {
        routeObjectStore.add(route);
      });
      return;
    }
  );
}

function fetchMemosToDb(db) {
  AgencyAPI.getMemos(
    (response) => {
      let MemosIdFromApi = response.data.data.map((memos) => {
        return { id: memos.id, agencyMemoId: memos.agencyMemoId };
      });
      // Store values in the newly created objectStore.
      var memosObjectStore = db.transaction("memos", "readwrite").objectStore("memos");
      memosObjectStore.clear();
      MemosIdFromApi.forEach(function (memos) {
        memosObjectStore.add(memos);
      });
      return;
    }
  );
}

function fetchDepotToDb(db) {
  AgencyAPI.getDepot(
    (response) => {
      let DepotIdFromApi = response.data.data.map((depot) => {
        return { id: depot.id, depotname: depot.name, depotCode: depot.depotCode };
      });
      // Store values in the newly created objectStore.
      var depotObjectStore = db.transaction("depot", "readwrite").objectStore("depot");
      depotObjectStore.clear();
      DepotIdFromApi.forEach(function (depot) {
        depotObjectStore.add(depot);
      });
      return;
    }  
  );
}

function login(username, password, callbackSuccess, callbackError) {
  let obj = {};
  obj.username = username;
  obj.password = password;

  AuthAPI.postAuth(obj,
    (response) => {
      var db;
      let authorization = "Bearer " + response.data.data.authToken;
      // Save data to localStorage
      localStorage.setItem('authkey', authorization);
      SessionUtils.setRole(authorization);
      localStorage.setItem('refertoken', "Bearer " + response.data.data.refreshToken);
      localStorage.setItem('agencyname', response.data.data.agencyCode);
      localStorage.setItem('agencydisplayname', response.data.data.agencyName);
      localStorage.setItem('employeeName', response.data.data.employeeName);
      
      API.updateAuthorizationHeader();
      
      createAFCSDatabase(db);

      let currentUser1 = response.data.data;
      currentUser1.role = SessionUtils.getRole();
      localStorage.setItem('currentUser', JSON.stringify(currentUser1));
      currentUserSubject.next(currentUser1);
      callbackSuccess(response);
    },
    (error) => {
      callbackError(error);
    }
  );
  
}

function register(default_authorization, agency, email, password) {
  let obj = {};
  obj.agencyCode = agency.toUpperCase();
  let agencyCode = obj.agencyCode;
  obj.depotCode = "DEFAULT";
  obj.name = "DEFAULT";
  return API.postAuthorisation('/apis/agency/depot/', default_authorization, JSON.stringify(obj),
    (response) => {
      if (response.data.message === 'SUCCESS') {
        let depotid = response.data.data.id;
        let obj1 = {};
        obj1.agencyCode = agencyCode;
        obj1.employeeCode = email;
        obj1.name = agencyCode;
        obj1.password = password;
        obj1.depots = [depotid];
        obj1.designation = "MANAGER";
        obj1.role = "ADMINISTRATOR";
        obj1.status = "ACTIVE";
        return API.postAuthorisation('/apis/employees',default_authorization, JSON.stringify(obj1),
          (response) => {
            if (response.data.message === 'SUCCESS') {
              let obj2 = {};
              obj2.username = email;
              obj2.password = password;
              return API.postNoAuthorisation('/apis/auth/', JSON.stringify(obj2),
                (response) => {
                  if (response.data.message === 'SUCCESS') {
                    let new_authorization = "Bearer " + response.data.data.authToken;
                    let obj3 = {};
                    obj3.agencyStopId = "1";
                    obj3.agencyCode = agencyCode;
                    obj3.shortName = "DEFAULT_STOP";
                    obj3.longName = "DEFAULT_STOP";
                    obj3.latitude = "0.0";
                    obj3.longitude = "0.0";
                    return API.postAuthorisation('/apis/agency/stops', new_authorization, JSON.stringify(obj3),
                      (response) => {
                        if (response.data.message === 'SUCCESS') {
                          let stopId = response.data.data.id;
                          let obj4 = {};
                          obj4.agencyRouteId = 1;
                          obj4.agencyCode = agencyCode;
                          obj4.shortName = "DEFAULT_ROUTE";
                          obj4.longName = "DEFAULT_ROUTE";
                          obj4.type = "0-Ordinary";
                          let obj5 = {};
                          obj5.stopId = stopId;
                          obj5.distanceFromPreviousStop = 0;
                          obj4.stops = [obj5];
                          obj4.depot = depotid;
                          obj4.type = "0-Ordinary";
                          API.post('/apis/agency/routes', new_authorization, JSON.stringify(obj4),
                            (response) => {
                              if (response.data.message === 'SUCCESS') {
                                let routeId = response.data.data.id;
                                let obj6 = {};
                                obj6.agencyCode = agencyCode;
                                obj6.agencyMemoId = "DEFAULT";
                                let obj7 = {};
                                obj7.routeId = routeId;
                                obj7.routeDirection = "UP";
                                obj7.startTime = "00:00:00";
                                obj7.endTime = "00:00:00";
                                obj6.trips = [obj7];
                                API.postAuthorisation('/apis/agency/memos', new_authorization, JSON.stringify(obj6),
                                  (response) => {
                                    if (response.data.message === 'SUCCESS') {
                                      return response.data.data.agencyMemoId;
                                    } else {
                                      const error = (response.data.message);
                                      return Promise.reject(error);
                                    }
                                  }
                                );
                              } else {
                                const error = (response.data.message);
                                return Promise.reject(error);
                              }
                            }
                          );
                        } else {
                          const error = (response.data.message);
                          return Promise.reject(error);
                        }
                      }
                    );
                  } else {
                    const error = (response.data.message);
                    return Promise.reject(error);
                  }
                },
              );
            } else {
              const error = (response.data.message);
              return Promise.reject(error);
            }
          }
        );
      } else {
        const error = (response.data.message);
        return Promise.reject(error);
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  //End Here //
}

function forgotPassword(email, callbackSuccess, callbackError) {
  let obj = {};
  obj.email = email;

  API.postNoAuthorisation('/apis/employees/password/forgot', JSON.stringify(obj),
    (response) => {
      callbackSuccess(response);
    },(error) => {
      callbackError(error);
    }
  );
}

function resetPassword(resetPwdToken, newPassword, callbackSuccess, callbackError) {
  let obj = {};
  obj.oldPassword = "";
  obj.newPassword = newPassword;
  API.postAuthorisation('/apis/employees/password/reset',  "Bearer " + resetPwdToken, JSON.stringify(obj),
    (response) => {
      callbackSuccess(response);
    },
    (error) => {
      callbackError(error);
    }
  );
}

function changePassword(oldPassword, newPassword, callbackSuccess, callbackError) {
  let obj = {};
  obj.oldPassword = oldPassword;
  obj.newPassword = newPassword;
  return EmployeesAPI.postPasswordReset(obj, 
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        callbackError(error);
    }
  );
}


function logout() {

  // remove user from local storage to log user out
  localStorage.removeItem('authkey');
  localStorage.removeItem('refertoken');
  localStorage.removeItem('agencyname');
  localStorage.removeItem('agencydisplayname');
  localStorage.removeItem('employeeName');
  localStorage.removeItem('role');
  localStorage.removeItem('currentUser');
  localStorage.removeItem('parentAgency');
  localStorage.removeItem('parent');
  ClevertapReact.logout();
  ClevertapReact.clear();
  currentUserSubject.next(null);
}