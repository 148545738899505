// @flow
import React from "react";
import { Page, Grid, Button } from "tabler-react";
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import DateUtils from "../utils/DateUtils";
import { isMobile } from 'react-device-detect';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { AnalyticsAPI } from "../apis/AnalyticsAPI.js";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Row, Col } from 'react-bootstrap';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const { ExportCSVButton } = CSVExport;

const options = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true
};
const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '300px'
}


function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {filterElement}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
                {column.text}
                {sortElement}
            </div>

        </div>
    );
}

class APCSTripDetailsReport extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            fields: {},
            errors: {},
            isLoadingReport: false,
            msgHandler: null,
            data: [],
            isFilterExpanded: true,
            summaryData: [],
            mapData: null,
            showInfoWindow: false,
            activeMarker:{},
            open: false,
            enableLiveLocation: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleShowMessageHandler = this.handleShowMessageHandler.bind(this);
        this.goToVideoDetails = this.goToVideoDetails.bind(this);
        this.onInfoWindowClose = this.onInfoWindowClose.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.loadMapData = this.loadMapData.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    loadMapData() {
        let deviceSerialId = this.state.summaryData.length > 0 && this.state.summaryData[0].apcsDeviceSerialId !== null ? this.state.summaryData[0].apcsDeviceSerialId : "1234";
        AnalyticsAPI.getApcsLiveLocationReport(deviceSerialId,
            (response) => {
                this.setState({
                    isLoadingMap: false,
                    mapData: response.data.data[0],
                    open: true
                });
            },
            (error) => {
                this.setState({ isLoadingMap: false, open:false });
            });
    }

    loadData() {
        this.setState({ isLoadingReport: true })
        AnalyticsAPI.getApcsTripDetailsReport(this.props.location.state.waybill,
            this.props.location.state.trip,
            (response) => {
                this.setState({
                    enableLiveLocation:true,
                    isLoadingReport: false,
                    data: response.data.data.report.data,
                    summaryData: response.data.data.summary.data
                });
                
            },
            (error) => {
                this.setState({ isLoadingReport: false });
            });
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleChangeEnd(date) {
        this.setState({
            endDate: date
        });
    }

    onMarkerClick = (props, marker) =>{
        this.setState({ showInfoWindow: true, activeMarker:marker });
    }

    onMapClick() {
        this.setState({ showInfoWindow: false,activeMarker: null });
    }

    getDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    handleShowMessageHandler(msgHandler) {
        this.setState({ msgHandler: msgHandler });
    }

    handleExpandClick(selectedCard, index) {
        const newIds = this.state.data.slice(); //copy the array
        newIds[index].expanded = !selectedCard.expanded; //execute the manipulations
        this.setState({ data: newIds });
    }

    componentDidMount() {
        let currentComponent = this;
        currentComponent.loadData();
    }

    handleClickOpen(card, showMessage) {
        let currentComponent = this;
        currentComponent.setState({ selectedCard: card, open: true });
        return;
    }

    handleClose() {
        let currentComponent = this;
        currentComponent.setState({ open: false });
    };

    goToVideoDetails(card) {
        
    }

    goToSurvellianceReport() {
        this.props.history.push({
            pathname: '/apcs-trip-survelliance-report',
            state: {
                waybill: this.props.location.state.waybill,
                trip: this.props.location.state.trip,
                deviceNumber: this.state.summaryData.length > 0 ? this.state.summaryData[0].deviceSerialId : "", busNumber: this.state.summaryData.length > 0 ? this.state.summaryData[0].vehicleNumber : ""
            }
        });
    }

    onInfoWindowClose() {
    }



    render() {
        const { columns = [{
            dataField: 'stopName',
            text: 'STOP NAME',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'apcsBoardCount',
            text: 'APCS IN',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'apcsAlightCount',
            text: 'APCS OUT',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'afcsBoardCount',
            text: 'AFCS IN',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'afcsAlightCount',
            text: 'AFCS Out',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'occupancy',
            text: 'Passengers In Bus',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'action',
            text: 'DETAILS',
            csvExport: false,
            headerStyle: (colum, colIndex) => {
                return { width: '110px', textAlign: 'center' };
            },
            headerFormatter: headerFormatter,
            isDummyField: true,
            formatExtraData: this,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div>
                        {row.videoAssets !== null && row.videoAssets.map((card, i) => {
                            return <Button key={card.videoId} type="button" onClick={()=>formatExtraData.goToVideoDetails(card) } className="btn btn-primary videoBtn">Video {i+1}</Button>
                        })}
                    </div>
                );
            }
        }] } = this.state;
        const { summaryColumns = [{
            dataField: 'tripNumber',
            text: 'TRIP NUMBER',
            headerFormatter: headerFormatter
        }, {
            dataField: 'apcsBoardCount',
            text: 'APCS IN',
            headerFormatter: headerFormatter
        }, {
            dataField: 'apcsAlightCount',
            text: 'APCS OUT',
            headerFormatter: headerFormatter
        }, {
            dataField: 'afcsBoardCount',
            text: 'AFCS IN',
            headerFormatter: headerFormatter
        }, {
            dataField: 'afcsAlightCount',
            text: 'AFCS Out',
            headerFormatter: headerFormatter
        }] } = this.state;

        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="APCS Trip Details Report" />
                    <div className="row row-cards">
                        <div className="col-md-4 col-lg-4">
                            <div className="cash-collection-card-label">TRIP NUMBER:
                            <span className="cash-collection-card-heading">{this.props.location.state.trip}</span></div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="cash-collection-card-label">DEVICE ID: <span className="cash-collection-card-heading">{this.state.summaryData.length > 0 ? this.state.summaryData[0].deviceSerialId : ""}</span></div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="cash-collection-card-label">BUS NUMBER: <span className="cash-collection-card-heading">{this.state.summaryData.length > 0 ? this.state.summaryData[0].vehicleNumber : ""}</span></div>
                        </div>
                    </div>
                    <br />
                    {!isMobile && <Grid>
                        <Row className="show-grid floatright paddingTop15">
                            <Col xsHidden md={6}>
                                <Button type="button" color="primary mb-2 mr-2 float-right" onClick={this.loadMapData}> Live Location </Button>
                            </Col>
                            <Col xsHidden md={2}>
                                <Button type="button" color="primary mb-2 mr-2 float-right"> CCTV </Button>
                            </Col>
                            <Col xsHidden md={4}>
                                <Button type="button" color="primary mb-2 mr-2 float-right" onClick={() => { this.goToSurvellianceReport() }}> Survelliance </Button>
                            </Col>
                        </Row>
                    </Grid>}
                    {isMobile && <Grid>
                        <Row className="show-grid floatright paddingTop15">
                            <Col xsHidden>
                                <Button type="button" color="primary mb-2 mr-2 float-right" onClick={this.loadMapData}> Live Location </Button>
                            </Col>
                            <Col xsHidden>
                                <Button type="button" color="primary mb-2 mr-2 float-right"> CCTV </Button>
                            </Col>
                            <Col xsHidden>
                                <Button type="button" color="primary mb-2 mr-2 float-right" onClick={() => this.goToSurvellianceReport()}> Survelliance </Button>
                            </Col>
                        </Row>
                    </Grid>}
                    <br />
                    {!isMobile && <div><br /><br /></div>}
                    {this.state.msgHandler}
                    {!isMobile &&
                        <div className="col-12">
                            <div className="paddingTop15">
                                <BootstrapTable
                                    bootstrap4
                                    keyField="dutyId"
                                    data={this.state.summaryData}
                                    columns={summaryColumns}
                                    bordered={false}
                                    noDataIndication="No Data Found" />
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="paddingTop15">
                                <ToolkitProvider
                                    keyField="stopName"
                                    data={this.state.data}
                                    columns={columns}
                                    exportCSV={{ fileName: "APCS-TRIP-DETAILS-" + this.props.location.state.waybill + "-" + this.props.location.state.trip + "-REPORT_".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
                                >
                                    {
                                        props => (
                                            <div>
                                                <BootstrapTable
                                                    {...props.baseProps} bootstrap4
                                                    keyField="stopName"
                                                    data={this.state.data}
                                                    columns={columns}
                                                    bordered={false}
                                                    pagination={paginationFactory(options)}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data Found" />
                                                <hr />
                                                <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    }
                    {isMobile && <div>
                        <br />
                        <br />
                        <br />
                        <div className="cards-list">
                            <Card className="cash-collection-card">
                                <CardContent className="cash-collection-card-content">
                                    <div className="cash-collection-card-header">
                                        <div className="cash-collection-row">
                                            <div className="cash-collection-header-row">
                                                <div className="cash-collection-card-label">TRIP NUMBER</div>
                                                <div className="cash-collection-card-heading">{this.state.summaryData.length > 0 ? this.state.summaryData[0].tripNumber : ""}</div>
                                            </div>
                                        </div>
                                        <div className="cash-collection-row">
                                            <div className="cash-collection-column">
                                                <div className="cash-collection-card-label">APCS IN</div>
                                                <div className="cash-collection-card-text">{this.state.summaryData.length > 0 ? this.state.summaryData[0].apcsBoardCount : ""}</div>
                                            </div>
                                            <div className="cash-collection-column">
                                                <div className="cash-collection-card-label">APCS OUT</div>
                                                <div className="cash-collection-card-text">{this.state.summaryData.length > 0 ? this.state.summaryData[0].apcsAlightCount : ""}</div>
                                            </div>
                                            <div className="cash-collection-column">
                                                <div className="cash-collection-card-label">AFCS IN</div>
                                                <div className="cash-collection-card-text-bold">{this.state.summaryData.length > 0 ? this.state.summaryData[0].afcsBoardCount : ""}</div>
                                            </div>
                                            <div className="cash-collection-column">
                                                <div className="cash-collection-card-label">AFCS OUT</div>
                                                <div className="cash-collection-card-text-bold">{this.state.summaryData.length > 0 ? this.state.summaryData[0].afcsAlightCount : ""}</div>
                                            </div>
                                        </div>
                                    </div>

                                </CardContent>
                            </Card>
                        </div>
                        {(this.state.data !== null && this.state.data !== undefined && this.state.data.length > 0) && <div className="cash-collection-click-label">Click to view more details</div>}
                        <div className="cards-list">
                            {this.state.data.map((card, i) => {
                                return <Card className="cash-collection-card" onClick={() => this.handleExpandClick(card, i)}
                                    key={card.stopName}
                                    card={card}
                                >
                                    <CardContent className="cash-collection-card-content">
                                        <div className="cash-collection-card-header">
                                            <div className="cash-collection-row">
                                                <div className="cash-collection-header-row">
                                                    <div className="cash-collection-card-label">STOP NAME</div>
                                                    <div className="cash-collection-card-heading">{card.stopName}</div>
                                                </div>
                                                <div className="cash-collection-card-arrow">
                                                    {!card.expanded && <i className="fe fe-chevron-down"></i>}
                                                    {card.expanded && <i className="fe fe-chevron-up"></i>}
                                                </div>
                                            </div>
                                            <div className="cash-collection-row">
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">APCS IN</div>
                                                    <div className="cash-collection-card-text">{card.apcsBoardCount}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">APCS OUT</div>
                                                    <div className="cash-collection-card-text">{card.apcsAlightCount}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">PASSENGERS</div>
                                                    <div className="cash-collection-card-text-bold">{card.apcsPassengers}</div>
                                                </div>
                                            </div>
                                            {card.expanded && <div className="cash-collection-row">
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">AFCS IN</div>
                                                    <div className="cash-collection-card-text">{card.afcsBoardCount}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">AFCS OUT</div>
                                                    <div className="cash-collection-card-text">{card.afcsAlightCount}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">Leakage</div>
                                                    <div className="cash-collection-card-text">{card.leakage}</div>
                                                </div>
                                            </div>}
                                        </div>
                                    </CardContent>
                                    {card.expanded && <CardActions className="cash-collection-card-actions">
                                        {card.videoAssets !== null && card.videoAssets.map((card, i) => {
                                            return <Button key={card.videoId} type="button" onClick={()=>this.goToVideoDetails(card) } className="btn btn-primary videoBtnMobile">Video {i+1}</Button>
                                        })}
                                    </CardActions>}
                                </Card>
                            })}

                        </div>
                    </div>
                    }
                    {this.state.mapData !== null &&
                    <Dialog open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <DialogContent>
                    <div className="cash-collection-click-label">Updated At: {DateUtils.getFormattedDateTimeFromTimestamp(this.state.mapData.updatedAt)}</div>
                            <div style={{ height: '300px', width: '500px' }}>
                                <Map onClick={this.onMapClick}
                                    initialCenter={{
                                        lat: this.state.mapData.latitude, lng: this.state.mapData.longitude
                                    }}
                                    containerStyle={containerStyle} google={this.props.google} zoom={14}>
                                    <Marker onClick={this.onMarkerClick}
                                        position={{ lat: this.state.mapData.latitude, lng: this.state.mapData.longitude }}
                                        name="Location"
                                        title={this.state.mapData.address}>
                                        
                                    </Marker>
                                    <InfoWindow
                                            marker={this.state.activeMarker}
                                            visible={this.state.showInfoWindow}
                                            onClose={this.onInfoWindowClose}>
                                            <div> 
                                                <h1>{this.state.mapData.address}</h1>
                                            </div>
                                        </InfoWindow>
                                </Map>

                        </div>
                    </DialogContent>
                </Dialog>}
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyDFGKMVgxqrF-DIrDZIq5QNDgzWMAd_8LY")
})(APCSTripDetailsReport)