import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';

export const CustomerAPI = {
    getPhoneSearch,
    postCustomer
}

function getPhoneSearch(phoneNumber, callbackSuccess, callbackError) {
    API.get("/apis/customer/phone/"+ phoneNumber + "?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch customer for phone number. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postCustomer(obj, callbackSuccess, callbackError) {
    API.post('/apis/customer/', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add customer. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}