import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';

export const EmployeesAPI = {
    getEmployees,
    getEmployeeInspectorCard,
    postPasswordReset,
    postEmployee,
    putEmployee
}

function getEmployees(callbackSuccess, callbackError) {
    API.get("/apis/employees?agencyCode=" + localStorage.getItem('agencyname') + "&size=10000",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch employees. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getEmployeeInspectorCard(id, callbackSuccess, callbackError) {
    API.get("/apis/employees/" + id + "/inspector/card?agencyCode=" + localStorage.getItem('agencyname') + "&cnNumber=10001",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch employee inspector card. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postPasswordReset(obj, callbackSuccess, callbackError) {
    return API.post('/apis/employees/password/reset', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to reset password. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postEmployee(obj, callbackSuccess, callbackError) {
    API.post('/apis/employees', JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add employee. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function putEmployee(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/employees/'+id, JSON.stringify(obj),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit employee. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}