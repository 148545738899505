// @flow

import * as React from "react";
import "tabler-react/dist/Tabler.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Router, Route, Switch } from "react-router-dom";
import { Homepage,EmployeesList,AssignDuty,APCSReport, APCSWaybillDetailsReport, APCSTripDetailsReport, APCSTripSurvellianceReport, ViewDuty,CashCollect,Routelist,CreateRoute,Stoplist,CreateStop,EditStop,EditDevice,WaybillDetail,Createroutetype,Editroutetype,Addconcession,AddExpense, AddCause, EditCause, Editconcession,CreateEmployee,EditEmployee,EditRoute,CreatePass,VarientCreate,varientproductlist,PassBonusList, Master,DeviceList,RegisterDevice,AddVehicle,EditVehicle,AddSchedule, EditSchedule, VehicleList,AddDepot,EditDepot,PassproductList,Operatorsetup,Luggage,Terms,Aboutus,Privacypolicy, DownloadAgreement, ReportIssue, MemosPage, Payment, PaymentResult, APCSDeviceList, RegisterAPCSDevice, EditAPCSDevice} from "./pages";
import DatatableReportComponent from "./pages/reports/DatatableReportComponent";
import MobileReport from "./pages/reports/MobileReport";
import Adduserlist from "./pages/users/Adduserlist";
import Issuepass from "./pages/users/Issuepass";
import Reloadpass from "./pages/users/Reloadpass";
import { LoginPage } from './LoginPage';
import { RegisterPage } from './RegisterPage';
import { ForgotPasswordPage } from './ForgotPasswordPage';
import { ResetPasswordPage } from './ForgotPasswordPage';
import { ChangePasswordPage } from './ForgotPasswordPage'
import Role from './enums/Role';
import { PrivateRoute } from './_components';
import { history } from "./utils/history";
import ForbiddenPage from "./pages/error/ForbiddenPage";
import NotificationList from "./pages/NotificationList";

function App(): React.Node{

    return (		
        <Router basename={process.env.PUBLIC_URL}  history={history}>
            <Switch>
                <Route exact path="/forbidden" component={ForbiddenPage}/>
                <Route exact path="/login" component={LoginPage} />
                
                <Route exact path="/register" component={RegisterPage} />
                <Route exact path="/forgotpassword" component={ForgotPasswordPage} />
				<Route exact path="/change-password" component={ChangePasswordPage} />
				<Route exact path="/reset-password" component={ResetPasswordPage} />
                <Route exact path="/report-issue" component={ReportIssue} />
				
                <PrivateRoute exact path="/" pageName="Home" component={Homepage} />
                <PrivateRoute exact path="/home" pageName="Home" component={Homepage} />
                
                <PrivateRoute exact path="/operator-setup" pageName="Operator Setup" component={Operatorsetup} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/add-depot" pageName="Add Depot" component={AddDepot} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/edit-depot/:id"  pageName="Edit Depot" component={EditDepot}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/create-routetype" pageName="Create Routetype" component={Createroutetype} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/edit-routetype/:id" pageName="Edit Routetype" component={Editroutetype} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/add-concession" pageName="Add Concession" component={Addconcession} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/edit-concession/:id"  pageName="Edit Concession" component={Editconcession} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/add-expense" pageName="Add Expense" component={AddExpense}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/add-cause" pageName="Add Cause" component={AddCause}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/edit-cause/:id" pageName="Edit Cause" component={EditCause}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                
                <PrivateRoute exact path="/route-list" pageName="Route List" component={Routelist}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/create-route" pageName="Add Route" component={CreateRoute} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/edit-route/:id" pageName="Edit Route" component={EditRoute} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                
                <PrivateRoute exact path="/device-list" pageName="Device List" component={DeviceList}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE, Role.INVENTORY]}/>
                <PrivateRoute exact path="/edit-device/:id" pageName="Edit Device" component={EditDevice}   roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE, Role.INVENTORY]}/>
                <PrivateRoute exact path="/register-device" pageName="Register Device" component={RegisterDevice}   roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE, Role.INVENTORY]}/>
                
                <PrivateRoute exact path="/apcs-device-list" pageName="APCS Device List" component={APCSDeviceList}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE, Role.INVENTORY]}/>
                <PrivateRoute exact path="/apcs-edit-device/:id" pageName="Edit APCS Device" component={EditAPCSDevice}   roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE, Role.INVENTORY]}/>
                <PrivateRoute exact path="/apcs-register-device" pageName="Register APCS Device" component={RegisterAPCSDevice}   roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE, Role.INVENTORY]}/>
                
                <PrivateRoute exact path="/vehicle-list" pageName="Vehicle List" component={VehicleList}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/add-vehicle" pageName="Add Vehicle" component={AddVehicle}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/edit-vehicle/:id" pageName="Edit Vehicle" component={EditVehicle}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                
                <PrivateRoute exact path="/stop-list" pageName="Stop List" component={Stoplist} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/create-stop" pageName="Add Stop" component={CreateStop} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/edit-stop/:id" pageName="Edit Stop" component={EditStop} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                
                <PrivateRoute exact path="/Luggage" pageName="Luggage" component={Luggage}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                
                <PrivateRoute exact path="/schedule-list" pageName="Schedule" component={MemosPage}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/add-schedule" pageName="Add Schedule" component={AddSchedule}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                <PrivateRoute exact path="/edit-schedule/:id" pageName="Edit Schedule" component={EditSchedule}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]}/>
                
                <PrivateRoute exact path="/manufacturer-device-list" pageName="Manufacturer Device List" component={DeviceList} roles={[Role.AFCS_ADMINISTRATOR, Role.DEVICE_PROVIDER]} />
                <PrivateRoute exact path="/manufacture-edit-device/:id" pageName="Manufacturer Edit Device" component={EditDevice} roles={[Role.AFCS_ADMINISTRATOR, Role.DEVICE_PROVIDER]} />
                <PrivateRoute exact path="/manufacturer-register-device" pageName="Manufacturer Register Device" component={RegisterDevice} roles={[Role.AFCS_ADMINISTRATOR, Role.DEVICE_PROVIDER]} />
                
                <PrivateRoute exact path="/assign-duty" pageName="Assign Duty" component={AssignDuty} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
                <PrivateRoute exact path="/view-duty" pageName="View Duty" component={ViewDuty} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
                
                <PrivateRoute exact path="/cashcollection" pageName="Collection" component={CashCollect}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/waybilldetail/:id" pageName="Waybill detail" component={WaybillDetail} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]}/>
                
                <PrivateRoute exact path="/all-employees" pageName="Employees" component={EmployeesList} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
                <PrivateRoute exact path="/create-employee" pageName="Add Employees" component={CreateEmployee} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
                <PrivateRoute exact path="/edit-employee/:id" pageName="Edit Employees" component={EditEmployee} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
                
                <PrivateRoute exact path="/apcs-report" pageName="APCS Report" component={APCSReport} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/apcs-waybill-details-report" pageName="APCS Waybill Report" component={APCSWaybillDetailsReport} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/apcs-trip-details-report" pageName="APCS Trip Report" component={APCSTripDetailsReport} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/apcs-trip-survelliance-report" pageName="APCS Trip Survelliance Report" component={APCSTripSurvellianceReport} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                
                <PrivateRoute exact path="/waybilldetail" pageName="Waybill detail Report"  component={WaybillDetail}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/RouteAnalytics"  pageName="Route Analytics Report"  component={DatatableReportComponent} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/RoutewiseDetail"  pageName="Routewise Detail Report"  component={DatatableReportComponent} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/CashCollectionReport" pageName="Cash Collection Report"  component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Conductorwisereport" pageName="Conductor Wise Report"   component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Depotwiseincomereport" pageName="Depot Wise Income Report" component={DatatableReportComponent} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Etimwise"  pageName="Etim Wise Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/etimsdatewise"  pageName="Etim Wise Detail Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/vehicledatewise"  pageName="Vehicle Number Detail Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Bustypewisereport"  pageName="Bus type Wise Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Farewisereport"   pageName="Fare Wise Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Tripticketissuedreport"   pageName="Trip Ticket Issued Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Doubledutyreport" pageName="Double Duty Report"   component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/StagewiseSurveyReport"  pageName="Stage Wise Survey Report"   component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Breakdownwisereport" pageName="Break Down Wise Report"  component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/inspectorreport" pageName="Inspector Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Killometeroperated" pageName="Kilometer Operated Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/PayrollReport" pageName="Payroll Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/DepotwiseShortReport" pageName="Depot Wise Short Report"  component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Issuedsmartcard" pageName="Issued Smartcard Report"  component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Smartcardrecharge" pageName="Smart Card Recharge Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/Diesel" pageName="Diesel Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/transactions" pageName="Transactions Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/agentreport" pageName="Agent Report" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/stagedetail" pageName="Stagewise Detail" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/stop-wise-passenger-count" pageName="Stopwise Passenger Count" component={DatatableReportComponent}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />

                <PrivateRoute exact path="/report-weekly-collection" pageName="Weekly Collection" component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/report-conductor-collection" pageName="Conductor Collection"  component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/report-routewise-collection" pageName="Route Wise Collection"  component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/report-routewise-epkm" pageName="Route Wise EPKM" component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/report-bus-inspection-count" pageName="Bus Inspection Count" component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]}/>
                <PrivateRoute exact path="/report-breakdown-alert" pageName="Breakdown alert" component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/report-diesel-amount" pageName="Diesel Amount" component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/report-stage-detail" pageName="Stagewise Detail" component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/report-trip-ticket-issue" pageName="Trip Ticket Issued Report" component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
                <PrivateRoute exact path="/report-transactions" pageName="Transactions" component={MobileReport}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.FINANCE, Role.OPERATIONS]} />
    
                <PrivateRoute exact path="/Adduserlist" pageName="Add User " component={Adduserlist}   roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
                <PrivateRoute exact path="/Issuepass"  pageName="Issue Pass" component={Issuepass}   roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
                <PrivateRoute exact path="/Reloadpass"  pageName="Reload Pass" component={Reloadpass}   roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
                
                <PrivateRoute exact path="/passproduct-list" pageName="Pass List" component={PassproductList} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/create-pass" pageName="Create Pass" component={CreatePass}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/varient-pass-list" pageName="Pass varient"  component={varientproductlist} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/createvarient-pass" pageName="Create Varient Pass" component={VarientCreate}  roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS, Role.EMPLOYEE]} />
                <PrivateRoute exact path="/pass-bonus-list" pageName="Pass Bonus List" component={PassBonusList} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
                
                <PrivateRoute exact path="/master" component={Master} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR, Role.OPERATIONS]} />
               
                <PrivateRoute exact path="/notifications" component={NotificationList} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR]} />
                
                <PrivateRoute exact path="/payment" component={Payment} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR]} />
                <PrivateRoute exact path="/payment-result" component={PaymentResult} roles={[Role.AFCS_ADMINISTRATOR, Role.ADMINISTRATOR]} />
               
                <Route exact path="/download-agreement" component={DownloadAgreement} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/privacy-policy" component={Privacypolicy} />
                <Route exact path="/about-us" component={Aboutus} />
            </Switch>
        </Router>
    );
}

export default App;
