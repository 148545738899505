import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined, isUndefined } from 'util';
import MessageResponse from '../enums/MessageResponse';

export const DutyAPI = {
    getUnassignedConductors,
    getUnassignedDrivers,
    getVehicle,
    getList,
    getCompleteList,
    getListFilter,
    postDuty,
    putDuty,
    postListWaybills,
}


function getUnassignedConductors(callbackSuccess, callbackError) {
    getDutyEmployee('&designation=CONDUCTOR&state=UNASSIGNED', callbackSuccess, callbackError);
}

function getUnassignedDrivers(callbackSuccess, callbackError) {
    getDutyEmployee('&designation=DRIVER&state=UNASSIGNED', callbackSuccess, callbackError);
}

function getDutyEmployee(queryString, callbackSuccess, callbackError) {
    API.get("/apis/duty/employee?agencyCode=" + localStorage.getItem('agencyname') + queryString,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch duty for employee. Please try again after sometime", MessageResponse.ERROR));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getVehicle(callbackSuccess, callbackError) {
    API.get("/apis/duty/vehicle?agencyCode=" + localStorage.getItem('agencyname') + "&state=UNASSIGNED",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch vehicle on duties. Please try again after sometime", MessageResponse.ERROR));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getList(from, to, page, size, callbackSuccess, callbackError) {
    API.get("/apis/duty/list?agencyCode=" + localStorage.getItem('agencyname') +
        "&fromDate=" + from + "&toDate=" + to + "&page=" + page + "&size=" + size + 
        "&sort=createdAt,DESC",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch duty list. Please try again after sometime", MessageResponse.ERROR));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getCompleteList(from, to, callbackSuccess, callbackError) {
    API.get("/apis/duty/list?agencyCode=" + localStorage.getItem('agencyname') +
        "&fromDate=" + from + "&toDate=" + to + 
        "&sort=createdAt,DESC",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch duty list. Please try again after sometime", MessageResponse.ERROR));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}


function getListFilter(dutyDate, conductorId, states, callbackSuccess, callbackError) {
    let url = "/apis/duty/list?agencyCode=" + localStorage.getItem('agencyname') +
    "&limit=10000&page=0&size=10000" + 
    "&sort=createdAt,DESC";
    if(!isUndefined(dutyDate) && dutyDate !== '') {
        url = url + "&fromDate=" + dutyDate + "&toDate=" + dutyDate;
    }
    if(!isUndefined(conductorId) && conductorId !== '') {
        url = url + "&conductorId=" + conductorId;
    }
    if(!isUndefined(states) && states !== '') {
        url = url + "&states=" + states;
    }
    API.get(url,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch duty list. Please try again after sometime", MessageResponse.ERROR));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}


function postDuty(obj, callbackSuccess, callbackError) {
    API.post('/apis/duty/', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add duty. Please try again after sometime", MessageResponse.ERROR));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putDuty(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/duty/'+id, JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit duty. Please try again after sometime", MessageResponse.ERROR));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function postListWaybills(id, callbackSuccess, callbackError) {
    API.post('/apis/duty/list/waybills', '["'+id+'"]',
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to post waybills. Please try again after sometime", MessageResponse.ERROR));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}
