import React from "react";
import { Button,Page } from "tabler-react";
import { Alert } from 'react-bootstrap';
import ReactDropdownAutoComplete from "./autocomplete";
import Select from 'react-select';
import BaseLayout from "./BaseLayout";
import "./alert.js";
import "../App.css";
import { AgencyAPI } from "../apis/AgencyAPI";
import "../index.css";	
import { Prompt } from 'react-router';


class EditRoute extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            DepotList:[],
            StopList: [],
            validationError: "",
            routeid:'',
            routenumber:'',
            routeshortname:'',
            routelongname:'',
            depoid:'',
            depotname:'',
            RoutetypeList:[],
            routetype:'',
            routetypename:'',
            isLoading: false,
            isloadingreset :false,
            isloadingsaveroute :false,
            isloadingresetfare :false,
            isloadingsavefare :false,
            name:'',
            startstage:'',
            endstage:'',
            numofstops:'',
            fareId:'',
            stopText:'',
            fareText:'',
            showmessage: false,
            errors: {},
            authkey: localStorage.getItem('authkey'),
            stopdata:{
                stopId: '',
                sequence: '',
                distanceFromOrigin: ''
            },
            stopsAddDone: false,
            routeAddDone: false,
            useFareRules:'',
            fareErrorTextbox:[],
            fareDataFromApi:[],
            shouldBlockNavigation: false,
            isParent: this.props.location.state.isParent
        };
        this.state.products = [];
        this.state.rows = [];
        this.state.stops = [];
        this.state.fares = {};
        this.state.fareRows = {};
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    handleUseFareRulesChange(e){
        let currentComponent = this;
        let isChecked = e.target.checked;
        if(isChecked === true){
            if (window.confirm("Are you sure you want to use Fare Master?")) {
                currentComponent.generateFares();
            }
        } else {
            if (window.confirm("Are you sure you want to use Manual Fare Updates?")) {		
                currentComponent.createManualFares();
            }
        }
           
        currentComponent.setState({ useFareRules: isChecked });
    }
    
    componentDidMount() {
        let thisstop = this;
        this.setState({ isLoading: true });
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function(event) {
            var db          = event.target.result;
            var depots      = db.transaction('depot', 'readwrite').objectStore("depot");
            var stops      = db.transaction('stops', 'readwrite').objectStore("stops");
            depots.getAll().onsuccess = function(event) {
                let DepotListFromInDB = event.target.result.map(depot => {
                    return {
                        value: depot.id,
                        label: depot.depotname
                    };
                }, thisstop);
                thisstop.setState({ DepotList: [{ value: '', label: 'Select Depot' }].concat(DepotListFromInDB) });
                return;
            };
            stops.getAll().onsuccess = function(event) {
                let StopListFromInDB = event.target.result.map(stops => { 
                    return { value: stops.id, display: stops.longName,longName:stops.longName };
                });
                thisstop.setState({ StopList: [{ value: '', display: 'Select Stop' }].concat(StopListFromInDB) });
                return;
            };
            return;
        };
        AgencyAPI.getRouteType(
            (response) => {
                thisstop.setState({ isLoading: false });
                let RoutetypeListFromDB = response.data.data.map(routetype => {
                    return {
                        value: routetype.id,
                        label: routetype.name
                    };
                }, thisstop);
                thisstop.setState({ RoutetypeList: [{ value: '', label: 'Select Route Type' }].concat(RoutetypeListFromDB) });
                return thisstop.fetchRouteDetails();
            },
            (error) => {
                thisstop.setState({ isLoading: false });
                return thisstop.fetchRouteDetails();
            }
        );
    }
    
    dropdownvalid(item, label, type){
        let errors = {};
        switch (type) {
            case 'depoid': {
                if(item === ""){
                    errors['depoid'] = "Cannot be empty";
                }
                this.setState({ depoid: item, errors: errors });
                break
            }
            case 'routetype': {
                if(item === ""){
                    errors['routetype'] = "Cannot be empty";
                }
                return this.setState({ routetype: item, routetypename: label, errors: errors });
            }
            case 'startstage': {
                if(item === ""){
                    errors['startstage'] = "Cannot be empty";
                }
                return this.setState({ startstage: item, errors: errors });
            }
            case 'endstage': {
                if(item === ""){
                    errors['endstage'] = "Cannot be empty";
                }
                return this.setState({ endstage: item, errors: errors });
            }
            default:    
        }
    }
    
    valid(item, type) {
        let itemValue = item.target.value;
        let errors = {};
        switch (type) {
            case 'routenumber': {
                if(itemValue === ""){
                    errors['routenumber'] = "Cannot be empty";
                }
                this.setState({ routenumber: itemValue, errors: errors });
                break
            }
            case 'numofstops': {
                if(itemValue === ""){
                    errors['numofstops'] = "Cannot be empty";
                }
                return this.setState({ numofstops: itemValue, errors: errors });
            }
            default:
        }
    }
    
    handleValidation(){
        let errors = {};
        let formIsValid = true;
        if(this.state.routenumber === ""){
            formIsValid = false;
            errors["routeNumber"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.routetype === ""){
            formIsValid = false;
            errors["routetype"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.depoid === ""){
            formIsValid = false;
            errors["depoid"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.startstage === ""){
            formIsValid = false;
            errors["startstage"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.endstage === ""){
            formIsValid = false;
            errors["endstage"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.numofstops === ""){
            formIsValid = false;
            errors["numofstops"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(Object.keys(this.state.errors).length !== 0){
            if(this.state.errors["name"] || this.state.errors["stagecode"] || this.state.errors["km"] || this.state.errors["fare"]){
                formIsValid = false;
            }
        }
        return formIsValid;
    }
    
    addDefaultValueNewRow(index){
        var val=[];
        for (var dStop = 0; dStop < this.state.products.length; dStop++) {
            if(dStop >= index){
                val.push(0);
            }else{
                val.push("");
            }
        }
         return val;
    }
    
    handleRowAdd(product) {
        var index = this.state.products.indexOf(product);
        var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
        var new_index = parseInt(index, 10) + 1;
        var new_sequence = new_index + 1;
        var new_product = {
            id: id,
            name: '',
            stagecode: '',
            km: '',
            stageno: new_sequence,
            stopId: '',
            stopName: '',
            sequenceNumber: new_sequence,
            distance: ''
        };
        this.state.products.splice(new_index, 0, new_product);
        if(this.state.fareDataFromApi.length > 0){
            this.state.fareDataFromApi.splice(new_index, 0, this.addDefaultValueNewRow(new_index));
            for (var dStop = 0; dStop < this.state.fareDataFromApi.length; dStop++) {
                    if(dStop !== new_index && dStop < new_index){ 
                    this.state.fareDataFromApi[dStop].push(0);
                    }
                    if(dStop > new_index ){
                        this.state.fareDataFromApi[dStop].splice(new_index, 0, "");
                    }
                    
                }
            this.setState({fareDataFromApi:this.state.fareDataFromApi});
        }
        this.setState({products: this.state.products, numofstops: this.state.products.length});
        for(var i = 0; i < this.state.products.length; i++){
            var next_product = this.state.products[i];
            next_product['stageno'] = i + 1;
            next_product['sequenceNumber'] = i + 1;
            let numofstops = parseInt(this.state.products.length, 10);
            let errors = {};
            errors['id'] = next_product['id'];
            errors['name'] = "";
            errors['stagecode'] = "";
            errors['km'] = "";
            if(next_product['stageno'] === 1){
                if(next_product['name'] === ""){
                    errors['startstage'] = "Cannot be empty";
                }
                this.setState({startstage: next_product['name']});
            } else if(next_product['stageno'] === numofstops){
                if(next_product['name'] === ""){
                    errors['endstage'] = "Cannot be empty";
                }
                this.setState({endstage: next_product['name']});
            }
            if(next_product['name'] === ""){
                errors['name'] = "Cannot be empty";
            }
            if(next_product['stagecode'] === ""){
                errors['stagecode'] = "Cannot be empty";
            }
            if(next_product['km'] === ""){
                errors['km'] = "Cannot be empty";
            }
            this.setState({errors: errors});
        }
    };
    
    handleRowDel(product) {
        var index = this.state.products.indexOf(product);
        this.state.products.splice(index, 1);
        this.state.fareDataFromApi.splice(index, 1);
        for (var dStop = 0; dStop < this.state.fareDataFromApi.length; dStop++) {
            this.state.fareDataFromApi[dStop].splice(index, 1);
        }
        this.setState({fareDataFromApi:this.state.fareDataFromApi});
        this.setState({products: this.state.products, numofstops: this.state.products.length});
        for(var i = 0; i < this.state.products.length; i++){
            var next_product = this.state.products[i];
            next_product['stageno'] = i + 1;
            next_product['sequenceNumber'] = i + 1;
            let numofstops = parseInt(this.state.products.length, 10);
            let errors = {};
            errors['id'] = next_product['id'];
            errors['name'] = "";
            errors['stagecode'] = "";
            errors['km'] = "";
            if(next_product['stageno'] === 1){
                if(next_product['name'] === ""){
                    errors['startstage'] = "Cannot be empty";
                }
                this.setState({startstage: next_product['name']});
            } else if(next_product['stageno'] === numofstops){
                if(next_product['name'] === ""){
                    errors['endstage'] = "Cannot be empty";
                }
                this.setState({endstage: next_product['name']});
            }
            if(next_product['name'] === ""){
                errors['name'] = "Cannot be empty";
            }
            if(next_product['stagecode'] === ""){
                errors['stagecode'] = "Cannot be empty";
            }
            if(next_product['km'] === ""){
                errors['km'] = "Cannot be empty";
            }
            this.setState({errors: errors});
        }
    };
    
    handleAddEvent(evt) {
        // eslint-disable-next-line
        this.state.products = [];
        // eslint-disable-next-line
        this.state.fares = {};
        let stagename;
        let firststage = this.state.startstage;
        let endstage = this.state.endstage;
        let numofstops = parseInt(this.state.numofstops, 10);
        let km;
        if(this.handleValidation()){
            this.setState({isloadingreset: true, stopsAddDone: false});
            for (let i=1; i <= numofstops; i++) {
                if(i === 1){
                    stagename = firststage;
                    km = (0).toString();
                } else if(i === numofstops){
                    stagename = endstage;
                    km = "";
                } else {
                    stagename = '';
                    km = "";
                }
                var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
                var product = {
                    id: id,
                    name: stagename.toUpperCase(),
                    stagecode: stagename.toUpperCase().substring(0, 3),
                    km: km,
                    stageno: i,
                    stopId: stagename.toUpperCase().substring(0, 3),
                    stopName: stagename,
                    sequenceNumber: i,
                    distance: km
                };
                this.state.products.push(product);
            }
            this.setState({products: this.state.products, numofstops: this.state.products.length});
            this.setState({isloadingreset: false, stopText: 'Update Stops', stopsAddDone: true});
        }
    }
     
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    
    handleRowUpdate(item) {
        var current = this;
        let errors = {};
        errors['id'] = item.id;
        errors['name'] = "";
        errors['stagecode'] = "";
        errors['km'] = "";
        var newProducts = this.state.products.map((product, index) => {
            for (var key in product) {
                if(product.id === item.id) {
                    product[key] =  item[key];
                    let numofstops = parseInt(current.state.products.length, 10);
                    if(product['stageno'] === 1){
                        if(product['name'] === ""){
                            errors['startstage'] = "Cannot be empty";
                        }
                        current.setState({startstage: product['name']});
                    } else if(product['stageno'] === numofstops){
                        if(product['name'] === ""){
                            errors['endstage'] = "Cannot be empty";
                        }
                        current.setState({endstage: product['name']});
                    }
                    if(product['name'] === ""){
                        errors['name'] = "Cannot be empty";
                    }
                    if(product['stagecode'] === ""){
                        errors['stagecode'] = "Cannot be empty";
                    }

                    if(product['km'] === "") {
                        errors['km'] = "Cannot be empty";
                    }else if (isNaN(product['km'])) {
                        errors['km'] = "Should be valid number"
                        product['km'] = "";
                    }
                    var next_product = current.state.products[index + 1];
                    var previous_product = current.state.products[index - 1];
                    if(next_product !== undefined){
                        if(next_product["km"] !== "" && product["km"] >= next_product["km"]){
                            errors["km"] = "Should be less than Next Stop";
                        }
                    }
                    if(previous_product !== undefined){
                        if(previous_product["km"] !== "" && product["km"]<= previous_product["km"]){
                            errors["km"] = "Should be greater than Previous Stop";
                        }
                    }
                }
            }
            return product;
        });
        this.setState({products: newProducts, errors: errors, numofstops: this.state.products.length});
    };
    
    handleProductTable(evt) {
        var item;
        item = {
            id: evt.target.id,
            name: evt.target.name,
            value: evt.target.value
        };
        var current = this;
        let errors = {};
        errors['id'] = item.id;
        errors['name'] = "";
        errors['stagecode'] = "";
        errors['km'] = "";
        var newProducts = this.state.products.map((product, index) => {
            for (var key in product) {
                if(key === item.name && product.id === item.id) {
                    product[key] =  item.value;
                    let numofstops = parseInt(current.state.products.length, 10);
                    if(product['stageno'] === 1){
                        if(product['name'] === ""){
                            errors['startstage'] = "Cannot be empty";
                        }
                        current.setState({startstage: product['name']});
                    } else if(product['stageno'] === numofstops){
                        if(product['name'] === ""){
                            errors['endstage'] = "Cannot be empty";
                        }
                        current.setState({endstage: product['name']});
                    }
                    if(product['name'] === ""){
                        errors['name'] = "Cannot be empty";
                    }
                    if(product['stagecode'] === ""){
                        errors['stagecode'] = "Cannot be empty";
                    }
                    if(product['km'] === "") {
                        errors['km'] = "Cannot be empty";
                    }else if (isNaN(product['km'])) {
                        errors['km'] = "Should be valid number"
                        product['km'] = "";
                    }
                    var next_product = current.state.products[index + 1];
                    var previous_product = current.state.products[index - 1];
                    if(next_product !== undefined){
                        if(next_product["km"] !== "" && product["km"] >= next_product["km"]){
                            errors["km"] = "Should be less than Next Stop";
                        }
                    }
                    if(previous_product !== undefined){
                        if(previous_product["km"] !== "" && product["km"]<= previous_product["km"]){
                            errors["km"] = "Should be greater than Previous Stop";
                        }
                    }
                }
            }
            return product;
        });
        this.setState({products: newProducts, errors: errors, numofstops: this.state.products.length});
    };
    
    handleFareTable(evt) {
        var item;
        item = {
            id: evt.target.id,
            name: evt.target.name,
            value: evt.target.value
        };
        if(item.value !== "" && parseInt(item.value, 10) === 0){
            return false;
        }
        let errors = {};
        let currentComponent = this;
        var key = item.name.substring(0, item.name.indexOf('['));
        for(var i = 0; i < currentComponent.state.fares[key].length; i++){
            if(item.name === key+"["+i+"]"){
                if(item.value !== ""){
                    item.value = parseInt(item.value, 10);
                    errors = {};
                } else {
                    errors["fare"] = "Fares Cannot be 0 or empty";
                }
                // eslint-disable-next-line
                currentComponent.state.fareRows[key][i] = item.value;
            } 
        }
        this.setState({ fares: this.state.fareRows, errors: errors });
    };
    
    fetchRouteDetails(){
        let thisstop = this;
        this.setState({ isLoading: true });
        //fetch data base on route id//
        AgencyAPI.getRouteId(thisstop.props.match.params.id,
            (response) => {
                let item = {};
                let numofstops = parseInt(response.data.data.stops.length, 10);
                for(let i = 1; i <= numofstops; i++) {
                    var stop = response.data.data.stops[i-1];
                    if(stop.distanceFromOrigin === null){
                        stop.distanceFromOrigin = "";
                    }
                    var stageno = parseInt(i, 10);
                    item = {
                        id: stop.stopId,
                        stageno: stageno,
                        km: stop.distanceFromOrigin.toString()
                    };
                    for(var j in thisstop.state.StopList){
                        var element = thisstop.state.StopList[j];
                        if (element.value === item.id) {
                            if(stageno === 1){
                                thisstop.state.startstage = element.longName.toUpperCase();
                            }
                            if(stageno === numofstops){
                                thisstop.state.endstage = element.longName.toUpperCase();
                            }
                            item.name = element.longName.toUpperCase();
                            item.stagecode = element.display.toUpperCase().substring(0, 3);
                        }
                    }
                    thisstop.state.products.push(item);
                }
                thisstop.setState({
                    routeid:response.data.data.id,
                    routenumber:response.data.data.agencyRouteId,
                    depoid:response.data.data.depot,
                    routetype:response.data.data.type,
                    routeshortname:response.data.data.shortName.toUpperCase(),
                    routelongname:response.data.data.longName.toUpperCase(),
                    numofstops: numofstops,
                    isLoading: false,
                    stopText: 'Update Stops',
                    useFareRules:response.data.data.isAutoFareGeneration
                });
                for(var l in thisstop.state.DepotList){
                    var depot = thisstop.state.DepotList[l];
                    if(depot.value === thisstop.state.depoid){
                        thisstop.setState({ depotname: depot.label });
                    }
                }
                for(var k in thisstop.state.RoutetypeList){
                    var routetype = thisstop.state.RoutetypeList[k];
                    if(routetype.value === thisstop.state.routetype){
                        thisstop.setState({ routetypename: routetype.label });
                    }
                }
                return thisstop.fetchFares();
            },
            (error) => {
                thisstop.setState({ isLoading: false  });
                return;
            }
        );
    }

    fetchFares(){
        let currentComponent = this;
        this.setState({ isLoading: true,fareText:'' });
        //fetch fares base on route id//
        AgencyAPI.getFaresForRouteId(currentComponent.props.match.params.id,
            (response) => {
                var fareId = response.data.data[0].id;
                currentComponent.setState({fareId: fareId});
                var fares = currentComponent.transpose(response.data.data[0].fares[0].table);
                if(currentComponent.state.products.length === fares.length){
                    currentComponent.setState({fareDataFromApi:currentComponent.transpose(response.data.data[0].fares[0].table)});
                    var fareRows = {};
                    for(var i in currentComponent.state.products){
                        var stop = currentComponent.state.products[i];
                        fareRows[stop.id] = [];
                        fareRows[stop.id][i] = [];
                        for(var j = 0; j < fares[i].length; j++){
                            if(j <= i){
                                fareRows[stop.id][j] = fares[i][j];
                            }
                        }
                    }
                    currentComponent.setState({fares: fareRows, fareRows: fareRows, isLoading: false, stopsAddDone: true, fareText: 'Update Fares', routeAddDone: true});
                } else {
                    if(currentComponent.state.fareDataFromApi === undefined 
                        || currentComponent.state.fareDataFromApi === null 
                        || currentComponent.state.fareDataFromApi.length === 0) {
                        currentComponent.setState({isLoading: false, stopsAddDone: true, routeAddDone: true});
                        if(currentComponent.state.useFareRules) {
                            currentComponent.generateFares();
                        } else {
                            currentComponent.createManualFares();    
                        }
                    } else {
                        var isFareInvalid=false;
                        fareRows = {};
                        for(var x in currentComponent.state.products){
                            stop = currentComponent.state.products[x];
                            fareRows[stop.id] = [];
                            fareRows[stop.id][x] = [];
                            for(var y = 0; y < currentComponent.state.fareDataFromApi[x].length; y++){
                                if(y <= x){
                                    fareRows[stop.id][y] = currentComponent.state.fareDataFromApi[x][y];
                                }
                                // eslint-disable-next-line
                                if(currentComponent.state.fareDataFromApi[x][y] !== "" && isFareInvalid == true){
                                    currentComponent.state.fareErrorTextbox[stop.id+"["+y+"]"] = "fareinvalidError";
                                    }
                                else if(currentComponent.state.fareDataFromApi[x][y] === ""){
                                    isFareInvalid = true;
                                    // eslint-disable-next-line
                                    currentComponent.state.fareErrorTextbox[stop.id+"["+y+"]"] = "fareError";
                                }else{
                                    // eslint-disable-next-line
                                    currentComponent.state.fareErrorTextbox[stop.id+"["+y+"]"] = "";
                                }
                            }
                        }
                        currentComponent.setState({fareErrorTextbox:currentComponent.state.fareErrorTextbox,fares: fareRows,fareRows: fareRows,isLoading: false, stopsAddDone: true, fareText: 'Stop has been updated,Please update fares.', routeAddDone: true});
                        //this.toggleAddModal();
                    }
                }
            },
            (error) => {
                currentComponent.setState({isLoading: false, stopsAddDone: true, routeAddDone: true});
                if (error.status === 200 && error.data.code === 3320) {
                    if(currentComponent.state.useFareRules) {
                        return currentComponent.generateFares(); 
                    } else {
                        return currentComponent.createManualFares();
                    }
                }
            }
        );
    }

    createManualFares(){
        let currentComponent = this;
        this.setState({ isLoading: true });
        if(currentComponent.state.products.length > 0){
            var fareRows = {};
            for(var i = 0; i < currentComponent.state.products.length; i++){
                var stop = currentComponent.state.products[i];
                fareRows[stop.id] = [];
                fareRows[stop.id][i] = [];
                for(var j in currentComponent.state.products){
                    if(j < i){ 
                        fareRows[stop.id][j] = "";
                    } 
                    // eslint-disable-next-line
                    if(i == j) { 
                        fareRows[stop.id][j] = 0;
                       
                    }
                }
            }
            currentComponent.setState({fares: fareRows, fareRows: fareRows, isLoading: false, stopsAddDone: true, fareText: 'Update Fares', routeAddDone: true});
        }
    }
    
    createOrFetchStops(){
        // eslint-disable-next-line
        this.state.stops = [];
        let currentComponent = this;
        let stopdata = {};
        let stopcreateobj = {};
        if(this.handleValidation()){
            this.setState({isloadingsaveroute: true, shouldBlockNavigation: true});
            for(var i = 0; i < currentComponent.state.products.length; i++) {
                var element = currentComponent.state.products[i];
                stopcreateobj = {
                    "agencyStopId":element.id,
                    "agencyCode":localStorage.getItem('agencyname'),
                    "longName":element.name.toUpperCase(),
                    "shortName":element.stagecode.toUpperCase(),
                    "latitude":"0.0",
                    "longitude":"0.0"
                };
                currentComponent.postStopsCall(stopcreateobj, stopdata);
                stopcreateobj = {};
                stopdata = {};
            }
        }
        return;
    }

    postStopsCall(stopcreateobj, stopdata) {
        let currentComponent = this;
        AgencyAPI.postStopsCreateOrFetch(stopcreateobj,
            (response) => {
                for(var j in currentComponent.state.products){
                    var stop = currentComponent.state.products[j];
                    let stopsArr = currentComponent.state.stops;
                    if(stop.id === response.data.data.agencyStopId){
                        stopdata = {
                            "stopId":response.data.data.id,
                            "sequence": stop.stageno,
                            "distanceFromPreviousStop": stop.km,
                            "distanceFromOrigin":stop.km
                        };
                        currentComponent.setState({
                            showmessage: true,
                            responsemessage:'Stops Updated!'
                        });
                        stopsArr.push(stopdata);
                        currentComponent.setState({stops: stopsArr}, currentComponent.updateStops());
                    }
                }
            }
        );
    }

    fetchStops() {
        let currentComponent = this;
        if(currentComponent.state.stops.length === currentComponent.state.products.length){
            currentComponent.state.rows = currentComponent.state.stops;
            const dbName = "RIDLR AFCS";
            const version = 1; //versions start at 1
            var request = indexedDB.open(dbName, version);
            request.onsuccess = function(event) {
                var db         = event.target.result;
                var stops      = db.transaction('stops', 'readwrite').objectStore("stops");
                var clear =  stops.clear();
                clear.onsuccess = function(event) {
                    //  stopsData.transaction.oncomplete = function(event) {
                    AgencyAPI.getStops(
                        (response) => {
                            let stopsFromApi = response.data.data.map((stops) => {
                                return { id: stops.id, agencyStopId: stops.agencyStopId, shortName: stops.shortName, longName: stops.longName, latitude: stops.latitude, longitude: stops.longitude, createdAt: stops.createdAt, status: stops.status, parent:stops.parent };
                            });
                            // Store values in the newly created objectStore.
                            var stopObjectStore = db.transaction("stops", "readwrite").objectStore("stops");
                            //stopObjectStore.clear();
                            stopsFromApi.forEach(function (stops) {
                                stopObjectStore.add(stops);
                            });
                            currentComponent.updateRoute();
                            return;
                        }
                    );
                };
            };
            currentComponent.setState({
                showmessage: true,
                responsemessage:'Stops Added!'
            });
        }
        return;
    }
    
    updateStops(){
        // eslint-disable-next-line
        this.state.rows = [];
        this.setState({isloadingsaveroute: true});
        let currentComponent = this;
        if(currentComponent.state.stops.length === currentComponent.state.products.length){
            currentComponent.state.stops = currentComponent.state.stops.sort(function(obj1, obj2) {
                // Ascending: first age less than the previous
                return obj1.sequence - obj2.sequence;
            });
            const dbName = "RIDLR AFCS";
            const version = 1; //versions start at 1
            var request = indexedDB.open(dbName, version);
            request.onsuccess = function(event) {
                var db         = event.target.result;
                var stops      = db.transaction('stops', 'readwrite').objectStore("stops");
                var clear =  stops.clear();
                clear.onsuccess = function(event) {
                    //  stopsData.transaction.oncomplete = function(event) {
                    AgencyAPI.getStops(
                        (response) => {
                            let stopsFromApi = response.data.data.map((stops) => {
                                return { id: stops.id, agencyStopId: stops.agencyStopId, shortName: stops.shortName, longName: stops.longName, latitude: stops.latitude, longitude: stops.longitude, createdAt: stops.createdAt, status: stops.status, parent: stops.parent };
                            });
                            // Store values in the newly created objectStore.
                            var stopObjectStore = db.transaction("stops", "readwrite").objectStore("stops");
                            //stopObjectStore.clear();
                            stopsFromApi.forEach(function (stops) {
                                stopObjectStore.add(stops);
                            });
                            return;
                        }
                    );
                };
            };
            currentComponent.setState({
                showmessage: true,
                responsemessage:'Stops Added!'
            });
            currentComponent.state.rows = currentComponent.state.stops;
            return currentComponent.updateRoute();
        }
    }
    
    updateRoute(){
        let currentComponent = this;
        if(this.handleValidation()){
            this.setState({isloadingsaveroute: true});
            let obj = {};
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.agencyRouteId = this.state.routenumber;
            obj.longName = (this.state.startstage+" To "+this.state.endstage).toUpperCase();
            obj.shortName = (this.state.startstage.substring(0, 3)+" To "+this.state.endstage.substring(0, 3)).toUpperCase();
            obj.depot = this.state.depoid;
            obj.type = this.state.routetype;
            obj.isAutoFareGeneration = this.state.useFareRules;
            obj.stops = this.state.rows.sort(function(obj1, obj2) {
                // Ascending: first age less than the previous
                return obj1.sequence - obj2.sequence;
            });
            //Edit Route
            AgencyAPI.putRoutes(currentComponent.props.match.params.id, obj,
                (response) => {
                    let routeid = response.data.data.id;
                    const dbName = "RIDLR AFCS";
                    const version = 1; //versions start at 1
                    var request = indexedDB.open(dbName, version);
                    request.onsuccess = function(event) {
                        var db         = event.target.result;
                        var routes      = db.transaction('routes', 'readwrite').objectStore("routes");
                        var clear =  routes.clear();
                        clear.onsuccess = function(event) {
                            AgencyAPI.getRoutes(
                                (response) => {
                                    let RouteDataFromApi = response.data.data.map((route) => {
                                        return { id: route.id, agencyRouteId: route.agencyRouteId, shortName: route.shortName, longName: route.longName, routeLength: route.routeLength, stops: route.stops, depot: route.depot, status: route.status, type: route.type, createdAt: route.createdAt, parent: route.parent };
                                    });
                                    // Store values in the newly created objectStore.
                                    var routeObjectStore = db.transaction("routes", "readwrite").objectStore("routes");
                                    routeObjectStore.clear();
                                    RouteDataFromApi.forEach(function (routes) {
                                        routeObjectStore.add(routes);
                                    });
                                    return;
                                }
                            );
                        };
                    };
                    currentComponent.setState({
                        showmessage: true,
                        isloadingsaveroute: false,
                        useFareRules:response.data.data.isAutoFareGeneration,
                        responsemessage:'Route Updated!',
                        routeAddDone: true,
                        routeid: routeid
                    }, currentComponent.fetchFares());
                },
                (error) => {
                    currentComponent.setState({
                        showmessage: true,
                        isloadingsaveroute: false,
                        routeAddDone: false,
                        responsemessage:error.message
                    });
                    return;
                }
            );
        }
    }

    updateFares(){
        let currentComponent = this;
        if(this.handleValidation()){
            this.setState({isloadingsavefare: true, shouldBlockNavigation: false});
            var faretable = [];
            for(var i = 0; i < currentComponent.state.products.length; i++){
                faretable[i] = [];
                for(var j in currentComponent.state.fares){
                    if(currentComponent.state.fares[j][i]){
                        faretable[i].push(currentComponent.state.fares[j][i]);
                    } else {
                        faretable[i].push(0);
                    }
                }
            }
            let obj = {};
            obj.routeId = currentComponent.props.match.params.id;
            obj.agencyCode = localStorage.getItem('agencyname');
            let fareobj = {};
            fareobj.productType = "TICKET";
            fareobj.concessionType = "ADULT";
            fareobj.table = faretable;
            obj.fares = [fareobj];
            //Update Fares
            let urlMethod = "POST";
            if (currentComponent.state.fareId !== ""){
               urlMethod = "PUT";
            }
            if(urlMethod === 'POST') {
                AgencyAPI.postFares(obj,
                    (response) => {
                        currentComponent.handleResponse(response);
                    }
                );
            } else if(urlMethod === 'PUT') {
                AgencyAPI.putFares(currentComponent.state.fareId, obj,
                    (response) => {
                        currentComponent.handleResponse(response);
                    }
                );
            }
        }
    }

    handleResponse(response) {
        let currentComponent = this;
        if (response.data.code === 2000 && response.data.message === 'SUCCESS') {
            currentComponent.setState({
                showmessage: true,
                fareDataFromApi:this.transpose(response.data.data.fares[0].table),
                isloadingsavefare: false,
                responsemessage:'Fares Updated!',
                fareErrorTextbox:[],
                fareText:'Fares Updated!'
            });
            return;
        } else {
            currentComponent.setState({
                showmessage: true,
                isloadingsavefare: false,
                responsemessage:response.data.message
            });
            return;
        }
    }
    
    generateFares(){
        let currentComponent = this;
        if(this.handleValidation()){
            this.setState({isloadingresetfare: true, routeAddDone: false});
            // eslint-disable-next-line
            this.state.fares = {};
            let obj = {};
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.routeType = currentComponent.state.routetypename;
            let stoplist = [];
            for(var i = 0; i < this.state.products.length; i++) {
                var element = this.state.products[i];
                let stopobj = {};
                stopobj.stopId = element.id;
                stopobj.stopName = element.name;
                stopobj.distance = element.km;
                stopobj.sequenceNumber = element.stageno;
                stoplist.push(stopobj);
            }
            obj.stopList = stoplist;
            //Generate Fares
            AgencyAPI.postFaresSuggestionMatrix(obj, 
                (response) => {
                    currentComponent.state.fares = response.data.data;
                    currentComponent.state.fareRows = response.data.data;
                    currentComponent.setState({
                        fares: response.data.data,
                        fareRows: response.data.data,
                        showmessage: true,
                        isloadingresetfare: false,
                        responsemessage:'Fares Generated!',
                        routeAddDone: true,
                    });
                    return "";
                },
                (error) => {
                    currentComponent.setState({
                        showmessage: true,
                        isloadingresetfare: false,
                        responsemessage:error.message,
                        routeAddDone: true
                    });
                    return;
                }
            );
        }
    }
    
    transpose(a) {
        return Object.keys(a[0]).map(function(c) {
            return a.map(function(r) { return r[c]; });
        });
    }
  
    render() {
        var opts = {};
        if (this.state.isParent === true) {
            opts['disabled'] = true;
        }
        
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let startkey = 1;
        let endkey = 1;
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            );
        }
        
        const valueFromId = (opts, id) => opts.find(o => o.value === id);
        return (
            <BaseLayout>
                <Page.Content>
                <React.Fragment>
                    <Prompt
                    when={this.state.shouldBlockNavigation}
                    message="It looks like you have been editing route and fares. If you leave before saving, your changes will be lost."
                    />
                </React.Fragment>
                    {fadeinout}
                    <a href="/route-list" className="floatright"><Button className="btn-primary">View Routes</Button></a>
                    <Page.Header title="Route & Fares Setup" />
                    <React.Suspense fallback={<div>Loader...</div>}>
                        <form className="card">
                            {!this.state.isLoading &&
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Route Number<span className="form-required">*</span></label>
                                            <input type="text" className="form-control upercase" value={this.state.routenumber} onChange={(item) => this.valid(item, 'routef')} placeholder="Route Number" disabled={opts['disabled']}></input>
                                            <span style={{color: "red"}}>{this.state.errors["routeNumber"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Select Depot<span className="form-required">*</span></label>
                                            <Select options={this.state.DepotList} value={valueFromId(this.state.DepotList, this.state.depoid,this.state.depotname)} onChange={(item) => this.dropdownvalid(item.value, item.label, 'depoid')}  isDisabled={opts['disabled']} />
                                            <span style={{color: "red"}}>{this.state.errors["depoid"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                    <div className="form-group">
                                            <label className="form-label">Select Route Type<span className="form-required">*</span></label>
                                            <Select options={this.state.RoutetypeList} value={valueFromId(this.state.RoutetypeList, this.state.routetype,this.state.routetypename)} onChange={(item) => this.dropdownvalid(item.value, item.label, 'routetype')}  isDisabled={opts['disabled']}  />
                                            <span style={{color: "red"}}>{this.state.errors["routetype"]}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Start Stop<span className="form-required">*</span></label>
                                            <ReactDropdownAutoComplete
                                                getItemValue={item => item.display}
                                                className="form-control upercase"
                                                id="startstage"
                                                name="startstage"
                                                placeholder="Select Start Point"
                                                data={this.state.StopList || []}
                                                renderItem={item => (
                                                    <div key={startkey++} role="button" tabIndex="-1" onClick={(val) => this.dropdownvalid(val, val, 'startstage')}>{item.display}</div>
                                                )}
                                                opts={opts['disabled']}
                                                icon="search"
                                                iconColor="#ff000"
                                                iconClick={() => { /* TODO */ }}
                                                value={this.state.startstage}
                                                onChange={(val) =>{ this.dropdownvalid(val, val, 'startstage');}} 
                                                onEnter={() => { /* TODO */ }}
                                            />
                                            <span style={{color: "red"}}>{this.state.errors["startstage"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">End Stop<span className="form-required">*</span></label>
                                            <ReactDropdownAutoComplete
                                                getItemValue={item => item.display}
                                                className="form-control upercase"
                                                id="endstage"
                                                name="endstage"
                                                opts={opts['disabled']}
                                                placeholder="Select End Point"
                                                data={this.state.StopList || []}
                                                renderItem={item => (
                                                    <div key={endkey++} role="button" tabIndex="-1" onClick={(val) => this.dropdownvalid(val, val, 'endstage')}>{item.display}</div>
                                                )}
                                                icon="search"
                                                iconColor="#ff000"
                                                iconClick={() => { /* TODO */ }}
                                                value={this.state.endstage}
                                                onChange={(val) =>{ this.dropdownvalid(val, val, 'endstage');}} 
                                                onEnter={() => { /* TODO */ }}
                                            />
                                            <span style={{color: "red"}}>{this.state.errors["endstage"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Number Of Stops<span className="form-required">*</span></label>
                                            <input type="text" className="form-control" value={this.state.numofstops} onChange={(item) => this.valid(item, 'numofstops')} placeholder="No Of Stops"  disabled={opts['disabled']}></input>
                                            <span style={{color: "red"}}>{this.state.errors["numofstops"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">&nbsp;</label>
                                        <div className="form-group">
                                            <button type="button"  disabled={opts['disabled']} onClick={this.handleAddEvent.bind(this)} className="btn btn-alert pull-right">Reset Stops
                                            {this.state.isloadingreset &&
                                                <img src="/images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {this.state.isLoading &&
                            <div className="card-footer">
                                <div className="row">
                                    <img style={{margin: 'auto'}} src={'/images/spinner-loder.gif'} alt="spinner-loder" />
                                </div>
                            </div>        
                            }
                            {this.state.stopsAddDone &&
                            <div className="card-footer">
                                <h6>{this.state.stopText}</h6>
                                <div className="row">
                                    <ProductTable onProductTableUpdate={this.handleProductTable.bind(this)} onRowUpdate={this.handleRowUpdate.bind(this)} handleAddEvent={this.handleAddEvent.bind(this)} onRowAdd={this.handleRowAdd.bind(this)} onRowDel={this.handleRowDel.bind(this)} products={this.state.products} errors={this.state.errors} opts={opts['disabled']}/>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="col-md-4 " style={{float: 'left'}}>
                                        <div>
                                            <label className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" className="custom-control-input" onChange={e => this.handleUseFareRulesChange(e)} name="example-checkbox1" value="option1" checked={this.state.useFareRules}  disabled={opts['disabled']}></input>
                                                <span className="custom-control-label">Use Fare Rules </span>
                                            </label>
                                        </div>
                                    </div>
                                <div className="col-md-8 text-right" style={{margin: '0 0 0 40px', padding: '0'}}>
                                    <button type="button" onClick={() => this.createOrFetchStops()} className="btn btn-primary"  disabled={opts['disabled']}>Save Route
                                    {this.state.isloadingsaveroute &&
                                        <img src="/images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                    }
                                    </button>
                                </div>
                            </div>
                            }
                            {!this.state.stopsAddDone &&
                            <div className="card-footer">
                                <div className="row">
                                    <img style={{margin: 'auto'}} src={'/images/spinner-loder.gif'} alt="spinner-loder" />
                                </div>
                            </div>
                            }
                            {this.state.routeAddDone &&
                            <div className="card-footer">
                                <h6>{this.state.fareText}</h6>
                                <div className="row">
                                    <FareTable fareErrorTextbox ={this.state.fareErrorTextbox} onFareTableUpdate={this.handleFareTable.bind(this)} fares={this.state.fares} stops={this.state.products} errors={this.state.errors} opts={opts['disabled']}/>
                                </div>
                                <div className="text-left">
                                    <button type="button" onClick={() => this.updateFares()} className="btn btn-primary" disabled={opts['disabled']}>Save Fares
                                        {this.state.isloadingsavefare &&
                                            <img src="/images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </button>&nbsp;
                                    <button type="button" onClick={() => this.generateFares()} className="btn btn-alert"  disabled={opts['disabled']} >Reset Fares
                                        {this.state.isloadingresetfare &&
                                            <img src="/images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </button>
                                </div>
                            </div>
                            }
                            {!this.state.routeAddDone &&
                            <div className="card-footer">
                                <div className="row">
                                    <img style={{margin: 'auto'}} src={'/images/spinner-loder.gif'} alt="spinner-loder" />
                                </div>
                            </div>
                            }
                        </form>
                    </React.Suspense>
                </Page.Content>
            </BaseLayout>
        );
    }
}

class FareTable extends React.Component {

    render() {
        var onFareTableUpdate = this.props.onFareTableUpdate;
        var fares = this.props.fares;
        var stops = this.props.stops;
        return (
            <div>
                <table className="table table-bordered">
                    <tbody>
                    {(() => {
                        const fareRows = [];
                        for(var key in fares) {
                            for(var index in stops) {
                                var stop = stops[index];
                                if(key === stop.id){
                                    if(fares.hasOwnProperty(key)) {
                                        var value = fares[key];
                                        fareRows.push(<FareRow key={key} fareErrorTextbox ={this.props.fareErrorTextbox}  onFareTableUpdate={onFareTableUpdate} farecells={value} stopid={stop.id} stopname={stop.name} opts={this.props.opts}/>);
                                        //do something with value;
                                    } 
                                }
                            }
                        }
                        return fareRows;
                    })()}
                    </tbody>
                </table>
                {this.props.errors["fare"] &&
                    <span style={{color: "red"}}>{this.props.errors["fare"]}<br/><br/></span>
                }
            </div>
        );
    }
}

class FareRow extends React.Component {

    render() {
        var fareCols = this.props.farecells.map((fare, index) => {
            return (<EditableFareCell 
                    fareErrorTextbox ={this.props.fareErrorTextbox} 
                    key={index} onFareTableUpdate={this.props.onFareTableUpdate} stopid={this.props.stopid} stopname={this.props.stopname} cellData={{
                    type: this.props.stopid+"["+index+"]",
                    value: fare,
                    id: this.props.stopname
                }} opts={this.props.opts}/>);
        });
        return (
            <tr className="eachRow" >
                <td className="fontsize12">{this.props.stopname}</td>
                {fareCols}
            </tr>
        );
    }  
}

class EditableFareCell extends React.Component {

    render() {
        let key = 1;
        return (
            <td className="fontsize12 fareBox">
            {(() => {
                let farevalue = this.props.cellData.value;
                const options = [];
                if(farevalue === 0){
                    options.push(this.props.stopname);
                } else {
                    options.push(<input key={key++} type='text' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value || ''} onChange={this.props.onFareTableUpdate} className={"upercase width45 " + this.props.fareErrorTextbox[this.props.cellData.type]} disabled={this.props.opts}/>);
                }
                return options;
            })()}
            </td>
        );
    }
}

class ProductTable extends React.Component {

    render() {
        var onProductTableUpdate = this.props.onProductTableUpdate;
        var onRowUpdate = this.props.onRowUpdate;
        var rowAdd = this.props.onRowAdd;
        var rowDel = this.props.onRowDel;
        var errors = this.props.errors;
        var isDisabled = this.props.opts;
        var product = this.props.products.map(function(product) {
            return (<ProductRow onProductTableUpdate={onProductTableUpdate} onRowUpdate={onRowUpdate.bind(this)} product={product} onAddEvent={rowAdd.bind(this)} onDelEvent={rowDel.bind(this)} errors={errors} key={product.id}  opts={isDisabled}/>);
        });
        return (
            <div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>Stop Long Name</th>
                            <th>Stop Short Name</th>
                            <th>Kilometer</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product}
                    </tbody>
                </table>
            </div>
        );
    }
}

class ProductRow extends React.Component {
  
    onAddEvent() {
        this.props.onAddEvent(this.props.product);
    }
    
    onDelEvent() {
        this.props.onDelEvent(this.props.product);
    }
    
    onRowUpdate() {
        this.props.onRowUpdate(this.props.product);
    }
    
    render() {
        return (
            <tr className="eachRow" >
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "stageno",
                    value: this.props.product.stageno,
                    id: this.props.product.id,
                    readonly: true,
                }} opts={this.props.opts}/>
                <AutocompletestopCell  onRowUpdate={this.onRowUpdate.bind(this)} errors={this.props.errors} product={this.props.product} cellData={{
                    "type": "name",
                    value: this.props.product.name,
                    id: this.props.product.id,
                }} opts={this.props.opts}/>
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "stagecode",
                    value: this.props.product.stagecode,
                    id: this.props.product.id,
                    readonly: false,
                }} opts={this.props.opts}/>
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "km",
                    value: this.props.product.km,
                    id: this.props.product.id,
                    readonly: false,
                }} opts={this.props.opts}/>
                <td className="del-cell">
                    <input type="button" onClick={this.onDelEvent.bind(this)} value="-" className="del-btn"  disabled={this.props.opts}/>&nbsp;
                    <input type="button" onClick={this.onAddEvent.bind(this)} value="+" className="add-btn" disabled={this.props.opts}/>
                </td>
            </tr>
        );
    }
}

class EditableCell extends React.Component {

    render() {
        return (
            <td>
                <input readOnly={this.props.cellData.readonly} type='text' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value || ''} onChange={this.props.onProductTableUpdate} className="form-control upercase" disabled={this.props.opts}/>
                <span style={{color: "red"}}>
                    {this.props.errors.id === this.props.cellData.id && 
                        this.props.errors[this.props.cellData.type]
                    }
                </span>
            </td>
        );
    }
}

class AutocompletestopCell extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            StopList: []
        };
    }
    
    onProductTableUpdate(value) {
        this.props.cellData.value = value.toUpperCase();
        this.props.product.name = value.toUpperCase();
        this.props.product.stagecode = value.toUpperCase().substring(0, 3);
        this.props.product.stopId = value.toUpperCase().substring(0, 3);
        this.props.product.stopName = value;
        this.props.onRowUpdate(this.props.product);
    }
    
    componentDidUpdate = () => {
        if (this.state.shouldBlockNavigation) {
          window.onbeforeunload = true
        } else {
          window.onbeforeunload = undefined
        }
      }

    componentDidMount() {
        let thisstop = this;
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function(event) {
            var db          = event.target.result;
            var stops      = db.transaction('stops', 'readwrite').objectStore("stops");
            var stopsdata  = stops.getAll();
            stops.getAll().onsuccess = function(event) {
                let StopListFromInDB = stopsdata.result.map(stops => { return { value: stops.id, display: stops.longName }; });
                thisstop.setState({ StopList: [{ value: '', display: 'Select Stop' }].concat(StopListFromInDB) });
            };
        };
    }
    
    render() {
        var key = 0;
        return (
            <td>
                <ReactDropdownAutoComplete
                    getItemValue={item => item.display}
                    className="form-control upercase"
                    id={this.props.cellData.id}
                    name={this.props.cellData.type}
                    placeholder="Enter Stop Name"
                    data={this.state.StopList || []}
                    opts={this.props.opts}
                    renderItem={item => (
                        <div
                        key={key++}
                        role="button"
                        tabIndex="-1"
                        onClick={(val) => this.onProductTableUpdate(val)}
                        >{item.display}</div>
                    )}
                    icon="search"
                    iconColor="#ff000"
                    value={this.props.cellData.value}
                    onChange={(val) => this.onProductTableUpdate(val)}
                    onEnter={() => { /* TODO */ }}
                />
                <span style={{color: "red"}}>
                    {this.props.errors.id === this.props.cellData.id && 
                        this.props.errors[this.props.cellData.type]
                    }
                </span>
            </td>
        );
    }
}
  
export default EditRoute;
