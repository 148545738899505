// @flow
import React from "react";
import { Page,Button} from "tabler-react";
import { Alert } from 'react-bootstrap';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI.js";
import SessionUtils from "../utils/SessionUtils.js";
const vehicletypelist = [
    { value: 'SD', label: 'Standard' },
    { value: 'DD', label: 'Double Decker' },
    { value: 'MD', label: 'Mini Bus',},
    {  value: 'AC', label: 'AC Bus' }
  ];
class AddVehicle extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            
            vehiclenumber:'',
            depot:'',
            vehicletype:'',
            showmessage: false,
            responsemessage:'',
            isloading:false,
            fields: {},
            errors: {},
            agencyId:'',
            seatingCapacity:0,
            authkey: localStorage.getItem('authkey')
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }  
    valid(item, type) { 
        let itemValue = item.target.value;
        
        switch (type) {
            case 'vehiclenumber': {
                let fields = this.state.fields;
                fields[type] =itemValue; 
                const re = /^[0-9a-zA-Z\b]+$/;
                if (itemValue !== "" && !re.test(itemValue)) {
                    return false;
                } else {
                    return this.setState({ vehiclenumber: itemValue,fields })
                }
            }
            case 'vehicletype': {
                let fields = this.state.fields;
                fields[type] =itemValue; 
                return this.setState({ vehicletype: itemValue,fields })
            }
            case 'depot': {
                let fields = this.state.fields;
                fields[type] =itemValue; 
                return this.setState({ depot: itemValue,fields })
            }
            case 'seatingCapacity': {
                let { value, min, max } = item.target;
                value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                return this.setState({ seatingCapacity: value })
           }
          default:    
        }
    }
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    
    handleValidation(){
       
        let errors = {};
        let formIsValid = true;
        if(!this.state.vehiclenumber){
          formIsValid = false;
          errors["vehiclenumber"] = "Cannot be empty";
        }
        if(!this.state.vehicletype){
          formIsValid = false;
          errors["vehicletype"] = "Cannot be empty";
        }
        if(!this.state.depot){
            formIsValid = false;
            errors["depot"] = "Cannot be empty";
          }

        
        this.setState({errors: errors});
        return formIsValid;
    } 
    SubmitVehicle() {
        
       
        if(this.handleValidation()){
            this.setState({isLoader: true});   
        let obj = {}
        obj.agencyCode = localStorage.getItem('agencyname') ;
        obj.vehicleNumber = this.state.vehiclenumber;
        obj.vehicleType = this.state.vehicletype;
        obj.depot = this.state.depot;
        obj.seatingCapacity = this.state.seatingCapacity;
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        AgencyAPI.postVehicle(obj,
            (response) => {
        
                currentComponent.setState({
                            responsemessage:'Vehicle added successfully!',
                            vehiclenumber: '',
                            isLoader: false,
                            showmessage:true
                });
                currentComponent.loadCommentsFromServer();
            },
            (error) => {
                currentComponent.setState({isLoader: false,responsemessage:error.message,showmessage:true  });
            }
        );
    }return;
}
loadCommentsFromServer(){
        let thisstop = this;
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function(event) {
              var db          = event.target.result;
              var depots      = db.transaction('depot', 'readwrite').objectStore("depot");
              var depotdata  = depots.getAll();
              depots.getAll().onsuccess = function(event) {
                    let DepotListFromInDB = depotdata.result.map(depots => { return { value: depots.id, label: depots.depotname } })
                    thisstop.setState({ DepotList: DepotListFromInDB });
                };
            }
}
getroutetype(type,data){
    
    if(data === 'depot'){
        this.setState({depot:type.value});
    }else{
        this.setState({vehicletype:type.value});
    } 
}
componentDidMount() { 
    this.loadCommentsFromServer();
    
}

goBack(e) {
    this.props.history.push('/vehicle-list');
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
}

    render() {
        
        var stylescope = {
            splitterStyle: {
                margin: 5
              }
          };
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <div className="col-md-5">
                    <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                        <h4>{this.state.responsemessage}</h4> 
                        <p>
                            <Button onClick={(event)=>this.goBack(event)} className="btn-primary">View</Button>
                                <span> or </span>
                            <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                        </p>
                    </Alert>
                </div>
              
          )
        }
        
        return (
            <BaseLayout>
                <Page.Content>
               
                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                            {fadeinout }
                            <h3 className="card-title">Add Vehicle </h3>
                                <div className="row">
                                
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Vehicle Number<span className="form-required">*</span></label>
                                                <input type="text" className="form-control" value={this.state.vehiclenumber} maxLength="10" onChange={(item) => this.valid(item, 'vehiclenumber')} placeholder="Vehicle Number"></input>
                                                <span style={{color: "red"}}>{this.state.errors["vehiclenumber"]}</span>
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Depot<span className="form-required">*</span></label>
                                            <Select options={this.state.DepotList} onChange={(item) => this.getroutetype(item,'depot')} />
                                            <span style={{color: "red"}}>{this.state.errors["depot"]}</span>
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Vehicle Types<span className="form-required">*</span></label>
                                            <Select options={vehicletypelist}  onChange={(item) => this.getroutetype(item,'vehicletype')} />
                                            <span style={{color: "red"}}>{this.state.errors["vehicletype"]}</span>
                                        </div>
                                    </div>
                                    {SessionUtils.isSeatNumbersEnabled() === true &&
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Seating Capacity<span className="form-required">*</span></label>
                                            <input type="number" className="form-control" min="1" max="255" value={this.state.seatingCapacity} maxLength="3" onChange={(item) => this.valid(item, 'seatingCapacity')} placeholder="Seating Capacity"></input>
                                            <span style={{color: "red"}}>{this.state.errors["seatingCapacity"]}</span>
                                        </div>
                                    </div>}
                                    
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                        <label className="form-label"> &nbsp; </label>
                                        <button type="button" onClick={() => this.SubmitVehicle()} className="btn btn-primary">Add
                                        {this.state.isloading &&
                                                    <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                }
                                    </button>
                                        <a href="/vehicle-list"> <Button type="button"  color="primary mb-2 mr-2 float-right"> View </Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                   
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default AddVehicle;