// @flow
import * as React from "react";
import BaseLayout from "../BaseLayout";
import { Page, Grid, Button } from "tabler-react";
import { Form} from "tabler-react";
import { Row, Col, Alert } from 'react-bootstrap';
import PdfContainer from '../DocService/PdfContainer';
import Doc from "../DocService/DocService";
import DatePicker from "react-datepicker";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import ReactDropdownAutoComplete from "../autocomplete";
import DateUtils from "../../utils/DateUtils";
import { AnalyticsAPI } from "../../apis/AnalyticsAPI";
import { DutyAPI } from "../../apis/DutyAPI";
import { AgencyAPI} from "../../apis/AgencyAPI";


const { ExportCSVButton } = CSVExport;
const perPage = 10;

const options = {
  sizePerPage: 10,
  hideSizePerPage: false,
  hidePageListOnlyOnePage: true
};

function filterHeaderFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filterElement}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold',color:'#495057', fontSize:'11px' }}>
        {column.text}
        {sortElement}
      </div>
      
    </div>
  );
}

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold',color:'#495057', fontSize:'11px' }}>
        {column.text}
      </div>
    </div>
  );
}
function dateTimeFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    DateUtils.getFormattedDateTimeFromDateString(cell)
  );
 }
 function dateFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    DateUtils.getFormattedDateFromDateString(cell)
  );
 }


function headerRightFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ fontWeight: 'bold',color:'#495057', fontSize:'11px' }}>
        {column.text}
      </div>
    </div>
  );
}


class DatatableReportComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoader: false,
      data: [],
      columns: [{ dataField: "srNo", text: "Sr No" }],
      pageCount: 0,
      page: 1,
      sizePerPage: 10,
      from: new Date(),
      to: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      routeList: [],
      selectedRoute:'',
      showMessage: false,
      responseMessage: '',
      pageTitle: '',
      isTripTicketIssuedReport: false,
      isStageDetailReport: false,
      isStopwisePassengerCountReport: false,
      waybillsList: [],
      selectedWaybill: '',
      selectedVehicleId :'',
      selectedStopId: '',
      vehicleList: [],
      stopList: [],
      authkey: localStorage.getItem('authkey')
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  getDateFormat(date) {
    var month = (date.getMonth() + 1).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return date.getFullYear() + '-' + month + '-' + day;
  }

  loadReportDataFromServer(props) {
    let currentComponent = this;
    currentComponent.setState({ isLoader: true, showMessage: false, responseMessage: '',isTripTicketIssuedReport: false,
      isStageDetailReport: false,
      isStopwisePassengerCountReport: false });
    let reportType = '';
    let pathname = props.location.pathname;
    if(pathname === "/RouteAnalytics") {
      currentComponent.setState({pageTitle: "ROUTE ANALYTICS REPORT"})
      reportType = "routes?";
    } else if(pathname === "/RoutewiseDetail") {
      currentComponent.setState({pageTitle: "Routewise Detail Report"})
      reportType = "routes/datewise?";
    } else if(pathname === "/CashCollectionReport") {
      currentComponent.setState({pageTitle: "Cash Collection Report"})
      reportType = "waybill?";
    } else if(pathname === "/Conductorwisereport") {
      currentComponent.setState({pageTitle: "Conductor wise Report"})
      reportType = "conductors?";
    } else if(pathname === "/Depotwiseincomereport") {
      currentComponent.setState({pageTitle: "Depotwise income Report"})
      reportType = "depots?";
    } else if(pathname === "/Etimwise") {
      currentComponent.setState({pageTitle: "Etimwise revenue pattern report"})
      reportType = "etims?";
    } else if(pathname === "/etimsdatewise") {
      currentComponent.setState({pageTitle: "Etimwise detail report"})
      reportType = "etims/datewise?";
    } else if(pathname === "/vehicledatewise") {
      currentComponent.setState({pageTitle: "Vehicle Number detail report"})
      reportType = "vehicle/datewise?";
    } else if(pathname === "/Bustypewisereport") {
      currentComponent.setState({pageTitle: "Bus Type wise Report"})
      reportType = "servicetype?";
    } else if(pathname === "/Farewisereport") {
      currentComponent.setState({pageTitle: "Farewise Report"})
      reportType = "denom?";
    } else if(pathname === "/Tripticketissuedreport") {
      currentComponent.setState({pageTitle: "Trip Ticket Issued Report"})
      reportType = "routes/"+this.state.selectedRoute +"?";
    } else if(pathname === "/Doubledutyreport") {
      currentComponent.setState({pageTitle: "Double duty report"})
      reportType = "doubleduty?"
    } else if(pathname === "/StagewiseSurveyReport") {
      currentComponent.setState({pageTitle: "Stage wise Survey Report"})
      reportType = "stagesurvey?";
    } else if(pathname === "/Breakdownwisereport") {
      currentComponent.setState({pageTitle: "Break down wise report"})
      reportType = "breakdown?";
    } else if(pathname === "/inspectorreport") {
      currentComponent.setState({pageTitle: "inspector report"})
      reportType = "inspector?";
    } else if(pathname === "/Killometeroperated") {
      currentComponent.setState({pageTitle: "Kilometer operated by different depot"})
      reportType = "depots/vehicles/kms?";
    } else if(pathname === "/PayrollReport") {
      currentComponent.setState({pageTitle: "Payroll Report"})
      reportType = "payroll?";
    } else if(pathname === "/DepotwiseShortReport") {
      currentComponent.setState({pageTitle: "Depotwise Short Excess Report"})
      reportType = "depots/shortexcess?";
    } else if(pathname === "/Issuedsmartcard") {
      currentComponent.setState({pageTitle: "Issued smartcard report"})
      reportType = "pass?action=ISSUE";
    } else if(pathname === "/Smartcardrecharge") {
      currentComponent.setState({pageTitle: "Smart card recharge report"})
      reportType = "pass?action=RECHARGE";
    } else if(pathname === "/Diesel") {
      currentComponent.setState({pageTitle: "Diesel report"})
      reportType = "diesel?";
    } else if(pathname === "/transactions") {
      currentComponent.setState({pageTitle: "Transactions report"})
      reportType = "transactions?limit=2147483647";
    }else if(pathname === "/agentreport") {
      currentComponent.setState({pageTitle: "Agent report"})
      reportType = "agent/commission?";
    }else if(pathname === "/stagedetail") {
      currentComponent.setState({pageTitle: "Stagewise Detail Report"})
      reportType = "stagedetail?routeNo=" + this.state.selectedRoute + "&waybillNo=" + this.state.selectedWaybill;
    }else if (pathname === "/stop-wise-passenger-count") {
      currentComponent.setState({pageTile: "Stopwise Passenger Count Report", isStopwisePassengerCountReport: true})
      reportType = "stop/passenger/count?vehicleId=" + this.state.selectedVehicleId + "&stopId=" + this.state.selectedStopId;
      // reportType = "stop/passenger/count?vehicleId=c3731037-8366-4468-8044-790e8b90bf27&stopId=1caa6441-787e-4f41-9269-443838705518";
    }
    
    
     AnalyticsAPI.getReports(reportType,
        this.getDateFormat(this.state.from), 
        this.getDateFormat(this.state.to), 
        this.state.page, 
        perPage,
      (response) => {
        if (response.data.code === 2000 && response.data.message === "SUCCESS") {
          let columnsList = response.data.data.report.columns.map(
            (exp, index) => {
              if (exp === "depotName" || exp === "routeName" || exp === "waybillNo" || exp === "tripNo" ||  exp === "conductorName" || exp === "schedule"
               || exp === "etim" || exp === "stopName" || exp === "vehicleNo" || exp === "inspectorName" || exp === "passSerialNo" || exp === "busType" || exp === "inspectorId" || exp === "startStop" || exp === "endStop" || exp === "direction"  || exp === "productType" || exp === "paidType" || exp ==="agentCode" || exp === "agentName" || exp === "stage" || exp === "passengers" || exp === "commissionPercentage" || exp === "commissionAmount") {
                return {
                  dataField: exp, text: response.data.data.report.displayColumns[index],
                  filter: textFilter(),
                  headerFormatter: filterHeaderFormatter
                }
              } else if(exp === "date" || exp === "dutyDate"  || exp === "orderId" ) {
                return {
                  dataField: exp, text: response.data.data.report.displayColumns[index],
                  headerFormatter: filterHeaderFormatter
                }
              } else if( exp === "dutyStartTime" || exp === "dutyEndTime" || exp === "time" || exp === "createdAt" || exp === "dateTime") {
                return {
                  dataField: exp, text: response.data.data.report.displayColumns[index],
                  headerFormatter: filterHeaderFormatter,
                  formatter: dateTimeFormatter,
                  csvFormatter:dateTimeFormatter
                }
              } else if(exp === "etimDate" || exp === "vehicleDate") {
                return {
                  dataField: exp, text: response.data.data.report.displayColumns[index],
                  headerFormatter: filterHeaderFormatter,
                  formatter: dateFormatter,
                  csvFormatter:dateFormatter
                }
              }
              else {
                return {
                  dataField: exp, text:response.data.data.report.displayColumns[index],
                  align: 'right',
                  headerAlign: 'right',
                  headerFormatter: headerRightFormatter,
                }
              }
            }
          );

          //footer: response.data.data.summary.data[index] + "",
                  
          columnsList.unshift({ dataField: "srNo", text: "Sr No", headerFormatter: headerFormatter, fontSize:'11px' });

          if (!response.data.data.report.data) {
            response.data.data.report.data = [];
          }

          if (response.data.data.report.data) {
            let dataList = response.data.data.report.data.map(
                (exp, index) => {
                  exp["srNo"] = index + 1;
                  return exp;
                }
            );

            currentComponent.setState({
              data: dataList,
              columns: columnsList,
              isLoader: false,
              pageCount: Math.ceil(response.data.data.report.data.length / 10)
            });
            //currentComponent.handleTableChange();
          }
        } else {
          currentComponent.setState({ isLoader: false });
        }
      }
    );
  }

  loadRoutes() {
    let currentComponent = this;
    let routedata='';
    const dbName = "RIDLR AFCS";
    const version = 1; //versions start at 1
    var request = indexedDB.open(dbName, version);
    request.onsuccess = function(event) {
        var db          = event.target.result;
        var routes      = db.transaction('routes', 'readwrite').objectStore("routes");
        routedata  = routes.getAll();
        // get data from Stops object
        routes.getAll().onsuccess = function(event) {
        currentComponent.setState({ routeList:routedata.result });
        };
    }
  }

  componentDidMount() {
    this.initialLoad(this.props)
  }

  componentWillReceiveProps(nextProps) {
     this.initialLoad(nextProps);
  }

  initialLoad(allProps) {
    if(allProps.location.pathname === "/Tripticketissuedreport") {
      this.setState({pageTitle: "Trip Ticket Issued Report"})
      this.setState({
        isTripTicketIssuedReport: true,
        isStageDetailReport: false,
        responseMessage: "Please enter Route No!. ",
        isStopwisePassengerCountReport: false,
        showMessage: true
      });
      this.loadRoutes();
    }else if(allProps.location.pathname === "/stagedetail") {
      this.setState({pageTitle: "Stagewise Detail Report"})
      this.setState({
        isTripTicketIssuedReport: false,
        isStageDetailReport: true,
        isStopwisePassengerCountReport: false,
        responseMessage: "Please enter Route No. and Waybill No.!. ",
        showMessage: true
      });
      this.loadWaybills();
      this.loadRoutes();
    }else if(allProps.location.pathname === "/stop-wise-passenger-count") {
      this.setState({ pageTitle: "Stopwise Passenger Count Report"})
      this.setState({
        isStopwisePassengerCountReport: true,
        isTripTicketIssuedReport: false,
        isStageDetailReport: false,
        responseMessage: "Please enter Vehicle No and Stop",
        showMessage: true
      });
      this.loadVehicles();
      this.loadStops();
    } else {
      this.loadReportDataFromServer(allProps);
    }
  }

  loadVehicles() {
    let currentComponent = this;
    AgencyAPI.getVehicle(
        (response) => {
          currentComponent.setState({
            vehicleList: response.data.data,
            isLoader: false
          });
        },
        (error) => {
          currentComponent.setState({isLoader: false  });
        }
    );
  }

  loadStops() {
    let currentComponent = this;
    let stopsdata = '';
    const dbName = "RIDLR AFCS";
    const version = 1; //versions start at 1
    var request = indexedDB.open(dbName, version);
    request.onsuccess = function (event) {
      var db = event.target.result;
      var transaction = db.transaction(['stops'], 'readonly');
      var stops = transaction.objectStore('stops');
      stopsdata = stops.getAll();
      // get data from Stops object
      stops.getAll().onsuccess = function (event) {
        currentComponent.setState({
          stopList: stopsdata.result
        });
      }
    }
  }

  loadWaybills() {
    let currentComponent = this;
    DutyAPI.getList(DateUtils.getDateFormatted(this.state.endDate), 
    DateUtils.getDateFormatted(this.state.endDate), 0, 1000,
    (response) => {
        currentComponent.setState({
            isloadingviewduty:false,
            waybillsList: response.data.data.content,
            pageCount: Math.ceil(response.data.data.totalElements / response.data.data.size),
            isLoader: false
        });
    },
    (error) => {
        currentComponent.setState({isLoader: false, isloadingviewduty:false });
    });
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date
    });
  }

  handleSubmitClick() {
    let from = this.state.startDate;
    let to = this.state.endDate;

    if (from == null || to == null) {
      alert("Please select date range!");
      return;
    }
    if(this.state.isTripTicketIssuedReport && this.state.selectedRoute === '') {
        return this.setState({
          showMessage :true,
          responseMessage:"Please enter Route No!. "
        });
    } else if(this.state.isStageDetailReport && (this.state.selectedRoute === '' || this.state.selectedWaybill === '')) {
      return this.setState({
        showMessage: true,
        responseMessage: "Please enter Route No and Waybill No.!. "
      });
    }else if (this.state.isStopwisePassengerCountReport && (this.state.selectedVehicleId === '' || this.state.selectedStopId === '')) {
      return this.setState({
        showMessage: true,
        responseMessage: "Please enter Vehicle No and Stop."
      });
    } else {
      this.setState({ from: from, to: to }, () => {
        this.loadReportDataFromServer(this.props);
      });
    }
    
  }

  createPdf = (html) => Doc.createPdf(html);

  onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState((state) => {
      state[name] = value;
      return state;
    });
  }

  render() {
    const { editFields } = this.state;
    let fadeinout;
    if (this.state.showMessage) {
        fadeinout = (
            <Alert bsStyle="info" className="col-lg-4 m-2" onDismiss={this.handleDismiss}>
                <h4>{this.state.responseMessage}</h4>
            </Alert>
        )
    }
    let filterTransaction = (
      <Grid>
        <Row className="show-grid">
          <Col xs={2} md={2}>
            <label className="m-1">From : </label>
            <DatePicker name="transFromDate"
              dateFormat="yyyy/MM/dd"
              selected={this.state.startDate}
              onChange={this.handleChange}
              isClearable={true}
              placeholderText="I have been cleared!"
              className="form-control mb-2"
            />
          </Col>
          <Col xs={2} md={2}>
            <label className="m-1">To :   </label>
            <br/>
            <DatePicker name="transToDate"
              dateFormat="yyyy/MM/dd"
              selected={this.state.endDate}
              onChange={this.handleChangeEnd}
              isClearable={true}
              placeholderText="I have been cleared!"
              className="form-control mb-2"
            />
          </Col>
          {(this.state.isTripTicketIssuedReport || this.state.isStageDetailReport) && <Col xs={3} md={3}>
            <label className="m-1">Route No. : </label>
            <ReactDropdownAutoComplete
                getItemValue={item => item.agencyRouteId}
                className="form-control mb-2"
                id="route"
                name="route"
                placeholder="Enter Route No"
                data={this.state.routeList || []}
                renderItem={item => (
                    <div
                    role="button"
                    tabIndex="-1"
                    onClick={(val) => { editFields.name = val; }}
                    >{item.agencyRouteId}</div>
                )}
                icon="search"
                iconColor="#ff000"
                iconClick={() => { /* TODO */ }}
                value={this.state.selectedRoute}
                onChange={(val) => { this.setState({ selectedRoute: val }); }}
                onEnter={() => { }}
            />
          </Col>}
          {this.state.isStageDetailReport && <Col xs={3} md={3}>
            <label className="m-1">Waybill No. : </label>
                    <ReactDropdownAutoComplete
                      getItemValue={item => item.waybillNumber}
                      className="form-control mb-2"
                      id="waybill"
                      name="waybill"
                      placeholder="Enter Waybill Number"
                      data={this.state.waybillsList || []}
                      renderItem={item => (
                          <div
                          role="button"
                          tabIndex="-1"
                          onClick={(val) => { editFields.name = val; }}
                          >{item.waybillNumber}</div>
                      )}
                      value={this.state.selectedWaybill}
                      onChange={(val) => { this.setState({ selectedWaybill: val }); }}
                      onEnter={() => { }}
                  />
                </Col>}

          {this.state.isStopwisePassengerCountReport && <Col xs={1} md={2}>
            <label className="m-1">Vehicle No. : </label>
            <Form.Select name="vehicle" id="vehicle" onChange={(val) => { this.setState({ selectedVehicleId: val.target.value }); }}>
              <option key="all" value="">Select Vehicle</option>
              {this.state.vehicleList
                  .map((item, index) =>
                      <option key={item.id} value={item.id}>{item.vehicleNumber}</option>)
              }
            </Form.Select>
          </Col>}

          {this.state.isStopwisePassengerCountReport && <Col xs={1} md={3}>
            <label className="m-1">Stop: </label>
            <Form.Select name="stop" id="stop" onChange={(val) => { this.setState({ selectedStopId: val.target.value }); }}>
              <option key="all" value="">Select Stop</option>
              {this.state.stopList
                  .map((item, index) =>
                      <option key={item.id} value={item.id}>{item.longName}</option>)
              }
            </Form.Select>
          </Col>}

          <Col xsHidden md={2}>
            <br/>
            <Button type="button" onClick={this.handleSubmitClick} color="primary mb-2 ">Submit</Button>
          </Col>
        </Row>
      </Grid>
    );
    return (
      <BaseLayout>
        <Page.Content>
          <Page.Header title={this.state.pageTitle} />
          {fadeinout}
          {filterTransaction}
          <div className="card">
            <div className="card-body" >
              <PdfContainer createPdf={this.createPdf} xsHidden md={3}>
                <div className="paddingTop15">
                  <ToolkitProvider
                    keyField="srNo"
                    data={this.state.data}
                    columns={this.state.columns}
                    exportCSV={ { fileName: this.state.pageTitle.toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false } }
                  >
                    {
                      props => (
                        <div>
                          <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                          <hr />
                          <BootstrapTable
                            {...props.baseProps} bootstrap4
                            keyField="srNo"
                            data={this.state.data}
                            columns={this.state.columns}
                            bordered={false}
                            pagination={paginationFactory(options)}
                            filter={filterFactory()}
                            noDataIndication="No Data Found" />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </PdfContainer>
            </div>
          </div>
        </Page.Content>
      </BaseLayout>
    );
  }

}

export default DatatableReportComponent;
