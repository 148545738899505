import API from '../apis/API';
import MessageResponse from '../enums/MessageResponse';
import { ViewUtils } from '../utils/ViewUtils';

export const RFIDCardCommunicator = {
    postRFIDData
};

function postRFIDData(outputcommand, callbackSuccess, callbackError) {
    API.postText("http://localhost:8080/rfid_data", outputcommand,
        (response) => {
            let msgHandler = cardReaderErrors(response.data.trim());
            if (msgHandler !== null) {
                response.setMsgHandler(msgHandler);
                callbackError(response);
            } else {
                callbackSuccess(response);
            }
        },
        (error) => {
            error.setMsgHandler(ViewUtils.createMessageHandler('RFID Card Reader API is down. Please try after some time', MessageResponse.ERROR));
            error.setMessage(error.msgHandler.props.message);
            callbackError(error);
        }
    );
}

function cardReaderErrors(data) {
    let msgHandler = null;
    if (data !== '') {
        if (data.startsWith("SERIAL FAULT")) {
            msgHandler = ViewUtils.createMessageHandler('Card Reader Not Connected', MessageResponse.ERROR);
        } else if (data.startsWith("ERROR IN TRANSMISSION OF FIRST BYTE >>")) {
            msgHandler = ViewUtils.createMessageHandler('Error Transferring Data to Card', MessageResponse.ERROR);
        } else if (data.startsWith("ERROR IN TRANSMISSION OF SECOND BYTE >>")) {
            msgHandler = ViewUtils.createMessageHandler('Invalid Request', MessageResponse.ERROR);
        } else if (data.startsWith("ERROR IN TRANSMISSION BYTE >>")) {
            msgHandler = ViewUtils.createMessageHandler('Unable to Write Data from Card Reader', MessageResponse.ERROR);
        }
    } else {
        msgHandler = ViewUtils.createMessageHandler('No Response from Card Reader', MessageResponse.ERROR);
    }
    return msgHandler;
}

