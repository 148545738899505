// @flow
import React from "react";
import { Page, Form, Grid } from "tabler-react";
import { Button } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI";
import "./alert.js";
import "../App.css";
import { ETIMCommunicator, DeviceTranslator } from '../_components';
import MessageResponse from "../enums/MessageResponse";
import { ViewUtils } from '../utils/ViewUtils';
import { DutyAPI } from "../apis/DutyAPI";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DateUtils from "../utils/DateUtils";
import BootstrapTable from 'react-bootstrap-table-next';
import DutyList from './DutyList';
import { isMobile } from "react-device-detect";
import { InventoryAPI } from "../apis/InventoryAPI";
import RepeatWaybill from "../pages/RepeatWaybill";

const options = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true
};

const { ExportCSVButton } = CSVExport;
const selectOptions = {
    'ENDED': 'ENDED',
    'PENDING': 'PENDING'
};
function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {filterElement}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
                {column.text}
                {sortElement}
            </div>
        </div>
    );
}

function conductorFormatter(cell, row) {
    return (
        <div style={{ wordWrap: 'break-word' }}>{cell}</div>
    );
}

function actionFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div style={{ fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
                {column.text}
            </div>
        </div>
    );
}


class CashCollect extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            ConductorList: [],
            vehicleListFromAPI: [],
            conductorListFromDb: [],
            driverListFromDb: [],
            selectedMemos: "",
            selectedConductor: "",
            selectedDutyDate: '',
            validationError: "",
            conductorid: '',
            //agencyCode: 'GMCBL', //new agency
            redirect: false,
            name: '',
            addstatusclass: 'none',
            readonly: false,
            conductorName: '',
            dutyStatus: '',
            cashCollected: '',
            dutyStateCollected: false,
            cashSubmit: '',
            cashNote: '',
            hidesubmit: 'hidesubmitbtn',
            isloading: false,
            iscashsubmit: false,
            iscashCollected: false,
            isloadingerase: false,
            isloadingreadetm: false,
            dutyid: '',
            errors: {},
            fields: {},
            matchcash: 'notcashmatch',
            authkey: localStorage.getItem('authkey'),
            etmBResponseString: '',
            etmBWayBillNumber: '',
            conductorPendingList: [],
            selectedConductorName: '',
            msgHandler: null,
            imeiNumber: '',
            simId:'',
            swVersion:'',
            isLoadingDutyList: false,
            isLoadingRepeat:false,
            showReplicateDialog: false,
            replicateObj: null,
            selectedReplicateDate: new Date(),
            repeatMsgHandler: null,
            isReadETM: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.searchFilterData = this.searchFilterData.bind(this);
        this.fetchAgencyEndedOrPendingDuties = this.fetchAgencyEndedOrPendingDuties.bind(this);
        this.setMessagesFromDutyList = this.setMessagesFromDutyList.bind(this);
        this.handleCloseReplicateDialog = this.handleCloseReplicateDialog.bind(this);
        this.repeatWaybillHandler = this.repeatWaybillHandler.bind(this);
    }

    componentDidMount() {
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        let currentComponent = this;
        let ConductorListFromApi = [];
        let driverListFromDb1 = [];
        let condata;
        
        request.onsuccess = function (event) {
            var db = event.target.result;
            var conductor = db.transaction('conductor', 'readwrite').objectStore("conductor");
            var conductordata = conductor.getAll();
            // get data from conductor
            conductor.getAll().onsuccess = function (event) {
                conductordata.result.forEach(conductor => {
                    if (conductor.designation === 'DRIVER') {
                        var driverData = {
                            value: conductor.employeeId,
                            name: conductor.name,
                            designation: conductor.designation,
                            employeeCode: conductor.employeeCode,
                            label: conductor.name + "/" + conductor.employeeCode
                        };
                        driverListFromDb1.push(driverData);
                    }
                    if (conductor.designation === 'CONDUCTOR') {
                        condata = {
                            value: conductor.employeeId,
                            name: conductor.name,
                            designation: conductor.designation,
                            employeeCode: conductor.employeeCode,
                            label: conductor.name + "/" + conductor.employeeCode
                        };
                        ConductorListFromApi.push(condata);
                    }
                });
                currentComponent.setState({ driverListFromDb: driverListFromDb1, conductorListFromDb: ConductorListFromApi, ConductorList: [{ value: '', label: 'Select Conductor' }].concat(ConductorListFromApi) });
                currentComponent.mapData();
            };
        };

        AgencyAPI.getVehicle(
            (response) => {
                if (response.data.code === 2000) {
                    let deviceIdFromApi = response.data.data.map(device => { return { value: device.id, label: device.vehicleNumber }; });
                    currentComponent.setState({ vehicleListFromAPI: deviceIdFromApi });
                    currentComponent.mapData();
                }
            }
        );

        currentComponent.fetchAgencyEndedOrPendingDuties();
    }

    fetchAgencyEndedOrPendingDuties() {
        /* Detail of Cash Collection */
        let currentComponent = this;
        currentComponent.setState({isLoadingDutyList: true});
        //DutyAPI.getAgencyDutyEndedOrPending(
        DutyAPI.getListFilter("", "", "ENDED,PENDING",
            (response) => {
                currentComponent.setState({conductorPendingList: response.data.data.content, isLoadingDutyList: false});
                currentComponent.mapData();
                return;
            },
            (error) => {
                currentComponent.setState({
                    conductorPendingList: [],
                    isLoadingDutyList: false
                });
                if(error.data.code !== 3206) {
                    currentComponent.setState({
                        msgHandler: error.msgHandler
                    });
                }
                return;
            }
        );
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    mapData() {
        let currentComponent = this;
        let tempList = [];
        currentComponent.state.conductorPendingList.forEach((obj) => {
            currentComponent.state.conductorListFromDb.find(function(conductor) {
                if (conductor.value === obj.conductorId) {
                    obj.conductorName = conductor.label;
                }
                return obj.conductorName;
            });
            currentComponent.state.driverListFromDb.find(function(driver) {
                if (driver.value === obj.driverId) {
                    obj.driverName = driver.label;
                }
                return obj.driverName;
            });
            currentComponent.state.vehicleListFromAPI.find(function (vehicle) {
                if (vehicle.value === obj.vehicleId) {
                    obj.vehicleName = vehicle.label;
                }
                return obj.vehicleName;
            });
            obj.expanded = false;
            tempList.push(obj);
        });

        currentComponent.setState({ conductorPendingList: tempList,  isLoadingDutyList: false });
                
    }

    valid(item, type) {
        let itemValue = item.target.value;
        switch (type) {
            case 'cashSubmit': {
                return this.setState({ cashSubmit: itemValue });
            }
            case 'cashNote': {
                return this.setState({ cashNote: itemValue });
            }
            default:
        }
    }

    GetDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    readEtmWayBill(mode) {
        let currentComponent = this;
        currentComponent.setState({ isloadingreadetm: true, isloadingerase: true });
        ETIMCommunicator.postDownloadDataDeviceDetails(
            (response) => {
                currentComponent.setState({ isloadingreadetm: false, isloadingerase: false });
                currentComponent.setState({
                    etmBResponseString: response.data,
                });
                return currentComponent.sendDataToTranslator(mode);
            },
            (error) => {
                currentComponent.setState({
                    isloadingreadetm: false,
                    isloadingerase: false,
                    msgHandler: error.msgHandler
                });
            }
        );
    }

    sendDataToTranslator(mode) {
        let currentComponent = this;
        currentComponent.setState({ isloadingreadetm: true, isloadingerase: true });
        let command = currentComponent.state.etmBResponseString;
        DeviceTranslator.getDeviceDetails(command,
            (response) => {
                let params = response.data.data;
                if ("simSerialNumber" in params){
                    let simno = params.simSerialNumber;
                    currentComponent.setState({ simId:  simno.slice(0, -1) });
                }
                if ("osVersion" in params){
                    currentComponent.setState({ swVersion: params.osVersion + "," + params.date });
                }
                if ("imeiNumber" in params){
                    currentComponent.setState({ imeiNumber: params.imeiNumber});
                }
                let obj = {}; 
                obj.imei =currentComponent.state.imeiNumber;
                obj.simSerialNumber = currentComponent.state.simId;
                obj.softwareVersion = currentComponent.state.swVersion;
                InventoryAPI.putDevice(params.deviceSerialId, obj,
                    (response) => {
                        //console.log(response); 
                    }
                );
                currentComponent.setState({ etmBWayBillNumber: params.hexWayBillNo, isloadingreadetm: false, isloadingerase: false });
                if (currentComponent.state.etmBWayBillNumber !== "") {
                    switch (mode) {
                        default:
                        case "READ":
                            return currentComponent.downloadEtmTransactions();
                        case "ERASE":
                            return currentComponent.fetchWaybillData();
                    }
                } else {
                    currentComponent.setState({
                        isloadingreadetm: false,
                        isloadingerase: false,
                        msgHandler: ViewUtils.createMessageHandler("No Waybill found in ETIM", MessageResponse.ERROR)
                    });
                }
            },
            (error) => {
                currentComponent.setState({
                    isloadingreadetm: false,
                    isloadingerase: false,
                    msgHandler: error.msgHandler
                });
            }
        );
    }

    downloadEtmTransactions() {
        let currentComponent = this;
        currentComponent.setState({ isloadingreadetm: true, isloadingerase: true });
        ETIMCommunicator.postDownloadDataDeviceData(
            (response) => {
                DeviceTranslator.convertCollections(currentComponent.state.etmBWayBillNumber, response.data,
                    (response1) => {
                        currentComponent.setState({ isloadingreadetm: false, isloadingerase: false });
                        var params = response1.data;
                        if (params.data) {
                            if (params.data[0]) {
                                return currentComponent.populateCollectionData(params.data[0], true, true);
                            } else {
                                return currentComponent.populateCollectionData(params.data, true, true);
                            }
                        } else {
                            return currentComponent.setState({ msgHandler: ViewUtils.createMessageHandler(params.message, MessageResponse.INFO) });
                        }
                    },
                    (error) => {
                        currentComponent.setState({
                            isloadingreadetm: false,
                            isloadingerase: false,
                            msgHandler: error.msgHandler
                        });
                    }
                );
            },
            (error) => {
                currentComponent.setState({
                    isloadingreadetm: false,
                    isloadingerase: false,
                    msgHandler: error.msgHandler
                });
            }
        );
    }

    fetchWaybillData() {
        let currentComponent = this;
        currentComponent.setState({ isloadingreadetm: true, isloadingerase: true });
        DutyAPI.postListWaybills(currentComponent.state.etmBWayBillNumber,
            (response) => {
                var params = response.data;
                if (params !== '') {
                    if (params.data) {
                        if (params.data[0]) {
                            if (params.data[0].dutyState === 'STARTED') {
                                currentComponent.setState({ isloadingreadetm: false, isloadingerase: false,
                                    msgHandler: ViewUtils.createMessageHandler('Cannot Erase Data - Duty is in Started State. Please click on Read Data ETIM', MessageResponse.ERROR)
                                });
                                return currentComponent.populateCollectionData(params.data[0], true, false);
                            } else {
                                return currentComponent.eraseWaybill();
                            }
                        } else {
                            return currentComponent.eraseWaybill();
                        }
                    } else {
                        return currentComponent.eraseWaybill();
                    }
                }
            },
            (error) => {

            }
        );
    }

    eraseWaybill() {
        this.setState({ isloadingreadetm: true, isloadingerase: true });
        let formData = this;
        ETIMCommunicator.postDownloadDataEraseData(
            (response) => {
                formData.setState({
                    isloadingreadetm: false,
                    isloadingerase: false,
                    msgHandler: ViewUtils.createMessageHandler("Erased the Waybill Data Successfully !", MessageResponse.SUCCESS)
                });
            },
            (error) => {
                formData.setState({
                    isloadingreadetm: false,
                    isloadingerase: false,
                    msgHandler: error.msgHandler
                });
            }
        );
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (this.state.cashSubmit === "") {
            formIsValid = false;
            errors["cashSubmit"] = "Cannot be empty";
        }
        if (this.state.cashSubmit !== "" && this.state.cashCollected !== "" && parseInt(this.state.cashSubmit, 10) !== parseInt(this.state.cashCollected, 10) && this.state.cashNote === "") {
            formIsValid = false;
            errors["cashNote"] = "Cannot be empty";
        }
        if ((this.state.dutyStatus === "PENDING" || this.state.dutyStatus === "MISMATCH") && this.state.cashNote === "") {
            formIsValid = false;
            errors["cashNote"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    submitcash() {
        if (this.handleValidation()) {
            this.setState({ iscashsubmit: true });
            let obj = {};
            let currentComponent = this;
            let collectiondetails = {};
            obj.state = "COLLECTED";
            collectiondetails.amountCollected = this.state.cashSubmit;
            collectiondetails.comments = this.state.cashNote;
            obj.collectionDetails = collectiondetails;
            DutyAPI.putDuty(this.state.dutyid, obj,
                (response) => {
                    var collectStates = ["COLLECTED", "COLLECTED_WITH_ETIM_LOCKED", "COLLECTION_MISMATCH"];
                    if (collectStates.some(v => response.data.data.dutyState === v)) {
                        currentComponent.setState({ dutyStateCollected: true });
                    }
                    let link = "/Waybilldetail/" + response.data.data.waybillNumber;
                    let waybillReportView = (
                            <p>
                                <a href={link} className="btn btn-primary m-2" >Waybill Report</a>
                            </p>
                    );
                
                    currentComponent.setState({
                        iscashsubmit: false,
                        iscashCollected: true,
                        hidesubmit: 'hidesubmitbtn',
                        conductorName: '',
                        memoid: '',
                        dutyStatus: '',
                        cashCollected: '',
                        waybillNumber: '',
                        dutyid: '',
                        cashSubmit: '',
                        cashNote: '',
                        msgHandler: ViewUtils.createMsgHandlerCustomView("Cash Collected successfully!", MessageResponse.SUCCESS, waybillReportView)
                    });
                    if(currentComponent.state.isReadETM) {
                        currentComponent.showReplicateWaybillDialog();
                    }
                    currentComponent.fetchAgencyEndedOrPendingDuties();
                },

                (error) => {
                    currentComponent.setState({
                        iscashsubmit: false,
                        hidesubmit: 'hidesubmitbtn',
                        msgHandler: error.msgHandler
                    });
                    return;
                }
            );
        }
    }

    populateCollectionData(data, showMessage, isReadETM) {
        let currentComponent = this;
        let conductor_name = '';
        let vehicleName = '';
        let driverName = '';
        if (data.dutyState === 'ENDED') {
            if(showMessage) {
                currentComponent.setState({ 
                    msgHandler: ViewUtils.createMessageHandler("Duty has been Ended. Please do the Collection", MessageResponse.SUCCESS)
                });    
            }
            currentComponent.setState({ isloadingreadetm: false});
            currentComponent.setState({ readonly: false, hidesubmit: '', addstatusclass: 'green', dutyStateCollected: false });
        } else if (data.dutyState === 'PENDING') {
            currentComponent.setState({ readonly: false, hidesubmit: '', addstatusclass: 'pending', dutyStateCollected: false });
        } else if (data.dutyState === 'STARTED' || data.dutyState === 'ASSIGNED') {
            currentComponent.setState({ readonly: true, hidesubmit: 'hidesubmitbtn', addstatusclass: 'green', dutyStateCollected: false });
        } else if (data.dutyState === 'COLLECTED' || data.dutyState === 'COLLECTED_WITH_ETIM_LOCKED' || data.dutyState === 'COLLECTION_MISMATCH') {
            if(showMessage) {
                currentComponent.setState({ 
                    msgHandler: ViewUtils.createMessageHandler("Cash has already been Collected. You can Erase the Data from the ETIM!", MessageResponse.SUCCESS)
                });    
            }
            currentComponent.setState({ readonly: true, hidesubmit: 'hidesubmitbtn', addstatusclass: 'green', dutyStateCollected: true });
        } else {
            currentComponent.setState({ hidesubmit: 'hidesubmitbtn', addstatusclass: 'none', dutyStateCollected: false });
        }
        this.state.ConductorList.find(function (element) {
            if (element.value === data.conductorId) {
                conductor_name = element.name;
            }
            return conductor_name;
        });
        this.state.vehicleListFromAPI.find(function (element) {
            if (element.value === data.vehicleId) {
                vehicleName = element.label;
            }
            return vehicleName;
        });
        this.state.driverListFromDb.find(function (element) {
            if (element.value === data.driverId) {
                driverName = element.name;
            }
            return driverName;
        });

        
        currentComponent.setState({
            data: data,
            conductorid: data.conductorId,
            conductorName: conductor_name,
            driverName: driverName,
            vehicleName: vehicleName,
            waybillNumber: data.waybillNumber,
            dutyStatus: data.dutyState,
            cashCollected: data.totalAmount,
            dutyid: data.id,
            isloadingreadetm: false,
            isloadingconductor: false
        });
        if(isReadETM) {
            let obj = {};
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.memoId = "";
            obj.conductorId = data.conductorId;
            obj.driverId = data.driverId;
            obj.deviceSerialId = data.deviceSerialId;
            obj.vehicleId = data.vehicleId;
            obj.conductorName = conductor_name;
            obj.driverName = driverName;
            obj.vehicleName = vehicleName;
            obj.manualFare = data.manualFare === 1 ? 1 : 0;
            currentComponent.setState({
                replicateObj: obj,
                isReadETM: true
             })
        }
        return;
    }

    searchFilterData = (conductorId, dutyDate, showErrorMessage) => {
        let currentComponent = this;
        currentComponent.setState({conductorPendingList: [], msgHandler: null, isLoadingDutyList: true });
        DutyAPI.getListFilter('', conductorId, 'ENDED,PENDING',
            (response) => {
                currentComponent.setState({conductorPendingList: response.data.data.content});
                currentComponent.mapData();
                currentComponent.setState({isLoadingDutyList: false});
                return;
            },
            (error) => {
                if (showErrorMessage) {
                    currentComponent.setState({
                        showmessage: true,
                        msgHandler: error.msgHandler,
                        responsemessage: error.message,
                        isLoadingDutyList: false
                    });
                }
                currentComponent.setState({
                    conductorName: '',
                    waybillNumber: '',
                    dutyStatus: '',
                    cashCollected: '',
                    isloadingreadetm: false,
                    isloadingconductor: false,
                    dutyStateCollected: false,
                    hidesubmit: 'hidesubmitbtn',
                    isLoadingDutyList: false
                });
                return;
            });
        /* Detail of Cash Collection */
        
    }

    
    setMessagesFromDutyList(msgHandler) {
        let currentComponent = this;
        currentComponent.setState({msgHandler: msgHandler});
    }

    showReplicateWaybillDialog() {
        this.setState({
            showReplicateDialog: true,
        });
    }

    handleCloseReplicateDialog() {
        this.setState({
            showReplicateDialog: false
        });
    }

    repeatWaybillHandler(success, msgHandler) {
        let currentComponent = this;
        currentComponent.handleCloseReplicateDialog();
        currentComponent.setState({repeatMsgHandler: msgHandler, replicateObj: null, isReadETM: false});
    }


    render() {
        const { columns = [{
            dataField: 'waybillNumber',
            text: 'WAYBILL #',
            headerStyle: { width: '110px' },
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'conductorName',
            text: 'Conductor',
            filter: textFilter(),
            headerFormatter: headerFormatter,
            formatter: conductorFormatter
        }, {
            dataField: 'dutyDate',
            headerStyle: { width: '110px' },
            text: 'Duty Date',
            headerFormatter: headerFormatter
        }, {
            dataField: 'dutyState',
            text: 'STATUS',
            formatter: cell => selectOptions[cell],
            filter: selectFilter({
                options: selectOptions
            }),
            headerStyle: { width: '110px' },
            headerFormatter: headerFormatter
        }, {
            dataField: 'action',
            text: '',
            csvExport: false,
            isDummyField: true,
            headerStyle: { width: '20px' },
            headerFormatter: actionFormatter,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <i onClick={() => this.populateCollectionData(row, false, false)} className="active download-icon fe fe-download"></i>
                );
            }
        }] } = this.state;

        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        var opts = {};
        if (this.state.readonly === true) {
            opts['readOnly'] = 'readOnly';
        }
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header
                        title="Collect duty"
                    />
                    <React.Fragment>
                            {!isMobile && <RepeatWaybill showReplicateDialog={this.state.showReplicateDialog} replicateObj={this.state.replicateObj} repeatWaybillHandler={this.repeatWaybillHandler} handleCloseReplicateDialog={this.handleCloseReplicateDialog} isDutyPage={false}></RepeatWaybill>}
                            {!isMobile && <Grid.Row md={20} lg={20}>
                                <Grid.Col lg={20} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                <Grid.Col lg={4} className="">
                                    <Button type="button" onClick={() => this.readEtmWayBill("READ")} hidden={this.state.isloadingreadetm} className="btn btn-primary" >
                                        Read DATA ETM
                                        </Button>
                                    {this.state.isloadingreadetm &&
                                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                    }
                                    <Button type="button" onClick={() => this.readEtmWayBill("ERASE")} hidden={this.state.isloadingerase} className="btn btn-primary m-5" >
                                        Erase Data
                                        </Button>
                                    {this.state.isloadingerase &&
                                        <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                    }
                                </Grid.Col>
                            </Grid.Row>}
                            {this.state.repeatMsgHandler}
                            {this.state.msgHandler}
                            {!isMobile && <hr></hr>}
                            {!isMobile && <div className="cash-collection">
                                <div className="div-width-50-percent">
                                    <Form.Group label="Conductor Details"></Form.Group>
                                    <Grid.Row md={12} lg={12}>
                                        <Grid.Col lg={11} className="bgdetails">
                                            <Grid.Row lg={12} className="detailschild">
                                                <Grid.Col lg={5} className="detailschildreadonlypad"> <label className="floatright">Conductor Name</label></Grid.Col>
                                                <Grid.Col lg={6} className="detailschildreadonly">{this.state.conductorName} </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row lg={11} className="detailschild">
                                                <Grid.Col lg={5} className="detailschildreadonlypad"> <label className="floatright">waybillNumber</label></Grid.Col>
                                                <Grid.Col lg={6} className="detailschildreadonly">{this.state.waybillNumber} </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row lg={11} className="detailschild">
                                                <Grid.Col lg={5} className="detailschildreadonlypad"> <label className="floatright">Status</label></Grid.Col>
                                                <Grid.Col lg={6} className={this.state.addstatusclass}>{this.state.dutyStatus} </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row lg={11} className="detailschild">
                                                <Grid.Col lg={5} className="detailschildreadonlypad"> <label className="floatright">Cash To Collected</label></Grid.Col>
                                                <Grid.Col lg={6} className="detailschildreadonly">{this.state.cashCollected} </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row lg={11} className="detailschild"  >
                                                <Grid.Col lg={5} className="detailschildreadonlypad"> <label className="floatright" >Cash Submited</label></Grid.Col>
                                                <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                    <input {...opts} type="text" className={'form-control ' + this.state.matchcash} value={this.state.cashSubmit} onChange={(item) => this.valid(item, 'cashSubmit')} ></input>
                                                    <span style={{ color: "red" }}>{this.state.errors["cashSubmit"]}</span>
                                                </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row lg={11} className="detailschild" >
                                                <Grid.Col lg={5} className="detailschildreadonlypad"> <label className="floatright">Note</label></Grid.Col>
                                                <Grid.Col lg={6} className="paddingnull" bsStyle="padding:0px">
                                                    <textarea cols="2"   {...opts} placeholder="Enter note in case of cash mismatch" value={this.state.cashNote} className="form-control " onChange={(item) => this.valid(item, 'cashNote')} >{this.state.cashNote}</textarea>
                                                    <span style={{ color: "red" }}>{this.state.errors["cashNote"]}</span>
                                                </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row lg={11} className={'detailschild  ' + this.state.hidesubmit}>
                                                <Grid.Col lg={5} className="detailschildreadonlypad"> <label></label></Grid.Col>
                                                <Grid.Col lg={6} className="">
                                                    <Button type="button" onClick={() => this.submitcash()} className="btn btn-primary" >
                                                        Submit
                                                {this.state.isloading &&
                                                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                        }
                                                    </Button>

                                                </Grid.Col>
                                            </Grid.Row>
                                        </Grid.Col>
                                    </Grid.Row>
                                </div>
                                <div className="div-width-50-percent">
                                    {this.state.conductorPendingList.length > 0 && <div><Form.Group label="Pending Collections"></Form.Group>
                                        <ToolkitProvider
                                            keyField="id"
                                            data={this.state.conductorPendingList}
                                            columns={columns}
                                            exportCSV={{ fileName: "COLLECTION-LIST".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
                                        >
                                            {
                                                props => (
                                                    <div>
                                                        <BootstrapTable
                                                            {...props.baseProps} bootstrap4
                                                            bordered={false}
                                                            pagination={paginationFactory(options)}
                                                            filter={filterFactory()}
                                                            noDataIndication="No Data Found" >
                                                        </BootstrapTable>
                                                        <hr />
                                                        <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                                                    </div>
                                                )
                                            }
                                        </ToolkitProvider>
                                    </div>}
                                </div>
                            </div>}
                            {isMobile && <DutyList dutyList={this.state.conductorPendingList} conductorList={this.state.ConductorList} searchFilterData={this.searchFilterData} fetchDutyList={this.fetchAgencyEndedOrPendingDuties} setMessage={this.setMessagesFromDutyList} showDateFilter={false} isViewDuty={false} isLoadingDutyList={this.state.isLoadingDutyList} /> }
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default CashCollect;
