import React from 'react';
import "./alert.js";
import "../App.css";
class Privacypolicy extends React.Component {
    
    render() {
        var stylescope = {
            splitterStyle: {
                margin: 20
            }
       };
        return(
            
                <div>
                    <div>
                    
                        <main className='main-content bgc-grey-100'>
                        <div id='mainContent'>
                            <div className="full-container">
                            <h1 className="text-center"><strong><u>PRIVACY POLICY</u></strong></h1>
<p style={stylescope.splitterStyle}>Birds Eye Systems Private Limited is a registered company and owns a mobile application <a href="http://www.ridlr.in/"> <em>http://www.ridlr.in</em></a> (hereinafter referred to as&nbsp; &ldquo;We&rdquo; or &ldquo;Ridlr&rdquo;), We value your trust and privacy. This Privacy Policy is in accordance with the Indian Information Technology Act, 2000 and the Information Technology (Reasonable security and procedures and sensitive personal data or information) Rules, 2011 prescribed thereunder, (&ldquo;Applicable Law&rdquo;) and provides you with details about the manner in which your data is collected, stored and used by us. This Privacy Policy is intended to provide you with clear and complete information about your treatment of the personally identifiable data and information that Ridlr may collect in connection with the services offered by Ridlr and the way your personally identifiable information and data is stored and used by Ridlr. You are advised to read this Privacy Policy carefully, if you do not agree to the terms of the policy, please do not use or access Ridlr.</p>
<p style={stylescope.splitterStyle}>Personally Identifiable Information means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with Ridlr, is capable of identifying such person. Therefore information such as your name, age, gender, date of birth, address, telephone/mobile number, e-mail ID, current location, and/or any other personal information that we collect, in combination with any other information about you, which is capable of identifying yourself, is known as personally identifiable information.</p>
<ol>
<li><strong><u>What this Privacy Policy Covers</u></strong></li>
</ol>
<p style={stylescope.splitterStyle}>&nbsp;This Privacy Policy covers Ridlr&rsquo;s treatment of your personally identifiable information that Ridlr may collect when you download Ridlr.</p>
<p style={stylescope.splitterStyle}>&nbsp;This policy also covers Ridlr&rsquo;s treatment of your personally identifiable information that Ridlr&rsquo;s business partners share with Ridlr, which you may have disclosed to such business partners.</p>
<p style={stylescope.splitterStyle}>&nbsp;This policy does not apply to companies or entities that Ridlr does not own or control, or has no contractual relationship with or to people that Ridlr does not employ, engage or manage.</p>
<p style={stylescope.splitterStyle}>&nbsp;This Privacy Policy also covers information about the mobile/wireless device such as type of Mobile device, mobile device&rsquo;s unique Device ID, The IP Address of your mobile Device, Operating Systems and the type of mobile internet browser (&ldquo;Automatically Collected Information&rdquo;) that you use to access the information, which is transmitted using Ridlr.</p>
<ol>
<li><strong><u>Information Collection and Use</u></strong></li>
</ol>
<p style={stylescope.splitterStyle}>&nbsp;Ridlr may collect personally identifiable information when you download Ridlr. Ridlr may also receive personally identifiable information about yourself through its business partners, as stated above.</p>
<p style={stylescope.splitterStyle}>&nbsp;When you download Ridlr; Ridlr may capture your full name, email address, mobile/telephone number, birth date, and technical information of your mobile device like type of mobile device, unique identification number of the mobile device, IP address of mobile device, operating system of the mobile internet browser, current location through GPS (or other Similar Technology) to determine your current location in order to determine the city where you are located within and display in location map for better serve .</p>
<p style={stylescope.splitterStyle}>&nbsp;Ridlr also automatically receives and records information on Ridlr&rsquo;s server logs from your browser on your mobile/wireless device including your IP address, Ridlr&rsquo;s Cookie information and the mobile page you have requested to view.</p>
<p style={stylescope.splitterStyle}>&nbsp;By signing up users is automatically eligible for receiving company's promotional offers through SMS, Email, Call, Pus, InApp notification etc. To unsubscribe users need to put a formal request on company support email at support@ridlr.in.</p>
<p style={stylescope.splitterStyle}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ridlr uses the information collected by it to provide the services that you seek from Ridlr.</p>
<p style={stylescope.splitterStyle}>III. <strong><u>Information Sharing and Disclosure</u></strong></p>
<p style={stylescope.splitterStyle}>Ridlr will not sell or rent your personally identifiable information to anyone.</p>
<p style={stylescope.splitterStyle}>Ridlr will&nbsp; divulge personally identifiable information about you to other companies or people or entities when:</p>
<p style={stylescope.splitterStyle}>Ridlr has your specific written consent to share the information, which Ridlr will request from you, as and when the need arises;</p>
<p style={stylescope.splitterStyle}>With its business partners to provide the services that you have requested from Ridlr;</p>
<p style={stylescope.splitterStyle}>Ridlr needs to share the information with certain service partners in order to respond to your comments or to resolve service issues. (Unless Ridlr inform you differently, these partners have no independent right to use your information except to respond to your comments or to resolve service issues.);</p>
<p style={stylescope.splitterStyle}>&nbsp;There are any requests from any Indian or foreign government, security agencies, law enforcement agencies, defence, revenue, regulatory or other authority, agency or officers;</p>
<p style={stylescope.splitterStyle}>Ridlr will respond to any court summons, court orders or legal process; or</p>
<p style={stylescope.splitterStyle}>Ridlr believes it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or as otherwise required by law.</p>
<p style={stylescope.splitterStyle}>&nbsp;Ridlr may transfer information about you if Ridlr is acquired by or merged with another company. In this event, Ridlr will notify you before information about you is transferred and becomes subject to a different privacy policy.</p>
<ol>
<li><strong><u>Collection </u></strong><strong><u>and</u></strong><strong><u> Use of Non-Personal Information</u></strong></li>
</ol>
<p style={stylescope.splitterStyle}>We also collect data in a form that does not, on its own, permit direct association with any specific individual. We may collect, use, transfer, and disclose non-personal information for any purpose. The following are some examples of non-personal information that we collect and how we may use it:</p>
<p style={stylescope.splitterStyle}>&nbsp;We may collect information such as occupation, language, zip code, area code, unique device identifier, referrer URL, location, so that we can better understand customer behaviour, do internal research on user&rsquo;s demographics and improve our products, services, and advertising.</p>
<p style={stylescope.splitterStyle}>&nbsp;We may collect and store details of how you use our services, including search queries. This information may be used to improve the relevancy of results provided by our services. Except in limited instances to ensure quality of our services over the Internet, such information will not be associated with your IP address.</p>
<p style={stylescope.splitterStyle}>If we do combine non-personal information with personally identifiable information the combined information will be treated as personally identifiable information for as long as it remains combined.&nbsp;</p>
<ol>
<li><strong><u>Cookies</u></strong></li>
</ol>
<p style={stylescope.splitterStyle}>Ridlr may set and access Ridlr Cookies on your mobile/wireless device.</p>
<p style={stylescope.splitterStyle}>Ridlr shall allow other companies/entities that are presenting advertisements on Ridlr&rsquo;s mobile pages or other publishers&rsquo; mobile to set and access their respective Cookies on your mobile device. Other companies' use of their cookies is subject to their own privacy policies, not this one. Advertisers or other companies do not have access to the Cookies of Ridlr.</p>
<ol>
<li><strong><u>Your Ability </u></strong><strong><u>to</u></strong><strong><u> Edit and Delete Your Account Information and Preferences</u></strong></li>
</ol>
<p style={stylescope.splitterStyle}>&nbsp;Ridlr gives you the ability to edit the information that is logged in when you download Ridlr, and preferences at any time, including whether you want Ridlr to contact you about offers etc and new updates Ridlr.</p>
<ul>
<li><strong><u>Security</u></strong></li>
</ul>
<p style={stylescope.splitterStyle}>&nbsp;In certain areas Ridlr uses industry-standard encryption to protect data transmissions.</p>
<ul>
<li><strong><u>Grievance Officer</u></strong></li>
</ul>
<p style={stylescope.splitterStyle}>In accordance with Information Technology Act, 2000 and Rule made thereunder, the name and contact details of Grievance Officer is provided below:</p>
<p style={stylescope.splitterStyle}>Mr. Vishwanath Sharma</p>
<p style={stylescope.splitterStyle}><strong>Birds Eye Systems Private Limited.</strong></p>
<p style={stylescope.splitterStyle}>e-mail ID: - vishwanaths@ridlr.in</p>
<ol>
<li><strong><u>Changes to this Privacy Policy</u></strong></li>
</ol>
<p style={stylescope.splitterStyle}>&nbsp;Ridlr may amend this privacy policy from time to time. If Ridlr makes any substantial changes in the way Ridlr uses your personally identifiable information Ridlr will notify you by posting a prominent announcement on Ridlr.</p>
<p className="text-center m-4"><a href="/login"> <button type="button"  className="btn submit-btn-bg "> Back </button></a></p>
                             </div>
                        </div>
                        </main>
                    </div>
                    </div>
            
        );
    }
}
export default Privacypolicy