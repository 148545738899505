// @flow
import React from "react";
import { Page } from "tabler-react";
import { Button } from 'react-bootstrap';
import BaseLayout from "./BaseLayout";
import MessageResponse from '../enums/MessageResponse';
import { ViewUtils } from '../utils/ViewUtils';
import "./alert.js";
import "../App.css";
import { FreshdeskAPI } from "../apis/FreshdeskAPI";

class ReportIssue extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,
            isReported: false,
            selectedOption: '',
            msgHandler: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.isReported) {
            this.setState({
                isLoading: false,
                isReported: false,
                selectedOption: '',
                msgHandler: null
            });
        }
    }

    submitReportIssue() {
        if(this.handleValidation()) {
            this.setState({ isLoading: true });
            let obj = {};
            obj.source = 2;
            obj.description = "Report Issue: " + this.state.selectedOption;
            obj.email = localStorage.getItem('agencydisplayname')+"@gmail.com";
            obj.name = localStorage.getItem('agencydisplayname');
            obj.subject = "Urgent Private AFCS";
            obj.status = 2;
            obj.priority = 4;

            if(this.state.selectedOption === "ETIM Issue") {
                obj.type = "AFCS_Hardware";
            } else if(this.state.selectedOption === "Software Issue") {
                obj.type = "AFCS_Software";
            } else if(this.state.selectedOption === "Schedule a training") {
                obj.type = "AFCS_Training";
            } else if(this.state.selectedOption === "Other") {
                obj.type = "AFCS_Others";
            }
            obj.group_id = 9000173429;
            obj.responder_id = 9004704177;

            /**obj.feedback = this.state.selectedOption;
            obj.email = "Urgent_AFCS_" + localStorage.getItem('agencyname')+"@gmail.com";
            obj.device_id = '';
            obj.user_id = localStorage.getItem('agencyname');
            obj.mobile = '';*/

            FreshdeskAPI.postFreshdeskTicket(JSON.stringify(obj), 
                (response) => {
                    this.setState({ isLoading: false, isReported: true });
                },
                (error) => {
                    this.setState({ isLoading: false, msgHandler: ViewUtils.createMessageHandler("Failed to report issue. Please try again after sometime", MessageResponse.ERROR) });
                }
            );
        } else {
            this.setState({ msgHandler: ViewUtils.createMessageHandler('Please select the type of issue', MessageResponse.ERROR) });
        }
    }

    handleReportIssueChange(event) {
        this.setState({ selectedOption: event.target.value, msgHandler: null });
    }

    handleValidation() {
        if(this.state.selectedOption === '') {
            return false;
        } else {
            return true;
        }

    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        return (
            <BaseLayout> 
                <Page.Content>
                    {!this.state.isReported && <div className="page-header"><h1 className="page-title paddingLeft15">Report Issue</h1></div>}
                    <React.Fragment>
                        <div className="container">
                            {this.state.msgHandler}
                            {!this.state.isReported && 
                            <div className="form-group">
                                <div className="custom-controls-stacked">
                                    <label className="custom-control custom-radio report-radio-buttons">
                                        <input type="radio" className="custom-control-input report-radio-buttons" name="report-selection" value="ETIM Issue"  onChange={e => this.handleReportIssueChange(e)} />
                                        <div className="custom-control-label">ETIM Issue</div>
                                    </label>
                                    <label className="custom-control custom-radio report-radio-buttons text-none">
                                        <input type="radio" className="custom-control-input report-radio-buttons" name="report-selection" value="Software Issue"  onChange={e => this.handleReportIssueChange(e)} />
                                        <div className="custom-control-label">Software Issue</div>
                                    </label>
                                    <label className="custom-control custom-radio report-radio-buttons">
                                        <input type="radio" className="custom-control-input report-radio-buttons" name="report-selection" value="Schedule a training"  onChange={e => this.handleReportIssueChange(e)} />
                                        <div className="custom-control-label">Schedule a training</div>
                                    </label>
                                    <label className="custom-control custom-radio report-radio-buttons">
                                        <input type="radio" className="custom-control-input report-radio-buttons" name="report-selection" value="Other" onChange={e => this.handleReportIssueChange(e)} />
                                        <div className="custom-control-label">Other</div>
                                    </label>
                                </div>
                            </div> }
                            {!this.state.isReported && 
                            <Button type="button" onClick={() => this.submitReportIssue()} className="btn btn-primary report-issue-button" >Report
                                {this.state.isLoading && <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." /> }
                            </Button> }
                            {this.state.isReported && 
                                <div>
                                    <div className="report-issue-success-msg">
                                        <br/><br/><br/>
                                        THANK YOU! <br/><br/><br/>
                                        Someone will call you shortly.<br/><br/>
                                        If you need to talk to us, call on<br/><br/>
                                    </div>
                                    {/*<div><a href="tel:1800-121-3233" className="btn btn-sm btn-outline-primary text-center report-issue-call-button">1800 121 3233</a></div>*/}
                                </div>
                            }
                        </div>
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default ReportIssue;
