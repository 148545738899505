// @flow
import * as React from "react";
import { Link } from 'react-router-dom';
import { Page,Grid,Button } from "tabler-react";
import { Table, Row, Col } from 'react-bootstrap';
import Pagination from "./Pagination";
import { AgencyAPI } from "../apis/AgencyAPI";
import SessionUtils from "../utils/SessionUtils";
const perPage = 10;

class Routetypelist extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        datatransection: [],
        RoutetypeList: [],
        CurrentRoutetypeList:[],
        data: [],
        offset: 0,
        page: 0,
        isLoader: false,
        from: new Date(),
        to: new Date(),
        authkey: localStorage.getItem('authkey')
      };
    }
   
    
      componentDidMount() {
        let currentComponent = this;
        currentComponent.setState({ isLoader: true });
        AgencyAPI.getRouteType(
          (response) => {
            currentComponent.setState({
              fetchInProgress: false,
              RoutetypeList: response.data.data,
              pageCount: Math.ceil(response.data.data.length / 20) ,
              isLoader: false
            });
          },
          (error) => {
            currentComponent.setState({isLoader: false  });
          }
        );
      }
      onPageChanged = data => {
        const { RoutetypeList } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
      
        const offset = (currentPage - 1) * pageLimit;
        const CurrentRoutetypeList = RoutetypeList.slice(offset, offset + pageLimit);
      
        this.setState({ currentPage, CurrentRoutetypeList, totalPages });
      };
      render() {

        let paginationtransaction;
        const {
            RoutetypeList,
            currentPage,
            totalPages
        } = this.state;
        const totalroutes = RoutetypeList.length;
       
        if (this.state.pageCount > 0) {
              paginationtransaction = (
                <Pagination
                  totalRecords={totalroutes}
                  pageLimit={perPage}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              )
            } else {
              paginationtransaction = (
                <div>
                  No Data Found...
                  </div>
        
              )
            }
            
        if (this.state.isLoader) {
            return (
                <Page.Content>
                  <Grid>
                    <Row className="show-grid float-right">
                        <Col xsHidden md={12}>
                        {!SessionUtils.isChildAgency() && <a href="/create-routetype"> <Button type="button"  color="primary mb-2 mr-2"> Add Service </Button></a>}
                        </Col>
                    </Row>
                </Grid>
                  <Page.Header title="Route Type List" />
                  <React.Suspense fallback={<div>Loader...</div>}>
                  
                    <Table striped bordered condensed hover>
                      <thead>
                        <tr>
                            <th >Name</th>
                            <th >Status</th>
                            <th >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td colSpan="3" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                    </tbody>
                    </Table>
                    {paginationtransaction}
                  </React.Suspense>
                </Page.Content>
            );
      
          } else {
            return (
                <Page.Content>
                  <Grid>
                        <Row className="show-grid float-right">
                        <Col xsHidden md={12}>
                        {!SessionUtils.isChildAgency() && <a href="/create-routetype"> <Button type="button"  color="primary mb-2 mr-2"> Add Service </Button></a>}
                        </Col>
                        </Row>
                    </Grid>
                  <Page.Header title="Bus service List" />
                  <React.Suspense fallback={<div>Loader...</div>}>
                    
                    <Table striped bordered condensed hover>
                      <thead>
                        <tr>
                            <th >Name</th>
                            <th >Status</th>
                            {!SessionUtils.isChildAgency() && <th >Action</th>}
                        </tr>
                      </thead>
                      <tbody>
      
                        { this.state.CurrentRoutetypeList.map(function(obj, i){
      
                            return (
                              <tr key={i}>
                                <td >
                                  {obj.name}
                                </td>
                                <td >
                                  {obj.status}
                                </td>
                                {!SessionUtils.isChildAgency() && <td>
                                  <Link to={{ pathname: `/edit-routetype/${obj.id}`, state: { cause: obj} }}  className="active"><i className="fe fe-edit"></i></Link>
                                </td>}
                              </tr>
                            );
                          })}
      
                      </tbody>
                    </Table>
                    {paginationtransaction} {currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                  <span className="font-weight-bold">{totalPages}</span>
                </span>
                )}
                  </React.Suspense>
                </Page.Content>
            );
          }
    }
}
export default Routetypelist;