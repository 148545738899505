// @flow
import React from "react";
import "./alert.js";
import "../App.css";
import DateUtils from "../utils/DateUtils";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';

class PaymentList extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoadingPaymentList: false,
            paymentList: []
        };
    }

    componentDidMount() {
    }

    componentWillUpdate() {
    }

    handleExpandClick(selectedCard, index) {
        const newIds = this.props.paymentList.slice(); //copy the array
        newIds[index].expanded = !selectedCard.expanded; //execute the manipulations
        this.setState({ paymentList: newIds });
    }

    render() {
        return (
            <div className="duty-page">
                {this.props.isLoadingPaymentList && <div className="duty-loader"></div>}
                {(this.props.paymentList !== null && this.props.paymentList !== undefined && this.props.paymentList.length > 0) && <div className="cash-collection-click-label">Click to view more details</div>}
                <div className="cards-list">
                    {this.props.paymentList.map((card, i) => {
                        return <Card className="cash-collection-card" onClick={() => this.handleExpandClick(card, i)}
                            key={card.id}
                            card={card}
                        >
                            <CardContent className="cash-collection-card-content">
                                <div className="cash-collection-card-header">
                                    <div className="cash-collection-row">
                                        <div className="cash-collection-header-row">
                                            <div className="cash-collection-card-label">Transaction ID.</div>
                                            <div className="cash-collection-card-heading">{card.paymentHandlerReferenceId}</div>
                                        </div>
                                        <div className="cash-collection-card-arrow">
                                            {!card.expanded && <i className="fe fe-chevron-down"></i>}
                                            {card.expanded && <i className="fe fe-chevron-up"></i>}
                                        </div>
                                    </div>
                                    <div className="cash-collection-row">
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Amount.</div>
                                            <div className="cash-collection-card-text">{card.totalAmount}</div>
                                        </div>
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Date.</div>
                                            <div className="cash-collection-card-text">{(card.paymentStatus === "INITIATED" || card.paymentStatus === "PENDING")?DateUtils.getFormattedDateFromDateString(card.initiatedAt):DateUtils.getFormattedDateFromDateString(card.completedAt)}</div>
                                        </div>
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Status</div>
                                            <div className="cash-collection-card-text-bold">{card.paymentStatus}</div>
                                        </div>
                                    </div>
                                    {card.expanded && <div className="cash-collection-row">
                                        <div className="cash-collection-column">
                                            <div className="cash-collection-card-label">Type</div>
                                            <div className="cash-collection-card-text">{card.transactionType}</div>
                                        </div>
                                        <div className="payment-column">
                                            <div className="cash-collection-card-label">Order ID.</div>
                                            <div className="cash-collection-card-text">{card.ridlrOrderId}</div>
                                        </div>
                                    </div>}
                                </div>
                            </CardContent>
                        </Card>
                    })}

                </div>
            </div>

                        
        );
}
}

PaymentList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default PaymentList;
