import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';
import MessageResponse from '../enums/MessageResponse';

export const PaymentAPI = {
    postPayment,
    getTransactionStatus,
    getPaymentHistory,
}


function postPayment(obj, callbackSuccess, callbackError) {
    API.post('/apis/billing/payment?', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to make payment. Please try again after sometime", MessageResponse.ERROR));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function getTransactionStatus(transactionId, callbackSuccess, callbackError) {
    API.get("/apis/billing/transaction/" + transactionId +"/status",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to get transaction status. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getPaymentHistory(callbackSuccess, callbackError) {
    API.get("/apis/billing/payment/history?agencyCode=" + localStorage.getItem('agencyname') + "&statuses=COMPLETED,FAILED,DECLINED,PENDING,CANCELLED" ,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to get payment. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

