import React from "react";
import { Page, Button } from "tabler-react";
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DateUtils from "../utils/DateUtils";
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';


const options = {
  sizePerPage: 10,
  hideSizePerPage: false,
  hidePageListOnlyOnePage: true
};

const { ExportCSVButton } = CSVExport;
const selectOptions = {
  'ACTIVE': 'ACTIVE',
  'INACTIVE': 'INACTIVE'
};


function headerFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filterElement}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
        {column.text}
        {sortElement}
      </div>
    </div>
  );
}

function tripsFormatter(cell, row) {
  return (
    <div style={{ wordWrap: 'break-word' }}>{cell[0].agencyRouteId}</div>
  );
}

function actionFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div>
      <div style={{ fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
        {column.text}
      </div>
    </div>
  );
}


class MemosPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      memosList: [],
    };

  }
  loadCommentsFromServer() {
    let currentComponent = this;
    currentComponent.setState({ isLoader: true });
    AgencyAPI.getMemos(
      (response) => {
        currentComponent.setState({
          memosList: response.data.data,
        });
      },
      (error) => {

      }
    );
  }

  componentDidMount() {
    this.loadCommentsFromServer();
  }

  render() {
    const { columns = [{
      dataField: 'agencyMemoId',
      text: 'Schedule',
      filter: textFilter(),
      headerFormatter: headerFormatter
    }, {
      dataField: 'trips',
      text: 'Route',
      headerFormatter: headerFormatter,
      formatter: tripsFormatter
    }, {
      dataField: 'status',
      text: 'STATUS',
      formatter: cell => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions
      }),
      headerStyle: { width: '160px' },
      headerFormatter: headerFormatter
    }, {
      dataField: 'action',
      text: '',
      csvExport: false,
      isDummyField: true,
      headerStyle: { width: '100px' },
      headerFormatter: actionFormatter,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Link to={{ pathname: `/edit-schedule/${row.id}`, state: { schedule: row} }}  className="active"><i className="fe fe-edit"></i></Link>
        );
      }
    }] } = this.state;
    return (
      <BaseLayout>
        <Page.Content>
          <Page.Header title="Schedule List" />
          <React.Fragment>
            <div className="floatright marginBottom15">
            <a href="/add-schedule"><Button type="button" className="btn btn-primary float-right">Add Schedule</Button></a>
            </div>

            <ToolkitProvider
              keyField="id"
              data={this.state.memosList}
              columns={columns}
              exportCSV={{ fileName: "SCHEDULE-LIST".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
            >
              {
                props => (
                  <div>
                    <BootstrapTable
                      {...props.baseProps} bootstrap4
                      bordered={false}
                      pagination={paginationFactory(options)}
                      filter={filterFactory()}
                      noDataIndication="No Data Found" >
                    </BootstrapTable>
                    <hr />
                    <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                  </div>
                )
              }
            </ToolkitProvider>
          </React.Fragment>
        </Page.Content>
      </BaseLayout>
    )
  }
}
export default MemosPage;
