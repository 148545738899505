// @flow
import React from "react";
import { Page, Button } from "tabler-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import DateUtils from "../utils/DateUtils";
import { isMobile } from 'react-device-detect';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { AnalyticsAPI } from "../apis/AnalyticsAPI.js";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ReactPlayer from 'react-player'

const { ExportCSVButton } = CSVExport;

const options = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true
};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {filterElement}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
                {column.text}
                {sortElement}
            </div>

        </div>
    );
}

function dateTimeFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        DateUtils.getFormattedDateFromDateString(cell)
    );
}



class APCSReport extends React.Component {

    static defaultProps = {
        center: {
          lat: 59.95,
          lng: 30.33
        },
        zoom: 11
      };

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            fields: {},
            errors: {},
            isLoadingReport: false,
            msgHandler: null,
            data: [],
            isFilterExpanded: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleShowMessageHandler = this.handleShowMessageHandler.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.goToWaybillDetailsPage = this.goToWaybillDetailsPage.bind(this);
    }

    loadData(from, to) {
        this.setState({ isLoadingReport: true })
        AnalyticsAPI.getApcsReports(from, to,
            (response) => {
                this.setState({
                    isLoadingReport: false,
                    data: response.data.data.report.data
                });
            },
            (error) => {
                this.setState({ isLoadingReport: false });
            });
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleChangeEnd(date) {
        this.setState({
            endDate: date
        });
    }

    getDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    handleShowMessageHandler(msgHandler) {
        this.setState({ msgHandler: msgHandler });
    }

    handleSubmitClick() {
        let from = this.state.startDate;
        let to = this.state.endDate;
        if (from === null || to === null) {
            alert("Please select date range!");
            return;
        }
        this.loadData(this.getDateFormat(from), this.getDateFormat(to));
    }

    handleExpandClick(selectedCard, index) {
        const newIds = this.state.data.slice(); //copy the array
        newIds[index].expanded = !selectedCard.expanded; //execute the manipulations
        this.setState({ data: newIds });
    }

    prevDateClick() {
        let currentComponent = this;
        let prevDate = DateUtils.getPrevDate(currentComponent.state.startDate);
        currentComponent.setState(
            {
                startDate: prevDate,
                endDate: prevDate
            }, function () {
                currentComponent.enableNextButton();
            }
        );
    }

    nextDateClick() {
        let currentComponent = this;
        let nextDate = DateUtils.getNextDate(currentComponent.state.startDate);
        currentComponent.setState(
            {
                startDate: nextDate,
                endDate: nextDate
            }, function () {
                currentComponent.enableNextButton();
            }
        );
    }

    componentDidMount() {
        let currentComponent = this;
        currentComponent.enableNextButton();
    }

    enableNextButton() {
        let currentComponent = this;
        currentComponent.setState({
            isNextEnabled: DateUtils.isCurrentDate(currentComponent.state.startDate)
        });
    }

    handleClickOpen(card, showMessage) {
        let currentComponent = this;
        currentComponent.setState({ selectedCard: card, open: true });
        return;
    }

    handleClose() {
        let currentComponent = this;
        currentComponent.setState({ open: false, selectedCard: null });
    };

    goToWaybillDetailsPage(card) {
        this.props.history.push({pathname:'/apcs-waybill-details-report', state:{ waybill: card}});
    }

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let filterTransaction = (
            <div className="row row-cards">
                <div className="col-md-3 col-lg-2">
                    <div className="form-group">
                        <label className="form-label">From :</label>
                        <DatePicker name="transFromDate"
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            isClearable={true}
                            placeholderText=""
                            className="form-control mb-2"
                        />
                    </div>
                </div>
                <div className="col-md-3 col-lg-2">
                    <div className="form-group">
                        <label className="form-label">To :</label>
                        <DatePicker name="transToDate"
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.endDate}
                            onChange={this.handleChangeEnd}
                            isClearable={true}
                            placeholderText=""
                            className="form-control mb-2"
                        />
                    </div>
                </div>
                <div className="col-md-3 col-lg-2">
                    <div className="form-group">
                        <label className="form-label">&nbsp;&nbsp;</label>
                        <Button type="button" onClick={this.handleSubmitClick} hidden={this.state.isLoadingReport} color="primary mb-2 ">Search</Button>
                        {this.state.isLoadingReport &&
                            <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                        }
                    </div>
                </div>
            </div>
        );
        const { columns = [{
            dataField: 'dutyDate',
            text: 'DUTY DATE',
            headerStyle: (colum, colIndex) => {
                return { width: '180px' };
            },
            formatter: dateTimeFormatter,
            filter: dateFilter({
                comparators: [Comparator.GE, Comparator.LE]
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'waybillNo',
            text: 'WAYBILL NUMBER',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'afcsPassengers',
            text: 'AFCS Count',
            filter: textFilter(),
            headerFormatter: headerFormatter,
        }, {
            dataField: 'apcsPassengers',
            text: 'APCS COUNT',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'leakage',
            text: 'LEAKAGE',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'action',
            text: 'DETAILS',
            csvExport: false,
            headerStyle: (colum, colIndex) => {
                return { width: '150px', textAlign: 'center' };
            },
            headerFormatter: headerFormatter,
            isDummyField: true,
            formatExtraData: this.state,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <Button type="button" onClick={() => this.goToWaybillDetailsPage(row)} className="btn btn-primary collectBtn">Waybill Details</Button>
            }
        }] } = this.state;
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="APCS Report" />
                    {this.state.msgHandler}
                    {!isMobile &&
                        <div className="col-12">
                            <div className="paddingTop15">
                                {filterTransaction}
                                <ToolkitProvider
                                    keyField="waybillNo"
                                    data={this.state.data}
                                    columns={columns}
                                    exportCSV={{ fileName: "APCS-REPORT_".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
                                >
                                    {
                                        props => (
                                            <div>
                                                <BootstrapTable
                                                    {...props.baseProps} bootstrap4
                                                    keyField="waybillNo"
                                                    data={this.state.data}
                                                    columns={columns}
                                                    bordered={false}
                                                    pagination={paginationFactory(options)}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data Found" />
                                                <hr />
                                                <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    }
                    {isMobile && <div>
                        {this.state.isFilterExpanded &&
                            <div className="duty-filter-box">
                                <div className="duty-filter-search">
                                    <div className="row">
                                        <div className="cash-collection-filter-left width100">
                                            <label className="cash-collection-card-text">Date</label>
                                            <br />
                                            <button type="button" onClick={() => this.prevDateClick()} className="btn btn-icon  btn-primary  btn-secondary arrowbtn"><i className="fe fe-chevron-left"></i></button>
                                            <DatePicker name="dutyDate"
                                                maxDate={new Date()}
                                                dateFormat="dd MMM, yyyy"
                                                selected={this.state.startDate}
                                                onChange={this.handleDateChange}
                                                //isClearable={true}
                                                placeholderText="Select Date"
                                                className="form-control mb-2 duty-date-picker"
                                            />
                                            <button type="button" disabled={this.state.isNextEnabled} onClick={() => this.nextDateClick()} className="btn btn-icon  btn-primary  btn-secondary arrowbtn"><i className="fe fe-chevron-right"></i></button>
                                        </div>
                                    </div>
                                    <div className="row duty-search">
                                        <div className="col-md-12 col-lg-12">
                                            <button type="button" onClick={() => this.handleSubmitClick()} className="btn btn-primary duty-search-btn">Search
                                                {this.state.isLoadingDutyList &&
                                                    <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                                }</button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(this.state.data !== null && this.state.data !== undefined && this.state.data.length > 0) && <div className="cash-collection-click-label">Click to view more details</div>}
                        <div className="cards-list">
                            {this.state.data.map((card, i) => {
                                return <Card className="cash-collection-card" onClick={() => this.handleExpandClick(card, i)}
                                    key={card.id}
                                    card={card}
                                >
                                    <CardContent className="cash-collection-card-content">
                                        <div className="cash-collection-card-header">
                                            <div className="cash-collection-row">
                                                <div className="cash-collection-header-row">
                                                    <div className="cash-collection-card-label">WAYBILL NUMBER</div>
                                                    <div className="cash-collection-card-heading">{card.waybillNo}</div>
                                                </div>
                                                <div className="cash-collection-card-arrow">
                                                    {!card.expanded && <i className="fe fe-chevron-down"></i>}
                                                    {card.expanded && <i className="fe fe-chevron-up"></i>}
                                                </div>
                                            </div>
                                            <div className="cash-collection-row">
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">Leakage</div>
                                                    <div className="cash-collection-card-text">{card.leakage}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">APCS Count</div>
                                                    <div className="cash-collection-card-text">{card.apcsPassengers}</div>
                                                </div>
                                                <div className="cash-collection-column">
                                                    <div className="cash-collection-card-label">AFCS Count</div>
                                                    <div className="cash-collection-card-text-bold">{card.afcsPassengers}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                    {card.expanded && <CardActions className="cash-collection-card-actions">
                                        <Button type="button" onClick={() => this.goToWaybillDetailsPage(card)} className="btn btn-primary collectBtn">Waybill Details</Button>
                                    </CardActions>}
                                </Card>
                            })}

                        </div>
                    </div>
                    }
                    {!isMobile && <div>
                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
                    </div>}
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default APCSReport;