// @flow
import React from "react";
import { Page, Button } from "tabler-react";
import "react-datepicker/dist/react-datepicker.css";
import "./alert.js";
import BaseLayout from "./BaseLayout";
import DateUtils from "../utils/DateUtils";
import { isMobile } from 'react-device-detect';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { AnalyticsAPI } from "../apis/AnalyticsAPI.js";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';


const { ExportCSVButton } = CSVExport;

const options = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true
};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {filterElement}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: '#495057', fontSize: '11px' }}>
                {column.text}
                {sortElement}
            </div>

        </div>
    );
}

function dateTimeFormatter(cell, row, rowIndex, formatExtraData) {
    return (
        DateUtils.getFormattedDateTimeFromTimestamp(cell)
    );
}


class APCSTripSurvellianceReport extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            fields: {},
            errors: {},
            isLoadingReport: false,
            msgHandler: null,
            data: [],
            isFilterExpanded: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleShowMessageHandler = this.handleShowMessageHandler.bind(this);
    }

    loadData() {
        this.setState({ isLoadingReport: true })
        console.log("load data: " + this.props.location.state.waybill + "::" + this.props.location.state.trip);
        AnalyticsAPI.getApcsSurvellianceReport(this.props.location.state.waybill,
            this.props.location.state.trip,
            (response) => {
                console.log("response:" + JSON.stringify(response.data.data));
                this.setState({
                    isLoadingReport: false,
                    data: response.data.data,
                });
            },
            (error) => {
                this.setState({ isLoadingReport: false });
            });
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleChangeEnd(date) {
        this.setState({
            endDate: date
        });
    }

    getDateFormat(date) {
        var month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    handleShowMessageHandler(msgHandler) {
        this.setState({ msgHandler: msgHandler });
    }

    handleExpandClick(selectedCard, index) {
        const newIds = this.state.data.slice(); //copy the array
        newIds[index].expanded = !selectedCard.expanded; //execute the manipulations
        this.setState({ data: newIds });
    }

    componentDidMount() {
        let currentComponent = this;
        currentComponent.loadData();
    }

    handleClickOpen(card, showMessage) {
        let currentComponent = this;
        currentComponent.setState({ selectedCard: card, open: true });
        return;
    }

    handleClose() {
        let currentComponent = this;
        currentComponent.setState({ open: false, selectedCard: null });
    };

    goToVideoDetails(card) {
        console.log("trip video details:" + JSON.stringify(card));
        this.props.history.push({pathname:'/apcs-trip-video-details-report', state:{ waybill:this.props.location.state.waybill.waybillNo, trip: card.tripNumber}});
    }



    render() {
        const { columns = [{
            dataField: 'startTime',
            text: 'START TIME',
            headerFormatter: headerFormatter,
            formatter: dateTimeFormatter,
            filter: dateFilter({
                comparators: [Comparator.GE, Comparator.LE]
            }),
        }, {
            dataField: 'cameraNo',
            text: 'CAMERA',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'filePath',
            text: 'FILENAME',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'videoLength',
            text: 'LENGTH',
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'action',
            text: 'PLAY',
            csvExport: false,
            headerStyle: (colum, colIndex) => {
                return { width: '110px', textAlign: 'center' };
            },
            headerFormatter: headerFormatter,
            isDummyField: true,
            formatExtraData: this.state,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <Button type="button" onClick={() => { this.goToVideoDetails(row)}} className="btn btn-primary collectBtn">Video Details</Button>
            }
        }] } = this.state;
        return (
            <BaseLayout>
                <Page.Content>
                    <Page.Header title="APCS Trip Survelliance Report" />
                    <div className="row row-cards">
                        <div className="col-md-4 col-lg-4">
                            <div className="cash-collection-card-label">TRIP NUMBER:
                            <span className="cash-collection-card-heading">{this.props.location.state.trip}</span></div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="cash-collection-card-label">DEVICE ID: <span className="cash-collection-card-heading">{this.props.location.state.deviceNumber}</span></div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="cash-collection-card-label">BUS NUMBER: <span className="cash-collection-card-heading">{this.props.location.state.busNumber}</span></div>
                        </div>
                    </div>
                    {!isMobile && <div><br /><br /></div>}
                    {this.state.msgHandler}
                    {!isMobile &&
                        <div className="col-12">
                            <div className="paddingTop15">
                                <ToolkitProvider
                                    keyField="videoId"
                                    data={this.state.data}
                                    columns={columns}
                                    exportCSV={{ fileName: "APCS-TRIP-SURVELLIANCE-" + this.props.location.state.waybill + "-" + this.props.location.state.trip + "-REPORT_".toUpperCase().replace(/ /g, "_") + "_" + DateUtils.getDateTimeFormatted(new Date()) + '.csv', onlyExportFiltered: true, exportAll: false }}
                                >
                                    {
                                        props => (
                                            <div>
                                                <BootstrapTable
                                                    {...props.baseProps} bootstrap4
                                                    keyField="videoId"
                                                    data={this.state.data}
                                                    columns={columns}
                                                    bordered={false}
                                                    pagination={paginationFactory(options)}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data Found" />
                                                <hr />
                                                <ExportCSVButton {...props.csvProps} className="btn btn-primary">Export CSV</ExportCSVButton>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    }
                    {isMobile && <div>
                        <div className="cards-list paddingTop15">
                            {this.state.data.map((card, i) => {
                                return <Card className="cash-collection-card"
                                    key={card.id}
                                    card={card}
                                >
                                    <CardContent className="cash-collection-card-content">
                                        <div className="cash-collection-card-header">
                                            <div className="cash-collection-row">
                                                <div className="cash-collection-header-row">
                                                    <div className="cash-collection-card-label">Start Time</div>
                                                    <div className="cash-collection-card-heading">{DateUtils.getFormattedDateTimeFromTimestamp(card.startTime)}</div>
                                                </div>
                                            </div>
                                            <div className="cash-collection-row">
                                                <div className="cash-collection-column-60">
                                                    <div className="cash-collection-card-label">File Name</div>
                                                    <div className="cash-collection-card-text">{card.filePath}</div>
                                                </div>
                                                <div className="cash-collection-column-20">
                                                    <div className="cash-collection-card-label">Camera</div>
                                                    <div className="cash-collection-card-text">{card.cameraNo}</div>
                                                </div>
                                                <div className="cash-collection-column-20">
                                                    <div className="cash-collection-card-label">Length</div>
                                                    <div className="cash-collection-card-text">{card.videoLength}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                    <CardActions className="cash-collection-card-actions">
                                        <Button type="button" onClick={() => this.goToWaybillDetailsPage(card)} className="btn btn-primary collectBtn">Play</Button>
                                    </CardActions>
                                </Card>
                            })}

                        </div>
                    </div>
                    }
                </Page.Content>
            </BaseLayout>
        );
    }
}

export default APCSTripSurvellianceReport;