// @flow
import * as React from "react";
import { Button } from 'react-bootstrap';
import MessageResponse from "../enums/MessageResponse";
import { ViewUtils } from "../utils/ViewUtils";
import { DutyAPI } from "../apis/DutyAPI";
import {isMobile} from 'react-device-detect';
import DateUtils from "../utils/DateUtils";
import Switch from 'react-switch';
import { isNullOrUndefined } from "util";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import SessionUtils from "../utils/SessionUtils";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class RepeatWaybill extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoadingRepeat:false,
            showReplicateDialog: props.showReplicateDialog,
            replicateObj: props.replicateObj,
            selectedReplicateDate: new Date(),
            isDutyPage: props.isDutyPage
        };
        this.replicateWaybill = this.replicateWaybill.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }
    
    onDateChange(event) {
        if(event !== undefined) {
            this.setState({
                selectedReplicateDate: event.target.value,
            });    
        }
    }

    replicateWaybill() {
        let currentComponent = this;
        let obj = {};
        obj.agencyCode = localStorage.getItem('agencyname');
        obj.memoId = "";
        obj.conductorId = this.state.replicateObj.conductorId;
        obj.driverId = this.state.replicateObj.driverId;
        obj.deviceSerialId = this.state.replicateObj.deviceSerialId;
        obj.vehicleId = this.state.replicateObj.vehicleId;
        obj.dutyDate = DateUtils.getDateToFormattedString(this.state.selectedReplicateDate, "YYYY-MM-DD");
        if(SessionUtils.isRouteScheduleEnabled() === true) {
            obj.memoId = this.state.replicateObj.memoId;
        }
        obj.manualFare = this.state.replicateObj.manualFare === 1 ? 1 : 0;
        
        currentComponent.setState({
            isLoadingRepeat: true,
        });
        
        DutyAPI.postDuty(obj,
            (response) => {
                let viewDutyCloseView = (
                    <p>
                        <Button className="btn-primary"  onClick={(event)=>this.viewDuty(event)} >View</Button>
                            <span> or </span>
                        <Button className="btn-info" onClick={this.closeDuty}>Close</Button>
                    </p>
                );
                currentComponent.setState({
                    isLoadingRepeat: false,
                    msgHandler: isMobile ? ViewUtils.createMsgHandlerCustomView('Duty repeated successfully WayBill- ' + response.data.data.waybillNumber, MessageResponse.SUCCESS, viewDutyCloseView) :
                        ViewUtils.createMessageHandler('Duty repeated successfully WayBill- ' + response.data.data.waybillNumber, MessageResponse.SUCCESS),
                });
                currentComponent.props.repeatWaybillHandler(1, this.state.msgHandler);
                return;
            },
            (error) => {
                currentComponent.setState({
                    isLoadingRepeat: false,
                    msgHandler: error.msgHandler,
                });
                currentComponent.props.repeatWaybillHandler(0, this.state.msgHandler);
    
                return;
            }
        );
    }        

    
    componentWillReceiveProps(nextProps) {
        this.setState({replicateObj:nextProps.replicateObj, showReplicateDialog: nextProps.showReplicateDialog, isDutyPage: nextProps.isDutyPage});
    }
    
    render() {
        return (
            <Dialog fullScreen={isMobile?true:false} open={this.state.showReplicateDialog} onClose={this.props.handleCloseReplicateDialog} TransitionComponent={Transition}>
                    <DialogContent>
                
                        {!this.state.isDutyPage && <div>
                            <label className="form-label dialog-title">Cash Collected and Duty Ended</label>
                            <label className="form-label">Would you like to repeat this duty?</label>
                        </div>}
                        {this.state.isDutyPage && <label className="form-label dialog-title">Are you sure you want to repeat this duty?</label>}
                        <div className="row row-cards">
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Badge Number</label>
                                    <input className="form-control" value={!isNullOrUndefined(this.state.replicateObj)?this.state.replicateObj.conductorName:""} disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Driver</label>
                                    <input className="form-control" value={!isNullOrUndefined(this.state.replicateObj)?this.state.replicateObj.driverName:""} disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="row row-cards">
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Device Number</label>
                                    <input value={!isNullOrUndefined(this.state.replicateObj)?this.state.replicateObj.deviceSerialId:""} className="form-control" disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Vehicle Number</label>
                                    <input className="form-control" value={!isNullOrUndefined(this.state.replicateObj)?this.state.replicateObj.vehicleName:""} disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="row row-cards">
                            {SessionUtils.isRouteScheduleEnabled() && <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Schedule</label>
                                    <input className="form-control" value={!isNullOrUndefined(this.state.replicateObj)?this.state.replicateObj.agencyMemoId:""} disabled={true} />
                                </div>
                            </div>}
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label className="form-label">Allow Manual Fare</label>
                                    <Switch checked={!isNullOrUndefined(this.state.replicateObj)?this.state.replicateObj.manualFare===1?true:false:false}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            disabled
                                            className="react-switch switch-opacity"
                                            id="material-switch" />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row row-cards">
                            <div className="col-md-12 col-lg-12">
                                <label className="form-label">Duty Date</label>
                                </div>
                        </div>
                        <div className="row row-cards">
                            <div className="col-md-6 col-lg-6">
                                <div className="radio">
                                    <label className="form-label"><input type="radio" onChange={this.onDateChange} name="dutyDate" value={DateUtils.getCurrentDate(new Date())} checked={true} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{DateUtils.getDateToDDMMMYYYYFormattedString()}</label>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="radio">
                                    <label className="form-label"><input type="radio" onChange={this.onDateChange} name="dutyDate" value={DateUtils.getNextDate(new Date())} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{DateUtils.getNextDateToDDMMMYYYYFormattedString()}</label>
                                </div>
                            </div>
                        </div>
                            <br/>
                            <br/>
                            </DialogContent>
                            
                        <DialogActions>
                            <Button variant="secondary" className="btn btn-secondary" onClick={this.props.handleCloseReplicateDialog}>Do Not Repeat</Button>
                          <Button variant="secondary" className="btn btn-primary" onClick={this.replicateWaybill}>Repeat</Button>
                          </DialogActions>
                          <br/>
                        </Dialog>
            );
    }
}

export default RepeatWaybill;