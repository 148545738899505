// @flow
import * as React from "react";
import { Page,Grid,Button } from "tabler-react";
import { Alert, Table, Row, Col } from 'react-bootstrap';
import Pagination from "./Pagination"
import BaseLayout from "./BaseLayout";
import ReactDropdownAutoComplete from "./autocomplete";
import { Link } from 'react-router-dom';
import { AgencyAPI } from "../apis/AgencyAPI";
import SessionUtils from "../utils/SessionUtils";

const perPage = 20;

class Routelist extends React.Component {
    constructor() {
        super();
        this.state = {
            data:  [],
            currentRouteList:[],
            RouteList: [],
            DepotList:[],
            currentPage: null,
            totalPages: null,
            offset: 0,
            name:'',
            status:'',
            errors: {},
            isLoading: true,
            routeUpdateDone: false,
            responsemessage: '',
            isAllSelected: false,
            authkey: localStorage.getItem('authkey')
        };
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleUpdateClick = this.handleUpdateClick.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
    }
    
    loadCommentsFromServer() {
        let currentComponent = this;
        AgencyAPI.getRoutes(
            (response) => {
                    let RouteDataFromApi = response.data.data.map((route) => {
                        return { id: route.id, agencyRouteId: route.agencyRouteId, checked: false, shortName: route.shortName, stops: route.stops, status: route.status, createdAt: route.createdAt,depot:route.depot, parent: route.parent };
                    });
                    const dbName = "RIDLR AFCS";
                    const version = 1; //versions start at 1
                    var request = indexedDB.open(dbName, version);
                    request.onsuccess = function(event) {
                        var db         = event.target.result;
                        var routes      = db.transaction('routes', 'readwrite').objectStore("routes");
                        var clear =  routes.clear();
                        clear.onsuccess = function(event) {
                            RouteDataFromApi.forEach(function (route) {
                                routes.add(route);
                            });
                        };
                        RouteDataFromApi = RouteDataFromApi.sort(function(obj1, obj2) {
                            // Ascending: first age less than the previous
                            return parseInt(obj1.agencyRouteId, 10) - parseInt(obj2.agencyRouteId, 10);
                        });
                        if(currentComponent.state.name !== ''){
                            RouteDataFromApi.forEach(function (route) {
                                if(route.agencyRouteId === currentComponent.state.name){
                                    currentComponent.setState({ RouteList: [route], currentRouteList: [route], pageCount: Math.ceil([route].length / 20) });
                                }
                            });
                        } else {
                            currentComponent.setState({ RouteList:RouteDataFromApi, currentRouteList: RouteDataFromApi, pageCount: Math.ceil(RouteDataFromApi.length / 20) });
                        }
                        var depot      = db.transaction('depot', 'readwrite').objectStore("depot");
                        let depotdata  = depot.getAll();
                        // get data from memos
                        depot.getAll().onsuccess = function (event) {
                            let DepotFromApi = depotdata.result.map(depot => {
                                return { value: depot.id, name: depot.depotname,depotCode:depot.depotCode };
                            });
                            currentComponent.setState({ DepotList: DepotFromApi, isLoading: false });
                        };
                    };
                    return;
            },
            (error) => {
                currentComponent.setState({ isLoading: false });
            }
        );
    }
    
    componentDidMount() {
        this.loadCommentsFromServer();
    }
    
    onPageChanged = data => {
        const { RouteList } = this.state;
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        const currentRouteList = RouteList.slice(offset, offset + pageLimit);

        this.setState({ currentPage, currentRouteList, totalPages });
    };
     
    handleDismiss() {
        this.setState({ showmessage: false });
    }

    handleSubmitClick() {
        let key = this.state.name;

        if (key === null || key === '') {
            alert("Please enter Route Number!");
            return;
        }
        this.setState({ name: key }, () => {
            this.loadCommentsFromServer();
        });
    }
    
    handleResetClick(){
        this.setState({ name: '' }, () => {
            this.loadCommentsFromServer();
        });
    }

    onCheckBoxChange(checkName, isChecked) {
        let currentComponent = this;
        let isAllChecked = (checkName === 'all' && isChecked);
        let isAllUnChecked = (checkName === 'all' && !isChecked);
        const checked = isChecked;
        const checkList = currentComponent.state.currentRouteList.map((route, index) => {
            if(isAllChecked || route.id === checkName) {
                return Object.assign({}, route, {
                    checked
                });
            } else if (isAllUnChecked) {
                return Object.assign({}, route, {
                    checked: false
                });
            }
            return route;
        });
        let isAllSelected = (checkList.findIndex((item) => item.checked === false) === -1) || isAllChecked;
        currentComponent.setState({ currentRouteList: checkList, isAllSelected: isAllSelected});
    }
    
    valid(item, type) {
        let itemValue = item.target.value;
        this.setState({ status: itemValue, errors: {} });
    }
    
    handleValidation(){
        let status = this.state.status;
        let errors = {};
        let formIsValid = true;
        if(status === ""){
            formIsValid = false;
            errors["status"] = "Cannot be empty";
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleUpdateClick(){
        let currentComponent = this;
        if(currentComponent.state.currentRouteList.findIndex((item) => item.checked === true) === -1){
            alert("Please select atleast one Checkbox!");
            return;
        } else {
            var cnt = 0;
            var update_cnt = 0;
            if(this.handleValidation()){
                currentComponent.setState({ isLoading: true, routeUpdateDone: true });
                for(var i = 0; i < currentComponent.state.currentRouteList.length; i++){
                    var route = currentComponent.state.currentRouteList[i];
                    if(route.checked === true){
                        cnt++;
                        let obj = {};
                        obj.status = this.state.status;
                        currentComponent.editRouteCall(route, obj, cnt, update_cnt);
                    }
                }
            }
        }
    }

    editRouteCall(route, obj, cnt, update_cnt) {
        let currentComponent = this;
        AgencyAPI.putRoutes(route.id, obj,
            (response) => {
                update_cnt++;
                currentComponent.setState({
                    showmessage: true,
                    isLoading: true,
                    routeUpdateDone: false,
                    responsemessage:'Route Updated!'
                });
                if(cnt === update_cnt){
                    return currentComponent.setState({ name: '', status: '', isAllSelected: false }, () => {
                        currentComponent.loadCommentsFromServer();
                    });
                } else {
                    return;
                }
            },
            (error) => {
                currentComponent.setState({
                    name: '',
                    status: '',
                    isAllSelected: false,
                    showmessage: true,
                    isLoading: false,
                    routeUpdateDone: false,
                    responsemessage:error.message
                });
                return;
            }
        );
    }

    render(){
        let currentComponent = this;
        let paginationtransaction;
        const {
            RouteList,
            currentPage,
            totalPages,
            DepotList
        } = this.state;
        const totalroutes = RouteList.length;
        const { editFields } = this.state; // you can get your input value by other ways
        if (this.state.pageCount > 0) {
            paginationtransaction = (
              <Pagination
                totalRecords={totalroutes}
                pageLimit={perPage}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            )
        } else {
            paginationtransaction = (
                <div>
                  No Data Found...
                  </div>
        
            )
        }
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                    <p>
                        <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                    </p>
                </Alert>
            )
        }
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        return(
            <BaseLayout>
                <Page.Content>
                    {fadeinout}
                    <Page.Header title="Route List" />
                    <Grid>
                        <Row className="show-grid">
                            <Col xs={3} md={3}>
                                <div className="row gutters-xs">
                                    <div className="col">
                                        <ReactDropdownAutoComplete
                                            getItemValue={item => item.agencyRouteId}
                                            className="form-control"
                                            id="route"
                                            name="route"
                                            placeholder="Enter Route Id"
                                            data={this.state.RouteList || []}
                                            renderItem={item => (
                                                <div
                                                role="button"
                                                tabIndex="-1"
                                                onClick={(val) => { editFields.name = val; }}
                                                >{item.agencyRouteId}</div>
                                            )}
                                            icon="search"
                                            iconColor="#ff000"
                                            iconClick={() => { /* TODO */ }}
                                            value={this.state.name}
                                            onChange={(val) => { this.setState({ name: val }); }}
                                            onEnter={() => { }}
                                        />
                                    </div>
                                    <span className="col-auto">
                                        <button className="btn btn-secondary"  onClick={this.handleSubmitClick} type="button"><i className="fe fe-search"></i></button>
                                    </span>
                                </div>
                            </Col>
                            <Col xsHidden md={4}>
                                <Button  type="button" color="secondary mb-2 mr-2"  onClick={this.handleResetClick} >Reset</Button>
                            </Col>
                            {!SessionUtils.isChildAgency() && <Col xsHidden md={2}>
                                <select className="form-control" value={this.state.status} onChange={(item) => this.valid(item, 'status')}>
                                    <option value="">Change Status</option>
                                    <option value="ACTIVE" >ACTIVE</option>
                                    <option value="INACTIVE" >INACTIVE</option>
                                </select>
                                <span style={{color: "red"}}>{this.state.errors["status"]}</span>
                            </Col>}
                            {!SessionUtils.isChildAgency() && <Col xsHidden md={1}>
                                <Button type="button" color="success mb-2 mr-2 float-right" onClick={this.handleUpdateClick}>Update
                                    {this.state.routeUpdateDone &&
                                        <img src="/images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                    }
                                </Button>
                            </Col>}
                            <Col xsHidden md={2}>
                                {!SessionUtils.isChildAgency() && <a href="/create-route"> <Button type="button"  color="primary mb-2 mr-2 float-right"> Add New Route </Button></a>}
                            </Col>
                        </Row>
                    </Grid>
                    <React.Fragment>
                        <Table striped bordered condensed hover >
                            <thead>
                                <tr>
                                    <th style={{width:'90px'}}>Select All &nbsp;
                                        <CheckBox
                                            name="select-all"
                                            value="ALL"
                                            tick={this.state.isAllSelected}
                                            onCheck={(e) => this.onCheckBoxChange('all', e.target.checked)}
                                        />
                                    </th>
                                    <th >Route Id</th>
                                    <th >Name</th>
                                    <th >Depot</th>
                                    <th >Status</th>
                                    <th >View/Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.isLoading &&
                                    <tr><td colSpan="6" className="text-center" ><img src={'./images/spinner-loder.gif'} alt="spinner-loder" /></td></tr>
                                }
                                {!this.state.isLoading &&
                                this.state.currentRouteList.map(function(obj, i){
                                    let depotname = '';
                                    DepotList.find(function (element) {
                                        if (element.value === obj.depot) {
                                            depotname = element.name;
                                        }
                                        return depotname;
                                    });
                                    return (
                                        <tr key={i}>
                                            <td style={{textAlign:'center'}}>
                                                <CheckBox
                                                    key={i}
                                                    name={obj.shortName}
                                                    value={obj.id}
                                                    tick={obj.checked}
                                                    onCheck={(e) => currentComponent.onCheckBoxChange(obj.id, e.target.checked)}
                                                />
                                            </td>
                                            <td>
                                                {obj.agencyRouteId}
                                            </td>
                                            <td >
                                                {obj.shortName}
                                            </td>
                                            <td >
                                                {depotname}
                                            </td>
                                            <td>
                                                {obj.status}
                                            </td>
                                            <td>
                                                {SessionUtils.isChildAgency() ?<Link to={{
                                                        pathname: `/edit-route/${obj.id}`,
                                                        state: {
                                                            isParent: true
                                                        }
                                                        }} className="active" title="View Route"><i className="fe fe-eye"></i></Link>:
                                                        <Link to={{
                                                            pathname: `/edit-route/${obj.id}`,
                                                            state: {
                                                                isParent: false
                                                            }
                                                            }} className="active" title="Edit Route"><i className="fe fe-edit"></i></Link>}
                                            </td>
                                        </tr>
                                    );
                                })
                                }
                            </tbody>
                        </Table>
                        {paginationtransaction} {currentPage && (
                            <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                                Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                                <span className="font-weight-bold">{totalPages}</span>
                            </span>
                        )}
                    </React.Fragment>
                </Page.Content>
            </BaseLayout>
        );
    };
}
    
class CheckBox extends React.Component {
    
    render(){
        var name = this.props.name;
        var value = this.props.value;
        var tick = this.props.tick;
        var onCheck = this.props.onCheck;
        return (
            <input
                name={name}
                type="checkbox"
                value={value}
                checked={tick || false}
                onChange={onCheck}
            />
        );
    }
}

export default Routelist;