import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';

export const InventoryAPI = {
    getDevice,
    getDeviceSerialIdAscList,
    getDeviceList,
    getAPCSDeviceList,
    postDevice,
    putDeviceState,
    putDevice,
    putAPCSDevice,
    postApnMsg
}

function getDevice(callbackSuccess, callbackError) {
    API.get("/apis/inventory/device?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch devices. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getDeviceSerialIdAscList(callbackSuccess, callbackError) {
    API.get("/apis/inventory/device?agencyCode=" + localStorage.getItem('agencyname') + "&state=UNASSIGNED&sort=deviceSerialId&deviceSerialId.dir=asc",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch devices. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}


function getDeviceList(url, callbackSuccess, callbackError) {
    API.get(url,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch devices. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getAPCSDeviceList(callbackSuccess, callbackError) {
    API.get("/apis/apcs/inventory?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch APCS devices. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postDevice(obj, callbackSuccess, callbackError) {
    API.post('/apis/inventory/device', JSON.stringify(obj), 
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add device. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function postApnMsg(requestBody, callbackSuccess, callbackError) {
    API.post('/apis/notification', JSON.stringify(requestBody), 
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to send apn. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putDeviceState(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/inventory/device/' + id + '/state', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit device state. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putDevice(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/inventory/device/' + id, JSON.stringify(obj),
        (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit device. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putAPCSDevice(id, obj, callbackSuccess, callbackError) {
    API.put('/apis/apcs/inventory/' + id, JSON.stringify(obj),
        (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit apcs device. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}


