// @flow
import React from "react";
import { Page } from "tabler-react";
import { Alert, Button } from 'react-bootstrap';
import "./alert.js";
import BaseLayout from "./BaseLayout";
import { AgencyAPI } from "../apis/AgencyAPI.js";
import Select from 'react-select';

const optionsStatus=[
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' }
];

const valueFromId = (opts, id) => opts.find(o => o.value === id);
        

class EditSchedule extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showMessage: false,
            responseMessage: '',
            isloading: false,
            fields: {},
            errors: {},
            routeList: [],
            selectedRoute: {},
            scheduleId: this.props.location.state.schedule.agencyMemoId,
            editFields: {},
            status: this.props.location.state.schedule.status
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    handleDismiss() {
        this.setState({ showMessage: false });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (!this.state.scheduleId) {
            formIsValid = false;
            errors["scheduleId"] = "Cannot be empty";
        }
        if (!this.state.selectedRoute) {
            formIsValid = false;
            errors["routeList"] = "Cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }


    dropdownvalid(item, type){
      
        switch (type) {
            case 'status': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ status: item});
            }
            case 'routeList': {
                let fields = this.state.fields;
                fields[type] = item;
                return this.setState({ selectedRoute: item});
            }
           default:    
        }
    }
    
    submitSchedule() {
        if (this.handleValidation()) {
            let obj = {}
            obj.trips = [];
            let route = {};
            route.routeId = this.state.selectedRoute.value;
            obj.trips.push(route);
            obj.agencyMemoId = this.state.scheduleId;
            obj.agencyCode = localStorage.getItem('agencyname') ;
            obj.status = this.state.status;

            let currentComponent = this;
            currentComponent.setState({ isloading: true });
            AgencyAPI.putMemo(this.props.location.state.schedule.id, obj,
                (response) => {
                    currentComponent.setState({
                            responseMessage:'Schedule edited successfully!',
                            isloading: false,
                            showMessage:true,
                            params: ''
                    });
                },
                (error) => {
                    currentComponent.setState({isloading: false,responseMessage:error.message,showMessage:true  });
                }
            );
        }
    }


    loadRoutes() {
        let currentComponent = this;
        let routedata = '';
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function (event) {
            var db = event.target.result;
            var routes = db.transaction('routes', 'readwrite').objectStore("routes");
            routedata = routes.getAll();
            // get data from Stops object
            routes.getAll().onsuccess = function (event) {
                let selRoute = {};
                let routeListTemp = routedata.result.map(element => {
                    if(currentComponent.props.location.state.schedule.trips[0].agencyRouteId === element.agencyRouteId) {
                        selRoute = { value: element.id, label: element.agencyRouteId };
                    }
                    return { value: element.id, label: element.agencyRouteId };
                });
                currentComponent.setState({ routeList: [{ value: '', label: 'Select Route' }].concat(routeListTemp),
                    selectedRoute: selRoute });
            };
        }


    }

    componentDidMount() {
        this.loadRoutes();
    }

    componentWillReceiveProps() {

    }

    goBack(e) {
        this.props.history.push('/schedule-list');
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    valid(item, type) { 
        let itemValue = item.target.value;
        
        switch (type) {
            case 'scheduleId': {
                let fields = this.state.fields;
                fields[type] = itemValue; 
                return this.setState({ scheduleId: itemValue,fields })
            }
            
          default:    
        }
    }
    

    render() {
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;

        if (this.state.showMessage) {
            fadeinout = (
                <div className="col-md-5">
                    <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                        <h4>{this.state.responseMessage}</h4>
                        <p>
                            <Button  onClick={(event) => this.goBack(event)}  className="btn-primary">View</Button>
                            <span> or </span>
                            <Button className="btn-info" onClick={this.handleDismiss}>Close</Button>
                        </p>
                    </Alert>
                </div>

            )
        }

        return (
            <BaseLayout>
                <Page.Content>

                    <React.Fragment>
                        <form className="card">
                            <div className="card-body">
                                {fadeinout}
                                <h3 className="card-title">Edit Schedule</h3>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Schedule Id<span className="form-required">*</span></label>
                                            <input type="text" className="form-control" value={this.state.scheduleId} maxLength="10" onChange={(item) => this.valid(item, 'scheduleId')} placeholder="Schedule Id"></input>
                                            <span style={{ color: "red" }}>{this.state.errors["scheduleId"]}</span>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Route<span className="form-required">*</span></label>
                                            <Select options={this.state.routeList} value={this.state.selectedRoute}  onChange={(item) => this.dropdownvalid(item, 'routeList')}  />
                                            <span style={{ color: "red" }}>{this.state.errors["routeList"]}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Status<span className="form-required">*</span></label>
                                            <Select options={optionsStatus} value={valueFromId(optionsStatus, this.state.status,this.state.status)} onChange={(item) => this.dropdownvalid(item.value, 'status')}  />
                                            <span style={{color: "red"}}>{this.state.errors["status"]}</span> 
                                    </div>
                                    </div>

                                </div>
                               <div className="row">
                                   
                               <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label"> &nbsp; </label>
                                            <button type="button" onClick={() => this.submitSchedule()} hidden={this.state.isloading} className="btn btn-primary">Save
                                </button>
                                            {this.state.isloading &&
                                                <img src="./images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            <a href="/schedule-list"> <Button type="button" color="primary mb-2 mr-2 float-right"> View </Button></a>
                                        </div>
                                    </div></div>     
                            </div>
                        </form>
                    </React.Fragment>

                </Page.Content>
            </BaseLayout>
        );
    }

}

export default EditSchedule;