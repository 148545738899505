
// @flow
import React from "react";
import { Button,Page } from "tabler-react";
import { Alert } from 'react-bootstrap';
import ReactDropdownAutoComplete from "./autocomplete";
import Select from 'react-select';
import BaseLayout from "./BaseLayout";
import "./alert.js";
import "../App.css";
import { AgencyAPI } from "../apis/AgencyAPI";
class CreateRoute extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startDate: new Date(),
            DepotList:[],
            StopList: [],
            validationError: "",
            routeid:'',
            routenumber:'',
            routeshortname:'',
            routelongname:'',
            depoid:'',
            RoutetypeList:[],
            routetype:'',
            routetypename:'',
            stopText:'',
            isLoading: false,
            isloadingreset :false,
            isloadingsaveroute :false,
            name:'',
            startstage:'',
            endstage:'',
            numofstops:'',
            showmessage: false,
            errors: {},
            authkey: localStorage.getItem('authkey'),
            stopsAddDone: false,
            routeAddDone: false,
            useFareRules: false
        };
        this.state.products = [];
        this.state.rows = [];
        this.state.stops = [];
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    handleUseFareRulesChange(e){
        let isChecked = e.target.checked;
        if(isChecked === true){
            if (window.confirm("Are you sure you want to use Fare Master?")) {
                isChecked = true;
            } else{
                isChecked = false;
            }
        }
           
        this.setState({ useFareRules: isChecked });
    }
    
      
    componentDidMount() {
        let thisstop = this;
        thisstop.setState({ isLoading: true });
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function(event) {
            var db          = event.target.result;
            var depots      = db.transaction('depot', 'readwrite').objectStore("depot");
            var depotdata  = depots.getAll();
            var stops      = db.transaction('stops', 'readwrite').objectStore("stops");
            var stopsdata  = stops.getAll();

            depots.getAll().onsuccess = function(event) {
                let DepotListFromInDB = depotdata.result.map(depots => {
                    return {
                        value: depots.id,
                        label: depots.depotname
                    };
                });
                thisstop.setState({ DepotList: [{ value: '', label: 'Select Depot' }].concat(DepotListFromInDB) });
            };

            stops.getAll().onsuccess = function(event) {
                let StopListFromInDB = stopsdata.result.map(stops => { return { value: stops.id, display: stops.longName }; });
                thisstop.setState({ StopList: [{ value: '', display: 'Select Stop' }].concat(StopListFromInDB) });
            };
        };
        AgencyAPI.getRouteType(
            (response) => {
                thisstop.setState({ isLoading: false });
                let RoutetypeListFromDB = response.data.data.map(routetype => {
                    return {
                        value: routetype.id,
                        label: routetype.name
                    };
                });
                thisstop.setState({ RoutetypeList: [{ value: '', label: 'Select Route Type' }].concat(RoutetypeListFromDB) });
            },
            (error) => {
                thisstop.setState({ isLoading: false  });
            }
        );
    }
    
    dropdownvalid(item, label, type){
        let errors = {};
        switch (type) {
            case 'depoid': {
                if(item === ""){
                    errors['depoid'] = "Cannot be empty";
                }
                this.setState({ depoid: item, errors: errors });
                break
            }
            case 'routetype': {
                if(item === ""){
                    errors['routetype'] = "Cannot be empty";
                }
                return this.setState({ routetype: item, routetypename: label, errors: errors });
            }
            case 'startstage': {
                if(item === ""){
                    errors['startstage'] = "Cannot be empty";
                }
                return this.setState({ startstage: item, errors: errors });
            }
            case 'endstage': {
                if(item === ""){
                    errors['endstage'] = "Cannot be empty";
                }
                return this.setState({ endstage: item, errors: errors });
            }
            default:    
        }
    }
    
    valid(item, type) {
        let itemValue = item.target.value;
        let errors = {};
        switch (type) {
            case 'routenumber': {
                if(itemValue === ""){
                    errors['routenumber'] = "Cannot be empty";
                }
                this.setState({ routenumber: itemValue, errors: errors });
                break
            }
            case 'numofstops': {
                if(itemValue === ""){
                    errors['numofstops'] = "Cannot be empty";
                }
                return this.setState({ numofstops: itemValue, errors: errors });
            }
            default:
        }
    }
    
    handleValidation(){
        let errors = {};
        let formIsValid = true;
        if(this.state.routenumber === ""){
            formIsValid = false;
            errors["routeNumber"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.routetype === ""){
            formIsValid = false;
            errors["routetype"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.depoid === ""){
            formIsValid = false;
            errors["depoid"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.startstage === ""){
            formIsValid = false;
            errors["startstage"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.endstage === ""){
            formIsValid = false;
            errors["endstage"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(this.state.numofstops === ""){
            formIsValid = false;
            errors["numofstops"] = "Cannot be empty";
            this.setState({errors: errors});
        }
        if(Object.keys(this.state.errors).length !== 0){
            if(this.state.errors["name"] || this.state.errors["stagecode"] || this.state.errors["km"] || this.state.errors["fare"]){
                formIsValid = false;
            }
        }
        return formIsValid;
    }
    
    createOrFetchStops(){
        // eslint-disable-next-line
        this.state.stops = [];
        // eslint-disable-next-line
        this.state.rows = [];
        let currentComponent = this;
        let stopdata = {};
        let stopcreateobj = {};
        if(this.handleValidation()){
            this.setState({isloadingsaveroute: true, routeAddDone: false});
            for(var i = 0; i < currentComponent.state.products.length; i++) {
                var element = currentComponent.state.products[i];
                stopcreateobj = {
                    "agencyStopId":element.id,
                    "agencyCode":localStorage.getItem('agencyname'),
                    "longName":element.name.toUpperCase(),
                    "shortName":element.stagecode.toUpperCase(),
                    "latitude":"0.0",
                    "longitude":"0.0"
                };
                currentComponent.postStopsCall(stopcreateobj, stopdata);
                stopcreateobj = {};
                stopdata = {};
            }
        }
        return;
    }

    postStopsCall(stopcreateobj, stopdata) {
        let currentComponent = this;
        AgencyAPI.postStopsCreateOrFetch(stopcreateobj,
            (response) => {
                for(var j in currentComponent.state.products){
                    var stop = currentComponent.state.products[j];
                    let stopsArr = currentComponent.state.stops;
                    if(stop.id === response.data.data.agencyStopId){
                        stopdata = {
                            "stopId":response.data.data.id,
                            "sequence": stop.stageno,
                            "distanceFromPreviousStop": stop.km,
                            "distanceFromOrigin":stop.km
                        };
                        stopsArr.push(stopdata);
                        currentComponent.setState({stops: stopsArr}, currentComponent.fetchStops());
                    }
                }
                
            }
        );
    }

    fetchStops() {
        let currentComponent = this;
        if(currentComponent.state.stops.length === currentComponent.state.products.length){
            currentComponent.state.rows = currentComponent.state.stops;
            const dbName = "RIDLR AFCS";
            const version = 1; //versions start at 1
            var request = indexedDB.open(dbName, version);
            request.onsuccess = function(event) {
                var db         = event.target.result;
                var stops      = db.transaction('stops', 'readwrite').objectStore("stops");
                var clear =  stops.clear();
                clear.onsuccess = function(event) {
                    //  stopsData.transaction.oncomplete = function(event) {
                    AgencyAPI.getStops(
                        (response) => {
                            let stopsFromApi = response.data.data.map((stops) => {
                                return { id: stops.id, agencyStopId: stops.agencyStopId, shortName: stops.shortName, longName: stops.longName, latitude: stops.latitude, longitude: stops.longitude, createdAt: stops.createdAt, status: stops.status, parent: stops.parent };
                            });
                            // Store values in the newly created objectStore.
                            var stopObjectStore = db.transaction("stops", "readwrite").objectStore("stops");
                            //stopObjectStore.clear();
                            stopsFromApi.forEach(function (stops) {
                                stopObjectStore.add(stops);
                            });
                            currentComponent.createRoute();
                            return;
                        }
                    );
                };
            };
            currentComponent.setState({
                showmessage: true,
                responsemessage:'Stops Added!'
            });
        }
        return;
    }
    
    createRoute(){
        let currentComponent = this;
        if(this.handleValidation()){
            this.setState({isloadingsaveroute: true, routeAddDone: false});
            let obj = {};
            obj.agencyCode = localStorage.getItem('agencyname');
            obj.agencyRouteId = this.state.routenumber;
            obj.longName = (this.state.startstage+" To "+this.state.endstage).toUpperCase();
            obj.shortName = (this.state.startstage.substring(0, 3)+" To "+this.state.endstage.substring(0, 3)).toUpperCase();
            obj.depot = this.state.depoid;
            obj.type = this.state.routetype;
            obj.isAutoFareGeneration =this.state.useFareRules;
            obj.stops = this.state.rows.sort(function(obj1, obj2) {
                // Ascending: first age less than the previous
                return obj1.sequence - obj2.sequence;
            });
            //Create Route
            AgencyAPI.postRoutes(obj,
                (response) => {
                    let routeid = response.data.data.id;
                    const dbName = "RIDLR AFCS";
                    const version = 1; //versions start at 1
                    var request = indexedDB.open(dbName, version);
                    request.onsuccess = function(event) {
                        var db         = event.target.result;
                        var routes      = db.transaction('routes', 'readwrite').objectStore("routes");
                        var clear =  routes.clear();
                        clear.onsuccess = function(event) {
                            AgencyAPI.getRoutes(
                                (response) => {
                                    let RouteDataFromApi = response.data.data.map((route) => {
                                        return { id: route.id, agencyRouteId: route.agencyRouteId, shortName: route.shortName, longName: route.longName, routeLength: route.routeLength, stops: route.stops, depot: route.depot, status: route.status, type: route.type, createdAt: route.createdAt, parent: route.parent };
                                    });
                                    // Store values in the newly created objectStore.
                                    var routeObjectStore = db.transaction("routes", "readwrite").objectStore("routes");
                                    routeObjectStore.clear();
                                    RouteDataFromApi.forEach(function (routes) {
                                        routeObjectStore.add(routes);
                                    });
                                    return;
                                }
                            );
                        };
                    };
                    currentComponent.setState({
                        showmessage: true,
                        isloadingsaveroute: false,
                        responsemessage:'Route Created!',
                        routeAddDone: true,
                        routeid: routeid
                    });
                    currentComponent.props.history.push(
                        {
                            pathname: "/edit-route/"+routeid,
                            state: { isParent: null }
                          });
                    return;
                },
                (error) => {
                    currentComponent.setState({
                        showmessage: true,
                        isloadingsaveroute: false,
                        routeAddDone: false,
                        responsemessage:error.message
                    });
                    return;
                }
            );
        }
    }
    
    handleRowAdd(product) {
        var index = this.state.products.indexOf(product);
        var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
        var new_index = parseInt(index, 10) + 1;
        var new_sequence = new_index + 1;
        var new_product = {
            id: id,
            name: '',
            stagecode: '',
            km: '',
            stageno: new_sequence,
            stopId: '',
            stopName: '',
            sequenceNumber: new_sequence,
            distance: ''
        };
        this.state.products.splice(new_index, 0, new_product);
        this.setState({products: this.state.products, numofstops: this.state.products.length});
        for(var i = 0; i < this.state.products.length; i++){
            var next_product = this.state.products[i];
            next_product['stageno'] = i + 1;
            next_product['sequenceNumber'] = i + 1;
            let numofstops = parseInt(this.state.products.length, 10);
            let errors = {};
            errors['id'] = next_product['id'];
            errors['name'] = "";
            errors['stagecode'] = "";
            errors['km'] = "";
            if(next_product['stageno'] === 1){
                if(next_product['name'] === ""){
                    errors['startstage'] = "Cannot be empty";
                }
                this.setState({startstage: next_product['name']});
            } else if(next_product['stageno'] === numofstops){
                if(next_product['name'] === ""){
                    errors['endstage'] = "Cannot be empty";
                }
                this.setState({endstage: next_product['name']});
            }
            if(next_product['name'] === ""){
                errors['name'] = "Cannot be empty";
            }
            if(next_product['stagecode'] === ""){
                errors['stagecode'] = "Cannot be empty";
            }
            if(next_product['km'] === ""){
                errors['km'] = "Cannot be empty";
            }
            this.setState({errors: errors});
        }
    };
    
    handleRowDel(product) {
        var index = this.state.products.indexOf(product);
        this.state.products.splice(index, 1);
        this.setState({products: this.state.products, numofstops: this.state.products.length});
        for(var i = 0; i < this.state.products.length; i++){
            var next_product = this.state.products[i];
            next_product['stageno'] = i + 1;
            next_product['sequenceNumber'] = i + 1;
            let numofstops = parseInt(this.state.products.length, 10);
            let errors = {};
            errors['id'] = next_product['id'];
            errors['name'] = "";
            errors['stagecode'] = "";
            errors['km'] = "";
            if(next_product['stageno'] === 1){
                if(next_product['name'] === ""){
                    errors['startstage'] = "Cannot be empty";
                }
                this.setState({startstage: next_product['name']});
            } else if(next_product['stageno'] === numofstops){
                if(next_product['name'] === ""){
                    errors['endstage'] = "Cannot be empty";
                }
                this.setState({endstage: next_product['name']});
            }
            if(next_product['name'] === ""){
                errors['name'] = "Cannot be empty";
            }
            if(next_product['stagecode'] === ""){
                errors['stagecode'] = "Cannot be empty";
            }
            if(next_product['km'] === ""){
                errors['km'] = "Cannot be empty";
            }
            this.setState({errors: errors});
        }
    };
    
    handleAddEvent(evt) {
        // eslint-disable-next-line
        this.state.products = [];
        // eslint-disable-next-line
        this.state.fares = {};
        let stagename;
        let firststage = this.state.startstage;
        let endstage = this.state.endstage;
        let numofstops = parseInt(this.state.numofstops, 10);
        let km;
        if(this.handleValidation()){
            this.setState({isloadingreset: true, stopsAddDone: false});
            for (let i = 1; i <= numofstops; i++) {
                if(i === 1){
                    stagename = firststage;
                    km = (0).toString();
                } else if(i === numofstops){
                    stagename = endstage;
                    km = "";
                } else {
                    stagename = '';
                    km = "";
                }
                var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
                var product = {
                    id: id,
                    name: stagename.toUpperCase(),
                    stagecode: stagename.toUpperCase().substring(0, 3),
                    km: km,
                    stageno: i,
                    stopId: stagename.toUpperCase().substring(0, 3),
                    stopName: stagename,
                    sequenceNumber: i,
                    distance: km
                };
                this.state.products.push(product);
            }
            this.setState({products: this.state.products, numofstops: this.state.products.length});
            this.setState({isloadingreset: false, stopText: 'Add Stops', stopsAddDone: true});
        }
    }
     
    handleDismiss() {
        this.setState({ showmessage: false });
    }
    
    handleRowUpdate(item) {
        var current = this;
        let errors = {};
        errors['id'] = item.id;
        errors['name'] = "";
        errors['stagecode'] = "";
        errors['km'] = "";
        var newProducts = this.state.products.map((product, index) => {
            for (var key in product) {
                if(product.id === item.id) {
                    product[key] =  item[key];
                    let numofstops = parseInt(current.state.products.length, 10);
                    if(product['stageno'] === 1){
                        if(product['name'] === ""){
                            errors['startstage'] = "Cannot be empty";
                        }
                        current.setState({startstage: product['name']});
                    } else if(product['stageno'] === numofstops){
                        if(product['name'] === ""){
                            errors['endstage'] = "Cannot be empty";
                        }
                        current.setState({endstage: product['name']});
                    }
                    if(product['name'] === ""){
                        errors['name'] = "Cannot be empty";
                    }
                    if(product['stagecode'] === ""){
                        errors['stagecode'] = "Cannot be empty";
                    }

                    if(product['km'] === "") {
                        errors['km'] = "Cannot be empty";
                    }else if (isNaN(product['km'])) {
                        errors['km'] = "Should be valid number"
                        product['km'] = "";
                    }
                    var next_product = current.state.products[index + 1];
                    var previous_product = current.state.products[index - 1];
                    if(next_product !== undefined){
                        if(next_product["km"] !== "" && product["km"] >= next_product["km"]){
                            errors["km"] = "Should be less than Next Stop";
                        }
                    }
                    if(previous_product !== undefined){
                        if(previous_product["km"] !== "" && product["km"]<= previous_product["km"]){
                            errors["km"] = "Should be greater than Previous Stop";
                        }
                    }
                }
            }
            return product;
        });
        this.setState({products: newProducts, errors: errors, numofstops: this.state.products.length});
    };
    
    handleProductTable(evt) {
        var item;
        item = {
            id: evt.target.id,
            name: evt.target.name,
            value: evt.target.value
        };
        var current = this;
        let errors = {};
        errors['id'] = item.id;
        errors['name'] = "";
        errors['stagecode'] = "";
        errors['km'] = "";
        var newProducts = this.state.products.map((product, index) => {
            for (var key in product) {
                if(key === item.name && product.id === item.id) {
                    product[key] =  item.value;
                    let numofstops = parseInt(current.state.products.length, 10);
                    if(product['stageno'] === 1){
                        if(product['name'] === ""){
                            errors['startstage'] = "Cannot be empty";
                        }
                        current.setState({startstage: product['name']});
                    } else if(product['stageno'] === numofstops){
                        if(product['name'] === ""){
                            errors['endstage'] = "Cannot be empty";
                        }
                        current.setState({endstage: product['name']});
                    }
                    if(product['name'] === ""){
                        errors['name'] = "Cannot be empty";
                    }
                    if(product['stagecode'] === ""){
                        errors['stagecode'] = "Cannot be empty";
                    }
                    if(product['km'] === "") {
                        errors['km'] = "Cannot be empty";
                    }else if (isNaN(product['km'])) {
                        errors['km'] = "Should be valid number"
                        product['km'] = "";
                    }
                    var next_product = current.state.products[index + 1];
                    var previous_product = current.state.products[index - 1];
                    if(next_product !== undefined){
                        if(next_product["km"] !== "" && product["km"] >= next_product["km"]){
                            errors["km"] = "Should be less than Next Stop";
                        }
                    }
                    if(previous_product !== undefined){
                        if(previous_product["km"] !== "" && product["km"]<= previous_product["km"]){
                            errors["km"] = "Should be greater than Previous Stop";
                        }
                    }
                }
            }
            return product;
        });
        this.setState({products: newProducts, errors: errors, numofstops: this.state.products.length});
    };
    
    render() {
        var startkey = 0;
        var endkey = 0;
        var stylescope = {
            splitterStyle: {
                margin: 5
            }
        };
        let fadeinout;
        if (this.state.showmessage) {
            fadeinout = (
                <Alert bsStyle="success" onDismiss={this.handleDismiss}>
                    <h4>{this.state.responsemessage}</h4>
                </Alert>
            );
        }
        return (
            <BaseLayout>
                <Page.Content>
                    {fadeinout}
                    <a href="/route-list" className="floatright"><Button className="btn-primary">View Routes</Button></a>
                    <Page.Header title="Route & Fares Setup" />
                    <React.Suspense fallback={<div>Loader...</div>}>
                        <form className="card">
                            {!this.state.isLoading &&
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Route Number<span className="form-required">*</span></label>
                                            <input type="text" className="form-control upercase" value={this.state.routenumber}   onChange={(item) => this.valid(item, 'routenumber')} placeholder="Route Number"></input>
                                            <span style={{color: "red"}}>{this.state.errors["routeNumber"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Select Depot<span className="form-required">*</span></label>
                                            <Select options={this.state.DepotList}  onChange={(item) => this.dropdownvalid(item.value, item.label, 'depoid')}  />
                                            <span style={{color: "red"}}>{this.state.errors["depoid"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Select Route Type<span className="form-required">*</span></label>
                                            <Select options={this.state.RoutetypeList} onChange={(item) => this.dropdownvalid(item.value, item.label, 'routetype')}  />
                                            <span style={{color: "red"}}>{this.state.errors["routetype"]}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Start Stop<span className="form-required">*</span></label>
                                            <ReactDropdownAutoComplete
                                                getItemValue={item => item.display}
                                                className="form-control upercase"
                                                id="concession"
                                                name="concession"
                                                placeholder="Select Start Point"
                                                data={this.state.StopList || []}
                                                renderItem={item => (
                                                    <div
                                                    key={startkey++}
                                                    role="button"
                                                    tabIndex="-1"
                                                    onClick={(val) => this.dropdownvalid(val, val, 'startstage')}
                                                    >{item.display}</div>
                                                )}
                                                icon="search"
                                                iconColor="#ff000"
                                                iconClick={() => { /* TODO */ }}
                                                value={this.state.startstage}
                                                onChange={(val) =>{ this.dropdownvalid(val, val, 'startstage');}} 
                                                onEnter={() => { /* TODO */ }}
                                                
                                            />
                                            <span style={{color: "red"}}>{this.state.errors["startstage"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">End Stop<span className="form-required">*</span></label>
                                            <ReactDropdownAutoComplete
                                                getItemValue={item => item.display}
                                                className="form-control upercase"
                                                id="concession"
                                                name="concession"
                                                placeholder="Select End Point"
                                                data={this.state.StopList || []}
                                                renderItem={item => (
                                                    <div
                                                    key={endkey++}
                                                    role="button"
                                                    tabIndex="-1"
                                                    onClick={(val) => this.dropdownvalid(val, val, 'endstage')}
                                                    >{item.display}</div>
                                                )}
                                                icon="search"
                                                iconColor="#ff000"
                                                iconClick={() => { /* TODO */ }}
                                                value={this.state.endstage}
                                                onChange={(val) =>{ this.dropdownvalid(val, val, 'endstage');}} 
                                                onEnter={() => { /* TODO */ }}
                                            />
                                            <span style={{color: "red"}}>{this.state.errors["endstage"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label">Number Of Stops<span className="form-required">*</span></label>
                                            <input type="text" className="form-control" value={this.state.numofstops} onChange={(item) => this.valid(item, 'numofstops')} placeholder="No Of Stops"></input>
                                            <span style={{color: "red"}}>{this.state.errors["numofstops"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">&nbsp;</label>
                                        <div className="form-group">
                                            <button type="button" onClick={this.handleAddEvent.bind(this)} className="btn btn-primary pull-right">Create Route
                                            {this.state.isloadingreset &&
                                                <img src="/images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                            }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {this.state.isLoading &&
                            <div className="card-footer">
                                <div className="row">
                                    <img style={{margin: 'auto'}} src={'/images/spinner-loder.gif'} alt="spinner-loder" />
                                </div>
                            </div>        
                            }
                            {this.state.stopsAddDone &&
                            <div className="card-footer">
                                <h6>Add Stops</h6>
                                <div className="row">
                                    <ProductTable onProductTableUpdate={this.handleProductTable.bind(this)} onRowUpdate={this.handleRowUpdate.bind(this)} handleAddEvent={this.handleAddEvent.bind(this)} onRowAdd={this.handleRowAdd.bind(this)} onRowDel={this.handleRowDel.bind(this)} products={this.state.products} errors={this.state.errors}/>
                                </div>
                                <div className="col-md-4 " style={{float: 'left'}}>
                                    <div>
                                        <label className="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" className="custom-control-input" onChange={e => this.handleUseFareRulesChange(e)} name="example-checkbox1" value="option1" checked={this.state.useFareRules}></input>
                                            <span className="custom-control-label">Use Fare Rules </span>
                                        </label>
                                    </div>
                                </div>
                                
                                <div className="col-md-8 text-right" style={{margin: '0 0 0 40px', padding: '0'}}>
                                    <button type="button" onClick={() => this.createOrFetchStops()} className="btn btn-primary">Save & Add Fares
                                        {this.state.isloadingsaveroute &&
                                            <img src="/images/download.gif" style={stylescope.splitterStyle} alt="Loading..." />
                                        }
                                    </button>
                                </div>
                            </div>
                            }
                        </form>
                    </React.Suspense>
                </Page.Content>
            </BaseLayout>
        );
    }
}

class ProductTable extends React.Component {

    render() {
        var onProductTableUpdate = this.props.onProductTableUpdate;
        var onRowUpdate = this.props.onRowUpdate;
        var rowAdd = this.props.onRowAdd;
        var rowDel = this.props.onRowDel;
        var errors = this.props.errors;
        var product = this.props.products.map(function(product) {
            return (<ProductRow onProductTableUpdate={onProductTableUpdate} onRowUpdate={onRowUpdate.bind(this)} product={product} onAddEvent={rowAdd.bind(this)} onDelEvent={rowDel.bind(this)} errors={errors} key={product.id}/>);
        });
        return (
            <div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>Stop Long Name</th>
                            <th>Stop Short Name</th>
                            <th>Kilometer</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product}
                    </tbody>
                </table>
            </div>
        );
    }
}

class ProductRow extends React.Component {
  
    onAddEvent() {
        this.props.onAddEvent(this.props.product);
    }
  
    onDelEvent() {
        this.props.onDelEvent(this.props.product);
    }
    
    onRowUpdate() {
        this.props.onRowUpdate(this.props.product);
    }
    
    render() {
        return (
            <tr className="eachRow" >
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "stageno",
                    value: this.props.product.stageno,
                    id: this.props.product.id,
                    readonly: true
                }}/>
                <AutocompletestopCell onRowUpdate={this.onRowUpdate.bind(this)} errors={this.props.errors} product={this.props.product} cellData={{
                    "type": "name",
                    value: this.props.product.name,
                    id: this.props.product.id
                }}/>
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "stagecode",
                    value: this.props.product.stagecode,
                    id: this.props.product.id,
                    readonly: false
                }}/>
                <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} errors={this.props.errors} cellData={{
                    type: "km",
                    value: this.props.product.km,
                    id: this.props.product.id,
                    readonly: false
                }}/>
                <td className="del-cell">
                    <input type="button" onClick={this.onDelEvent.bind(this)} value="-" className="del-btn"/>&nbsp;
                    <input type="button" onClick={this.onAddEvent.bind(this)} value="+" className="add-btn"/>
                </td>
            </tr>
        );
    }  
}

class EditableCell extends React.Component {

    render() {
        return (
            <td>
                <input readOnly={this.props.cellData.readonly} type='text' name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value || ''} onChange={this.props.onProductTableUpdate} className="form-control upercase"/>
                <span style={{color: "red"}}>
                    {this.props.errors.id === this.props.cellData.id && 
                        this.props.errors[this.props.cellData.type]
                    }
                </span>
            </td>
        );
    }
}

class AutocompletestopCell extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            StopList: []
        };
    }
    
    onProductTableUpdate(value) {
        this.props.cellData.value = value.toUpperCase();
        this.props.product.name = value.toUpperCase();
        this.props.product.stagecode = value.toUpperCase().substring(0, 3);
        this.props.product.stopId = value.toUpperCase().substring(0, 3);
        this.props.product.stopName = value;
        this.props.onRowUpdate(this.props.product);
    }
    
    componentDidMount() {
        let thisstop = this;
        const dbName = "RIDLR AFCS";
        const version = 1; //versions start at 1
        var request = indexedDB.open(dbName, version);
        request.onsuccess = function(event) {
            var db          = event.target.result;
            var stops      = db.transaction('stops', 'readwrite').objectStore("stops");
            var stopsdata  = stops.getAll();
            stops.getAll().onsuccess = function(event) {
                let StopListFromInDB = stopsdata.result.map(stops => { return { value: stops.id, display: stops.longName }; });
                thisstop.setState({ StopList: [{ value: '', display: 'Select Stop' }].concat(StopListFromInDB) });
            };
        };
    }

    render() {
        var key = 0;
        return (
            <td>
                <ReactDropdownAutoComplete
                    getItemValue={item => item.display}
                    className="form-control upercase"
                    id={this.props.cellData.id}
                    name={this.props.cellData.type}
                    placeholder="Enter Stop Name"
                    data={this.state.StopList || []}
                    renderItem={item => (
                        <div
                        key={key++}
                        role="button"
                        tabIndex="-1"
                        onClick={(val) => this.onProductTableUpdate(val)}
                        >{item.display}</div>
                    )}
                    icon="search"
                    iconColor="#ff000"
                    value={this.props.cellData.value}
                    onChange={(val) => this.onProductTableUpdate(val)}
                    onEnter={() => { /* TODO */ }}
                />
                <span style={{color: "red"}}>
                    {this.props.errors.id === this.props.cellData.id && 
                        this.props.errors[this.props.cellData.type]
                    }
                </span>
            </td>
        );
    }
}
  
export default CreateRoute;
