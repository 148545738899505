import API from '../apis/API';
import MessageResponse from '../enums/MessageResponse';
import { ViewUtils } from '../utils/ViewUtils';

export const ETIMCommunicator = {
    getCOMList,
    postConnectOn,
    postDownloadDataDeviceDetails,
    postDownloadDataDeviceData,
    postDownloadDataEraseData,
    postUploadData
};

function getCOMList(callbackSuccess, callbackError) {
    API.getText("http://localhost:8080/com_list",
            (response) => {
                if (response.data === "NO COM PORT DETECTED") {
                    response.setMsgHandler(ViewUtils.createMessageHandler('No ETIM found. Please connect ETIM device to PC via USB Port.', MessageResponse.ERROR));
                    callbackError(response);
                } else if (response.data.length > 0) {
                    let portsList = response.data.split(",").map(
                        port => {
                            return { value: port, label: port };
                        }
                    );
                    response.setData(portsList);
                    callbackSuccess(response);
                }
            },
            (error) => {
                error.setMsgHandler(ViewUtils.createMessageHandler('No ETIM found. Please connect ETIM device to PC via USB Port.', MessageResponse.ERROR));
                error.setMessage(error.msgHandler.props.message);
                callbackError(error);
            }
        );
}

function postConnectOn(command, callbackSuccess, callbackError) {
    API.postText("http://localhost:8080/connect_on", command,
        (response) => {
            if (response.data !== '') {
                if (response.data === command) {
                    response.setMsgHandler(ViewUtils.createMessageHandler('ETIM Device Connected Successfully!!', MessageResponse.SUCCESS));
                    callbackSuccess(response);
                } else {
                    response.setMsgHandler(deviceErrors(response.data));
                    callbackError(response);
                }
            } else {
                response.setMsgHandler(deviceErrors(response.data));
                callbackError(response);
            }
        },
        (error) => {
            error.setMsgHandler(ViewUtils.createMessageHandler('ETIM Connection API is down Please try after some time !', MessageResponse.ERROR));
            error.setMessage(error.msgHandler.props.message);
            callbackError(error);
        }
    );
}

function postDownloadDataDeviceDetails(callbackSuccess, callbackError) {
    postDownloadData('$B', callbackSuccess, callbackError);
}

function postDownloadDataDeviceData(callbackSuccess, callbackError) {
    postDownloadData('$D', callbackSuccess, callbackError);
}

function postDownloadDataEraseData(callbackSuccess, callbackError) {
    postDownloadData('$E', callbackSuccess, callbackError);
}

function postDownloadData(command, callbackSuccess, callbackError) {
    API.postText("http://localhost:8080/download_data", command,
        (response) => {
            let msgHandler = deviceErrors(response.data.trim());
            if(msgHandler !== null) {
                response.setMsgHandler(msgHandler);
                callbackError(response);
            } else {
                callbackSuccess(response);
            }
        },
        (error) => {
            error.setMsgHandler(ViewUtils.createMessageHandler('Download API is down Please try after some time !', MessageResponse.ERROR));
            error.setMessage(error.msgHandler.props.message);
            callbackError(error);
        }
    );
}

function postUploadData(command, callbackSuccess, callbackError) {
    API.postText("http://localhost:8080/upload_data", command, 
        (response) => {
            let msgHandler = deviceErrors(response.data.trim());
            if(msgHandler !== null) {
                response.setMsgHandler(msgHandler);
                callbackError(response);
            } else {
                callbackSuccess(response);
            }
        },
        (error) => {
            error.setMsgHandler(ViewUtils.createMessageHandler('Upload API is down Please try after some time !', MessageResponse.ERROR));
            error.setMessage(error.msgHandler.props.message);
            callbackError(error);
        }
    );
}

function deviceErrors(data) {
    let msgHandler = null;
    if (data !== '') {
        if (data.startsWith("SERIAL FAULT")) {
            msgHandler = ViewUtils.createMessageHandler('ETIM Device Not Connected', MessageResponse.ERROR);
        } else if (data.startsWith("ERROR IN TRANSMISSION OF FIRST BYTE >>")) {
            msgHandler = ViewUtils.createMessageHandler('ETIM Device Switched Off', MessageResponse.ERROR);
        } else if (data.startsWith("ERROR IN TRANSMISSION OF SECOND BYTE >>")) {
            msgHandler = ViewUtils.createMessageHandler('Invalid Request', MessageResponse.ERROR);
        } else if (data.startsWith("ERROR IN TRANSMISSION BYTE >>")) {
            msgHandler = ViewUtils.createMessageHandler('Unable to Process Data from ETIM', MessageResponse.ERROR);
        }
    } else {
        msgHandler = ViewUtils.createMessageHandler('No Response from ETIM', MessageResponse.ERROR);
    }
    return msgHandler;
}

