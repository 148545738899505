import API from './API';
import { ViewUtils } from '../utils/ViewUtils';
import { isNullOrUndefined } from 'util';

export const PassAPI = {
    getReadCardCommands,
    getProduct,
    getProductIdVariant,
    getPassDetails,
    postProduct,
    postCardInterpret,
    postProductCalculatePassFare,
    postProductIdVariant,
    putPrintedCard
}

function getReadCardCommands(callbackSuccess, callbackError) {
    API.get("/apis/pass/read/card/commands?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to read card commands. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getProduct(callbackSuccess, callbackError) {
    API.get("/apis/pass/product?agencyCode=" + localStorage.getItem('agencyname'),
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to get product. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getProductIdVariant(id, callbackSuccess, callbackError) {
    API.get("/apis/pass/product/" + id + "/variant",
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch product variant. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function getPassDetails(id, callbackSuccess, callbackError) {
    API.get("/apis/pass/details/" + localStorage.getItem('agencyname') + "/" + id,
        (response) => {
            callbackSuccess(response);
        },
        (error) => {
            if (callbackError !== undefined) {
                if(isNullOrUndefined(error.msgHandler)) {
                    error.setMsgHandler(ViewUtils.createMessageHandler("Failed to fetch pass details. Please try again after sometime"));
                    error.setMessage(error.msgHandler.props.message);
                }
                callbackError(error);
            }
        }
    );
}

function postProduct(obj, callbackSuccess, callbackError) {
    API.post('/apis/pass/product', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add product. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function postCardInterpret(obj, callbackSuccess, callbackError) {
    API.post("/apis/pass/card/interpret", JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add card interpret. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function postProductCalculatePassFare(obj, callbackSuccess, callbackError) {
    API.post('/apis/pass/product/calculate/pass/fare', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add product calculate pass fare. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function postProductIdVariant(id, obj, callbackSuccess, callbackError) {
    API.post('/apis/pass/product/'+id+'/variant', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to add product variant. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}

function putPrintedCard(obj, callbackSuccess, callbackError) {
    API.put('/apis/pass/printed/card', JSON.stringify(obj),
    (response) => {
        callbackSuccess(response);
    },
    (error) => {
        if (callbackError !== undefined) {
            if(isNullOrUndefined(error.msgHandler)) {
                error.setMsgHandler(ViewUtils.createMessageHandler("Failed to edit printed card. Please try again after sometime"));
                error.setMessage(error.msgHandler.props.message);
            }
            callbackError(error);
        }
    }
);
}